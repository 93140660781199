import { TEAM_MEMBER_ROLE_OPTIONS } from '../../team/constants';
import React from 'react';
import { TeamMember } from '../../team/types';

const UserDataReadOnly = ({ teamMember }: { teamMember: TeamMember }) => {
  return (
    <>
      <p>{teamMember.name}</p>
      <p>
        {
          TEAM_MEMBER_ROLE_OPTIONS.find(
            (role) => role.value === teamMember.role
          )?.text
        }
      </p>
      <p>{teamMember.email}</p>
      <p>{teamMember.telephone}</p>
    </>
  );
};

export default UserDataReadOnly;
