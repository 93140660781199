import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import { ProjectSetting } from '../types';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import {
  SelectInputField,
  TextInputField,
} from '../../../components/forms/fields';
import React, { useContext, useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react';
import {
  PROJECT_STAGES_BROAD_ROLES,
  PROJECT_STAGES_DEFAULT_ROLES,
  PROJECT_STAGES_LEADING_ROLE,
  PROJECT_STAGES_ROLES,
} from '../keys';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import Confirm from '../../../components/dialogs/Confirm';

type Props = {
  settings: ProjectSetting[];
  reloadSettings: () => Promise<void>;
  loading: boolean;
};

const settingKey = PROJECT_STAGES_ROLES;
const leadingRoleSettingKey = PROJECT_STAGES_LEADING_ROLE;
const defaultRolesSettingKey = PROJECT_STAGES_DEFAULT_ROLES;
const broadRolesSettingKey = PROJECT_STAGES_BROAD_ROLES;

const ProjectStagesSettings = (props: Props) => {
  const { settings, reloadSettings, loading } = props;

  const { performRequest: updateOrCreateSetting } = useAPIRequest(
    ProjectSettingResource.updateOrCreate
  );

  const { theme } = useContext(ThemeContext);

  const formMethods = useForm({
    defaultValues: {
      [`${settingKey}_add_new`]: '',
      roles: [],
      leadingRole: '',
      defaultRoles: [],
      broadRoles: [],
    },
  });

  const roles = settings.find(({ key }) => key === settingKey);

  const { setValue } = formMethods;

  useEffect(() => {
    setValue(
      'roles',
      settings.find(({ key }) => key === settingKey)?.value || []
    );
    setValue(
      'leadingRole',
      settings.find(({ key }) => key === leadingRoleSettingKey)?.value || ''
    );
    setValue(
      'defaultRoles',
      settings.find(({ key }) => key === defaultRolesSettingKey)?.value || []
    );
    setValue(
      'broadRoles',
      settings.find(({ key }) => key === broadRolesSettingKey)?.value || []
    );
  }, [settings, setValue]);

  const leadingRole = formMethods.watch('leadingRole');
  const defaultRoles = formMethods.watch('defaultRoles');

  return (
    <>
      <Form loading={loading} inverted={theme === themes.dark}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <TextInputField
            label="Add a new role"
            name={`${settingKey}_add_new`}
            submitHandler={() => {}}
            className="project-settings-field"
            {...formMethods}
          />{' '}
          <div style={{ alignSelf: 'flex-end' }}>
            <Button
              inverted={theme === themes.dark}
              icon="plus"
              basic
              style={{
                verticalAlign: 'text-top',
                fontSize: '1.1em',
              }}
              onClick={async () => {
                const roleName = formMethods.getValues(`${settingKey}_add_new`);
                const roleId = uuidv4();

                const value = { name: roleName, id: roleId, active: true };
                if (roleName) {
                  let updatedValue;

                  if (roles) {
                    updatedValue = roles.value.find(
                      (element: {
                        name: string;
                        id: string;
                        active: boolean;
                      }) => element?.name === roleName && element.active
                    )
                      ? roles.value
                      : roles.value.concat([value]);
                  } else {
                    updatedValue = [value];
                  }
                  await updateOrCreateSetting({
                    key: settingKey,
                    value: JSON.stringify(updatedValue),
                    module: 'projects',
                  });
                  formMethods.setValue(`${settingKey}_add_new`, '');
                  reloadSettings();
                }
              }}
            />
          </div>
        </div>
        {roles?.value?.map(
          (
            role: { active: boolean; name: string; id: string },
            idx: number
          ) => (
            <React.Fragment key={idx}>
              {role.active && (
                <div style={{ display: 'flex', gap: '10px' }}>
                  <TextInputField
                    label=""
                    name={`roles[${idx}].name`}
                    submitHandler={async () => {
                      const value = roles.value.map(
                        (role: { active: boolean }, i: number) => {
                          if (i === idx) {
                            return {
                              ...role,
                              name: formMethods.getValues(`roles[${idx}].name`),
                            };
                          }
                          return role;
                        }
                      );
                      await updateOrCreateSetting({
                        key: settingKey,
                        value: JSON.stringify(value),
                        module: 'projects',
                      });
                    }}
                    activateInputOnClick
                    className="project-settings-field"
                    {...formMethods}
                  />{' '}
                  <Confirm
                    content="Are you sure you want to delete the role?"
                    trigger={
                      <Button
                        inverted={theme === themes.dark}
                        icon="trash"
                        basic
                        size="large"
                        style={{
                          verticalAlign: 'bottom',
                          alignSelf: 'flex-end',
                        }}
                        disabled={
                          role.id === leadingRole ||
                          defaultRoles?.includes(role.id)
                        }
                      />
                    }
                    onSubmit={async () => {
                      const updatedValue = roles.value.map(
                        (role: { active: boolean }, i: number) => {
                          if (i === idx) {
                            return { ...role, active: false };
                          }
                          return role;
                        }
                      );
                      await updateOrCreateSetting({
                        key: settingKey,
                        value: JSON.stringify(updatedValue),
                        module: 'projects',
                      });
                      reloadSettings();
                    }}
                  />
                </div>
              )}
            </React.Fragment>
          )
        )}
        <div style={{ marginTop: '10px' }}>
          <SelectInputField
            activateInputOnClick
            label="Choose Leading Role"
            name="leadingRole"
            options={roles?.value
              ?.filter(
                (role: { active: boolean; id: string; name: string }) =>
                  role.active
              )
              .map((role: { active: boolean; id: string; name: string }) => ({
                value: role.id,
                text: role.name,
              }))}
            submitHandler={async (data) => {
              await updateOrCreateSetting({
                key: leadingRoleSettingKey,
                value: data.leadingRole,
                module: 'projects',
              });
              reloadSettings();
              if (defaultRoles?.includes(data.leadingRole)) {
                await updateOrCreateSetting({
                  key: defaultRolesSettingKey,
                  value: JSON.stringify(
                    defaultRoles.filter((id: string) => id !== data.leadingRole)
                  ),
                  module: 'projects',
                });
              }
            }}
            {...formMethods}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <SelectInputField
            activateInputOnClick
            multiple={true}
            label="Default Roles"
            name="defaultRoles"
            options={roles?.value
              ?.filter(
                (role: { active: boolean; id: string; name: string }) =>
                  role.active && role.id !== leadingRole
              )
              .map((role: { active: boolean; id: string; name: string }) => ({
                value: role.id,
                text: role.name,
              }))}
            submitHandler={async (data) => {
              await updateOrCreateSetting({
                key: defaultRolesSettingKey,
                value: JSON.stringify(data.defaultRoles),
                module: 'projects',
              });
              reloadSettings();
            }}
            {...formMethods}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <SelectInputField
            activateInputOnClick
            multiple={true}
            label="Broad Roles"
            name="broadRoles"
            options={roles?.value
              ?.filter(
                (role: { active: boolean; id: string; name: string }) =>
                  role.active
              )
              .map((role: { active: boolean; id: string; name: string }) => ({
                value: role.id,
                text: role.name,
              }))}
            submitHandler={async (data) => {
              await updateOrCreateSetting({
                key: broadRolesSettingKey,
                value: JSON.stringify(data.broadRoles),
                module: 'projects',
              });
              reloadSettings();
            }}
            {...formMethods}
          />
        </div>
      </Form>
    </>
  );
};

export default ProjectStagesSettings;
