import React, {
  ComponentType,
  MouseEvent,
  ReactNode,
  useContext,
  useMemo,
  useRef,
} from 'react';
import {
  CalendarWeeksRow,
  GridCell,
  GridFiller,
  ResourceCell,
} from './Calendar.styled';
import { getByAccessor } from '../../../utils/componentUtils';
import { Id } from '../../../types/base';
import { dateToString, toDateObject } from '../../../utils/dateUtils';
import CalendarEvent from './CalendarEvent';
import {
  differenceInCalendarDays,
  eachDayOfInterval,
  isBefore,
  isToday,
  isWeekend,
  isWithinInterval,
} from 'date-fns';
import CalendarMilestone from './CalendarMilestone';
import { BaseEvent, BaseMilestone } from '../types';
import get from 'lodash/get';
import { getCellWidths } from './CalendarHeader';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

type Props<
  Resource extends { id: Id },
  Event extends BaseEvent,
  Milestone extends BaseMilestone
> = {
  draggableEvent?: boolean;
  hasEventInfoComponent: boolean;
  resource: Resource;
  resourceIdAccessor?: keyof Resource;
  resourceNameAccessor?:
    | string
    | ((resource: Resource) => ReactNode)
    | string[];
  resourceAvatarComponent?: ComponentType<{ entity: Resource }>;
  eventIdAccessor?: string;
  eventColorAccessor?: string | ((event: Event) => string) | string[];
  eventNameAccessor?: string | ((event: Event) => ReactNode) | string[];
  milestoneNameAccessor:
    | string
    | ((milestone: Milestone) => ReactNode)
    | string[];
  days: Date[];
  addBottomBorder?: boolean;
  events: Event[];
  milestones: Milestone[];
  calendarBodyWidth?: number;
  createEventRange: {
    start: Date;
    end: Date;
    resource: Resource;
  } | null;
  dragEvent: {
    start: Date;
    end: Date;
    event: Event;
    sourceResource: Resource;
    destinationResource: Resource;
  } | null;
  onCellClick: (
    event: MouseEvent<HTMLDivElement>,
    day: Date,
    resource: Resource
  ) => void;
  onMouseDown: (
    event: MouseEvent<HTMLDivElement>,
    day: Date,
    resource: Resource
  ) => void;
  onEventMouseDown: (
    mouseEvent: MouseEvent<HTMLDivElement>,
    calendarEvent: Event,
    resource: Resource
  ) => void;
  onMouseOver: (
    event: MouseEvent<HTMLDivElement>,
    day: Date,
    resource: Resource
  ) => void;
  onMouseUp: (
    event: MouseEvent<HTMLDivElement>,
    day: Date,
    resource: Resource
  ) => void;
  onEventClick: (
    clickEvent: MouseEvent<HTMLDivElement>,
    calendarEvent: Event
  ) => void;
  onMilestoneClick: (
    clickEvent: MouseEvent<HTMLDivElement>,
    milstone: Milestone
  ) => void;
  resourceIsEditable: boolean;
  onResourceClick: (resource: Resource) => void;
  eventResourceIdAccessor: string;
  milestoneResourceIdAccessor?: string;
  mouseMoved: boolean;
  resourceAllowedEditsIds?: Array<string>;
  doNotShowIfEmpty: boolean;
  readOnly: boolean;
  editPermission?: string;
};

const CalendarResourceRow = <
  Resource extends { id: Id },
  Event extends BaseEvent,
  Milestone extends BaseMilestone
>(
  props: Props<Resource, Event, Milestone>
) => {
  const {
    draggableEvent,
    hasEventInfoComponent,
    resource,
    resourceIdAccessor = 'id',
    resourceNameAccessor = 'name',
    days,
    addBottomBorder = false,
    onCellClick,
    onMouseDown,
    onEventMouseDown,
    onMouseOver,
    onMouseUp,
    events: allEvents,
    milestones: allMilestones,
    eventColorAccessor = 'color',
    eventIdAccessor = 'id',
    eventNameAccessor = 'name',
    eventResourceIdAccessor,
    milestoneResourceIdAccessor,
    onEventClick,
    onMilestoneClick,
    milestoneNameAccessor = 'name',
    createEventRange,
    calendarBodyWidth,
    dragEvent,
    resourceIsEditable,
    onResourceClick,
    mouseMoved,
    resourceAllowedEditsIds,
    doNotShowIfEmpty,
    readOnly,
    resourceAvatarComponent: ResourceAvatarComponent,
    editPermission,
  } = props;

  const { theme } = useContext(ThemeContext);

  const events = useMemo(() => {
    return allEvents.filter(
      (event: Event) =>
        get(event, eventResourceIdAccessor) ===
        get(resource, resourceIdAccessor)
    );
  }, [allEvents, eventResourceIdAccessor, resource, resourceIdAccessor]);

  const milestones = useMemo(() => {
    if (!milestoneResourceIdAccessor) {
      return [];
    }
    return allMilestones.filter(
      (milestone: Milestone) =>
        get(milestone, milestoneResourceIdAccessor) ===
        get(resource, resourceIdAccessor)
    );
  }, [
    allMilestones,
    milestoneResourceIdAccessor,
    resource,
    resourceIdAccessor,
  ]);

  const { resourceCellWidth, dateCellWidth } = useMemo(
    () => getCellWidths(calendarBodyWidth ?? 0, days.length),
    [calendarBodyWidth, days]
  );

  const rowRef = useRef<HTMLDivElement | null>(null);

  const getCellBackgroundColor = (date: Date) => {
    if (
      createEventRange &&
      createEventRange.resource === resource &&
      isWithinInterval(date, {
        start: createEventRange.start,
        end: createEventRange.end,
      }) &&
      mouseMoved
    ) {
      return '#e7f0ff';
    } else if (
      dragEvent &&
      dragEvent.destinationResource === resource &&
      isWithinInterval(date, {
        start: dragEvent.start,
        end: dragEvent.end,
      }) &&
      mouseMoved
    ) {
      return '#e7f0ff';
    }

    if (isToday(date)) {
      if (theme === themes.dark) {
        return 'rgb(40, 40, 40)';
      }
      return '#fff4bf';
    }

    if (isWeekend(date)) {
      if (theme === themes.dark) {
        return 'rgba(14,14,14,0.51)';
      }
      return 'rgba(199,198,198,0.3)';
    }

    if (theme === themes.dark) {
      return 'inherit';
    }

    return '#fff';
  };

  const eventsByDay = useMemo(
    () =>
      events
        .slice()
        .sort((a, b) => {
          if (isBefore(toDateObject(b.start), toDateObject(a.start))) {
            return 1;
          } else if (isBefore(toDateObject(a.start), toDateObject(b.start))) {
            return -1;
          }
          return a.order - b.order;
        })
        .reduce((acc: { [x: string]: Array<string> }, current) => {
          let row: number;
          let start = toDateObject(current.start);
          let end = toDateObject(current.end);

          eachDayOfInterval({
            start: start,
            end: end,
          }).forEach((day, idx) => {
            if (acc.hasOwnProperty(dateToString(day))) {
              if (typeof row === 'undefined') {
                const firstPossibleRow = acc[dateToString(day)].findIndex(
                  (el) => el === ''
                );
                if (firstPossibleRow !== -1) {
                  acc[dateToString(day)][firstPossibleRow] = current.id;
                  row = firstPossibleRow;
                } else {
                  row = acc[dateToString(day)].length;
                  acc[dateToString(day)].push(current.id);
                }
              } else {
                if (row > acc[dateToString(day)].length) {
                  acc[dateToString(day)].push(
                    ...[...Array(row - acc[dateToString(day)].length)].map(
                      () => ''
                    )
                  );
                }
                acc[dateToString(day)][row] = current.id;
              }
            } else {
              if (typeof row !== 'undefined') {
                acc[dateToString(day)] = [...Array(row + 1)].map(() => '');
                acc[dateToString(day)][row] = current.id;
              } else {
                acc[dateToString(day)] = [current.id];
                row = 0;
              }
            }
          });

          return acc;
        }, {}),
    [events]
  );

  const milestonesByDay = useMemo(
    () =>
      milestones
        .slice()
        .sort((a, b) => {
          if (isBefore(new Date(a.datetime), new Date(b.datetime))) {
            return 1;
          } else if (isBefore(new Date(a.datetime), new Date(b.datetime))) {
            return -1;
          }

          return 0;
        })
        .reduce((acc: { [x: string]: Array<string> }, curr) => {
          const propertyName = dateToString(new Date(curr.datetime));
          acc[propertyName] = acc[propertyName]
            ? [...acc[propertyName], curr.id]
            : [curr.id];
          return acc;
        }, {}),
    [milestones]
  );

  const skipWeekends = useMemo(() => !days.some((date) => isWeekend(date)), [
    days,
  ]);

  return doNotShowIfEmpty && !events.length ? null : (
    <CalendarWeeksRow
      ref={rowRef}
      key={getByAccessor(resource, resourceIdAccessor)}
      numberOfDays={days.length}
      resourceCellWidth={resourceCellWidth}
      dateCellSize={dateCellWidth}
    >
      <GridCell>
        <ResourceCell
          style={{
            gridColumnStart: 1,
            gridRowStart: 1,
            backgroundColor: 'inherit',
            position: 'relative',
            overflow: 'visible',
            overflowX: 'clip',
            minHeight: '1em',
            maxHeight: '1.9em',
          }}
          editable={resourceIsEditable}
          onClick={() => {
            onResourceClick(resource);
          }}
          title={getByAccessor(resource, resourceNameAccessor)}
        >
          {ResourceAvatarComponent && (
            <ResourceAvatarComponent entity={resource} />
          )}
          {getByAccessor(resource, resourceNameAccessor)}
        </ResourceCell>
        <GridFiller
          columnStart={1}
          rowStart={2}
          borderBottom
          borderRight
          style={{ gridRowEnd: `span 1000` }}
          bottomDivider={addBottomBorder}
          backgroundColor={'inherit'}
        />
      </GridCell>
      {days.map((day, dayIdx) => (
        <GridCell
          key={day.toISOString()}
          onClick={(event) => onCellClick(event, day, resource)}
          onMouseDown={(event) => onMouseDown(event, day, resource)}
          onMouseOver={(event) => onMouseOver(event, day, resource)}
          onMouseUp={(event) => onMouseUp(event, day, resource)}
        >
          <GridFiller
            columnStart={dayIdx + 2}
            backgroundColor={getCellBackgroundColor(day)}
            style={{ gridRowEnd: `span 1000`, gridRowStart: 1 }}
            borderRight
            isLastDaysOfWeek={
              (skipWeekends ? day.getDay() === 5 : day.getDay() === 0) &&
              dayIdx !== days.length - 1
            }
          />
          {events
            .filter(
              (event: Event) =>
                dateToString(day) === event.start ||
                (dayIdx === 0 && new Date(event.start) < day) ||
                (skipWeekends &&
                  day.getDay() === 1 &&
                  differenceInCalendarDays(day, toDateObject(event.start)) <
                    3 &&
                  differenceInCalendarDays(day, toDateObject(event.start)) > 0)
            )
            .map((event: Event, eventIdx: number) => (
              <CalendarEvent
                key={getByAccessor(event, eventIdAccessor)}
                draggable={draggableEvent}
                columnStart={dayIdx + 2}
                rowStart={
                  (eventsByDay[dateToString(day)]?.indexOf(
                    getByAccessor(event, eventIdAccessor)
                  ) ?? 0) + 1
                }
                skipWeekends={skipWeekends}
                maxDays={days.length - dayIdx}
                event={event}
                eventIdAccessor={eventIdAccessor}
                currentDate={day}
                onMouseDown={(e) => onEventMouseDown(e, event, resource)}
                eventResourceIdAccessor={eventResourceIdAccessor}
                eventColorAccessor={eventColorAccessor}
                eventNameAccessor={eventNameAccessor}
                onClick={(e) => onEventClick(e, event)}
                resourceAllowedEditsIds={resourceAllowedEditsIds}
                editPermission={editPermission}
                hasEventInfoComponent={hasEventInfoComponent}
              />
            ))}
          {milestones
            .filter(
              (milestone: Milestone) =>
                dateToString(day) === dateToString(new Date(milestone.datetime))
            )
            .map((milestone: Milestone) => (
              <CalendarMilestone
                key={milestone.id}
                rowStart={
                  (eventsByDay[dateToString(day)]?.length ?? 0) +
                  milestonesByDay[dateToString(day)].indexOf(
                    getByAccessor(milestone, 'id')
                  ) +
                  1
                }
                columnStart={dayIdx + 2}
                milestone={milestone}
                onClick={(e) => onMilestoneClick(e, milestone)}
                milestoneNameAccessor={milestoneNameAccessor}
                maxDays={days.length - dayIdx}
                skipWeekends={skipWeekends}
                editPermission={editPermission}
                currentDate={day}
              />
            ))}
          <GridFiller
            minHeight={readOnly ? '.2em' : '0.85em'}
            columnStart={dayIdx + 2}
            backgroundColor={getCellBackgroundColor(day)}
            borderBottom
            borderRight
            isLastDaysOfWeek={
              (skipWeekends ? day.getDay() === 5 : day.getDay() === 0) &&
              dayIdx !== days.length - 1
            }
            bottomDivider={addBottomBorder}
          />
        </GridCell>
      ))}
    </CalendarWeeksRow>
  );
};

export default React.memo(CalendarResourceRow) as typeof CalendarResourceRow;
