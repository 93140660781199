import React, { RefObject } from 'react';
import { ProjectTask } from '../types';
import DeleteAction from '../../../components/actions/DeleteAction';
import ProjectTasksResource from '../../../api/resources/projects/ProjectTasksResource';

type Props = {
  selected: ProjectTask[];
  reload: () => Promise<void>;
  disabled?: boolean;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, disabled, mountNode } = props;

  const handleDelete = async () => {
    const filters = {
      where: { or: selected.map((projectTasks) => ({ id: projectTasks.id })) },
    };
    await ProjectTasksResource.deleteAll(filters);
    await reload();
  };

  return (
    <DeleteAction
      disabled={disabled}
      buttonSize="large"
      confirmText="Are you sure you want to delete the task?"
      callback={handleDelete}
      mountNode={mountNode}
    />
  );
};

export default Delete;
