import React from 'react';
import styled from 'styled-components';
import { GRADIENT_DEFAULT_COLOR } from '../../../contexts/theme/ThemeContext';
import tinycolor from 'tinycolor2';
import Heading from '../../../components/layout/Heading';
import {
  Bar,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
} from 'recharts';
import { eachMonthOfInterval, isSameMonth, subMonths } from 'date-fns';
import { toDateObject } from '../../../utils/dateUtils';
import BlueGreenLabel from '../../../components/BlueGreenLabel';
import Decimal from 'decimal.js-light';
import { get } from 'lodash';

const Wrapper = styled.div`
  background-color: #1e1e1e;
  border-radius: 20px !important;
  // width: 100%;
  height: 100%;

  .chart {
    text-align: center;
    height: 100%;

    h4 {
      color: white;
    }

    .green-label {
      flex-grow: 1;
    }

    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 20px !important;
    background: ${`linear-gradient(45deg, ${GRADIENT_DEFAULT_COLOR} 0%, ${tinycolor(
      GRADIENT_DEFAULT_COLOR
    )
      .setAlpha(tinycolor(GRADIENT_DEFAULT_COLOR).getAlpha() / 2)
      .toRgbString()} 50%, ${tinycolor(GRADIENT_DEFAULT_COLOR)
      .setAlpha(0)
      .toRgbString()} 100%)`};
  }
`;

const formatMonth = new Intl.DateTimeFormat('en-GB', { month: 'short' }).format;

export const formatSummary = new Intl.NumberFormat('BG', {
  style: 'currency',
  currency: 'BGN',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;

const labels = {
  average: 'Average',
  movingAverage: 'Moving Average (6 Months)',
};

const AverageSalaryComponentGraph = ({
  month,
  heading,
  chartData,
  summaryLabel,
  hoverText,
}: {
  month: Date;
  heading: string;
  chartData: { [key: string]: { average: number; movingAverage?: number } };
  summaryLabel: string;
  hoverText?: string;
}) => {
  const firstMonth = subMonths(month, 11);

  const data = eachMonthOfInterval({
    start: firstMonth,
    end: month,
  }).map((month) => {
    const monthKey = Object.keys(chartData).find((key) =>
      isSameMonth(toDateObject(key), month)
    );
    return {
      monthLabel: formatMonth(month),
      average: monthKey ? chartData[monthKey].average : 0,
      movingAverage: monthKey ? chartData[monthKey].movingAverage : undefined,
    };
  });

  const tooltipFormatter = (value: number, name: string) => {
    return [
      `${new Intl.NumberFormat('BG', {
        style: 'currency',
        currency: 'BGN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value)}`,
      get(labels, name),
    ];
  };

  const average = Object.values(chartData)
    .reduce((acc, item) => acc.add(new Decimal(item.average)), new Decimal(0))
    .div(Object.values(chartData).length)
    .toNumber();

  return (
    <Wrapper>
      <div className="chart">
        <Heading as="h4">{heading}</Heading>
        <ResponsiveContainer width={'100%'} height={250}>
          <ComposedChart
            margin={{ top: 25, right: 20, bottom: 5, left: 0 }}
            data={data}
          >
            <Tooltip
              formatter={tooltipFormatter}
              labelStyle={{
                color: '#000',
              }}
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="monthLabel" tick={{ fill: '#fff' }} />
            <YAxis tick={{ fill: '#fff' }} />
            <Bar dataKey="average" fill="#00a650" />
            <Line dataKey="movingAverage" fill="#0084ff" strokeWidth={3} />
          </ComposedChart>
        </ResponsiveContainer>

        <BlueGreenLabel
          label={summaryLabel}
          value={formatSummary(average)}
          hoverText={hoverText}
        />
      </div>
    </Wrapper>
  );
};

export default AverageSalaryComponentGraph;
