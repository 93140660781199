import React, { RefObject, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Document, Page } from 'react-pdf';
import { InvoiceWithRelations } from '../types';
import InvoiceResource from '../../../api/resources/invoices/InvoiceResource';
import { Modal, ModalContent, ModalHeader } from '../../../components/dialogs';
import ErrorMessage from '../../../components/ErrorMessage';
import useAPIRequest from '../../../hooks/useAPIRequest';
import Loader from '../../../components/Loader';

type Props = {
  selected: InvoiceWithRelations;
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const InvoicePreview = (props: Props) => {
  const { selected, mountNode } = props;
  const [open, setOpen] = useState(false);
  const [fileURL, setFileURL] = useState('');
  const [numPages, setNumPages] = useState<number | null>(null);

  const {
    loading,
    data: response,
    performRequest: printInvoice,
    error,
  } = useAPIRequest(InvoiceResource.print);

  const handleLoadPreview = async () => {
    setOpen(true);
    await printInvoice(selected.id);
  };

  useEffect(() => {
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setFileURL(url);
    }
  }, [response]);

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: {
    numPages: number | null;
  }) => {
    setNumPages(nextNumPages);
  };

  return (
    <>
      <Button size="large" content="Preview" onClick={handleLoadPreview} />
      <Modal
        closeIcon
        onClose={() => {
          setOpen(false);
          setFileURL('');
        }}
        open={open}
        style={{ width: 'min-content' }}
        mountNode={mountNode}
      >
        <ModalHeader>
          <>
            <Icon name="file pdf" size="small" />
            Invoice Preview
          </>
        </ModalHeader>
        <ModalContent
          scrolling
          style={{
            padding: '0 1em',
            minWidth: '300px',
            minHeight: '300px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ErrorMessage error={error} />
          <Loader active={loading} />
          {fileURL && (
            <Document file={fileURL} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={1.5}
                />
              ))}
            </Document>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default InvoicePreview;
