import React, { ReactNode, RefObject } from 'react';
import { Icon } from 'semantic-ui-react';
import { Id } from '../../../types/base';
import DeleteCalendarEvent from './DeleteCalendarEvent';
import ModalForm from '../../forms/ModalForm';
import { FormFieldsComponentType } from '../../forms/types';

type Props<Item extends { id: Id }> = {
  editItem: Item;
  formFields: FormFieldsComponentType;
  label: ReactNode;
  onClose: () => void;
  handleEditItem: (id: Id, data: Partial<Item>) => Promise<void>;
  handleDeleteItem: (id: Id) => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const EditCalendarItemForm = <Item extends { id: Id }>(props: Props<Item>) => {
  const {
    editItem,
    formFields,
    label,
    onClose,
    handleEditItem,
    handleDeleteItem,
    mountNode,
  } = props;

  return (
    editItem && (
      <ModalForm
        header={
          <>
            <Icon name="plus" color="green" circular inverted size="small" />
            {label}
          </>
        }
        defaultOpen
        formId={`edit-event-${editItem?.id}`}
        fields={formFields}
        instance={editItem ?? undefined}
        submitData={async (data) => {
          await handleEditItem(editItem.id, data);
          onClose();
        }}
        onClose={onClose}
        modalProps={{
          mountNode: mountNode,
        }}
        additionalActions={
          <DeleteCalendarEvent
            event={editItem}
            callback={(id: Id) => {
              handleDeleteItem(id);
              onClose();
            }}
            mountNode={mountNode}
          />
        }
      />
    )
  );
};

export default EditCalendarItemForm;
