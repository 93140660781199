import React, { RefObject } from 'react';
import { Button } from 'semantic-ui-react';
import { ProjectItem } from '../types';
import ProjectItemsResource from '../../../api/resources/projects/ProjectItemsResource';
import DeleteAction from '../../../components/actions/DeleteAction';
import Confirm from '../../../components/dialogs/Confirm';

type Props = {
  selected: ProjectItem[];
  reload: () => Promise<void>;
  disabled?: boolean;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, disabled, mountNode } = props;

  const handleDelete = async () => {
    const filters = {
      where: { or: selected.map((projectItem) => ({ id: projectItem.id })) },
    };
    await ProjectItemsResource.deleteAll(filters);
    await reload();
  };

  return Array.isArray(selected) &&
    selected.some(
      (item) => item.invoicedPercentage && Number(item.invoicedPercentage) !== 0
    ) ? (
    <Confirm
      content="The items cannot be deleted because some of them are invoiced. Please remove the invoice entries first."
      trigger={<Button negative content="Delete" size="large" />}
    />
  ) : (
    <DeleteAction
      disabled={disabled}
      buttonSize="large"
      confirmText="Are you sure you want to delete the items?"
      callback={handleDelete}
      mountNode={mountNode}
    />
  );
};

export default Delete;
