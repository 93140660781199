export const INVOICES_PERMISSION = 'Invoices';
export const PROPOSALS_PERMISSION = 'Proposals';
export const PROJECTS_PERMISSION = 'Projects';
export const CLIENTS_PERMISSION = 'Clients';
export const TEAM_PERMISSION = 'Team';
export const TIMESHEETS_PERMISSION = 'Timesheets';
export const RESOURCE_SCHEDULE_PERMISSION = 'ResourceSchedule';
export const PROJECT_SCHEDULE_PERMISSION = 'Flow';
export const FULL_ACCESS_PERMISSION = 'FullAccess';
export const MANAGE_HOLIDAYS_PERMISSION = 'ManageHolidays';

export const TIMESHEET_EMAIL = 'TimesheetEmail';
export const INVOICES_EMAIL = 'InvoicesEmail';
export const HOLIDAYS_EMAIL = 'HolidaysEmail';

export const HIDE_IN_SCHEDULES = 'HideInSchedules';
export const PROJECT_SETTINGS_PERMISSION = 'ProjectSettings';
export const SALARIES_PERMISSION = 'Salaries';

export const FullAccessPermissions = [
  PROPOSALS_PERMISSION,
  INVOICES_PERMISSION,
  PROJECTS_PERMISSION,
  PROJECT_SCHEDULE_PERMISSION,
  CLIENTS_PERMISSION,
  TEAM_PERMISSION,
  TIMESHEETS_PERMISSION,
  RESOURCE_SCHEDULE_PERMISSION,
  FULL_ACCESS_PERMISSION,
  PROJECT_SETTINGS_PERMISSION,
];

export const allPermissions = [
  FULL_ACCESS_PERMISSION,
  INVOICES_PERMISSION,
  PROPOSALS_PERMISSION,
  PROJECTS_PERMISSION,
  PROJECT_SCHEDULE_PERMISSION,
  CLIENTS_PERMISSION,
  TEAM_PERMISSION,
  TIMESHEETS_PERMISSION,
  RESOURCE_SCHEDULE_PERMISSION,
  MANAGE_HOLIDAYS_PERMISSION,
  TIMESHEET_EMAIL,
  INVOICES_EMAIL,
  HOLIDAYS_EMAIL,
  HIDE_IN_SCHEDULES,
  PROJECT_SETTINGS_PERMISSION,
  SALARIES_PERMISSION,
];
