import React, { useContext, useEffect } from 'react';
import useAPIRequest from '../../hooks/useAPIRequest';
import ProjectSettingResource from '../../api/resources/ProjectSettingResource';
import {
  PROJECT_STAGES_BROAD_ROLES,
  PROJECT_STAGES_DEFAULT_ROLES,
  PROJECT_STAGES_LEADING_ROLE,
  PROJECT_STAGES_ROLES,
} from '../settings/keys';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import Heading from '../../components/layout/Heading';
import Segment from '../../components/layout/Segment';
import { InfoBoxContentContainer } from '../../components/InfoBoxContentContainer';
import Attributes from '../../components/Attributes';
import DateFormatter, { toDateObject } from '../../utils/dateUtils';
import { TeamMember } from '../team/types';
import { ProjectSetting } from '../settings/types';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

//type Props = FormFieldsComponentTypeProps;

const EventInfoBox = ({ event }: { event: any }) => {
  const { data: response, performRequest: fetchSettings } = useAPIRequest(
    ProjectSettingResource.list
  );

  const {
    data: teamMembersResponse,
    performRequest: getTeamMembers,
  } = useAPIRequest(TeamMemberResource.list);

  useEffect(() => {
    fetchSettings({ where: { module: 'projects' } });
  }, [fetchSettings]);

  useEffect(() => {
    getTeamMembers({
      filter: { where: { archived: false }, order: 'name ASC' },
    });
  }, [getTeamMembers]);

  const allRoles = response?.data?.find(
    (setting: ProjectSetting) => setting.key === PROJECT_STAGES_ROLES
  )?.value;
  const leadingRole = response?.data?.find(
    (setting: ProjectSetting) => setting.key === PROJECT_STAGES_LEADING_ROLE
  )?.value;
  const defaultRoles = response?.data?.find(
    (setting: ProjectSetting) => setting.key === PROJECT_STAGES_DEFAULT_ROLES
  )?.value;
  const broadRoles = response?.data?.find(
    (setting: ProjectSetting) => setting.key === PROJECT_STAGES_BROAD_ROLES
  )?.value;

  const roleFields = leadingRole
    ? [leadingRole, ...defaultRoles]
    : defaultRoles;

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Segment style={{ borderRadius: 0, marginTop: 0 }} className=".handle">
        <Heading as="h3" style={{ textAlign: 'center' }}>
          {event.name}
        </Heading>
        <InfoBoxContentContainer>
          <Attributes
            attributes={[
              [
                {
                  key: `${event.id}-startDate`,
                  title: 'Start - End',
                  value: `${DateFormatter.format(
                    toDateObject(event.start)
                  )} - ${
                    event.end && DateFormatter.format(toDateObject(event.end))
                  }`,
                  icon: 'calendar alternate outline',
                },
                {
                  key: `${event.id}-roles`,
                  title: 'Roles',
                  icon: 'users',
                  value: (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {roleFields
                        ?.filter(
                          (roleId: string) =>
                            event.teamMemberRoles?.[roleId]?.length &&
                            !broadRoles?.includes(roleId)
                        )
                        .map((eventRole: string) => {
                          return (
                            <div
                              key={eventRole}
                              style={{
                                marginBottom: 0,
                                fontWeight: 'bold',
                                width: '50%',
                                marginTop: '5px',
                              }}
                            >
                              <span
                                style={{ fontWeight: 'normal', opacity: '0.7' }}
                              >
                                {
                                  allRoles?.find(
                                    (role: { id: string; name: string }) =>
                                      role.id === eventRole
                                  )?.name
                                }
                                :
                              </span>
                              <p
                                style={{
                                  marginBottom: 0,
                                  marginLeft: '10px',
                                  fontWeight: 'bold',
                                  color:
                                    theme === themes.dark ? '#fff' : '#000',
                                }}
                              >
                                {event.teamMemberRoles?.[eventRole]
                                  ?.map(
                                    (teamMemberId: string) =>
                                      teamMembersResponse?.data.find(
                                        (teamMember: TeamMember) =>
                                          teamMemberId === teamMember?.id
                                      )?.name
                                  )
                                  .join(', ')}
                              </p>
                            </div>
                          );
                        })}
                      {roleFields
                        ?.filter(
                          (roleId: string) =>
                            event.teamMemberRoles?.[roleId]?.length &&
                            broadRoles?.includes(roleId)
                        )
                        .map((eventRole: string) => {
                          return (
                            <div
                              key={eventRole}
                              style={{
                                marginBottom: 0,
                                fontWeight: 'bold',
                                width: '100%',
                                marginTop: '10px',
                              }}
                            >
                              <span
                                style={{ fontWeight: 'normal', opacity: '0.7' }}
                              >
                                {
                                  allRoles?.find(
                                    (role: { id: string; name: string }) =>
                                      role.id === eventRole
                                  )?.name
                                }
                                :
                              </span>
                              <p
                                style={{
                                  marginBottom: 0,
                                  marginLeft: '10px',
                                  fontWeight: 'bold',
                                  color:
                                    theme === themes.dark ? '#fff' : '#000',
                                }}
                              >
                                {event.teamMemberRoles?.[eventRole]
                                  ?.map(
                                    (teamMemberId: string) =>
                                      teamMembersResponse?.data.find(
                                        (teamMember: TeamMember) =>
                                          teamMemberId === teamMember?.id
                                      )?.name
                                  )
                                  .join(', ')}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  ),
                },
                {
                  key: `${event.id}-note`,
                  icon: 'sticky note',
                  title: 'Note',
                  value: event.note && (
                    <div
                      dangerouslySetInnerHTML={{ __html: event.note }}
                      style={{ whiteSpace: 'pre-wrap' }}
                    />
                  ),
                },
              ],
            ]}
          />
        </InfoBoxContentContainer>
      </Segment>
    </>
  );
};

export default EventInfoBox;
