import React, { KeyboardEvent, ReactNode, useRef } from 'react';
import { Form } from 'semantic-ui-react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import get from 'lodash/get';
import { ErrorLabel, StyledFormField } from './Fields.styled';

type Props = {
  label?: ReactNode;
  name: string;
  placeholder?: string;
  rules?: ValidationRules;
  activateInputOnClick?: boolean;
  submitHandler: (data: any) => any;
  preventDefaultOnEnter?: boolean;
} & React.ComponentProps<typeof Form.Field> &
  UseFormMethods;

const PasswordInputField = (props: Props) => {
  const {
    register,
    label,
    name,
    placeholder,
    trigger,
    rules = {},
    errors,
    preventDefaultOnEnter = true,
    activateInputOnClick,
    submitHandler,
    clearErrors,
    getValues,
    inline = true,
  } = props;

  const fieldError = get(errors, name);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (preventDefaultOnEnter || activateInputOnClick) {
        e.preventDefault();
      }

      if (activateInputOnClick) {
        const valid = await trigger(name);
        if (valid) {
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }
      }
    }
  };

  const handleBlur = async () => {
    if (activateInputOnClick) {
      const valid = await trigger(name);
      if (valid) {
        submitHandler(getValues());
      }
    }
  };

  const handleChange = () => {
    if (fieldError) {
      clearErrors(name);
    }
  };

  return (
    <StyledFormField
      activateInputOnClick={activateInputOnClick}
      error={!!fieldError}
      required={
        typeof rules.required === 'object'
          ? !!rules?.required?.value
          : !!rules?.required
      }
      inline={inline}
    >
      {label && <label>{label}:</label>}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <input
          name={name}
          ref={(ref) => {
            inputRef.current = ref;
            register(ref, rules);
          }}
          placeholder={placeholder}
          type="password"
          onKeyPress={handleKeyPress}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {fieldError && (
          <ErrorLabel pointing="above">{fieldError.message}</ErrorLabel>
        )}
      </div>
    </StyledFormField>
  );
};

export default PasswordInputField;
