import { Form as SemanticUIForm, FormProps } from 'semantic-ui-react';
import { useContext } from 'react';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import * as React from 'react';

type Props = FormProps & {};

export const Form = (props: Props) => {
  const { theme } = useContext(ThemeContext);

  return <SemanticUIForm inverted={theme === themes.dark} {...props} />;
};
