import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { Project } from '../../projects/types';
import ProposalResource from '../../../api/resources/proposals/ProposalResource';
import { ProposalContainer, OpenedProposalContext } from '../ProposalsTab';
import { useContainer } from 'unstated-next';

type Props = {
  selected: Project[];
  reload: () => Promise<void>;
};

const AddProposalToProject = (props: Props) => {
  const { selected } = props;

  const openedProposalContext = useContext(OpenedProposalContext);
  const proposalTableContainer = useContainer(ProposalContainer);

  const handleCreateNewInstance = async () => {
    const newProposalData = { projectId: selected[0].id };
    const response = await ProposalResource.create(newProposalData);
    await proposalTableContainer.list();
    openedProposalContext.setOpenedProposalId(response.data.id);
  };

  return (
    <Button
      positive
      size="huge"
      content="Add proposal"
      onClick={() => handleCreateNewInstance()}
    />
  );
};

export default AddProposalToProject;
