import BaseNestedCRUDResource from '../../BaseNestedCRUDResource';
import { Id } from '../../../types/base';
import Api from '../../Api';

class TeamMemberResource extends BaseNestedCRUDResource {
  objectIdentifier = 'leave-allowances';
  parentIdentifier = 'team-members';

  updateOrCreate = (parentId: Id, data: any) => {
    return Api.client.post(`${this.getPath(parentId)}/create-or-update`, data);
  };
}

export default new TeamMemberResource();
