import React, { RefObject } from 'react';
import { Id } from '../../../types/base';
import DeleteAction from '../../actions/DeleteAction';

type Props<Event extends { id: Id }> = {
  event: Event;
  callback: (id: Id) => void;
  type?: string;
  mountNode: RefObject<HTMLDivElement | null>;
};

const DeleteCalendarEvent = <Event extends { id: Id }>(props: Props<Event>) => {
  const { callback, event, type = 'item', mountNode } = props;

  const handleDelete = () => {
    callback(event.id);
  };

  return (
    <DeleteAction
      confirmText={`Are you sure you want to delete the ${type}?`}
      callback={handleDelete}
      mountNode={mountNode}
    />
  );
};

export default DeleteCalendarEvent;
