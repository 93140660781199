export const roundToTwo = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

function decimalAdjust(
  type: 'round' | 'floor' | 'ceil',
  value: number,
  exp: number
) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  const valueToStrng = value.toString().split('e');
  const valueShifted = Math[type](
    +(valueToStrng[0] + 'e' + (valueToStrng[1] ? +valueToStrng[1] - exp : -exp))
  );
  // Shift back
  const valueShiftBack = valueShifted.toString().split('e');
  return +(
    valueShiftBack[0] +
    'e' +
    (valueShiftBack[1] ? +valueShiftBack[1] + exp : exp)
  );
}

// Decimal round
export const round10 = (value: number, exp: number) =>
  decimalAdjust('round', value, exp);
// Decimal floor
export const floor10 = (value: number, exp: number) =>
  decimalAdjust('floor', value, exp);
// Decimal ceil
export const ceil10 = (value: number, exp: number) =>
  decimalAdjust('ceil', value, exp);

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(number)
    .replace(',', ' ');
};
