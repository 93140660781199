import React, { RefObject } from 'react';
import { TeamMember } from '../types';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';
import DeleteAction from '../../../components/actions/DeleteAction';

type Props = {
  selected: TeamMember | TeamMember[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const handleDelete = async () => {
    if (Array.isArray(selected)) {
      const filters = {
        where: { or: selected.map((teamMember) => ({ id: teamMember.id })) },
      };
      await TeamMemberResource.deleteAll(filters);
      await reload();
    } else {
      await TeamMemberResource.deleteById(selected.id);
      await reload();
    }
  };

  return (
    <DeleteAction
      confirmText={
        Array.isArray(selected) && selected.length > 1
          ? 'Are you sure you want to delete the team members?'
          : 'Are you sure you want to delete the team member?'
      }
      callback={handleDelete}
      mountNode={mountNode}
      buttonSize={Array.isArray(selected) ? 'huge' : 'large'}
    />
  );
};

export default Delete;
