import React, { useEffect } from 'react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import ClientResource from '../../../api/resources/clients/ClientResource';
import useAPIRequest from '../../../hooks/useAPIRequest';
import { ClientContact, CompanyInvoiceData } from '../../clients/types';
import { SelectInputField } from '../../../components/forms/fields';

type Props = FormFieldsComponentTypeProps & {
  clientId?: string;
};

const InvoiceClientFormFields = (props: Props) => {
  const { clientId, ...restProps } = props;

  const {
    loading: loadingClient,
    data: clientResponse,
    performRequest: getClient,
  } = useAPIRequest(ClientResource.findById);

  useEffect(() => {
    if (clientId) {
      getClient(clientId, {
        filter: {
          include: [
            { relation: 'companyInvoiceData' },
            { relation: 'contacts' },
          ],
        },
      });
    }
  }, [clientId, getClient]);

  return (
    <>
      <SelectInputField
        loading={loadingClient}
        options={clientResponse?.data.companyInvoiceData?.map(
          (companyInvoiceData: CompanyInvoiceData) => ({
            value: companyInvoiceData.id,
            text: companyInvoiceData.label,
            key: companyInvoiceData.id,
          })
        )}
        label="Client Address"
        name="companyInvoiceDataId"
        rules={{
          required: {
            value: true,
            message: 'Please select one of the entries.',
          },
        }}
        {...restProps}
      />
      <SelectInputField
        loading={loadingClient}
        options={clientResponse?.data.contacts?.map(
          (contact: ClientContact) => ({
            value: contact.id,
            text: `${contact.name}`,
            key: contact.id,
          })
        )}
        label="Client Contact"
        name="contactId"
        rules={{
          required: {
            value: true,
            message: 'Please select a contact.',
          },
        }}
        {...restProps}
      />
    </>
  );
};

export default InvoiceClientFormFields;
