import React, { ReactNode, useContext, useRef } from 'react';
import { Form, Select } from 'semantic-ui-react';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import get from 'lodash/get';
import { ErrorLabel, StyledFormField } from './Fields.styled';
import { dateToString } from '../../../utils/dateUtils';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

const timePickerOptions = Array.from(Array(24).keys()).map((value: number) => {
  return {
    key: value,
    value,
    text: value >= 10 ? `${value}:00` : `0${value}:00`,
  };
});

type Props = {
  rules?: ValidationRules;
  label?: ReactNode;
  name: string;
  setValue: (field: string, value: string | string[] | undefined) => void;
  datePickerProps?: SemanticDatepickerProps;
  activateInputOnClick?: boolean;
  submitHandler: (data: any) => any;
  dateOnly?: boolean;
} & React.ComponentProps<typeof Form.Field> &
  UseFormMethods;

const DateInputField = (props: Props) => {
  const {
    register,
    setValue,
    label,
    name,
    datePickerProps,
    errors,
    rules = {},
    clearErrors,
    watch,
    trigger,
    submitHandler,
    getValues,
    activateInputOnClick = false,
    dateOnly = false,
    placeholder,
    inline = true,
    width,
  } = props;

  const datePickerRef = useRef(null);

  const handleChange = async (
    e: React.SyntheticEvent | undefined,
    { value }: SemanticDatepickerProps
  ) => {
    clearErrors(name);
    if (Array.isArray(value)) {
      setValue(
        name,
        value.map((v) => (dateOnly ? dateToString(v) : v.toISOString()))
      );
    } else {
      setValue(
        name,
        dateOnly && value ? dateToString(value) : value?.toISOString() || null
      );
    }

    if (activateInputOnClick && datePickerRef.current) {
      // datePickerRef.current.blur();
      const valid = await trigger(name);
      if (valid) {
        submitHandler(getValues());
      }
    }
  };

  React.useEffect(() => {
    register(name, rules);
  }, [register, name, rules]);

  const value = watch(name);

  const fieldError = get(errors, name);
  const { theme } = useContext(ThemeContext);

  return (
    <StyledFormField
      activateInputOnClick={activateInputOnClick}
      error={!!fieldError}
      inline={inline}
      required={
        typeof rules.required === 'object'
          ? !!rules?.required?.value
          : !!rules?.required
      }
      width={width}
    >
      {label && <label>{label}:</label>}
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <SemanticDatepicker
            onChange={handleChange}
            error={!!get(errors, name)}
            value={value && new Date(value)}
            ref={datePickerRef}
            firstDayOfWeek={1}
            inverted={theme === themes.dark}
            format="DD/MMM/YYYY"
            clearOnSameDateClick={false}
            {...datePickerProps}
          />
          {!dateOnly && (
            <Select
              options={timePickerOptions}
              placeholder={placeholder}
              value={value && new Date(value).getHours()}
              onChange={(event, { value: timePickerValue }) => {
                if (value) {
                  const date = new Date(value);
                  date.setHours(timePickerValue as number);
                  setValue(name, date.toISOString());
                }
              }}
            />
          )}
        </div>
        {fieldError && (
          <ErrorLabel pointing="above">{fieldError.message}</ErrorLabel>
        )}
      </div>
    </StyledFormField>
  );
};

export default DateInputField;
