import React from 'react';

type Props = {
  children: React.ReactNode;
};

const MainContent = (props: Props) => {
  const { children } = props;

  return <main>{children}</main>;
};

export default MainContent;
