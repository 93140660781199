import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import styled from 'styled-components';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import clsx from 'clsx';
import DateFormatter from '../../utils/dateUtils';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

const StyledDatePicker = styled(SemanticDatepicker)`
  &.ui.input > input {
    border: 1px solid transparent;
  }

  &.ui.input > input::placeholder {
    color: rgba(116, 116, 116, 0.87);
  }
`;

const StyledDatePickerButton = styled(Button)`
  &.ui.basic.button,
  &.ui.basic.button:focus,
  &.ui.basic.button:hover {
    box-shadow: none !important;
  }
`;

type Props = {
  onChange: (date: Date | null | undefined) => void;
  startDate: Date;
  showSelectedWeek?: boolean;
};

const TimesheetDatePicker = (props: Props) => {
  const { onChange, startDate, showSelectedWeek = true } = props;

  const handleChange = (
    e: React.SyntheticEvent | undefined,
    { value }: SemanticDatepickerProps
  ) => {
    if (!Array.isArray(value) && value) {
      onChange(
        new Date(value.getFullYear(), value.getMonth(), value.getDate())
      );
    }
  };

  const lastDayOfWeek = new Date(startDate.getTime()).setDate(
    startDate.getDate() + 6
  );

  const setWeekBefore = () => {
    const weekBefore = new Date(startDate.getTime());
    weekBefore.setDate(weekBefore.getDate() - 7);
    onChange(weekBefore);
  };

  const setWeekAfter = () => {
    const weekAfter = new Date(startDate.getTime());
    weekAfter.setDate(weekAfter.getDate() + 7);
    onChange(weekAfter);
  };

  const { theme } = useContext(ThemeContext);

  return (
    <>
      {showSelectedWeek && (
        <>
          <strong> Week: </strong>
          {DateFormatter.format(startDate)} -{' '}
          {DateFormatter.format(lastDayOfWeek)}{' '}
        </>
      )}
      <StyledDatePickerButton
        basic
        icon="angle left"
        size="large"
        onClick={setWeekBefore}
        inverted={theme === themes.dark}
      />
      <StyledDatePicker
        onChange={handleChange}
        clearable={false}
        placeholder="Choose date"
        value={undefined}
        firstDayOfWeek={1}
        inverted={theme === themes.dark}
        className={clsx('secondary', theme === themes.dark && 'inverted')}
        clearOnSameDateClick={false}
      />
      <StyledDatePickerButton
        basic
        icon="angle right"
        size="large"
        onClick={setWeekAfter}
        inverted={theme === themes.dark}
      />
    </>
  );
};

export default TimesheetDatePicker;
