import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import Calendar from '../../components/calendar/Calendar';
import EventFormFields from '../resourceSchedule/forms/EventFormFields';
import ResourceScheduleProjectEventResource from '../../api/resources/resourceSchedule/ResourceScheduleProjectEventResource';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import NewEventFormFields from '../resourceSchedule/forms/NewEventFormFields';
import { ResourceScheduleEvent } from '../resourceSchedule/ResourceSchedule';
import { getProjectFullName } from '../projects/utils';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import useAPIRequest from '../../hooks/useAPIRequest';
import { Project } from '../projects/types';
import { TeamMember } from '../team/types';
import { UserContext } from '../auth/UserContext';
import { hasPermission } from '../auth/utils';
import { MANAGE_HOLIDAYS_PERMISSION } from '../team/permission';
import Segment from '../../components/layout/Segment';

export const HolidayScheduleContext = React.createContext(false);

type Props = {
  defaultStartDate?: string;
};

const HolidayResourceSchedule = (props: Props) => {
  const { defaultStartDate } = props;
  const { data: projectsResponse, performRequest: getProjects } = useAPIRequest(
    ProjectResource.list
  );

  const { user } = UserContext.useContainer();

  useEffect(() => {
    getProjects({
      filter: { where: { isHoliday: true }, fields: { id: true } },
    });
  }, [getProjects]);

  const allowedEditsIds = useMemo(() => {
    if (hasPermission(user, MANAGE_HOLIDAYS_PERMISSION)) {
      return undefined;
    }

    const teamMemberId = get(user, 'teamMemberId');
    if (teamMemberId) {
      return [teamMemberId];
    }
  }, [user]);

  const eventAdditionalFilters = useMemo(() => {
    return {
      or: projectsResponse?.data?.map((p: Pick<Project, 'id'>) => ({
        projectId: p.id,
      })),
    };
  }, [projectsResponse]);

  return (
    <Segment style={{ borderRadius: 0 }}>
      <HolidayScheduleContext.Provider value={true}>
        {projectsResponse && (
          <Calendar
            defaultStartDate={defaultStartDate}
            topCalendar={{
              calendarId: 'holiday-calendar',
              resourceAllowedEditsIds: allowedEditsIds,
              editEventFormFields: EventFormFields,
              eventIncludeRelations: [{ relation: 'project' }],
              eventAPIResource: ResourceScheduleProjectEventResource,
              eventAdditionalFilters,
              resourceAPIResource: TeamMemberResource,
              resourceAdditionalFilters: { archived: false },
              resourceNameAccessor: (teamMember: TeamMember) => {
                return `${teamMember.name.split(' ')[0] || teamMember.name}`;
              },
              resourceAPIMethod: 'listForSchedules',
              createEventFormFields: NewEventFormFields,
              eventResourceIdAccessor: 'teamMemberId',
              eventColorAccessor: ['project', 'eventColor'],
              eventNameAccessor: (event: ResourceScheduleEvent) =>
                getProjectFullName(event.project),
              copyEvent: (event) => ({
                start: event.start,
                end: event.end,
                color: event.project.eventColor,
                teamMemberId: event.teamMemberId,
                projectId: event.project.id,
              }),
            }}
          />
        )}
      </HolidayScheduleContext.Provider>
    </Segment>
  );
};

export default HolidayResourceSchedule;
