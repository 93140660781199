import React, { useEffect } from 'react';
import Decimal from 'decimal.js-light';
import { ResourceScheduleEvent } from '../../resourceSchedule/ResourceSchedule';
import { calculateEventDays } from '../docs/DocsTable';
import useAPIRequest from '../../../hooks/useAPIRequest';
import LeaveAllowanceResource from '../../../api/resources/team/LeaveAllowanceResource';
import { HOLIDAY_STATUS_APPROVED } from '../../resourceSchedule/constants';

const ANNUAL_HOLIDAY_TYPE = 'Annual Holiday';
const STUDY_DAYS_TYPE = 'Leave Study';

type Props = {
  items: ResourceScheduleEvent[];
  appliedFilter: any;
};

const formatDecimal = (decimal: Decimal) => {
  return decimal.toDecimalPlaces(2).toString();
};

const PageFooter = (props: Props) => {
  const { items, appliedFilter } = props;

  const teamMemberId = appliedFilter.where.and[2].teamMemberId;
  const year = appliedFilter.where.and[0].or[0].start.between[0].split('-')[0];

  const {
    loading,
    data: leaveAllowanceResponse,
    performRequest: getLeaveAllowance,
  } = useAPIRequest(LeaveAllowanceResource.list);

  useEffect(() => {
    getLeaveAllowance({ filter: { where: { teamMemberId, year } } });
  }, [getLeaveAllowance, teamMemberId, year]);

  const amountAnnualHoliday = items
    .filter(
      (event) =>
        event.project.name === ANNUAL_HOLIDAY_TYPE &&
        event.status === HOLIDAY_STATUS_APPROVED
    )
    .reduce((acc, curr) => {
      return acc.add(new Decimal(calculateEventDays(curr)));
    }, new Decimal(0));

  const amountStudyDays = items
    .filter(
      (event) =>
        event.project.name === STUDY_DAYS_TYPE &&
        event.status === HOLIDAY_STATUS_APPROVED
    )
    .reduce((acc, curr) => {
      return acc.add(new Decimal(calculateEventDays(curr)));
    }, new Decimal(0));

  const amountLeft = leaveAllowanceResponse?.data.length
    ? new Decimal(leaveAllowanceResponse?.data[0].days ?? 0).sub(
        amountAnnualHoliday
      )
    : new Decimal(0).sub(amountAnnualHoliday);

  const amountStudyDaysLeft = leaveAllowanceResponse?.data.length
    ? new Decimal(leaveAllowanceResponse?.data[0].studyDays ?? 0).sub(
        amountStudyDays
      )
    : new Decimal(0).sub(amountStudyDays);

  return (
    <div>
      {!loading && (
        <>
          <p>
            Annual Holiday: Spent /{' '}
            <span
              style={{
                color: amountLeft.toNumber() >= 0 ? '#21ba45' : '#db2828',
              }}
            >
              Left
            </span>
            : {formatDecimal(amountAnnualHoliday)} /{' '}
            <span
              style={{
                color: amountLeft.toNumber() >= 0 ? '#21ba45' : '#db2828',
              }}
            >
              {formatDecimal(amountLeft)}
            </span>
          </p>
          {amountStudyDays.toNumber() > 0 && (
            <p>
              Study Days: Spent /{' '}
              <span
                style={{
                  color:
                    amountStudyDaysLeft.toNumber() >= 0 ? '#21ba45' : '#db2828',
                }}
              >
                Left
              </span>
              : {formatDecimal(amountAnnualHoliday)} /{' '}
              <span
                style={{
                  color:
                    amountStudyDaysLeft.toNumber() >= 0 ? '#21ba45' : '#db2828',
                }}
              >
                {formatDecimal(amountStudyDaysLeft)}
              </span>
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default PageFooter;
