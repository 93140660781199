import { useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { Id } from '../types/base';
import useIsMounted from './useIsMounted';
import isEqual from 'lodash/isEqual';

const useNestedCRUDResource = (initialState: any) => {
  const resource = initialState && initialState?.resource;
  const defaultFilter = initialState?.defaultFilter || { where: {} };
  const parentId = initialState && initialState?.parentId;
  const defaultOrder = initialState && initialState?.defaultOrder;
  const searchFields = initialState && initialState?.searchFields;

  if (!resource || !parentId) {
    throw new Error('');
  }

  const isMounted = useIsMounted();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [filters, setFilters] = useState(defaultFilter);
  const [order, setOrder] = useState(defaultOrder);

  const [dropdownFilters, setDropdownFilter] = useState({
    where: defaultFilter?.where || {},
  });
  const [searchValue, setSearchValue] = useState('');

  const list = async () => {
    if (isMounted) {
      setLoading(true);
      try {
        const response = await resource.list(parentId, {
          filter: { ...filters, order: order },
        });
        isMounted && setData(response.data);
      } catch (e) {
        isMounted && setError(e);
      }
      isMounted && setLoading(false);
    }
  };

  const create = async (params: {}) => {
    setLoading(true);
    try {
      await resource.create(parentId, params);
    } catch (e) {
      setError(e);
    }

    await list();
  };

  const updateById = async (id: Id, params: {}) => {
    if (isMounted) {
      setLoading(true);
      try {
        await resource.updateAll(parentId, params, { where: { id } });
      } catch (e) {
        setError(e);
      }
      await list();
    }
  };

  const fetchUpdatedInstance = async (id: Id) => {
    await list();
  };

  const deleteByIds = async (ids: Id[]) => {
    setLoading(true);
    try {
      const filters = {
        where: { or: ids.map((id) => ({ id })) },
      };
      await resource.deleteAll(parentId, filters);
    } catch (e) {
      setError(e);
    }

    await list();
  };

  const sortData = (
    {
      orderingField,
      sortKey,
      transformValue,
    }: {
      orderingField: string;
      sortKey?: string;
      transformValue?: (value: any) => any;
    },
    reverse = false
  ) => {
    if (isMounted) {
      if (reverse) {
        setData(sortBy(data, sortKey || orderingField).reverse());
        setOrder(`${orderingField} DESC`);
      } else {
        setData(sortBy(data, sortKey || orderingField));
        setOrder(`${orderingField} ASC`);
      }
    }
  };

  const onDragSort = async (newData: any) => {
    if (isMounted) {
      setData(newData);
      await resource.reorder(
        parentId,
        newData.map((item: { id: string }) => item.id)
      );
    }
  };

  const updateFilters = (filters: any) => {
    setDropdownFilter(filters);
  };

  useEffect(() => {
    if (searchValue) {
      setFilters((prevState: any) => ({
        ...prevState,
        where: {
          and: [
            { ...dropdownFilters.where },
            {
              or: searchFields.map((field: string) => ({
                [field]: { ilike: `%${searchValue}%` },
              })),
            },
          ],
        },
      }));
    } else {
      setFilters((prevState: any) => {
        if (prevState && isEqual(prevState.where, dropdownFilters.where)) {
          return prevState;
        }
        return {
          ...prevState,
          where: dropdownFilters.where,
        };
      });
    }
  }, [dropdownFilters, searchValue, searchFields]);

  const search = (value: string) => {
    setSearchValue(value);
  };

  return {
    filters,
    updateFilters,
    data,
    list,
    create,
    fetchUpdatedInstance,
    updateById,
    deleteByIds,
    error,
    loading,
    sortData,
    search,
    dropdownFilters,
    onDragSort,
  };
};

export default useNestedCRUDResource;
