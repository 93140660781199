import Api from '../../Api';

class TeamMemberSalaryNotes {
  objectIdentifier = 'team-member-salary-notes';

  list = async (filters?: {} | null) => {
    return await Api.client.get(this.objectIdentifier, { params: filters });
  };

  createOrUpdateGeneralSalaryNote = async (teamMemberSalaryNotes: {
    note: string;
    teamMemberId: string;
  }) => {
    return await Api.client.post(
      `${this.objectIdentifier}/general/create-or-update`,
      teamMemberSalaryNotes
    );
  };

  createOrUpdateMonthlySalaryNote = async (teamMemberSalaryNotes: {
    note: string;
    teamMemberId: string;
    month: string;
  }) => {
    return await Api.client.post(
      `${this.objectIdentifier}/monthly/create-or-update`,
      teamMemberSalaryNotes
    );
  };
}

export default new TeamMemberSalaryNotes();
