import Api from '../Api';
import { Credentials } from '../../pages/auth/types';
import { Id } from '../../types/base';

class AuthResource {
  async login(credentials: Credentials) {
    Api.deleteAuthToken();
    const response = await Api.client.post('users/login', credentials);
    const { token } = response.data;
    Api.setAuthToken(token);

    return response;
  }

  async whoAmI() {
    const response = await Api.client.get('users/whoAmI');
    return response.data;
  }

  async updatePermissions(id: Id, name: string, value: boolean) {
    const requestData = { name, value };
    const response = await Api.client.patch(
      `users/${id}/update-permissions`,
      requestData
    );
    return response.data;
  }
}

export default new AuthResource();
