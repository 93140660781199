import React, { CSSProperties, ReactNode, useContext } from 'react';
import { ThemeContext } from '../../contexts/theme/ThemeContext';

const HomePageContainer = React.forwardRef<
  HTMLDivElement,
  {
    size: 'large' | 'small';
    children: ReactNode;
    title: ReactNode;
    style?: CSSProperties;
  }
>(({ size, children, style, title }, ref) => {
  const { theme } = useContext(ThemeContext);
  const backgroundColor = '#00a650';

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        boxSizing: 'border-box',
        borderColor: backgroundColor,
        borderWidth: size === 'large' ? '5px' : '4px',
        borderStyle: 'solid',
        borderRadius: '10px',
        borderTopWidth: '1px',
        backgroundColor: backgroundColor,
        ...style,
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor,
          textAlign: 'center',
          fontFamily: 'MarkOT-Medium',
          fontSize: size === 'large' ? '1.5em' : '1.25em',
          padding: size === 'large' ? '5px' : '2px',
          paddingTop: '3px',
        }}
      >
        <span style={{ color: '#fff' }}>{title}</span>
      </div>
      <div
        style={{
          borderRadius: '10px',
          padding: '10px',
          backgroundColor: theme === 'dark' ? '#1e1e1e' : '#fff',
          position: 'relative',
        }}
      >
        {children}
      </div>
    </div>
  );
});

export default HomePageContainer;
