import BaseCRUDResource from '../../BaseCRUDResource';
import Api from '../../Api';
import { Proposal } from '../../../pages/proposals/types';
import { Id } from '../../../types/base';

class ProposalResource extends BaseCRUDResource {
  objectIdentifier = 'proposals';

  list = async (filters?: any | null) => {
    const { search, where = {}, ...rest } = filters.filter;
    const { liveProjectsOnly, ...restWhere } = where;

    const params = {
      search: search,
      liveProjectsOnly: liveProjectsOnly || undefined,
      filter: { ...rest, where: restWhere },
    };
    const res = await Api.client.get(this.objectIdentifier, {
      params: params,
    });
    return {
      ...res,
      data: res.data.map((proposal: Proposal) => ({
        ...proposal,
        exchangeRateToBGN: +proposal.exchangeRateToBGN,
      })),
    };
  };

  findById = async (id: Id, filters?: any) => {
    const res = await Api.client.get(this.getObjectPath(id), {
      params: filters,
    });
    return {
      ...res,
      data: { ...res.data, exchangeRateToBGN: +res.data.exchangeRateToBGN },
    };
  };

  print = async (id: Id) => {
    return Api.client.post(
      `${this.objectIdentifier}/print/${id}`,
      {},
      { responseType: 'blob' }
    );
  };

  printAndDownload = async (id: Id) => {
    return Api.client.post(
      `${this.objectIdentifier}/printAndDownload/${id}`,
      {},
      { responseType: 'blob' }
    );
  };

  download = async (proposals: Array<number>) => {
    return Api.client.post(
      `${this.objectIdentifier}/download/`,
      { proposals },
      { responseType: 'blob' }
    );
  };
}

export default new ProposalResource();
