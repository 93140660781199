import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { Proposal } from '../types';
import ProposalResource from '../../../api/resources/proposals/ProposalResource';
import { dateToString } from '../../../utils/dateUtils';

type Props = {
  selected: Proposal | Proposal[];
  reload: () => Promise<void>;
};

const Download = (props: Props) => {
  const { selected } = props;

  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    let res;
    setLoading(true);

    try {
      if (Array.isArray(selected)) {
        const proposals = selected.map((proposal) => proposal.number);
        res = await ProposalResource.download(proposals);
      } else {
        res = await ProposalResource.printAndDownload(selected.id);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }

    if (res) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const a = document.createElement('a');

      a.style.display = 'none';
      a.href = url;
      a.download = `CC-PROPOSALS-${dateToString(new Date())}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Button
      loading={loading}
      size={Array.isArray(selected) ? 'huge' : 'large'}
      content="Download"
      onClick={handleDownload}
    />
  );
};

export default Download;
