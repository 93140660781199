import React, { useContext } from 'react';
import { Tab } from 'semantic-ui-react';
import TimesheetsTable from './TimesheetsTable';
import LeaveRequestsTable from './leave/LeaveRequestsTable';
import { UserContext } from '../auth/UserContext';
import { hasPermission } from '../auth/utils';
import { MANAGE_HOLIDAYS_PERMISSION } from '../team/permission';
import DocsTable from './docs/DocsTable';
import TabPane from '../../components/layout/TabPane';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

const TimesheetTabs = ({ addedStage }: { addedStage?: string }) => {
  const { user } = UserContext.useContainer();
  const { theme } = useContext(ThemeContext);

  const panes = [
    {
      menuItem: 'Timesheets',
      render: () => (
        <TabPane attached={false}>
          <TimesheetsTable addedStage={addedStage} />
        </TabPane>
      ),
    },
    {
      menuItem: 'Leave',
      render: () => (
        <TabPane attached={false}>
          <LeaveRequestsTable />
        </TabPane>
      ),
    },
  ];

  if (hasPermission(user, MANAGE_HOLIDAYS_PERMISSION)) {
    panes.push({
      menuItem: 'Docs',
      render: () => (
        <TabPane attached={false}>
          <DocsTable />
        </TabPane>
      ),
    });
  }

  return (
    <Tab
      menu={{
        tabular: true,
        inverted: theme === themes.dark,
        style: { background: 'transparent' },
      }}
      panes={panes}
    />
  );
};

export default TimesheetTabs;
