import React, { useEffect } from 'react';
import {
  SelectInputField,
  NumberInputField,
} from '../../../components/forms/fields';
import useNestedListData from '../../../hooks/useNestedListData';
import TeamMemberLeaveAllowanceResource from '../../../api/resources/team/TeamMemberLeaveAllowanceResource';
import { SelectValue } from '../../../types/forms';
import { LeaveAllowance } from '../types';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { Form } from 'semantic-ui-react';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import { DEFAULT_HOLIDAY_DAYS_KEY } from '../../settings/keys';

const currentYear = new Date().getFullYear();
const DEFAULT_ALLOWANCE = 22;

const YEAR_OPTIONS = [
  {
    key: (currentYear - 1).toString(),
    value: currentYear - 1,
    text: (currentYear - 1).toString(),
  },
  {
    key: currentYear.toString(),
    value: currentYear,
    text: currentYear.toString(),
  },
  {
    key: (currentYear + 1).toString(),
    value: currentYear + 1,
    text: (currentYear + 1).toString(),
  },
  {
    key: (currentYear + 2).toString(),
    value: currentYear + 2,
    text: (currentYear + 2).toString(),
  },
];

type Props = FormFieldsComponentTypeProps;

const LeaveAllowanceFields = (props: Props) => {
  const { instance, ...rest } = props;
  const { watch, setValue, getValues } = rest;

  const {
    list,
    data: leaveAllowances,
    loading: teamMemberLoading,
  } = useNestedListData(TeamMemberLeaveAllowanceResource);

  const {
    data: defaultLeaveAllowance,
    performRequest: getDefaultAllowance,
    loading,
  } = useAPIRequest(ProjectSettingResource.findByKey);

  const updateLeaveDays = React.useCallback(
    (year: SelectValue | number) => {
      if (year) {
        const existingAllowance: any = leaveAllowances.find(
          (leaveAllowance: LeaveAllowance) => leaveAllowance.year === year
        );
        setValue(
          'leaveAllowance.days',
          existingAllowance?.days ||
            defaultLeaveAllowance?.data?.value ||
            DEFAULT_ALLOWANCE
        );
        setValue(
          'leaveAllowance.studyDays',
          existingAllowance?.studyDays || undefined
        );
      } else {
        setValue('leaveAllowance.days', undefined);
        setValue('leaveAllowance.studyDays', undefined);
      }
    },
    [setValue, leaveAllowances, defaultLeaveAllowance]
  );

  const yearValue = watch('leaveAllowance.year');

  useEffect(() => {
    updateLeaveDays(yearValue);
  }, [leaveAllowances, yearValue, updateLeaveDays]);

  useEffect(() => {
    const setInitialFieldValue = async () => {
      await getDefaultAllowance(DEFAULT_HOLIDAY_DAYS_KEY);
      if (!getValues('leaveAllowance.year')) {
        setValue('leaveAllowance.year', new Date().getFullYear());
      }
      if (instance) {
        await list(instance.id);
      }
    };
    setInitialFieldValue();
  }, [instance, setValue, getValues, list, getDefaultAllowance]);

  const handleYearChange = (value: SelectValue) => {
    updateLeaveDays(value);
  };

  return (
    <>
      <Form.Group>
        <SelectInputField
          onChange={handleYearChange}
          label="Year"
          options={YEAR_OPTIONS}
          name="leaveAllowance.year"
          rules={{ required: false }}
          {...rest}
          width="8"
          submitHandler={() => {}}
        />
      </Form.Group>
      <Form.Group>
        <NumberInputField
          required
          width="8"
          label="Holiday Days"
          name="leaveAllowance.days"
          rules={{
            required: !!yearValue,
            min: { value: 0, message: 'Negative numbers are not allowed' },
          }}
          loading={loading || teamMemberLoading}
          {...rest}
        />
        <NumberInputField
          width="8"
          label="Leave Study Days"
          name="leaveAllowance.studyDays"
          loading={loading || teamMemberLoading}
          rules={{
            min: { value: 0, message: 'Negative numbers are not allowed' },
          }}
          {...rest}
        />
      </Form.Group>
    </>
  );
};

export default LeaveAllowanceFields;
