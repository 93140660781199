import React, { useContext } from 'react';
import {
  CalendarWeeksRow,
  OccupationRateWeekLabel,
  ResourceHeaderCell,
} from '../../components/calendar/components/Calendar.styled';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

const colors = {
  positive: '#2c662d',
  negative: '#9f3a38',
  warning: '#b4700b',
};

const OccupationRate = ({
  numberOfWeeks,
  numberOfDaysInWeek,
  resourceCellWidth,
  dateCellWidth,
  occupationRate,
}: {
  numberOfWeeks: number;
  numberOfDaysInWeek: number;
  resourceCellWidth: string;
  dateCellWidth: number;
  occupationRate: number[];
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <CalendarWeeksRow
      numberOfDays={numberOfWeeks * numberOfDaysInWeek}
      resourceCellWidth={resourceCellWidth}
      dateCellSize={dateCellWidth}
    >
      <ResourceHeaderCell inverted={theme === themes.dark} />
      {[...Array(numberOfWeeks)].map((n, idx) => (
        <OccupationRateWeekLabel
          key={idx}
          columnStart={idx * numberOfDaysInWeek + 2}
          span={numberOfDaysInWeek}
          isLastWeek={idx === numberOfWeeks - 1}
          backgroundColor={
            typeof occupationRate[idx] === 'number'
              ? occupationRate[idx] < 50
                ? colors.positive
                : occupationRate[idx] < 100
                ? colors.warning
                : colors.negative
              : 'inherit'
          }
        >
          {typeof occupationRate[idx] === 'number'
            ? `${Math.round(occupationRate[idx])}%`
            : ''}
        </OccupationRateWeekLabel>
      ))}
    </CalendarWeeksRow>
  );
};

export default OccupationRate;
