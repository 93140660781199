import React from 'react';
import TextInputField from './TextInputField';

type Props = {} & React.ComponentProps<typeof TextInputField>;

const EmailInputField = ({ rules, ...rest }: Props) => {
  return (
    <TextInputField
      rules={{
        pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Please enter a valid email address!',
        },
        ...rules,
      }}
      {...rest}
    />
  );
};

export default EmailInputField;
