import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import React from 'react';

const StyledButton = styled(Button)`
  &.ui.basic.button,
  &.ui.basic.button:focus,
  &.ui.basic.button:hover {
    box-shadow: none !important;
  }
`;

const ShowOverheadRolesAction = ({
  showOverheadRoles,
  toggleOverheadRoles,
}: {
  showOverheadRoles: boolean;
  toggleOverheadRoles: () => void;
}) => {
  return showOverheadRoles ? (
    <StyledButton
      basic
      icon="user times"
      size="massive"
      inverted
      title="Hide overhead roles"
      onClick={toggleOverheadRoles}
    />
  ) : (
    <StyledButton
      basic
      icon="user add"
      size="massive"
      inverted
      title="Show overhead roles"
      onClick={toggleOverheadRoles}
    />
  );
};

export default ShowOverheadRolesAction;
