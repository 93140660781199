import React, { useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

const StyledTextArea = styled(TextareaAutosize)`
  &,
  &:focus,
  &:focus-visible {
    width: 100%;
    resize: none;
    color: #fff;
    text-align: right;
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const NotesField = ({
  initialValue,
  onSubmit,
  style,
  maxRows,
  minRows,
}: {
  initialValue: string;
  onSubmit: (value: string) => void;
  style?: { height?: number | undefined };
  maxRows?: number;
  minRows?: number;
}) => {
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <StyledTextArea
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onBlur={() => {
        onSubmit(value);
      }}
      style={style}
      maxRows={maxRows}
      minRows={minRows}
    />
  );
};

export default NotesField;
