import React, { useState } from 'react';
import ResourceTable from '../../containers/resourceTable/ResourceTable';
import CreateFormFields from './forms/CreateFormFields';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import { TEAM_MEMBER_ROLE_OPTIONS } from './constants';
import EditFormFields from './forms/EditFormFields';
import { createContainer } from 'unstated-next';
import useCRUDResource from '../../hooks/useCRUDResource';
import Filters from './Filters';
import Archive from './actions/Archive';
import Activate from './actions/Activate';
import get from 'lodash/get';
import ViewAccess from './actions/ViewAccess';
import { FULL_ACCESS_PERMISSION, SALARIES_PERMISSION } from './permission';
import { TeamMember } from './types';
import Delete from './actions/Delete';
import { Id } from '../../types/base';
import { UserContext } from '../auth/UserContext';
import MailTo from '../../components/tables/MailTo';
import CostProductivity from './actions/CostProductivity';
import TeamMemberAvatar from '../resourceSchedule/TeamMemberAvatar';
import MiniProfile from '../userProfile/MiniProfile';
import SalariesChronology from './forms/SalariesChronology';
import { formatBGNSummary } from '../userProfile/financial-stats/YearSalaryChart';
import { roundToTwo } from '../../utils/numberUtils';
import { hasPermission } from '../auth/utils';

const TeamMemberNameColumn = ({ teamMember }: { teamMember: TeamMember }) => {
  const [profileOpen, setProfileOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setProfileOpen(true)}
        style={{ padding: '3px 0', cursor: 'pointer', maxWidth: 'max-content' }}
      >
        <TeamMemberAvatar entity={teamMember} /> {teamMember.name}{' '}
      </div>
      {profileOpen && (
        <MiniProfile
          teamMemberId={teamMember.id}
          onClose={() => setProfileOpen(false)}
        />
      )}
    </>
  );
};

const Table = () => {
  const config = [
    {
      label: 'Name',
      accessor: (teamMember: TeamMember) => {
        return <TeamMemberNameColumn teamMember={teamMember} />;
      },
      orderingField: 'name',
      key: 'name',
    },
    {
      label: 'Initials',
      accessor: 'initials' as const,
      orderingField: 'initials',
      key: 'initials',
    },
    {
      label: 'Role',
      accessor: (teamMember: TeamMember) =>
        TEAM_MEMBER_ROLE_OPTIONS.find((role) => role.value === teamMember.role)
          ?.text,
      orderingField: 'role',
      key: 'role',
    },
    {
      label: 'Email',
      accessor: (teamMember: TeamMember) => (
        <MailTo email={teamMember.email} label={teamMember.email} />
      ),
      orderingField: 'user.email',
      sortKey: 'email',
      key: 'email',
    },
    {
      label: 'Mobile',
      accessor: 'telephone' as const,
      orderingField: null,
      key: 'telephone',
    },
    {
      label: 'Productivity Rate',
      accessor: 'productivity' as const,
      orderingField: 'productivity',
      key: 'productivity',
      permission: FULL_ACCESS_PERMISSION,
      editable: {
        component: {
          type: 'number' as const,
          props: {
            rules: {
              min: {
                value: 0,
                message: 'Negative numbers are not allowed',
              },
            },
          },
        },
        valueAccessor: 'productivity' as const,
      },
    },
    {
      label: 'Cost',
      accessor: 'cost' as const,
      orderingField: 'cost',
      key: 'cost',
      permission: FULL_ACCESS_PERMISSION,
      editable: {
        component: {
          type: 'number' as const,
          props: {
            rules: {
              min: {
                value: 0,
                message: 'Negative numbers are not allowed',
              },
            },
          },
        },
        valueAccessor: 'cost' as const,
      },
    },
    {
      label: 'Base salary',
      accessor: 'baseSalary' as const,
      orderingField: 'baseSalary',
      key: 'baseSalary',
      permission: SALARIES_PERMISSION,
      editable: {
        component: {
          type: 'number' as const,
          props: {
            rules: {
              min: {
                value: 0,
                message: 'Negative numbers are not allowed',
              },
            },
          },
        },
        valueAccessor: 'baseSalary' as const,
      },
    },
    {
      label: 'Bank card salary',
      accessor: 'salaryBankCard' as const,
      orderingField: 'salaryBankCard',
      key: 'salaryBankCard',
      permission: SALARIES_PERMISSION,
      editable: {
        component: {
          type: 'number' as const,
          props: {
            rules: {
              min: {
                value: 0,
                message: 'Negative numbers are not allowed',
              },
            },
          },
        },
        valueAccessor: 'salaryBankCard' as const,
      },
    },
    {
      label: 'Bonus Threshold',
      accessor: 'minimalBonus' as const,
      orderingField: 'minimalBonus',
      key: 'minimalBonus',
      permission: SALARIES_PERMISSION,
      editable: {
        component: {
          type: 'number' as const,
        },
        valueAccessor: 'minimalBonus' as const,
      },
    },
    {
      label: 'Average Monthly Wage',
      accessor: (teamMember: TeamMember) =>
        formatBGNSummary(teamMember.averageSalary ?? 0),
      key: 'averageSalary',
      orderingField: 'averageSalary',
      permission: SALARIES_PERMISSION,
    },
    {
      label: 'Growth',
      accessor: (teamMember: TeamMember) =>
        formatBGNSummary(teamMember.sixMonthsSalaryGrowth ?? 0),
      key: 'sixMonthsSalaryGrowth',
      orderingField: 'sixMonthsSalaryGrowth',
      permission: SALARIES_PERMISSION,
    },
    {
      label: 'Images per Month',
      accessor: (teamMember: TeamMember) =>
        `${roundToTwo(teamMember.averageImgCount ?? 0)} CGI`,
      key: 'averageImgCount',
      orderingField: 'averageImgCount',
      permission: SALARIES_PERMISSION,
    },
    {
      label: 'Access',
      accessor: (teamMember: TeamMember) =>
        teamMember.fullAccess ? 'Full' : 'Limited',
      orderingField: 'fullAccess',
      key: 'access',
    },
  ];
  const TeamMembersContainer = createContainer(useCRUDResource);
  const { user } = UserContext.useContainer();

  const createInstanceConfig = {
    title: 'Create team member',
    formFields: CreateFormFields,
    formId: 'team-members-create-form',
  };

  const editInstanceConfig = {
    title: 'Edit team member',
    formFields: EditFormFields,
    formId: 'team-members-edit-form',
    nameAccessor: 'name' as const,
    activateInputOnClick: true,
    nestedContent: hasPermission(user, SALARIES_PERMISSION)
      ? SalariesChronology
      : undefined,
  };

  const onTeamMemberEdit = (id: Id) => {
    if (id === get(user, 'teamMemberId')) {
      // reloadUser();
    }
  };

  return (
    <TeamMembersContainer.Provider
      initialState={{
        resource: TeamMemberResource,
        defaultFilter: {
          where: { archived: false },
        },
        defaultOrder: ['role ASC', 'name ASC'],
        searchFields: ['name'],
      }}
    >
      <ResourceTable
        columns={config}
        newInstanceConfig={createInstanceConfig}
        editInstanceConfig={editInstanceConfig}
        stateContainer={TeamMembersContainer}
        filterComponent={Filters}
        forms={[
          {
            key: 'view-access',
            component: ViewAccess,
            permission: FULL_ACCESS_PERMISSION,
          },
        ]}
        editFormActions={[
          {
            key: 'archive',
            condition: (teamMember: TeamMember) =>
              teamMember.archived === false,
            component: Archive,
            permission: FULL_ACCESS_PERMISSION,
          },
          {
            key: 'activate',
            condition: (teamMember: TeamMember) => teamMember.archived === true,
            component: Activate,
            permission: FULL_ACCESS_PERMISSION,
          },
          {
            key: 'access',
            condition: () => true,
            component: ViewAccess,
            permission: FULL_ACCESS_PERMISSION,
          },
          {
            key: 'delete',
            condition: (teamMember: TeamMember) =>
              teamMember.archived === true &&
              teamMember.userId !== get(user, 'id'),
            component: Delete,
            permission: FULL_ACCESS_PERMISSION,
          },
          {
            key: 'cost-productivity',
            condition: () => true,
            component: CostProductivity,
            permission: FULL_ACCESS_PERMISSION,
          },
        ]}
        bulkActions={[
          {
            key: 'archive',
            condition: (filters: any) => filters?.where?.archived === false,
            component: Archive,
            permission: FULL_ACCESS_PERMISSION,
          },
          {
            key: 'activate',
            condition: (filters: any) => filters?.where?.archived,
            component: Activate,
            permission: FULL_ACCESS_PERMISSION,
          },
          {
            key: 'delete',
            condition: (filters: any) => filters?.where?.archived,
            component: Delete,
            permission: FULL_ACCESS_PERMISSION,
          },
        ]}
        onEdit={onTeamMemberEdit}
      />
    </TeamMembersContainer.Provider>
  );
};

export default Table;
