import { useCallback, useState } from 'react';
import BaseNestedCRUDResource from '../api/BaseNestedCRUDResource';
import { Id } from '../types/base';
import useIsMounted from './useIsMounted';

const useNestedListData = (
  resource: BaseNestedCRUDResource,
  defaultFilter?: any
) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [filters, setFilters] = useState(defaultFilter);

  const isMounted = useIsMounted();

  const list = useCallback(
    async (parentId: Id) => {
      setLoading(true);
      try {
        const response = await resource.list(parentId, { filter: filters });
        if (isMounted) setData(response.data);
      } catch (e) {
        if (isMounted) setError(e);
      }
      if (isMounted) setLoading(false);
    },
    [filters, isMounted, resource]
  );

  return {
    list,
    data,
    loading,
    error,
    filters,
    setFilters,
  };
};

export default useNestedListData;
