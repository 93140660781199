import React, { useCallback, useContext } from 'react';
import CreateInvoiceFormFields from './fields/CreateInvoiceFormFields';
import { createContainer, useContainer } from 'unstated-next';
import { FULL_ACCESS_PERMISSION } from '../team/permission';
import Delete from './actions/Delete';
import {
  ColumnConfig,
  default as ResourceTable,
} from '../../containers/resourceTable/ResourceTable';
import { Invoice, InvoiceWithRelations } from './types';
import DateFormatter, {
  dateToString,
  toDateObject,
} from '../../utils/dateUtils';
import {
  addDays,
  differenceInCalendarDays,
  format,
  isAfter,
  isSameDay,
} from 'date-fns';
import Filters from './Filters';
import InfoBox from './InfoBox';
import InvoiceResource from '../../api/resources/invoices/InvoiceResource';
import EditInvoiceFormFields from './fields/EditInvoiceFormFields';
import TableFooter from './TableFooter';
import InvoiceEntriesTable from './invoiceEntries/Table';
import { getProjectFullName } from '../projects/utils';
import InvoicePreview from './actions/InvoicePreview';
import Download from './actions/Download';
import ProjectItemsInvoiceTable from './projectItems/ProjectItemsInvoiceTable';
import InvoiceInvoiceEntryResource from '../../api/resources/invoices/InvoiceInvoiceEntryResource';
import useNestedCRUDResource from '../../hooks/useNestedCrudResource';
import ProjectProjectItemResource from '../../api/resources/projects/ProjectProjectItemResource';
import {
  InvoiceContainer,
  OpenedInvoiceContext,
  ProjectsToBeInvoicedContainer,
} from './InvoicesTab';
import Decimal from 'decimal.js-light';
import Segment from '../../components/layout/Segment';
import Heading from '../../components/layout/Heading';
import { formatNumber } from '../../utils/numberUtils';

export const createInvoiceInstanceConfig = {
  title: 'Create invoice',
  formFields: CreateInvoiceFormFields,
  formId: 'invoice-create-form',
};

export const invoiceEditFormActions = [
  {
    key: 'delete',
    condition: () => true,
    component: Delete,
    permission: FULL_ACCESS_PERMISSION,
  },
  {
    key: 'preview',
    condition: () => true,
    component: InvoicePreview,
    permission: FULL_ACCESS_PERMISSION,
  },
  {
    key: 'download',
    condition: () => true,
    component: Download,
    permission: FULL_ACCESS_PERMISSION,
  },
];

function equalProps(
  prevProps: { instance: InvoiceWithRelations },
  nextProps: { instance: InvoiceWithRelations }
) {
  return (
    prevProps.instance.projectId === nextProps.instance.projectId &&
    prevProps.instance.currency === nextProps.instance.currency
  );
}

export const InvoiceEntriesContainer = createContainer(useNestedCRUDResource);
export const ProjectItemsContainer = createContainer(useNestedCRUDResource);

const InvoiceEditNestedContent = React.memo(
  ({
    instance,
  }: {
    instance: InvoiceWithRelations;
    reload: () => Promise<void>;
  }) => {
    return (
      <>
        <InvoiceEntriesContainer.Provider
          initialState={{
            resource: InvoiceInvoiceEntryResource,
            parentId: instance.id,
            searchFields: ['description'],
            defaultOrder: ['sequence ASC'],
            defaultFilter: {
              include: [{ relation: 'projectItem' }],
            },
          }}
        >
          <ProjectItemsContainer.Provider
            initialState={{
              resource: ProjectProjectItemResource,
              parentId: instance.projectId,
              searchFields: ['name'],
              defaultOrder: [
                'type ASC',
                'createDate ASC',
                'name ASC',
                'costPerItem ASC',
              ],
            }}
          >
            <Heading as="h4">
              Invoice Entries{' '}
              <span style={{ fontStyle: 'italic' }}>
                (All amounts are excluding VAT)
              </span>
            </Heading>
            <Segment>
              <InvoiceEntriesTable
                invoiceId={instance.id}
                invoiceCurrency={instance.currency}
                invoice={instance}
              />
            </Segment>
            <Heading as="h4">
              Project Items & Expenses{' '}
              <span style={{ fontStyle: 'italic' }}>
                (All amounts are excluding VAT)
              </span>
            </Heading>
            <Segment>
              <ProjectItemsInvoiceTable
                projectId={instance.projectId}
                projectCurrency={instance.project.currency}
                invoice={instance}
              />
            </Segment>
          </ProjectItemsContainer.Provider>
        </InvoiceEntriesContainer.Provider>
      </>
    );
  },
  equalProps
);

export const invoiceEditInstanceConfig = {
  title: 'Edit invoice',
  formFields: EditInvoiceFormFields,
  formId: 'invoices-edit-form',
  nameAccessor: 'number' as const,
  activateInputOnClick: true,
  nestedContent: InvoiceEditNestedContent,
};

type Props = {
  filters?: {
    where?: any;
  };
};

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0');

const Table = (props: Props) => {
  const config: ColumnConfig<InvoiceWithRelations>[] = [
    {
      label: 'Invoice No',
      accessor: (invoice: InvoiceWithRelations) => zeroPad(invoice.number, 10),
      orderingField: 'number',
      key: 'number',
      cellWidth: '1',
    },
    {
      label: 'Project',
      accessor: (invoice: InvoiceWithRelations) =>
        getProjectFullName(invoice.project),
      orderingField: 'projectId',
      key: 'projectId',
      cellWidth: '5',
    },
    {
      label: 'Client',
      accessor: (invoice: InvoiceWithRelations) => invoice.client.name,
      orderingField: 'clientId',
      key: 'clientId',
    },
    {
      label: 'VAT',
      accessor: 'VAT',
      orderingField: 'vat',
      sortTransformValue: (invoice) => Number(invoice.vat),
      key: 'vat',
      cellWidth: '1',
    },
    {
      label: 'Currency',
      accessor: 'currency',
      orderingField: 'currency',
      key: 'currency',
      cellWidth: '1',
    },
    {
      label: 'A. excl. VAT',
      accessor: (invoice: InvoiceWithRelations) =>
        formatNumber(new Decimal(invoice.amountExcludingVAT).toNumber()),
      orderingField: 'amountExcludingVAT',
      sortTransformValue: (invoice) => Number(invoice.amountExcludingVAT),
      key: 'amountExcludingVAT',
      cellWidth: '1',
    },
    {
      label: 'Amount',
      accessor: (invoice: InvoiceWithRelations) =>
        formatNumber(new Decimal(invoice.amountWithVAT).toNumber()),
      orderingField: 'amountWithVAT',
      sortTransformValue: (invoice) => Number(invoice.amountWithVAT),
      key: 'amountWithVAT',
      cellWidth: '1',
    },
    {
      label: 'Issued',
      accessor: (invoice: InvoiceWithRelations) => {
        if (invoice.paid) {
          return DateFormatter.format(toDateObject(invoice.issueDate));
        } else {
          const date = toDateObject(invoice.issueDate);
          const paidUntil = addDays(date, invoice.payableWithin);
          const today = toDateObject(dateToString(new Date()));

          if (isAfter(paidUntil, today) || isSameDay(paidUntil, today)) {
            const differenceInDays = differenceInCalendarDays(today, date);

            if (differenceInDays === 0) {
              return 'Today';
            }
            return `${differenceInDays}d ago`;
          } else {
            const differenceInDays = Math.abs(
              differenceInCalendarDays(paidUntil, today)
            );
            return (
              <span style={{ color: '#c42415', display: 'block' }}>
                {`${differenceInDays}d over`}
              </span>
            );
          }
        }
      },
      orderingField: 'issueDate',
      key: 'issueDate',
      cellWidth: '1',
    },
    {
      label: 'Paid',
      accessor: (invoice: InvoiceWithRelations) =>
        invoice.credit ? (
          'Credit Note'
        ) : !invoice.paid ? (
          <span style={{ color: '#c42415', display: 'block' }}>No</span>
        ) : (
          'Yes'
        ),
      titleAccessor: (item: InvoiceWithRelations) =>
        item.paid && item.paidDate
          ? `Paid on ${format(new Date(item.paidDate), 'dd/MMM/yyyy')}`
          : '',
      key: 'paid',
      orderingField: 'paid',
      cellWidth: '1',
    },
  ];

  const projectsToBeInvoicedContainer = useContainer(
    ProjectsToBeInvoicedContainer
  );
  const { invoiceId: openedInvoiceId, setOpenedInvoiceId } = useContext(
    OpenedInvoiceContext
  );

  const handleEditFormClose = useCallback(async (instance: Invoice) => {
    setOpenedInvoiceId('');
    await projectsToBeInvoicedContainer.list();
    await InvoiceResource.print(instance.id);
    // eslint-disable-next-line
  }, []);

  return (
    <ResourceTable
      editInstanceId={openedInvoiceId}
      columns={config}
      newInstanceConfig={createInvoiceInstanceConfig}
      stateContainer={InvoiceContainer}
      filterComponent={Filters}
      infoBox={InfoBox}
      editFormActions={invoiceEditFormActions}
      editInstanceConfig={invoiceEditInstanceConfig}
      getRowAdditionalProps={(instance: InvoiceWithRelations) => {
        if (instance.credit) {
          return { warning: true };
        }
        return {};
      }}
      bulkActions={[
        {
          key: 'delete',
          condition: () => true,
          component: Delete,
          permission: FULL_ACCESS_PERMISSION,
        },
        {
          key: 'download',
          condition: () => true,
          component: Download,
          permission: FULL_ACCESS_PERMISSION,
        },
      ]}
      onEditFormClose={handleEditFormClose}
      footerComponent={TableFooter}
    />
  );
};

export default Table;
