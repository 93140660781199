import React from 'react';
import { Form } from 'semantic-ui-react';
import { TextInputField } from '../../../components/forms/fields';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  addressBGValidationpattern,
  cityBGValidationPattern,
  nameBGValidationpattern,
} from '../../../components/validationRules';

type Props = FormFieldsComponentTypeProps;

const CompanyInvoiceDataFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;

  return (
    <>
      <Form.Group>
        <TextInputField
          label="Company nickname"
          name="label"
          placeholder="Company nickname"
          width="8"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <TextInputField
          label="Name"
          name="name"
          placeholder="Company name"
          {...formMethods}
        />
        <TextInputField
          placeholder="Company name (BG)"
          label="Name (BG)"
          name="nameBG"
          {...formMethods}
          rules={{
            required: {
              value: true,
              message: 'Please enter the company name.',
            },
            ...nameBGValidationpattern,
          }}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <TextInputField
          label="Identification No."
          name="identificationNumber"
          {...formMethods}
          rules={{
            required: {
              value: true,
              message: 'Please enter the identification number.',
            },
          }}
        />
        <TextInputField
          label="VAT Number"
          name="VATNumber"
          placeholder="VAT Number"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <TextInputField label="Address" name="address" {...formMethods} />
        <TextInputField
          label="Address (BG)"
          name="addressBG"
          rules={{
            required: {
              value: true,
              message: 'Please enter the address in Bulgarian.',
            },
            ...addressBGValidationpattern,
          }}
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <TextInputField label="City" name="city" {...formMethods} />
        <TextInputField
          label="City (BG)"
          name="cityBG"
          rules={{
            required: {
              value: true,
              message: 'Please enter the city in Bulgarian.',
            },
            ...cityBGValidationPattern,
          }}
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <TextInputField label="Country" name="country" {...formMethods} />
        <TextInputField
          label="Country (BG)"
          name="countryBG"
          rules={{
            required: {
              value: true,
              message: 'Please enter the country in Bulgarian.',
            },
            ...nameBGValidationpattern,
          }}
          {...formMethods}
        />
      </Form.Group>
    </>
  );
};

export default CompanyInvoiceDataFormFields;
