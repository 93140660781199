import React, { ReactNode, useContext } from 'react';
import {
  Control,
  Controller,
  UseFormMethods,
  ValidationRules,
} from 'react-hook-form';
import { Dropdown, DropdownProps, Form, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledFormField } from './Fields.styled';
import get from 'lodash/get';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import SketchPicker from 'react-color/lib/components/sketch/Sketch';
import tinycolor from 'tinycolor2';
import { RGBColor } from 'react-color';

type Props = {
  label: ReactNode;
  name: string;
  control: Control;
  defaultValue?: string;
  submitHandler: (data: any) => any;
  activateInputOnClick?: boolean;
  rules?: ValidationRules;
  hideDropdownIcon?: boolean;
  pointing?: DropdownProps['pointing'];
} & React.ComponentProps<typeof Form.Field> &
  Pick<UseFormMethods, 'errors'>;

const StyledDropdown = styled(
  ({ color, inverted, ...rest }: DropdownProps & { color: string }) => (
    <Dropdown inverted={inverted} {...rest} />
  )
)`
  &.ui.dropdown {
    padding-right: 4px;
    box-shadow: none;
  }

  &.ui.icon.basic.button i.icon.square.full {
    color: ${(props) => props.color || '#999999'} !important;
  }

  &.ui.icon.basic.button {
    background-color: transparent !important;
  }

  &.ui.icon.basic.button.dropdown .dropdown.icon {
    left: 12px;
    top: 2px;
    color: #a9a9a9;
  }

  &.ui.dropdown > .text > .icon {
    margin-right: 0 !important;
  }

  &.ui.dropdown .sketch-picker > div:last-child div {
    width: 11px !important;
    height: 11px !important;
    margin: 0 5px 5px 0 !important;
  }

  ${(props) =>
    props.inverted &&
    `&.ui.dropdown .sketch-picker {
    background: #343434 !important;
    color: #a9a9a9;
  }
    
  &.ui.dropdown .sketch-picker label {
    color: #a9a9a9 !important;
  }
  `}
`;

const COLORS = [
  '#980000',
  '#FF0000',
  '#FF9900',
  '#FFFF00',
  '#00FF00',
  '#00FFFF',
  '#4A86E8',
  '#0000FF',
  '#9900FF',
  '#FF00FF',
  '#E6B8AF',
  '#F4CCCC',
  '#FCE5CD',
  '#FFF2CC',
  '#D9EAD3',
  '#D0E0E3',
  '#C9DAF8',
  '#CFE2F3',
  '#D9D2E9',
  '#EAD1DC',
  '#DD7E6B',
  '#EA9999',
  '#F9CB9C',
  '#FFE599',
  '#B6D7A8',
  '#A2C4C9',
  '#A4C2F4',
  '#9FC5E8',
  '#B4A7D6',
  '#D5A6BD',
  '#CC4125',
  '#E06666',
  '#F6B26B',
  '#FFDE00',
  '#93C47D',
  '#76A5AF',
  '#6D9EEB',
  '#6FA8DC',
  '#8E7CC3',
  '#C27BA0',
  '#A61C00',
  '#CC0000',
  '#E69138',
  '#F1C232',
  '#6AA84F',
  '#45818E',
  '#3C78D8',
  '#3D85C6',
  '#674EA7',
  '#A64D79',
  '#85200C',
  '#990000',
  '#B45F06',
  '#BF9000',
  '#38761D',
  '#134F5C',
  '#1155CC',
  '#0B5394',
  '#351C75',
  '#741B47',
  '#5B0F00',
  '#660000',
  '#783F04',
  '#7F6000',
  '#274E13',
  '#0C343D',
  '#1C4587',
  '#073763',
  '#20124D',
  '#4C1130',
  '#000000',
  '#434343',
  '#666666',
  '#999999',
  '#B7B7B7',
  '#CCCCCC',
  '#D9D9D9',
  '#EFEFEF',
  '#F3F3F3',
  '#FFFFFF',
];

const colorResultToRGBAstring = (color: RGBColor) => {
  return tinycolor({
    r: color.r,
    g: color.g,
    b: color.b,
    a: color.a,
  }).toRgbString();
};

const ColorPickerField = (props: Props) => {
  const {
    label,
    activateInputOnClick,
    control,
    submitHandler,
    getValues,
    defaultValue,
    name,
    rules,
    errors,
    inline = true,
    hideDropdownIcon = false,
    pointing,
  } = props;

  const fieldError = get(errors, name);
  const { theme } = useContext(ThemeContext);

  return (
    <StyledFormField
      activateInputOnClick={activateInputOnClick}
      error={!!fieldError}
      inline={inline}
      required={
        typeof rules?.required === 'object'
          ? !!rules?.required?.value
          : !!rules?.required
      }
    >
      <label>{label}:</label>
      <Controller
        control={control}
        render={({ value, onChange }) => (
          <StyledDropdown
            style={{ boxShadow: 'none' }}
            inverted={theme === themes.dark}
            color={value}
            button
            className="icon"
            basic
            icon={hideDropdownIcon ? null : 'dropdown'}
            text={
              <span>
                {' '}
                <Icon name="square full" />
              </span>
            }
            pointing={pointing}
          >
            <Dropdown.Menu
              onClick={(e: any) => e.stopPropagation()}
              onKeyPress={(e: any) => e.stopPropagation()}
            >
              <Dropdown.Item>
                <SketchPicker
                  color={value ?? ''}
                  //@ts-ignore
                  width={250}
                  presetColors={COLORS}
                  onChangeComplete={(color) => {
                    onChange(colorResultToRGBAstring(color.rgb));
                    if (activateInputOnClick) {
                      submitHandler(getValues());
                    }
                  }}
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </StyledDropdown>
        )}
        name={name}
        defaultValue={defaultValue || undefined}
      />
    </StyledFormField>
  );
};

export default ColorPickerField;
