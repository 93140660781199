import {
  differenceInBusinessDays,
  differenceInCalendarDays,
  format,
  parse,
} from 'date-fns';

export default new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

export const TimeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
});

export const getFirstDayOfWeekByDate = (date: Date) => {
  const firstDay = new Date(date.getTime());
  if (date.getDay() === 0) {
    firstDay.setDate(date.getDate() - 6);
  } else {
    firstDay.setDate(date.getDate() - (date.getDay() - 1));
  }
  return firstDay;
};

export const dateToString = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const toDateObject = (date: string) => {
  return parse(date, 'yyyy-MM-dd', new Date());
};

// a and b are javascript Date objects
// b is the earlier date
export const dateDiffInDays = (a: Date, b: Date, skipWeekends?: boolean) => {
  if (skipWeekends) {
    if (a.getDay() === 6 || a.getDay() === 0) {
      return differenceInBusinessDays(a, b);
    }
    return differenceInBusinessDays(a, b) + 1;
  }
  return differenceInCalendarDays(a, b) + 1;
};

export const getWeek = (firstDay: Date) =>
  Array.from(Array(7).keys()).map((day) => {
    const date = new Date(firstDay.getTime());
    date.setDate(firstDay.getDate() + day);
    return date;
  });

export const getPreviousDate = (date: Date) => {
  const d = new Date(date.getTime());
  d.setDate(d.getDate() - 1);
  d.setUTCHours(0, 0, 0, 0);
  return dateToString(d);
};

export const getNextDate = (date: Date) => {
  const d = new Date(date.getTime());
  d.setDate(d.getDate() + 1);
  d.setUTCHours(0, 0, 0, 0);
  return dateToString(d);
};
