import React from 'react';
import { Table } from 'semantic-ui-react';

type Props = {} & React.ComponentProps<typeof Table.Body>;

const Body = ({ children, ...rest }: Props) => {
  return <Table.Body {...rest}>{children}</Table.Body>;
};

export default Body;
