import React, { RefObject } from 'react';
import { Client } from '../types';
import ClientResource from '../../../api/resources/clients/ClientResource';
import DeleteAction, {
  DeleteButton,
} from '../../../components/actions/DeleteAction';
import Confirm from '../../../components/dialogs/Confirm';

type Props = {
  selected: Client | Client[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const handleDelete = async () => {
    if (Array.isArray(selected)) {
      const filters = {
        where: { or: selected.map((client) => ({ id: client.id })) },
      };
      await ClientResource.deleteAll(filters);
      await reload();
    } else {
      await ClientResource.deleteById(selected.id);
      await reload();
    }
  };

  return Array.isArray(selected) &&
    selected.some((client) => !!client.projects) ? (
    <Confirm
      content="The clients cannot be deleted because some of them have projects. Please delete the projects first."
      trigger={DeleteButton}
    />
  ) : (
    <DeleteAction
      confirmText={`Are you sure you want to delete the client${
        Array.isArray(selected) && selected.length > 1 ? 's' : ''
      }?`}
      callback={handleDelete}
      mountNode={mountNode}
    />
  );
};

export default Delete;
