import React from 'react';
import styled from 'styled-components';
import { Form, FormFieldProps, Label } from 'semantic-ui-react';

export const ErrorLabel = styled(Label)`
  &.ui.label {
    color: #9f3a38;
    background-color: #fff;
    border-color: #9f3a38;
    border: 1px solid #9f3a38;
  }
`;

export const StyledFormField = styled(
  ({
    activateInputOnClick,
    ...rest
  }: FormFieldProps & { activateInputOnClick?: boolean }) => (
    <Form.Field {...rest} />
  )
)`
  &.field .ui.dropdown .menu > .item {
    padding: 0.4em 0.5em !important;
    font-size: 0.96em;
  }

  &.field .ui.selection.dropdown {
    min-height: 0;
    background-color: inherit;
  }

  &.field .ui.multiple.search.dropdown {
    padding: 0.12em 0.5em;
  }

  &.field .ui.multiple.search.dropdown > input.search {
    margin: 0;
  }

  //&.field .selection.dropdown.multiple .dropdown.icon {
  //  padding: 0.8em;
  //}

  .ui.labeled.input > .label:not(.corner) {
    font-size: 0.8em;
    padding-top: 0.65em;
  }

  ${(props: { activateInputOnClick?: boolean }) =>
    props.activateInputOnClick &&
    `
      &.field:not(.error) input:not(:focus):not([type]),
      &.field:not(.error) input[type="number"]:not(:focus),
      &.field:not(.error) input[type="text"]:not(:focus),
      &.field:not(.error) input[type="password"]:not(:focus),
      &.field:not(.error) input[readonly=""] {
        border: 1px solid transparent;
        background: transparent;
        color: inherit;
     }
     
     &.field .ui.selection.dropdown {
        background-color: inherit;
     }
     
     &.field .ui.selection.dropdown:not(.active) {
        border: none;
     }
     
     &.field.warning > .selection.dropdown:not(.active) .divider.text {
        color: #F2711CAB !important;
     }
     
     &.field.warning > .selection.dropdown.active .warning-icon {
        display: none !important;
     }
     
     &.field > .selection.dropdown:not(.active) {
        background: transparent !important; 
        border: 1px solid transparent;
     }
     
     &.field > .selection.dropdown:not(.active):not(.multiple) .dropdown.icon {
        visibility: hidden;
     }
     
     &.field > .selection.dropdown:not(.active) input {
        caret-color: transparent;
     }
     
     &.required.field:not(:focus-within) label::after {
        content: '';
     }
     
     input[type="number"]:not(:focus) {
        -moz-appearance: textfield;
     }
  `}
`;
