import React from 'react';
import { Table } from 'semantic-ui-react';

type Props = {} & React.ComponentProps<typeof Table.HeaderCell>;

const HeaderCell = ({ children, ...rest }: Props) => {
  return (
    <Table.HeaderCell {...rest} collapsing>
      {children}
    </Table.HeaderCell>
  );
};

export default HeaderCell;
