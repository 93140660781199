import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import get from 'lodash/get';
import { Id } from '../../types/base';
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage';
import {
  differenceInCalendarDays,
  endOfWeek,
  isBefore,
  isWeekend,
  subDays,
} from 'date-fns';
import useClickOutside from '../../hooks/useClickOutside';
import CreateEventOrMilestoneForm from './forms/CreateEventOrMilestoneForm';
import EditCalendarItemForm from './forms/EditCalendarItemForm';
import RangeLabel from './components/RangeLabel';
import {
  BaseEvent,
  BaseMilestone,
  CalendarContainerProps,
  CalendarPresentationalProps,
} from './types';
import CalendarHeader from './components/CalendarHeader';
import CalendarResourceRow from './components/CalendarResourceRow';
import useSize from '../../hooks/useSize';
import { getByAccessor } from '../../utils/componentUtils';
import { dateToString, toDateObject } from '../../utils/dateUtils';
import { editInstanceConfig } from '../../pages/clients/Table';
import isEmpty from 'lodash/isEmpty';
import EditForm from '../../containers/resourceTable/EditForm';
import { MANAGE_HOLIDAYS_PERMISSION } from '../../pages/team/permission';
import { hasPermission } from '../../pages/auth/utils';
import { UserContext } from '../../pages/auth/UserContext';
import { HolidayScheduleContext } from '../../pages/timesheets/HolidayResourceSchedule';
import { User } from '../../pages/auth/types';
import { CalendarWrapper } from './components/Calendar.styled';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import { Modal } from '../dialogs';
import useAPIRequest from '../../hooks/useAPIRequest';
import OccupationRateResource from '../../api/resources/projectSchedule/OccupationRateResource';

type Props<
  Resource extends { id: Id },
  Event extends BaseEvent,
  Milestone extends BaseMilestone
> = CalendarPresentationalProps<Resource, Event, Milestone> &
  CalendarContainerProps<Resource, Event, Milestone>;

export const checkEditability = (
  event: any,
  user: User | null,
  holidayContext: boolean,
  editPermission?: string
) => {
  if (editPermission && !hasPermission(user, editPermission)) {
    return false;
  }

  if (
    get(event, ['project', 'isHolidayForAllUsers']) &&
    !hasPermission(user, MANAGE_HOLIDAYS_PERMISSION)
  ) {
    return false;
  }

  if (
    get(event, ['project', 'isHoliday']) &&
    !holidayContext &&
    !hasPermission(user, MANAGE_HOLIDAYS_PERMISSION)
  ) {
    return false;
  }

  return true;
};

const CalendarComponent = <
  Resource extends { id: Id },
  Event extends BaseEvent,
  Milestone extends BaseMilestone
>(
  props: Props<Resource, Event, Milestone> & {
    onEditResourceInstanceClose: () => any;
  }
) => {
  const {
    eventColorAccessor = 'color',
    eventIdAccessor = 'id',
    eventNameAccessor = 'name',
    editEventLabel = 'Edit event',
    createEventLabel = 'Create event',
    resourceIdAccessor = 'id',
    resourceNameAccessor = 'name',
    milestoneNameAccessor = 'name',
    resourceColorAccessor,
    editPermission,

    eventResourceIdAccessor,
    milestoneResourceIdAccessor,

    loading,
    error,
    resources,
    milestones,
    events,
    days,
    handleEditEvent,
    handleCreateEvent,
    copyEvent,
    editResourceConfig,
    handleEditResource,
    resourceAllowedEditsIds,
    readOnly = false,
    filterEmptyRows = false,
    hideHeader = false,
    resourceAvatarComponent,
    defaultCreateItemDataProps,
    onEditResourceInstanceClose,
    eventInfoComponent: EventInfoComponent,
    draggableEvent = false,
    showOccupationRate = false,
  } = props;

  const calendarBodyRef = useRef<HTMLDivElement>(null);
  const calendarBodySize = useSize(calendarBodyRef);
  const holidayContext = useContext(HolidayScheduleContext);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [editEvent, setEditEvent] = useState<Event | null>(null);
  const [showEventInfo, setShowEventInfo] = useState<Event | null>(null);

  const [editMilestone, setEditMilestone] = useState<Milestone | null>(null);
  const [createItemData, setCreateItemData] = useState<{
    start: Date;
    end: Date;
    resource: Resource;
    defaultProps?: any;
  } | null>(null);

  const { user } = UserContext.useContainer();
  const [mouseMoved, setMouseMoved] = useState<boolean>(false);

  const createItemDataRef = useRef(createItemData);
  const editEventRef = useRef(editEvent);

  const [createEventRange, setCreateEventRange] = useState<{
    start: Date;
    end: Date;
    resource: Resource;
    valid: boolean;
  } | null>(null);

  const createEventRangeRef = useRef(createEventRange);

  const [dragEvent, setDragEvent] = useState<{
    start: Date;
    end: Date;
    event: Event;
    sourceResource: Resource;
    destinationResource: Resource;
    valid: boolean;
  } | null>(null);

  const {
    data: occupationRateData,
    performRequest: getOccupationRate,
  } = useAPIRequest(OccupationRateResource.getOccupationRate);

  useEffect(() => {
    if (days.length > 1 && showOccupationRate) {
      getOccupationRate(
        dateToString(days[0]),
        dateToString(endOfWeek(days[days.length - 1], { weekStartsOn: 1 }))
      );
    }
  }, [showOccupationRate, getOccupationRate, days]);

  const dragEventRef = useRef(dragEvent);

  useEffect(() => {
    createItemDataRef.current = createItemData;
  }, [createItemData]);

  useEffect(() => {
    createEventRangeRef.current = createEventRange;
  }, [createEventRange]);

  useEffect(() => {
    dragEventRef.current = dragEvent;
  }, [dragEvent]);

  useEffect(() => {
    editEventRef.current = editEvent;
  }, [editEvent]);

  const [
    editResourceInstance,
    setEditResourceInstance,
  ] = useState<Resource | null>(null);

  useClickOutside(containerRef, () => {
    if (!isCreateFormOpen.current) {
      setCreateEventRange(null);
      setDragEvent(null);
    }
  });

  const handleEventClick = useCallback(
    (clickEvent: MouseEvent<HTMLDivElement>, event: Event) => {
      clickEvent.stopPropagation();
      if (!checkEditability(event, user, holidayContext, editPermission)) {
        if (EventInfoComponent) {
          setShowEventInfo(event);
        }
        return;
      }
      if (
        !resourceAllowedEditsIds ||
        resourceAllowedEditsIds.includes(
          getByAccessor(event, eventResourceIdAccessor)
        )
      ) {
        setEditEvent(event);
      }
    },
    [
      EventInfoComponent,
      resourceAllowedEditsIds,
      eventResourceIdAccessor,
      holidayContext,
      user,
      editPermission,
    ]
  );

  const handleMilestoneClick = useCallback(
    (clickEvent: MouseEvent<HTMLDivElement>, milestone: Milestone) => {
      clickEvent.stopPropagation();
      if (
        !resourceAllowedEditsIds ||
        (milestoneResourceIdAccessor &&
          resourceAllowedEditsIds?.includes(
            getByAccessor(milestone, milestoneResourceIdAccessor)
          ))
      ) {
        setEditMilestone(milestone);
      }
    },
    [resourceAllowedEditsIds, milestoneResourceIdAccessor]
  );

  const handleMouseDown = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement>,
      day: Date,
      resource: Resource
    ) => {
      setTimeout(() => {
        if (editPermission && !hasPermission(user, editPermission)) {
          event.stopPropagation();
          return;
        }
        event.preventDefault();
        if (!createItemDataRef.current) {
          const eventTarget = event.target as HTMLElement;
          if (!eventTarget.hasAttribute('data-calendar-event')) {
            if (
              !resourceAllowedEditsIds ||
              resourceAllowedEditsIds?.includes(
                getByAccessor(resource, resourceIdAccessor)
              )
            ) {
              setCreateEventRange({
                start: day,
                end: day,
                resource,
                valid: true,
              });
            }
          }
        }
      }, 100);
    },
    [resourceIdAccessor, resourceAllowedEditsIds, user, editPermission]
  );

  const handleEventMouseDown = useCallback(
    (
      mouseEvent: React.MouseEvent<HTMLDivElement>,
      calendarEvent: Event,
      resource: Resource
    ) => {
      mouseEvent.preventDefault();
      setTimeout(() => {
        if (
          !checkEditability(calendarEvent, user, holidayContext, editPermission)
        ) {
          return;
        }
        if (
          !resourceAllowedEditsIds ||
          resourceAllowedEditsIds.includes(
            getByAccessor(calendarEvent, eventResourceIdAccessor)
          )
        ) {
          if (!editEventRef.current) {
            setDragEvent({
              start: toDateObject(calendarEvent.start),
              end: toDateObject(calendarEvent.end),
              event: calendarEvent,
              sourceResource: resource,
              destinationResource: resource,
              valid: true,
            });
          }
        }
      }, 100);
    },
    [
      resourceAllowedEditsIds,
      eventResourceIdAccessor,
      holidayContext,
      user,
      editPermission,
    ]
  );

  const handleResourceClick = useCallback(
    (resource: Resource) => {
      if (editResourceConfig) {
        setEditResourceInstance(resource);
      }
    },
    [editResourceConfig]
  );

  const handleResourceRowCellClick = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement>,
      day: Date,
      resource: Resource
    ) => {
      if (editPermission && !hasPermission(user, editPermission)) {
        event.stopPropagation();
        return;
      }

      if (
        !resourceAllowedEditsIds ||
        resourceAllowedEditsIds.includes(
          getByAccessor(resource, resourceIdAccessor)
        )
      ) {
        setCreateItemData({
          start: day,
          end: day,
          resource,
          defaultProps: { ...defaultCreateItemDataProps },
        });
      }
    },
    [
      resourceAllowedEditsIds,
      resourceIdAccessor,
      user,
      editPermission,
      defaultCreateItemDataProps,
    ]
  );

  const handleMouseOver = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement>,
      day: Date,
      resource: Resource
    ) => {
      event.stopPropagation();
      event.preventDefault();

      if (createItemDataRef.current) {
        return;
      }

      if (
        createEventRangeRef.current?.resource &&
        createEventRangeRef.current.resource === resource
      ) {
        setMouseMoved(true);
        if (isBefore(day, createEventRangeRef.current.start)) {
          setCreateEventRange({
            start: day,
            end: createEventRangeRef.current.end,
            resource,
            valid: true,
          });
        } else {
          setCreateEventRange({
            start: createEventRangeRef.current.start,
            end: day,
            resource,
            valid: true,
          });
        }
      } else if (dragEventRef.current) {
        setMouseMoved(true);
        let valid = true;
        if (
          resourceAllowedEditsIds &&
          !resourceAllowedEditsIds.includes(
            getByAccessor(resource, resourceIdAccessor)
          )
        ) {
          valid = false;
        }
        let dragEventUpdate = {};
        const differenceInDays = differenceInCalendarDays(
          dragEventRef.current.start,
          day
        );
        dragEventUpdate = {
          start: day,
          end: subDays(dragEventRef.current.end, differenceInDays),
          valid,
        };
        setDragEvent((prevState) => {
          if (prevState)
            return {
              ...prevState,
              ...dragEventUpdate,
              destinationResource: resource,
            };
          return null;
        });
      } else if (createEventRangeRef.current) {
        setMouseMoved(true);
        setCreateEventRange((prevState) => {
          if (prevState) {
            return { ...prevState, valid: false };
          } else {
            return prevState;
          }
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  const handleMouseUp = useCallback(async () => {
    if (createEventRangeRef.current) {
      setCreateItemData({
        ...createEventRangeRef.current,
        defaultProps: { ...defaultCreateItemDataProps },
      });
    } else if (dragEventRef.current && dragEventRef.current.valid) {
      setMouseMoved(false);
      if (
        dragEventRef.current.sourceResource ===
          dragEventRef.current.destinationResource ||
        get(dragEventRef.current, ['event', 'project', 'isHolidayForAllUsers'])
      ) {
        //@ts-ignore
        await handleEditEvent(dragEventRef.current.event.id, {
          start: dateToString(dragEventRef.current.start),
          end: dateToString(dragEventRef.current.end),
        });

        if (showOccupationRate) {
          getOccupationRate(
            dateToString(days[0]),
            dateToString(endOfWeek(days[days.length - 1], { weekStartsOn: 1 }))
          );
        }
      } else {
        await handleCreateEvent({
          ...copyEvent(dragEventRef.current.event),
          start: dateToString(dragEventRef.current.start),
          end: dateToString(dragEventRef.current.end),
          [eventResourceIdAccessor]: get(
            dragEventRef.current.destinationResource,
            resourceIdAccessor
          ),
        });

        if (showOccupationRate) {
          getOccupationRate(
            dateToString(days[0]),
            dateToString(endOfWeek(days[days.length - 1], { weekStartsOn: 1 }))
          );
        }
      }
      setDragEvent(null);
    }
  }, [
    days,
    showOccupationRate,
    getOccupationRate,
    defaultCreateItemDataProps,
    handleEditEvent,
    copyEvent,
    eventResourceIdAccessor,
    handleCreateEvent,
    resourceIdAccessor,
  ]);

  useEffect(() => {
    if (editResourceInstance && resources) {
      const editInstance = resources.find(
        (r) => r.id === editResourceInstance.id
      );
      if (editInstance) {
        setEditResourceInstance(editInstance);
      }
    }
  }, [resources, editResourceInstance]);

  const setInvalidRange = useCallback((e) => {
    if (createItemDataRef.current) {
      return;
    }
    if (e.target instanceof Element) {
      if (
        calendarBodyRef.current &&
        !calendarBodyRef.current.contains(e.target)
      ) {
        setCreateEventRange((prevState) => {
          if (!prevState) {
            return prevState;
          }
          return { ...prevState, valid: false };
        });
        setDragEvent((prevState) => {
          if (!prevState) {
            return prevState;
          }
          return { ...prevState, valid: false };
        });
      }
    }
  }, []);

  useEffect(() => {
    const root = document.querySelector('#root');
    if (root) {
      root.addEventListener('mousemove', setInvalidRange);
    }

    return () => {
      if (root) {
        root.removeEventListener('mousemove', setInvalidRange);
      }
    };
  }, [setInvalidRange]);

  useEffect(() => {
    const root = document.querySelector('#root');
    if (root) {
      if (dragEvent || createEventRange) {
        if (root) {
          root.classList.add('dragging');
        }
      } else {
        root.classList.remove('dragging');
      }
    }
  }, [dragEvent, createEventRange]);

  const isCreateFormOpen = useRef(false);
  const onCreateFormOpen = useCallback(() => {
    isCreateFormOpen.current = true;
  }, []);

  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{ position: 'relative' }}
      className={
        (createEventRange && mouseMoved && !createItemData) ||
        (dragEvent && mouseMoved)
          ? 'no-select'
          : ''
      }
    >
      {createEventRange && mouseMoved && !createItemData && (
        <RangeLabel
          start={createEventRange.start}
          end={createEventRange.end}
          valid={createEventRange.valid}
          eventColorAccessor={eventColorAccessor}
          eventNameAccessor={eventNameAccessor}
          containerRef={containerRef}
        />
      )}
      {dragEvent && mouseMoved && (
        <RangeLabel
          valid={dragEvent.valid}
          start={dragEvent.start}
          end={dragEvent.end}
          event={dragEvent.event}
          eventColorAccessor={eventColorAccessor}
          eventNameAccessor={eventNameAccessor}
          shouldCopy={
            dragEvent.sourceResource !== dragEvent.destinationResource
          }
          containerRef={containerRef}
        />
      )}
      {!isEmpty(editResourceConfig) && editResourceInstance && (
        //@ts-ignore
        <EditForm
          key={`edit-form-${editResourceInstance?.id}`}
          {...editResourceConfig}
          instance={editResourceInstance}
          defaultOpen
          handleEditInstance={async (data) => {
            await handleEditResource(editResourceInstance.id, data);
          }}
          onClose={() => {
            setEditResourceInstance(null);
            onEditResourceInstanceClose();
            if (showOccupationRate) {
              getOccupationRate(
                dateToString(days[0]),
                dateToString(
                  endOfWeek(days[days.length - 1], { weekStartsOn: 1 })
                )
              );
            }
          }}
          mountNode={containerRef}
        />
      )}
      {props.editEventFormFields && editEvent && (
        <EditCalendarItemForm
          editItem={editEvent}
          formFields={props.editEventFormFields}
          label={editEventLabel}
          onClose={() => {
            setMouseMoved(false);
            setEditEvent(null);
          }}
          handleEditItem={(id: string, data: Partial<Event>) => {
            return props.handleEditEvent(id, data).then(() => {
              if (showOccupationRate) {
                getOccupationRate(
                  dateToString(days[0]),
                  dateToString(
                    endOfWeek(days[days.length - 1], { weekStartsOn: 1 })
                  )
                );
              }
            });
          }}
          handleDeleteItem={(id: Id) => {
            return props.handleDeleteEvent(id).then(() => {
              if (showOccupationRate) {
                getOccupationRate(
                  dateToString(days[0]),
                  dateToString(
                    endOfWeek(days[days.length - 1], { weekStartsOn: 1 })
                  )
                );
              }
            });
          }}
          mountNode={containerRef}
        />
      )}
      {editMilestone && props.editMilestoneFormFields && (
        <EditCalendarItemForm
          editItem={editMilestone}
          formFields={props.editMilestoneFormFields}
          label="Edit milestone"
          onClose={() => {
            setEditMilestone(null);
          }}
          handleEditItem={props.handleEditMilestone}
          handleDeleteItem={props.handleDeleteMilestone}
          mountNode={containerRef}
        />
      )}
      {showEventInfo && EventInfoComponent && (
        <Modal
          closeIcon
          defaultOpen
          onClose={() => {
            setShowEventInfo(null);
          }}
          content={
            <EventInfoComponent
              event={showEventInfo}
              handleDeleteEvent={(id: string) => {
                props.handleDeleteEvent(id).then(() => {
                  if (showOccupationRate) {
                    getOccupationRate(
                      dateToString(days[0]),
                      dateToString(
                        endOfWeek(days[days.length - 1], { weekStartsOn: 1 })
                      )
                    );
                  }
                });
                setShowEventInfo(null);
              }}
            />
          }
        />
      )}
      {createItemData && props.createEventFormFields && (
        <CreateEventOrMilestoneForm
          label={createEventLabel}
          createItemData={createItemData}
          resourceIdAccessor={resourceIdAccessor}
          formFields={props.createEventFormFields}
          handleCreateEvent={(data: Partial<Event>) =>
            props.handleCreateEvent(data).then(() => {
              if (showOccupationRate) {
                getOccupationRate(
                  dateToString(days[0]),
                  dateToString(
                    endOfWeek(days[days.length - 1], { weekStartsOn: 1 })
                  )
                );
              }
            })
          }
          handleCreateMilestone={props.handleCreateMilestone}
          onOpen={onCreateFormOpen}
          onClose={() => {
            isCreateFormOpen.current = false;
            setCreateEventRange(null);
            setCreateItemData(null);
            setMouseMoved(false);
          }}
          eventResourceIdAccessor={eventResourceIdAccessor}
          milestoneResourceIdAccessor={milestoneResourceIdAccessor || 'id'}
          resourceColorAccessor={resourceColorAccessor}
          mountNode={containerRef}
        />
      )}
      <CalendarWrapper
        ref={containerRef}
        inverted={theme === themes.dark}
        style={{
          overflow: 'auto',
          position: 'relative',
          pointerEvents: readOnly ? 'none' : 'auto',
        }}
      >
        <Loader active={loading} />
        <ErrorMessage error={error} />
        {!hideHeader && (
          <CalendarHeader
            showOccupationRate={showOccupationRate}
            dates={days}
            numberOfDaysInWeek={days.some((date) => isWeekend(date)) ? 7 : 5}
            calendarBodyWidth={calendarBodySize?.width}
            occupationRate={occupationRateData?.data || []}
          />
        )}
        <div ref={calendarBodyRef}>
          {resources.map((resource: Resource, idx: number) => (
            <CalendarResourceRow
              key={getByAccessor(resource, resourceIdAccessor)}
              draggableEvent={draggableEvent}
              resource={resource}
              days={days}
              dragEvent={dragEvent}
              onCellClick={handleResourceRowCellClick}
              onMouseDown={handleMouseDown}
              onMouseOver={handleMouseOver}
              onMouseUp={handleMouseUp}
              onEventMouseDown={handleEventMouseDown}
              eventResourceIdAccessor={eventResourceIdAccessor}
              milestoneResourceIdAccessor={milestoneResourceIdAccessor}
              events={events}
              milestones={milestones}
              onEventClick={handleEventClick}
              onMilestoneClick={handleMilestoneClick}
              eventColorAccessor={eventColorAccessor}
              eventNameAccessor={eventNameAccessor}
              milestoneNameAccessor={milestoneNameAccessor}
              eventIdAccessor={eventIdAccessor}
              resourceNameAccessor={resourceNameAccessor}
              createEventRange={
                createEventRange?.resource === resource
                  ? createEventRange
                  : null
              }
              addBottomBorder={idx === resources.length - 1}
              calendarBodyWidth={calendarBodySize?.width}
              resourceIsEditable={!!editInstanceConfig}
              onResourceClick={handleResourceClick}
              mouseMoved={mouseMoved}
              resourceAllowedEditsIds={resourceAllowedEditsIds}
              doNotShowIfEmpty={filterEmptyRows}
              readOnly={readOnly}
              resourceAvatarComponent={resourceAvatarComponent}
              editPermission={editPermission}
              hasEventInfoComponent={!!EventInfoComponent}
            />
          ))}
        </div>
      </CalendarWrapper>
    </div>
  );
};

export default CalendarComponent;
