import { useCallback, useState } from 'react';
import useIsMounted from './useIsMounted';

function useAPIRequest<T extends any[]>(
  request?: (...args: T) => Promise<any>
) {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isMounted = useIsMounted();

  const performRequest = useCallback(
    async (...params: T) => {
      if (request) {
        setLoading(true);
        try {
          const response = await request(...params);
          if (isMounted) {
            setLoading(false);
            setError(null);
            setData(response);
          }
          return response;
        } catch (e) {
          if (isMounted) {
            setError(e);
            setLoading(false);
          }
        }
      }
    },
    [request, isMounted]
  );

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    data,
    loading,
    error,
    performRequest,
    clearError,
  };
}

export default useAPIRequest;
