import { useForm } from 'react-hook-form';
import { TeamMember } from '../../team/types';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import { Form } from 'semantic-ui-react';
import React, { RefObject, useContext } from 'react';
import {
  PasswordInputField,
  TextInputField,
} from '../../../components/forms/fields';
import { TEAM_MEMBER_ROLE_OPTIONS } from '../../team/constants';
import useAPIRequest from '../../../hooks/useAPIRequest';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';

const UserDataEditForm = ({
  teamMember,
  formRef,
  onSubmit,
}: {
  teamMember: TeamMember;
  formRef: RefObject<HTMLFormElement | null>;
  onSubmit: () => void;
}) => {
  const formMethods = useForm({
    defaultValues: {
      name: teamMember.name,
      telephone: teamMember.telephone,
    },
  });

  const { theme } = useContext(ThemeContext);
  const { performRequest: submitData } = useAPIRequest(
    TeamMemberResource.updateOwnData
  );

  return (
    <Form
      ref={formRef}
      style={{ fontSize: '1em' }}
      className="login-form"
      inverted={theme === themes.dark}
      onSubmit={formMethods.handleSubmit(
        async ({ confirmPassword, ...rest }) => {
          submitData(rest).then(() => {
            onSubmit();
          });
        }
      )}
    >
      <Form.Group>
        <TextInputField
          width="16"
          {...formMethods}
          name="name"
          inline={false}
          placeholder="name"
          rules={{
            required: { value: true, message: 'Please enter your name.' },
          }}
          submitHandler={() => {}}
        />
      </Form.Group>
      <p>
        {
          TEAM_MEMBER_ROLE_OPTIONS.find(
            (role) => role.value === teamMember.role
          )?.text
        }
      </p>
      <p>{teamMember.email}</p>
      <Form.Group>
        <TextInputField
          width="16"
          {...formMethods}
          name="telephone"
          inline={false}
          placeholder="phone number"
          rules={{
            required: { value: true, message: 'Please enter your name.' },
          }}
          submitHandler={() => {}}
        />
      </Form.Group>
      <Form.Group>
        <PasswordInputField
          inline={false}
          required
          name="password"
          placeholder="new password"
          preventDefaultOnEnter={false}
          submitHandler={() => {}}
          {...formMethods}
        />
        <PasswordInputField
          inline={false}
          required
          name="confirmPassword"
          placeholder="confirm password"
          preventDefaultOnEnter={false}
          submitHandler={() => {}}
          {...formMethods}
          rules={{
            validate: (value) => {
              const { password } = formMethods.getValues();
              return password === value || 'Passwords should match!';
            },
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default UserDataEditForm;
