export class BrowserStorage {
  storage: typeof localStorage | typeof sessionStorage;

  constructor(storage: typeof localStorage | typeof sessionStorage) {
    this.storage = storage;
  }

  loadState = (item: string): any | undefined => {
    try {
      const persistedState = this.storage.getItem(item);
      if (persistedState === null) {
        return undefined;
      }
      return JSON.parse(persistedState);
    } catch (err) {
      return undefined;
    }
  };

  saveState = (item: string, state: any): void => {
    try {
      const persistedState = JSON.stringify(state);
      this.storage.setItem(item, persistedState);
    } catch (err) {
      console.log(err);
    }
  };

  removeState = (item: string): void => {
    this.storage.removeItem(item);
  };
}

export const SessionStorage = new BrowserStorage(sessionStorage);
export const LocalStorage = new BrowserStorage(localStorage);
