import React, { useCallback, useEffect, useRef, useState } from 'react';
import Table from '../../components/tables/Table';
import useAPIRequest from '../../hooks/useAPIRequest';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import { ProjectTask, ProjectWithRelations } from '../projects/types';
import { VerticalSeparator } from '../../components/VerticalSeparator';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import TeamMemberSelector from './TeamMemberSelector';
import { UserContext } from '../auth/UserContext';
import get from 'lodash/get';
import styled from 'styled-components';
import ProjectTimesheetWeekResource from '../../api/resources/timesheets/ProjectTimesheetWeekResource';
import Header from '../../components/tables/Header';
import Row from '../../components/tables/Row';
import Cell from '../../components/tables/Cell';
import HeaderCell from '../../components/tables/HeaderCell';
import { ProjectTimesheetWeekWithRelations, TimesheetEntry } from './types';
import Body from '../../components/tables/Body';
import Loader from '../../components/Loader';
import NumberInputCell from '../../components/tables/inputCells/NumberInputCell';
import TimesheetEntryResource from '../../api/resources/timesheets/TimesheetEntryResource';
import useClickOutside from '../../hooks/useClickOutside';
import { hasPermission } from '../auth/utils';
import { FULL_ACCESS_PERMISSION } from '../team/permission';
import DeleteProjectTimesheetWeek from './actions/Delete';
import { dateToString, getFirstDayOfWeekByDate } from '../../utils/dateUtils';
import TimesheetsReport from './actions/Report';
import SelectInputCell from '../../components/tables/inputCells/SelectInputCell';
import TextInputCell from '../../components/tables/inputCells/TextInputCell';
import CopyPreviousWeek from './actions/CopyPreviousWeek';
import CreateTimesheetEntry from './actions/CreateTimesheetEntry';
import Statement from './actions/Statement';
import { getProjectFullName } from '../projects/utils';
import Footer from '../../components/tables/Footer';
import Decimal from 'decimal.js-light';
import TimesheetDatePicker from './TimesheetDatePicker';

export const TimesheetsContext = React.createContext<{
  projects: null | Omit<ProjectWithRelations, 'projectItems'>[];
  loadingProjects: boolean;
}>({
  projects: null,
  loadingProjects: false,
});

export const TimesheetsActions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

const TimesheetsTable = ({ addedStage }: { addedStage?: string }) => {
  const {
    loading: loadingProjects,
    data: projectsResponse,
    performRequest: getProjects,
  } = useAPIRequest(ProjectResource.list);

  const {
    loading: loadingTeamMembers,
    data: teamMembersResponse,
    performRequest: getTeamMembers,
  } = useAPIRequest(TeamMemberResource.list);

  const {
    loading: loadingTimesheets,
    data: projectTimesheetWeekResponse,
    performRequest: getTimesheetWeek,
  } = useAPIRequest(ProjectTimesheetWeekResource.listWithHolidays);

  const { user } = UserContext.useContainer();

  const isInitialMount = useRef(true);

  useEffect(() => {
    isInitialMount.current = false;
  }, []);

  const [dateValue, setDateValue] = useState(new Date());
  const [teamMemberId, setTeamMemberId] = useState<string>(
    get(user, 'teamMemberId') || ''
  );

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const clearSelection = useCallback(() => {
    setSelectedRows((prevState) => {
      if (prevState.length) {
        return [];
      }
      return prevState;
    });
  }, []);

  const tableWrapperRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(tableWrapperRef, clearSelection);

  const [weekFirstDay, setWeekFirstDay] = useState(
    getFirstDayOfWeekByDate(new Date())
  );

  useEffect(() => {
    getProjects({
      filter: {
        include: [{ relation: 'projectTasks' }],
        where: {
          and: [{ or: [{ status: 2 }, { status: 1 }, { status: 4 }] }],
          isHoliday: false,
          onlyFlow: false,
          showInTimesheets: true,
        },
      },
    });
  }, [getProjects]);

  useEffect(() => {
    getTeamMembers({
      filter: { where: { archived: false }, order: 'name ASC' },
    });
  }, [getTeamMembers]);

  useEffect(() => {
    if (!isInitialMount.current) {
      const newWeekFirstDay = getFirstDayOfWeekByDate(dateValue);
      setWeekFirstDay((prevState) => {
        if (dateToString(newWeekFirstDay) !== dateToString(prevState)) {
          return newWeekFirstDay;
        }
        return prevState;
      });
    }
  }, [dateValue, isInitialMount]);

  useEffect(() => {
    getTimesheetWeek({
      teamMemberId,
      firstWeekDay: dateToString(weekFirstDay),
    });
  }, [teamMemberId, weekFirstDay, getTimesheetWeek]);

  const week = Array.from(Array(7).keys()).map((day) => {
    const date = new Date(weekFirstDay.getTime());
    date.setDate(weekFirstDay.getDate() + day);
    return date;
  });

  const reloadTimesheets = useCallback(async () => {
    await getTimesheetWeek({
      teamMemberId,
      firstWeekDay: dateToString(weekFirstDay),
    });
    clearSelection();

    //eslint-disable-next-line
  }, [getTimesheetWeek, teamMemberId, weekFirstDay, clearSelection]);

  useEffect(() => {
    if (addedStage) {
      reloadTimesheets();
    }
    //eslint-disable-next-line
  }, [addedStage]);

  const getTasksOptions = (weekEntry: ProjectTimesheetWeekWithRelations) => {
    const { projectId } = weekEntry;

    const project = projectsResponse?.data.find(
      (project: Omit<ProjectWithRelations, 'projectItems'>) =>
        project.id === projectId
    );

    const tasks =
      project?.projectTasks ||
      (weekEntry?.projectTask?.id
        ? [{ id: weekEntry.projectTask.id, name: weekEntry.projectTask.name }]
        : []);

    return tasks
      .map((projectTask: Pick<ProjectTask, 'id' | 'name'>) => ({
        value: projectTask.id,
        text: projectTask.name,
        key: projectTask.id,
      }))
      .sort((a: { text: string }, b: { text: string }) =>
        a.text.localeCompare(b.text)
      );
  };

  return (
    <TimesheetsContext.Provider
      value={{
        projects: projectsResponse?.data,
        loadingProjects,
      }}
    >
      <Loader
        active={loadingTeamMembers || loadingTimesheets || loadingProjects}
      />
      <TimesheetsActions>
        <div>
          <CreateTimesheetEntry
            teamMemberId={teamMemberId}
            weekFirstDay={weekFirstDay}
            onSubmit={reloadTimesheets}
            mountNode={tableWrapperRef}
          />
          <VerticalSeparator />
          <CopyPreviousWeek
            teamMemberId={teamMemberId}
            weekFirstDay={weekFirstDay}
            onSubmit={reloadTimesheets}
          />
          {selectedRows.length > 0 && (
            <>
              <VerticalSeparator />
              <DeleteProjectTimesheetWeek
                selected={projectTimesheetWeekResponse?.data.filter(
                  (entry: ProjectTimesheetWeekWithRelations) =>
                    selectedRows.includes(entry.id)
                )}
                onSubmit={reloadTimesheets}
                mountNode={tableWrapperRef}
              />
            </>
          )}
          {teamMembersResponse && hasPermission(user, FULL_ACCESS_PERMISSION) && (
            <>
              <VerticalSeparator />
              <TeamMemberSelector
                teamMembers={teamMembersResponse.data}
                loading={loadingTeamMembers}
                teamMemberValue={teamMemberId}
                onChange={(value) => setTeamMemberId(value)}
              />
            </>
          )}
          {hasPermission(user, FULL_ACCESS_PERMISSION) && (
            <>
              <VerticalSeparator />
              <Statement
                teamMemberId={teamMemberId}
                firstWeekDay={weekFirstDay}
                mountNode={tableWrapperRef}
              />
            </>
          )}
        </div>
        <div>
          <TimesheetDatePicker
            startDate={weekFirstDay}
            onChange={(date) => {
              if (date) {
                setDateValue(date);
              }
            }}
          />
          {teamMembersResponse &&
            hasPermission(user, FULL_ACCESS_PERMISSION) && (
              <TimesheetsReport
                defaultFirstDay={weekFirstDay}
                teamMembers={teamMembersResponse.data}
              />
            )}
        </div>
      </TimesheetsActions>
      <div ref={tableWrapperRef} style={{ marginTop: '1em' }}>
        <Table>
          <Header>
            <Row>
              <HeaderCell width="3">Project Name</HeaderCell>
              <HeaderCell width="3">Project Task</HeaderCell>
              <HeaderCell width="2">Description</HeaderCell>
              {week.map((day, idx) => {
                return (
                  <HeaderCell width="1" key={idx}>
                    {new Intl.DateTimeFormat('en', {
                      weekday: 'short',
                      month: 'short',
                      day: '2-digit',
                    }).format(day)}
                  </HeaderCell>
                );
              })}
              <HeaderCell width="1">Total</HeaderCell>
            </Row>
          </Header>
          <Body>
            {projectsResponse?.data &&
              projectTimesheetWeekResponse?.data
                .slice()
                .sort(
                  (
                    a: ProjectTimesheetWeekWithRelations,
                    b: ProjectTimesheetWeekWithRelations
                  ) => {
                    if (a.project.id === b.projectId) {
                      return (a?.projectTask?.name || '').localeCompare(
                        b?.projectTask?.name || ''
                      );
                    }
                    return getProjectFullName(a.project).localeCompare(
                      getProjectFullName(b.project)
                    );
                  }
                )
                .map((weekEntry: ProjectTimesheetWeekWithRelations) => {
                  return (
                    <Row
                      key={weekEntry.id}
                      clickable
                      active={selectedRows.includes(weekEntry.id)}
                      onClick={() => {
                        setSelectedRows((prevState) => {
                          if (prevState.includes(weekEntry.id)) {
                            return prevState.filter(
                              (itemId) => itemId !== weekEntry.id
                            );
                          }
                          return prevState.concat([weekEntry.id]);
                        });
                      }}
                    >
                      <SelectInputCell
                        item={weekEntry}
                        initialValue={weekEntry.projectId}
                        onSubmit={async (value) => {
                          await ProjectTimesheetWeekResource.updateById(
                            weekEntry.id,
                            {
                              projectId: value,
                              projectTaskId: '',
                            }
                          );
                          reloadTimesheets();
                        }}
                        options={
                          projectsResponse?.data
                            .map(
                              (
                                project: Omit<
                                  ProjectWithRelations,
                                  'projectItems'
                                >
                              ) => {
                                return {
                                  value: project.id,
                                  text: getProjectFullName(project),
                                  key: project.id,
                                };
                              }
                            )
                            .filter(
                              (p: {
                                key: string;
                                value: string;
                                text: string;
                              }) => p.value !== weekEntry.projectId
                            )
                            .concat([
                              {
                                value: weekEntry.projectId,
                                text: getProjectFullName(weekEntry.project),
                                key: weekEntry.projectId,
                              },
                            ]) ?? []
                        }
                        disabled={!weekEntry.editable}
                      />
                      <SelectInputCell
                        item={weekEntry}
                        initialValue={weekEntry.projectTaskId}
                        onSubmit={async (value) => {
                          await ProjectTimesheetWeekResource.updateById(
                            weekEntry.id,
                            {
                              projectTaskId: value,
                            }
                          );
                          reloadTimesheets();
                        }}
                        options={getTasksOptions(weekEntry)}
                        disabled={!weekEntry.editable}
                      />
                      <TextInputCell
                        initialValue={weekEntry.description}
                        onSubmit={async (value) => {
                          await ProjectTimesheetWeekResource.updateById(
                            weekEntry.id,
                            {
                              description: value,
                            }
                          );
                          reloadTimesheets();
                        }}
                        disabled={!weekEntry.editable}
                      />
                      {week.map((day) => {
                        const entry = weekEntry.timesheetEntries?.find(
                          (dayEntry) =>
                            dayEntry.date.split('T')[0] === dateToString(day)
                        );
                        return (
                          <NumberInputCell
                            key={`${weekEntry.id}-${dateToString(day)}`}
                            initialValue={entry?.hours ? entry.hours : 0}
                            disabled={!weekEntry.editable}
                            rules={{
                              max: {
                                value: 24,
                                message: 'The number cannot be greater than 24',
                              },
                              min: {
                                value: 0,
                                message: 'Negative numbers are not allowed',
                              },
                            }}
                            onSubmit={async (value) => {
                              await TimesheetEntryResource.updateOrCreate({
                                projectTimesheetWeekId: weekEntry.id,
                                hours: value ?? 0,
                                date: dateToString(day),
                              });
                              reloadTimesheets();
                            }}
                          />
                        );
                      })}
                      <Cell>
                        {weekEntry.timesheetEntries?.reduce(
                          (a, b) => a + +b.hours,
                          0
                        ) ?? 0}
                      </Cell>
                    </Row>
                  );
                })}
          </Body>
          <Footer>
            <Cell />
            <Cell colSpan={2}>
              <strong>Total</strong>
            </Cell>
            {week.map((date) => {
              return (
                <Cell key={date.toISOString()}>
                  <strong>
                    {(selectedRows?.length > 0
                      ? projectTimesheetWeekResponse?.data.filter(
                          ({ id }: ProjectTimesheetWeekWithRelations) =>
                            selectedRows.includes(id)
                        )
                      : projectTimesheetWeekResponse?.data || []
                    )
                      .map((weekEntry: ProjectTimesheetWeekWithRelations) => {
                        return (
                          weekEntry.timesheetEntries?.find(
                            (dayEntry: TimesheetEntry) =>
                              dayEntry.date.split('T')[0] === dateToString(date)
                          ) || { hours: 0 }
                        );
                      })
                      .reduce((acc: Decimal, current: TimesheetEntry) => {
                        acc = acc.add(new Decimal(current.hours));
                        return acc;
                      }, new Decimal(0))
                      .toDecimalPlaces(2)
                      .toString()}
                  </strong>
                </Cell>
              );
            })}
            <Cell>
              <strong>
                {(selectedRows?.length > 0
                  ? projectTimesheetWeekResponse?.data.filter(
                      ({ id }: ProjectTimesheetWeekWithRelations) =>
                        selectedRows.includes(id)
                    )
                  : projectTimesheetWeekResponse?.data || []
                )
                  .map(
                    (timesheet: ProjectTimesheetWeekWithRelations) =>
                      timesheet.timesheetEntries || []
                  )
                  .flat()
                  .reduce((acc: Decimal, current: TimesheetEntry) => {
                    acc = acc.add(new Decimal(current.hours));
                    return acc;
                  }, new Decimal(0))
                  .toDecimalPlaces(2)
                  .toString()}
              </strong>
            </Cell>
          </Footer>
        </Table>
      </div>
    </TimesheetsContext.Provider>
  );
};

export default TimesheetsTable;
