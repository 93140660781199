import BaseCRUDResource from '../BaseCRUDResource';
import Api from '../Api';
import { ProjectSetting } from '../../pages/settings/types';

class ProjectSettingResource extends BaseCRUDResource {
  objectIdentifier = 'project-settings';

  updateOrCreate = (data: ProjectSetting | Omit<ProjectSetting, 'id'>) => {
    return Api.client.post(`${this.objectIdentifier}/create-or-update`, data);
  };

  findByKey = (key: string) => {
    return Api.client.get(`${this.objectIdentifier}/findByKey/${key}`);
  };
}

export default new ProjectSettingResource();
