import React from 'react';

type Props = {
  email: string;
  label: string;
};

const MailTo = ({ email, label }: Props) => {
  return (
    <a
      href={`mailto:${email}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      {label}
    </a>
  );
};

export default MailTo;
