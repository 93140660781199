import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import CalendarDatePicker from './CalendarDatePicker';
import { startOfWeek } from 'date-fns';
import { getWeek, toDateObject } from '../../../utils/dateUtils';
import { HolidayScheduleContext } from '../../../pages/timesheets/HolidayResourceSchedule';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import { LocalStorage } from '../../../api/BrowserStorage';

const getDates = (
  numberOfWeeks: number,
  startDate: Date,
  showWeekends: boolean
) => {
  const days: Date[] = [];

  for (let i = 0; i < numberOfWeeks; i++) {
    const firstDay = new Date(startDate.getTime());
    firstDay.setDate(firstDay.getDate() + i * 7);

    if (!showWeekends) {
      days.push(...getWeek(firstDay).slice(0, 5));
    } else {
      days.push(...getWeek(firstDay));
    }
  }

  return days;
};

type Props = {
  onDatesChange: (dates: Date[]) => void;
  defaultStartDate?: string;
  id: string;
};

const CalendarDateRangeController = (props: Props) => {
  const { onDatesChange, defaultStartDate, id } = props;

  const holidayContext = useContext(HolidayScheduleContext);

  const [startDate, setStartDay] = useState(
    startOfWeek(
      defaultStartDate ? toDateObject(defaultStartDate) : new Date(),
      { weekStartsOn: 1 }
    )
  );

  const [numberOfWeeks, setNumberOfWeeks] = useState(
    Number(LocalStorage.loadState(`number-weeks-${id}`)) ||
      (holidayContext ? 3 : 5)
  );
  const [showWeekends, setShowWeekends] = useState(false);

  const dates: Date[] = useMemo(
    () => getDates(numberOfWeeks, startDate, showWeekends),
    [startDate, numberOfWeeks, showWeekends]
  );

  useEffect(() => {
    onDatesChange(dates);
  }, [dates, onDatesChange]);

  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ marginBottom: '10px' }}>
      <CalendarDatePicker
        showSelectedWeek={false}
        onChange={(value) => {
          if (value) {
            setStartDay(startOfWeek(value, { weekStartsOn: 1 }));
          }
        }}
        startDate={startDate}
        numberOfWeeks={numberOfWeeks}
        addWeek={() => {
          setNumberOfWeeks((n) => {
            LocalStorage.saveState(`number-weeks-${id}`, n + 1);
            return n + 1;
          });
        }}
        removeWeek={() => {
          setNumberOfWeeks((n) => {
            LocalStorage.saveState(`number-weeks-${id}`, n - 1);
            return n - 1;
          });
        }}
      />
      <Form style={{ display: 'inline' }} inverted={theme === themes.dark}>
        <Checkbox
          label="Show weekends"
          checked={showWeekends}
          onChange={() => setShowWeekends((prevState) => !prevState)}
          style={{ margin: '0 20px', fontFamily: 'Lato, sans-serif' }}
        />
      </Form>
    </div>
  );
};

export default CalendarDateRangeController;
