import React, {
  CSSProperties,
  ElementType,
  MouseEvent,
  ReactNode,
  SyntheticEvent,
  useRef,
} from 'react';
import { Icon, Ref, TableRowProps } from 'semantic-ui-react';
import get from 'lodash/get';
import useDoubleClick from 'use-double-click';
import { useMediaQuery } from 'react-responsive';
import { hasPermission } from '../../pages/auth/utils';
import { Id } from '../../types/base';
import { ColumnConfig } from './ResourceTable';
import Row from '../../components/tables/Row';
import { UserContext } from '../../pages/auth/UserContext';
import SelectInputCell from '../../components/tables/inputCells/SelectInputCell';
import CheckboxInputCell from '../../components/tables/inputCells/CheckboxInputCell';
import NumberInputCell from '../../components/tables/inputCells/NumberInputCell';
import Cell from '../../components/tables/Cell';
import TextInputCell from '../../components/tables/inputCells/TextInputCell';
import { getByAccessor } from '../../utils/componentUtils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type Props<DataItem extends { id: Id }> = {
  draggableSort: boolean;
  columns: Array<ColumnConfig<DataItem>>;
  item: DataItem;
  handleEditInstance: (id: Id, data: Partial<DataItem>) => Promise<void>;
  reload: () => Promise<void>;
  editPermission: string;
  infoBox?: ElementType<{
    dataItem: DataItem;
    trigger: ReactNode;
  }>;
  setEditInstance: (item: DataItem) => void;
  selected: boolean;
  selectRow: (id: Id) => void;
  getRowAdditionalProps: (instance: DataItem) => TableRowProps;
};

type BaseTableRowProps<DataItem extends { id: Id }> = {
  item: DataItem;
  columns: Array<ColumnConfig<DataItem>>;
  clickable?: boolean;
  reload: () => Promise<void>;
};

const BaseTableRow = <DataItem extends { id: Id }>({
  item,
  columns,
  clickable = false,
  reload,
}: BaseTableRowProps<DataItem>) => {
  const { user } = UserContext.useContainer();
  return (
    <Row key={item.id} clickable={clickable}>
      {columns
        .filter(
          (column) =>
            !column.permission || hasPermission(user, column.permission)
        )
        .map((column) => (
          <Cell key={`${item.id}-${column.key}`}>
            {typeof column.accessor === 'function'
              ? column.accessor(item, reload)
              : get(item, column.accessor)}
          </Cell>
        ))}
    </Row>
  );
};

const ResourceTableRow = <DataItem extends { id: Id }>(
  props: Props<DataItem>
) => {
  const {
    columns,
    item,
    handleEditInstance,
    reload,
    editPermission,
    infoBox: InfoBox,
    setEditInstance,
    selected,
    selectRow,
    getRowAdditionalProps,
    draggableSort,
  } = props;

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id });

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const { user } = UserContext.useContainer();

  const rowRef = useRef<HTMLElement | null>(null);

  useDoubleClick({
    onSingleClick: (e: MouseEvent<HTMLElement>) => {
      // @ts-ignore
      if (e.target?.parentNode?.classList.contains('checkbox')) {
        return;
      }
      // @ts-ignore
      if (e.target.tagName === 'TD' && hasPermission(user, editPermission)) {
        selectRow(item.id);
      }
    },
    onDoubleClick: (e: MouseEvent<HTMLElement>) => {
      document.getSelection()?.removeAllRanges();
      if (hasPermission(user, editPermission)) {
        setEditInstance(item);
      }
    },
    ref: rowRef,
    latency: 250,
  });

  const stackedTable = useMediaQuery({ query: '(max-width: 767px)' });

  return hasPermission(user, editPermission) ? (
    <Ref
      innerRef={(ref) => {
        setNodeRef(ref);
        rowRef.current = ref;
      }}
    >
      <Row
        key={`table-row-${item.id}`}
        clickable
        active={selected}
        {...getRowAdditionalProps(item)}
        style={style}
      >
        {draggableSort && (
          <Cell
            key={`${item.id}-dragHandle`}
            {...attributes}
            {...listeners}
            ref={setActivatorNodeRef}
            style={{ width: 1 }}
          >
            <Icon name="bars" />
          </Cell>
        )}
        {columns.map((column) => {
          if (!column.permission || hasPermission(user, column.permission)) {
            const title = column.titleAccessor
              ? getByAccessor(item, column.titleAccessor)
              : '';
            if (column.editable) {
              const initialValue: unknown = item[column.editable.valueAccessor];
              const onEditableCellSubmit = <Value extends any>(
                value: Value
              ) => {
                if (column.editable) {
                  const updateData: Partial<DataItem> = {};
                  const key = column.editable.valueAccessor as keyof DataItem;
                  //@ts-ignore
                  updateData[key] = value;
                  handleEditInstance(item.id, updateData);
                }
              };

              switch (column.editable.component.type) {
                case 'select':
                  return (
                    <SelectInputCell
                      key={`${item.id}-${column.editable.valueAccessor}-select-cell`}
                      initialValue={initialValue as string | number}
                      onSubmit={onEditableCellSubmit}
                      item={item}
                      width={column.cellWidth}
                      {...column.editable.component.props}
                      title={title}
                    />
                  );
                case 'checkbox':
                  return (
                    <CheckboxInputCell
                      key={`${item.id}-${column.editable.valueAccessor}-checkbox-cell`}
                      initialValue={initialValue as boolean}
                      onSubmit={onEditableCellSubmit}
                      width={column.cellWidth}
                    />
                  );
                case 'number':
                  return (
                    <NumberInputCell
                      key={`${item.id}-${column.editable.valueAccessor}-number-cell`}
                      initialValue={initialValue as number}
                      onSubmit={onEditableCellSubmit}
                      width={column.cellWidth}
                      {...column.editable.component.props}
                    />
                  );
                case 'text':
                  return (
                    <TextInputCell
                      key={`${item.id}-${column.editable.valueAccessor}-text-cell`}
                      initialValue={initialValue as string}
                      onSubmit={onEditableCellSubmit}
                      width={column.cellWidth}
                      {...column.editable.component.props}
                    />
                  );
              }
            }
            return (
              <Cell
                width={column.cellWidth}
                key={`${item.id}-${column.key}`}
                onClick={(e: SyntheticEvent) => {
                  if (column.stopPropagation) {
                    e.stopPropagation();
                  }

                  if (column.clickHandler) {
                    column.clickHandler(e, item);
                  }
                }}
                title={title}
              >
                {stackedTable && <strong>{column.label}: </strong>}
                {typeof column.accessor === 'function'
                  ? column.accessor(item, reload)
                  : get(item, column.accessor)}
              </Cell>
            );
          }
          return null;
        })}
      </Row>
    </Ref>
  ) : InfoBox ? (
    <InfoBox
      key={`info-box-${item.id}`}
      dataItem={item}
      trigger={
        <Ref innerRef={rowRef}>
          <Row key={item.id} clickable>
            {columns.map((column) => {
              if (
                !column.permission ||
                hasPermission(user, column.permission)
              ) {
                return (
                  <Cell
                    width={column.cellWidth}
                    key={`${item.id}-${column.key}`}
                    onClick={(e: SyntheticEvent) =>
                      column.stopPropagation && e.stopPropagation()
                    }
                  >
                    {typeof column.accessor === 'function'
                      ? column.accessor(item, reload)
                      : get(item, column.accessor)}
                  </Cell>
                );
              }
              return null;
            })}
          </Row>
        </Ref>
      }
    />
  ) : (
    <Ref innerRef={rowRef}>
      <BaseTableRow item={item} columns={columns} reload={reload} />
    </Ref>
  );
};

export default ResourceTableRow;
