import React from 'react';
import ResourceTable, {
  ColumnConfig,
} from '../../../containers/resourceTable/ResourceTable';
import { ProjectTask } from '../types';
import { FULL_ACCESS_PERMISSION } from '../../team/permission';
import Delete from './DeleteTask';
import ProjectTaskFormFields from './ProjectTaskFormFields';
import TableFooter from './TableFooter';
import { ProjectTasksContainer } from '../ProjectItemsAndTasks';
import { formatNumber } from '../../../utils/numberUtils';

const Table = () => {
  const config: ColumnConfig<ProjectTask>[] = [
    {
      label: 'Name',
      accessor: 'name' as const,
      key: 'name',
      orderingField: 'name',
      cellWidth: '1' as const,
      editable: {
        component: {
          type: 'text' as const,
          props: {
            rules: {
              required: { value: true, message: 'The name cannot be empty' },
            },
          },
        },
        valueAccessor: 'name' as const,
      },
    },
    {
      label: 'Project Item',
      accessor: (task) => task.projectItem?.name,
      key: 'projectItem.name',
      orderingField: 'name',
      cellWidth: '5' as const,
    },
    {
      label: 'Hours',
      accessor: (projectTask: ProjectTask) =>
        (+(projectTask.hours || 0)).toFixed(2),
      key: 'hours',
      orderingField: 'hours',
    },
    {
      label: 'Days',
      accessor: (projectTask: ProjectTask) =>
        (+(projectTask.days || 0)).toFixed(2),
      key: 'days',
      orderingField: 'days',
    },
    {
      label: 'Cost',
      accessor: (projectTask: ProjectTask) =>
        `${formatNumber(+(projectTask.cost || 0))} lv`,
      key: 'cost',
      orderingField: 'cost',
    },
  ];

  const createInstanceConfig = {
    title: 'Create new task',
    formFields: ProjectTaskFormFields,
    formId: 'project-task-create-form',
    buttonSize: 'large' as const,
  };

  return (
    <ResourceTable
      addSearch={false}
      columns={config}
      footerComponent={TableFooter}
      newInstanceConfig={createInstanceConfig}
      stateContainer={ProjectTasksContainer}
      bulkActions={[
        {
          key: 'delete',
          component: Delete,
          permission: FULL_ACCESS_PERMISSION,
          showAsDisabled: true,
        },
      ]}
    />
  );
};

export default Table;
