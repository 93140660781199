import React from 'react';
import ResourceTable from '../../../containers/resourceTable/ResourceTable';
import { Id } from '../../../types/base';
import { FULL_ACCESS_PERMISSION } from '../../team/permission';
import { roundToTwo } from '../../../utils/numberUtils';
import { ProjectItem } from '../../projects/types';
import {
  default as TableFooter,
  formatBudgetCurrency,
} from '../../projects/projectItems/TableFooter';
import { getCurrencyUnit } from '../../projects/constants';
import AddItemToInvoice from './AddItemToInvoice';
import { InvoiceWithRelations } from '../types';
import { ProjectItemsContainer } from '../Table';

type Props = {
  projectId: Id;
  projectCurrency: string;
  onReload?: () => void;
  readOnly?: boolean;
  invoice: InvoiceWithRelations;
};

export const ProjectItemsInvoiceContext = React.createContext<InvoiceWithRelations | null>(
  null
);

const ProjectItemsInvoiceTable = (props: Props) => {
  const { onReload, invoice } = props;

  const config = [
    {
      label: 'Name',
      accessor: 'name' as const,
      key: 'name',
      orderingField: 'name',
      cellWidth: '7' as const,
    },
    {
      label: 'Invoiced',
      accessor: (projectItem: ProjectItem) => (
        <span
          style={{
            color:
              parseInt(projectItem.invoicedPercentage ?? '0') > 100
                ? '#db2828'
                : parseInt(projectItem.invoicedPercentage ?? '0') === 100
                ? '#21ba45'
                : 'inherit',
          }}
        >{`${projectItem.invoicedPercentage ?? (0).toFixed(2)} %`}</span>
      ),
      key: 'invoiced',
      orderingField: null,
    },
    {
      label: '# Items',
      accessor: (projectItem: ProjectItem) => projectItem.items,
      key: 'items',
      orderingField: null,
    },
    {
      label: 'Cost per Item',
      accessor: (projectItem: ProjectItem) => projectItem.costPerItem,
      key: 'costPerItem',
      orderingField: 'costPerItem',
    },
    {
      label: 'Subtotal',
      accessor: (projectItem: ProjectItem) =>
        formatBudgetCurrency(
          getCurrencyUnit(projectItem.projectCurrency),
          roundToTwo(+projectItem.items * +projectItem.costPerItem)
        ),
      key: 'subtotal',
      orderingField: null,
    },
  ];

  return (
    <ProjectItemsInvoiceContext.Provider value={invoice}>
      <ResourceTable
        tableId="invoice-project-items"
        addSearch={false}
        footerComponent={TableFooter}
        footerComponentProps={{
          context: 'invoice',
        }}
        columns={config}
        stateContainer={ProjectItemsContainer}
        bulkActions={[
          {
            key: 'add-items-to-invoice',
            component: AddItemToInvoice,
            permission: FULL_ACCESS_PERMISSION,
            showAsDisabled: true,
          },
        ]}
        onReload={onReload}
      />
    </ProjectItemsInvoiceContext.Provider>
  );
};

export default ProjectItemsInvoiceTable;
