import React, { MouseEvent, ReactNode, useContext, useMemo } from 'react';
import get from 'lodash/get';
import { getByAccessor } from '../../../utils/componentUtils';
import { dateDiffInDays } from '../../../utils/dateUtils';
import { Event as StyledEvent } from './Calendar.styled';
import { BaseEvent } from '../types';
import tinycolor from 'tinycolor2';
import {
  HOLIDAY_STATUS_PENDING,
  HOLIDAY_STATUS_REJECTED,
} from '../../../pages/resourceSchedule/constants';
import { UserContext } from '../../../pages/auth/UserContext';
import { HolidayScheduleContext } from '../../../pages/timesheets/HolidayResourceSchedule';
import { checkEditability } from '../CalendarComponent';
import { hasPermission } from '../../../pages/auth/utils';
import { useDraggable } from '@dnd-kit/core';

type Props<Event extends BaseEvent> = {
  draggable?: boolean;
  hasEventInfoComponent: boolean;
  maxDays: number;
  event: Event;
  eventIdAccessor: string | ((event: Event) => string);
  eventResourceIdAccessor: string;
  eventColorAccessor: string | ((event: Event) => string) | string[];
  eventNameAccessor: string | ((event: Event) => ReactNode) | string[];
  skipWeekends: boolean;
  columnStart: number;
  rowStart: number;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  onMouseDown: (event: MouseEvent<HTMLDivElement>) => void;
  currentDate: Date;
  resourceAllowedEditsIds?: Array<string>;
  editPermission?: string;
};

const CalendarEvent = <Event extends BaseEvent>(props: Props<Event>) => {
  const {
    draggable,
    hasEventInfoComponent,
    maxDays,
    event,
    eventIdAccessor,
    eventNameAccessor,
    eventColorAccessor,
    skipWeekends,
    columnStart,
    onClick,
    onMouseDown,
    currentDate,
    rowStart,
    resourceAllowedEditsIds,
    eventResourceIdAccessor,
    editPermission,
  } = props;

  const { user } = UserContext.useContainer();

  const holidayContext = useContext(HolidayScheduleContext);

  const days = useMemo(
    () =>
      dateDiffInDays(
        new Date(event.end),
        new Date(event.start) < currentDate
          ? currentDate
          : new Date(event.start),
        skipWeekends
      ),
    [event, skipWeekends, currentDate]
  );

  const editable = useMemo(() => {
    if (editPermission && !hasPermission(user, editPermission)) {
      return false;
    }
    if (
      resourceAllowedEditsIds &&
      !resourceAllowedEditsIds.includes(
        getByAccessor(event, eventResourceIdAccessor)
      )
    ) {
      return false;
    }
    return checkEditability(event, user, holidayContext);
  }, [
    user,
    event,
    holidayContext,
    eventResourceIdAccessor,
    resourceAllowedEditsIds,
    editPermission,
  ]);

  const eventColor = getByAccessor(event, eventColorAccessor);

  const eventColorDarker = tinycolor.mix(eventColor, '#000', 20);

  const diagonalColor = tinycolor
    .mix(eventColorDarker, '#494949', 20)
    .toHexString();

  const { attributes, listeners = {}, setNodeRef } = useDraggable({
    id: `${event.id}_calendar-event`,
    disabled: !draggable,
    data: {
      type: 'calendar-event',
      ...event,
    },
  });

  return (
    <StyledEvent
      ref={setNodeRef}
      key={getByAccessor(event, eventIdAccessor)}
      color={eventColorDarker.toHexString()}
      span={days < maxDays ? days : maxDays}
      columnStart={columnStart}
      rowStart={rowStart}
      onClick={onClick}
      onMouseDown={onMouseDown}
      data-calendar-event="event"
      isPending={get(event, 'status') === HOLIDAY_STATUS_PENDING}
      isRejected={get(event, 'status') === HOLIDAY_STATUS_REJECTED}
      editable={editable}
      title={getByAccessor(event, eventNameAccessor)}
      diagonalColor={diagonalColor}
      hasEventInfoComponent={hasEventInfoComponent}
      {...attributes}
      {...listeners}
    >
      <span data-calendar-event="event">
        {get(event, 'hours') && get(event, 'hours') < 8 ? '*' : ''}
        {getByAccessor(event, eventNameAccessor)}
      </span>
    </StyledEvent>
  );
};

export default CalendarEvent;
