import useAPIRequest from './useAPIRequest';
import AuthResource from '../api/resources/AuthResource';
import { useEffect, useState } from 'react';
import TeamMemberResource from '../api/resources/team/TeamMemberResource';

const useFetchCurrentUser = () => {
  const [user, setUser] = useState(null);
  const [userProfilePictureUrl, setUserProfilePictureUrl] = useState<
    string | null
  >(null);

  const { data, ...rest } = useAPIRequest(AuthResource.whoAmI);
  const { data: profilePictureData, ...profilePictureRequest } = useAPIRequest(
    TeamMemberResource.fetchOwnProfilePicture
  );

  useEffect(() => {
    setUser(data);
  }, [data]);

  useEffect(() => {
    if (profilePictureData?.data) {
      setUserProfilePictureUrl(
        window.URL.createObjectURL(new Blob([profilePictureData.data]))
      );
    }
  }, [profilePictureData]);

  return {
    ...rest,
    user,
    setUser,
    userProfilePictureUrl,
    profilePictureRequest,
  };
};

export default useFetchCurrentUser;
