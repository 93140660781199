import React, { KeyboardEvent, ReactNode } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { Control, Controller, UseFormMethods } from 'react-hook-form';
import get from 'lodash/get';

type Props = {
  label: ReactNode;
  name: string;
  control: Control;
  defaultValue?: boolean;
  submitHandler: (data: any) => any;
  activateInputOnClick?: boolean;
} & React.ComponentProps<typeof Form.Field> &
  Pick<UseFormMethods, 'errors'>;

const CheckboxField = (props: Props) => {
  const {
    label,
    control,
    name,
    defaultValue,
    errors,
    activateInputOnClick,
    submitHandler,
    getValues,
    width,
  } = props;

  return (
    <Form.Field inline error={get(errors, name)} width={width}>
      <label style={{ paddingRight: '10px' }}>{label}:</label>
      <Controller
        control={control}
        render={({ value, onChange }) => (
          <Checkbox
            checked={value}
            onChange={(event, { checked }) => {
              onChange(checked);
              if (activateInputOnClick) {
                submitHandler(getValues());
              }
            }}
            onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            style={{ marginLeft: activateInputOnClick && '0.93em' }}
          />
        )}
        name={name}
        defaultValue={defaultValue || false}
      />
    </Form.Field>
  );
};

export default CheckboxField;
