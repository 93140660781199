import React, { RefObject, useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ModalForm from '../../../components/forms/ModalForm';
import ProjectTimeSpentSelectPeriodFormFields from './ProjectTimeSpentSelectPeriodFormFields';
import { dateToString, toDateObject } from '../../../utils/dateUtils';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import { isBefore } from 'date-fns';

type Props = {
  projectStartDate: string;
  mountNode: RefObject<HTMLDivElement | null>;
  setPeriod: (period: { from?: string; to?: string; id: string }) => void;
  period: { from?: string; to?: string; id: string } | null;
};

const ProjectTimeSpentSelectPeriod = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const { mountNode, setPeriod, projectStartDate, period } = props;
  const { theme } = useContext(ThemeContext);

  const submitData = async ({ from, to }: { from: string; to: string }) => {
    const fromDate = toDateObject(from);
    const toDate = toDateObject(to);

    if (isBefore(toDate, fromDate)) {
      throw new Error('The end date cannot be before the start date');
    }
    setPeriod({ from, to, id: `${from}-${to}` });
    setOpen(false);
  };

  return (
    <>
      <Button
        content="Select Period"
        basic
        icon="calendar alternate outline"
        inverted={theme === themes.dark}
        id="project-time-spent-select-period-button"
        style={{
          fontSize: '15px',
          padding: '0.5rem',
          fontFamily: 'Lato, sans-serif',
        }}
        onClick={() => setOpen(true)}
      />
      {open && (
        <ModalForm
          header={
            <>
              <Icon name="calendar" size="small" />
              Select Period
            </>
          }
          defaultOpen
          formId="project-time-spent-select-period-form"
          fields={ProjectTimeSpentSelectPeriodFormFields}
          submitData={submitData}
          modalProps={{
            mountNode,
          }}
          instance={
            period || {
              id: 'select-period-form',
              from: projectStartDate,
              to: dateToString(new Date()),
            }
          }
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default ProjectTimeSpentSelectPeriod;
