import React from 'react';
import get from 'lodash/get';
import { Event as StyledEvent } from './../../../components/calendar/components/Calendar.styled';
import tinycolor from 'tinycolor2';
import {
  HOLIDAY_STATUS_PENDING,
  HOLIDAY_STATUS_REJECTED,
} from '../../resourceSchedule/constants';
import { ProjectScheduleEvent } from '../../projectSchedule/ProjectSchedule';

type Props = {
  event: ProjectScheduleEvent;
};

const CalendarEventDragOverlay = (props: Props) => {
  const { event } = props;

  const eventColor = event.color;

  const eventColorDarker = tinycolor.mix(eventColor, '#000', 20);

  const diagonalColor = tinycolor
    .mix(eventColorDarker, '#494949', 20)
    .toHexString();

  return (
    <StyledEvent
      key={event.id}
      width={200}
      color={eventColorDarker.toHexString()}
      data-calendar-event="event"
      isPending={get(event, 'status') === HOLIDAY_STATUS_PENDING}
      isRejected={get(event, 'status') === HOLIDAY_STATUS_REJECTED}
      editable={false}
      title={event.name}
      diagonalColor={diagonalColor}
      hasEventInfoComponent={false}
    >
      <span data-calendar-event="event">
        {get(event, 'hours') && get(event, 'hours') < 8 ? '*' : ''}
        {event.name}
      </span>
    </StyledEvent>
  );
};

export default CalendarEventDragOverlay;
