import React, { ReactNode, useRef, useState } from 'react';
import { Form, Ref } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import get from 'lodash/get';
import { StyledFormField } from './Fields.styled';

type Props = {
  rules?: ValidationRules;
  label?: ReactNode;
  name: string;
  placeholder?: string;
  activateInputOnClick?: boolean;
  submitHandler: (data: any) => any;
  rows?: number;
} & React.ComponentProps<typeof Form.Field> &
  UseFormMethods;

const TextAreaField = (props: Props) => {
  const {
    register,
    label,
    name,
    rules,
    errors,
    activateInputOnClick,
    trigger,
    getValues,
    submitHandler,
    clearErrors,
    inline = false,
    fieldStyle = {},
    width,
    rows,
  } = props;

  const fieldError = get(errors, name);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [touched, setTouched] = useState(false);

  const handleBlur = async () => {
    if (activateInputOnClick) {
      const valid = await trigger(name);
      if (valid && touched) {
        await submitHandler(getValues());
        setTouched(false);
      }
    }
  };

  const handleChange = () => {
    setTouched(true);
    if (fieldError) {
      clearErrors(name);
    }
  };

  return (
    <StyledFormField
      error={Boolean(fieldError)}
      required={
        typeof rules?.required === 'object'
          ? !!rules?.required?.value
          : !!rules?.required
      }
      activateInputOnClick={activateInputOnClick}
      inline={inline}
      style={fieldStyle}
      width={width}
    >
      <label>{label}</label>
      <Ref
        innerRef={(ref: HTMLTextAreaElement) => {
          inputRef.current = ref;
          register(ref, rules);
        }}
      >
        <TextareaAutosize
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          minRows={2}
          rows={rows}
        />
      </Ref>
    </StyledFormField>
  );
};

export default TextAreaField;
