import React, { ReactNode, RefObject, useMemo } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { BaseEvent } from '../types';
import styled from 'styled-components';
import { getByAccessor } from '../../../utils/componentUtils';
import tinycolor from 'tinycolor2';
import useMouse from '@react-hook/mouse-position';

const DateFormatter = new Intl.DateTimeFormat('en', {
  month: 'short',
  day: '2-digit',
});

const formatDate = (date: Date) => DateFormatter.format(date);

type Props<Event extends BaseEvent> = {
  start: Date;
  end: Date;
  event?: Event;
  eventColorAccessor?: string | ((event: Event) => string) | string[];
  eventNameAccessor?: string | ((event: Event) => ReactNode) | string[];
  shouldCopy?: boolean;
  valid: boolean;
  containerRef: RefObject<HTMLDivElement>;
};

const CURSOR_POSITION_OFFSET = 5;

const EventLabel = styled.div<{ isLight: boolean | void; color: string }>`
  padding: 0.05em 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isLight ? '#000' : '#ffffff')};
  max-height: 2em;
  white-space: nowrap;
  background-color: ${(props) => props.color};
  font-weight: 400;
  max-width: 15em;
  font-size: 0.85em;
  margin-bottom: 0.25em;
`;

const StyledRangeLabel = styled(Label)`
  &.ui.basic.label {
    position: absolute;
    left: ${(props) => props.position.x + CURSOR_POSITION_OFFSET}px;
    top: ${(props) => props.position.y + CURSOR_POSITION_OFFSET}px;
    z-index: 3;
    font-weight: 400;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
    cursor: move;
    will-change: top, left;
  }
`;

const RangeLabel = <Event extends BaseEvent>(props: Props<Event>) => {
  const {
    start,
    end,
    event,
    eventColorAccessor = 'color',
    eventNameAccessor = 'name',
    shouldCopy,
    valid,
    containerRef,
  } = props;

  const position = useMouse(containerRef);

  const isLight = useMemo(() => {
    if (event)
      return tinycolor(getByAccessor(event, eventColorAccessor)).isLight();
    return false;
  }, [event, eventColorAccessor]);

  let verticalOffset = 0;
  let horizontalOffset = 0;

  return position?.x && position?.y ? (
    <StyledRangeLabel
      basic
      position={{
        x: (position?.x ?? 0) - horizontalOffset,
        y: (position?.y ?? 0) - verticalOffset,
      }}
    >
      <div style={{ display: 'flex' }}>
        <div>{!valid && <Icon name="ban" color="red" />}</div>
        <div>
          {event && (
            <EventLabel
              color={getByAccessor(event, eventColorAccessor)}
              isLight={isLight}
            >
              <span>{getByAccessor(event, eventNameAccessor)}</span>
            </EventLabel>
          )}
          {!event && ` Create for ${formatDate(start)} - ${formatDate(end)}`}
          {event &&
            valid &&
            (shouldCopy
              ? ` Copy for ${formatDate(start)} - ${formatDate(end)}`
              : ` Move to ${formatDate(start)} - ${formatDate(end)}`)}
        </div>
      </div>
    </StyledRangeLabel>
  ) : null;
};

export default RangeLabel;
