import React, {
  ReactText,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import TabPane from '../../components/layout/TabPane';
import MyPlan from './MyPlan';
import ProjectsPool from './ProjectsPool';
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core';
import useAPIRequest from '../../hooks/useAPIRequest';
import ProjectScheduleEventResource from '../../api/resources/projectSchedule/ProjectScheduleEventResource';
import { UserContext } from '../auth/UserContext';
import get from 'lodash/get';
import { dateToString } from '../../utils/dateUtils';
import { startOfToday } from 'date-fns';
import ProjectsPoolListItem from './projectsPool/ProjectsPoolListItem';
import { ProjectScheduleEvent } from '../projectSchedule/ProjectSchedule';
import { List } from 'semantic-ui-react';
import { ThemeContext } from '../../contexts/theme/ThemeContext';
import { rangeFilterQuery } from '../../components/calendar/utils';
import HomePageContainer from './HomePageContainer';
import CalendarEventDragOverlay from './myPlan/CalendarEventDragOverlay';
import TimesheetTabs from '../timesheets/TimesheetTabs';

const HomeTab = () => {
  const { user } = UserContext.useContainer();
  const [addedStage, setAddedStage] = useState<string>('');
  const [dates, setDates] = useState<{ start: Date; end: Date } | null>(null);
  const [draggedItem, setDraggedItem] = useState<{
    id: ReactText;
    data: { current: any };
  } | null>(null);
  const { performRequest: addUserToBroadRole } = useAPIRequest(
    ProjectScheduleEventResource.addUserToBroadRole
  );

  const { performRequest: removeUserRoles } = useAPIRequest(
    ProjectScheduleEventResource.removeUserRoles
  );

  const {
    data: projectStagesResponse,
    performRequest: getStages,
  } = useAPIRequest(ProjectScheduleEventResource.list);

  const stagesFilter = useMemo(() => {
    return dates
      ? {
          filter: {
            order: ['start', 'name'],
            include: [{ relation: 'project' }],
            where: {
              and: [
                {
                  ...rangeFilterQuery(dates.start, dates.end, 'start', 'end'),
                },
                {
                  or: [
                    { start: { gt: dateToString(startOfToday()) } },
                    {
                      end: {
                        gt: dateToString(startOfToday()),
                      },
                    },
                  ],
                },
              ],
            },
          },
        }
      : undefined;
  }, [dates]);

  const onDatesChange = useCallback(
    (start, end) => {
      if (start && end) {
        if (
          dates?.start.getTime() !== start.getTime() &&
          dates?.end.getTime() !== end.getTime()
        ) {
          setDates({ start, end });
        }
      }
    },
    [dates]
  );

  useEffect(() => {
    if (stagesFilter) {
      getStages(stagesFilter);
    }
  }, [getStages, user, stagesFilter]);

  const stages = projectStagesResponse?.data || [];
  const { theme } = useContext(ThemeContext);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  return (
    <TabPane
      style={{
        cursor: draggedItem ? 'grabbing' : 'inherit',
      }}
    >
      <DndContext
        sensors={sensors}
        onDragStart={(event) => {
          setDraggedItem(event.active);
        }}
        onDragEnd={(event) => {
          if (
            event.over?.id === 'droppable-my-plan' &&
            draggedItem?.data.current?.type === 'projects-pool-item'
          ) {
            addUserToBroadRole(
              event.active?.id as string,
              (get(user, 'teamMemberId') as unknown) as string
            ).then(() => {
              setAddedStage(
                `${event.active?.id as string}-${new Date().toISOString()}`
              );
              setDraggedItem(null);
              getStages(stagesFilter);
            });
          }

          if (
            event.over?.id !== 'droppable-my-plan' &&
            draggedItem?.data.current?.type === 'calendar-event'
          ) {
            removeUserRoles(
              draggedItem?.data.current.id as string,
              (get(user, 'teamMemberId') as unknown) as string
            ).then(() => {
              setAddedStage(
                `${event.active?.id as string}-${new Date().toISOString()}`
              );
              setDraggedItem(null);
              getStages(stagesFilter);
            });
          }

          setDraggedItem(null);
        }}
      >
        <HomePageContainer title="timesheet" size="large">
          <TimesheetTabs addedStage={addedStage} />
        </HomePageContainer>
        <MyPlan
          addedStage={addedStage}
          onDatesChange={onDatesChange}
          draggedItem={draggedItem}
        />
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            gap: '0.5%',
            flexWrap: 'wrap',
          }}
        >
          <div className="projects-pool-container">
            <ProjectsPool stages={stages} draggedItem={draggedItem} />
          </div>
          <div className="tasks-pool-container">
            <HomePageContainer title="tasks pool" size="small">
              <List
                divided
                relaxed
                inverted={theme === 'dark'}
                style={{
                  paddingLeft: '3px',
                  marginTop: 0,
                }}
              />
            </HomePageContainer>
          </div>
        </div>
        <DragOverlay>
          {draggedItem?.data?.current?.type === 'projects-pool-item' && (
            <List
              divided
              relaxed
              inverted={theme === 'dark'}
              style={{
                paddingLeft: '3px',
                marginTop: 0,
              }}
            >
              <ProjectsPoolListItem
                stage={stages.find(
                  (stage: ProjectScheduleEvent) => stage.id === draggedItem?.id
                )}
                onClick={() => {}}
                style={{
                  cursor: draggedItem ? 'grabbing' : 'pointer',
                }}
              />
            </List>
          )}
          {draggedItem?.data?.current?.type === 'calendar-event' && (
            <CalendarEventDragOverlay event={{ ...draggedItem.data.current }} />
          )}
        </DragOverlay>
      </DndContext>
    </TabPane>
  );
};

export default HomeTab;
