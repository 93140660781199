import { Id } from '../types/base';
import Api from './Api';

abstract class BaseCRUDResource {
  public abstract objectIdentifier: string;

  getObjectPath = (id: Id) => {
    return this.objectIdentifier + `/${id}`;
  };

  count = async () => {
    return await Api.client.get(this.objectIdentifier + '/count');
  };

  list = async (filters?: {} | null) => {
    return await Api.client.get(this.objectIdentifier, { params: filters });
  };

  create = async (data?: any) => {
    return await Api.client.post(this.objectIdentifier, data);
  };

  updateAll = async (data?: any, filters?: {}) => {
    return await Api.client.patch(this.objectIdentifier, data, {
      params: filters,
    });
  };

  findById = async (id: Id, filters?: any) => {
    return await Api.client.get(this.getObjectPath(id), { params: filters });
  };

  updateById = async (id: Id, data?: any) => {
    return await Api.client.patch(this.getObjectPath(id), data);
  };

  replaceById = async (id: Id, data?: any) => {
    return await Api.client.put(this.getObjectPath(id), data);
  };

  deleteById = async (id: Id) => {
    return await Api.client.delete(this.getObjectPath(id));
  };

  deleteAll = async (filters?: {}) => {
    return await Api.client.delete(this.objectIdentifier, { params: filters });
  };
}

export default BaseCRUDResource;
