import React, { useCallback, useContext } from 'react';
import Delete from './actions/Delete';
import Download from './actions/Download';
import ProposalPreview from './actions/ProposalPreview';

import { FULL_ACCESS_PERMISSION } from '../team/permission';
import CreateProposalFormFields from './fields/CreateProposalFormFields';
import EditProposalFormFields from './fields/EditProposalFormFields';
import ResourceTable, {
  ColumnConfig,
} from '../../containers/resourceTable/ResourceTable';
import { ProposalWithRelations, Proposal } from './types';
import { getProjectFullName } from '../projects/utils';
import { formatNumber } from '../../utils/numberUtils';
import Decimal from 'decimal.js-light';
import DateFormatter, { toDateObject } from '../../utils/dateUtils';
import { ProposalContainer, OpenedProposalContext } from './ProposalsTab';
import ProposalResource from '../../api/resources/proposals/ProposalResource';
import ProjectProjectItemResource from '../../api/resources/projects/ProjectProjectItemResource';
import Heading from '../../components/layout/Heading';
import Segment from '../../components/layout/Segment';
import { createContainer } from 'unstated-next';
import useNestedCRUDResource from '../../hooks/useNestedCrudResource';
import ProposalProposalEntryResource from '../../api/resources/proposals/ProposalProposalEntryResource';
import ProjectItemsProposalTable from './projectItems/ProjectItemsProposalTable';
import ProposalEntriesTable from './proposalEntries/ProposalEntriesTable';
import Filters from './Filters';
// import TableFooter from '../invoices/TableFooter';

export const createProposalInstanceConfig = {
  title: 'Create proposal',
  formFields: CreateProposalFormFields,
  formId: 'proposal-create-form',
};

export const ProposalEntriesContainer = createContainer(useNestedCRUDResource);
export const ProjectItemsContainer = createContainer(useNestedCRUDResource);

function equalProps(
  prevProps: { instance: ProposalWithRelations },
  nextProps: { instance: ProposalWithRelations }
) {
  return (
    prevProps.instance.projectId === nextProps.instance.projectId &&
    prevProps.instance.currency === nextProps.instance.currency
  );
}

const ProposalEditFormNestedContent = React.memo(
  ({
    instance,
  }: {
    instance: ProposalWithRelations;
    reload: () => Promise<void>;
  }) => {
    return (
      <>
        <ProposalEntriesContainer.Provider
          initialState={{
            resource: ProposalProposalEntryResource,
            parentId: instance.id,
            searchFields: ['description'],
            defaultOrder: ['sequence ASC'],
            defaultFilter: {
              include: [{ relation: 'projectItem' }],
            },
          }}
        >
          <ProjectItemsContainer.Provider
            initialState={{
              resource: ProjectProjectItemResource,
              parentId: instance.projectId,
              searchFields: ['name'],
              defaultOrder: [
                'type ASC',
                'createDate ASC',
                'name ASC',
                'costPerItem ASC',
              ],
            }}
          >
            <Heading as="h4">
              Proposal Entries{' '}
              <span style={{ fontStyle: 'italic' }}>
                (All amounts are excluding VAT)
              </span>
            </Heading>
            <Segment>
              <ProposalEntriesTable proposal={instance} />
            </Segment>
            <Heading as="h4">
              Project Items & Expenses{' '}
              <span style={{ fontStyle: 'italic' }}>
                (All amounts are excluding VAT)
              </span>
            </Heading>
            <Segment>
              <ProjectItemsProposalTable
                projectId={instance.projectId}
                projectCurrency={instance.project.currency}
                proposal={instance}
              />
            </Segment>
          </ProjectItemsContainer.Provider>
        </ProposalEntriesContainer.Provider>
      </>
    );
  },
  equalProps
);

export const editProposalInstanceConfig = {
  title: 'Edit proposal',
  formFields: EditProposalFormFields,
  formId: 'proposal-edit-form',
  nameAccessor: 'number' as const,
  activateInputOnClick: true,
  nestedContent: ProposalEditFormNestedContent,
};

export const proposalEditFormActions = [
  {
    key: 'delete',
    condition: () => true,
    component: Delete,
    permission: FULL_ACCESS_PERMISSION,
  },
  {
    key: 'preview',
    condition: () => true,
    component: ProposalPreview,
    permission: FULL_ACCESS_PERMISSION,
  },
  {
    key: 'download',
    condition: () => true,
    component: Download,
    permission: FULL_ACCESS_PERMISSION,
  },
];

const Table = () => {
  const config: ColumnConfig<ProposalWithRelations>[] = [
    {
      label: 'Proposal No',
      accessor: (proposal: ProposalWithRelations) => proposal.number,
      orderingField: 'number',
      key: 'number',
      cellWidth: '1',
    },
    {
      label: 'Project',
      accessor: (proposal: ProposalWithRelations) =>
        getProjectFullName(proposal.project),
      orderingField: 'projectId',
      key: 'projectId',
      cellWidth: '5',
    },
    {
      label: 'Client',
      accessor: (proposal: ProposalWithRelations) => proposal.client.name,
      orderingField: 'clientId',
      key: 'clientId',
    },
    {
      label: 'VAT',
      accessor: 'VAT',
      orderingField: 'vat',
      sortTransformValue: (proposal) => Number(proposal.vat),
      key: 'vat',
      cellWidth: '1',
    },
    {
      label: 'Currency',
      accessor: 'currency',
      orderingField: 'currency',
      key: 'currency',
      cellWidth: '1',
    },
    {
      label: 'A. excl. VAT',
      accessor: (proposal: ProposalWithRelations) =>
        formatNumber(new Decimal(proposal.amount).toNumber()),
      sortTransformValue: (proposal: ProposalWithRelations) =>
        Number(proposal.amount),
      orderingField: 'amount',
      key: 'amount',
      cellWidth: '1',
    },
    {
      label: 'Amount',
      accessor: (proposal: ProposalWithRelations) =>
        formatNumber(new Decimal(proposal.amountWithVAT).toNumber()),
      sortTransformValue: (proposal: ProposalWithRelations) =>
        Number(proposal.amountWithVAT),
      orderingField: 'amount',
      key: 'amount',
      cellWidth: '1',
    },
    {
      label: 'Issued',
      accessor: (proposal: ProposalWithRelations) =>
        DateFormatter.format(toDateObject(proposal.issueDate)),
      orderingField: 'issueDate',
      key: 'issueDate',
      cellWidth: '1',
    },
  ];

  const { proposalId: openedProposalId, setOpenedProposalId } = useContext(
    OpenedProposalContext
  );

  const handleEditFormClose = useCallback(async (instance: Proposal) => {
    setOpenedProposalId('');
    await ProposalResource.print(instance.id);
    // eslint-disable-next-line
  }, []);

  return (
    <ResourceTable
      editInstanceId={openedProposalId}
      columns={config}
      newInstanceConfig={createProposalInstanceConfig}
      stateContainer={ProposalContainer}
      filterComponent={Filters}
      // infoBox={InfoBox}
      editFormActions={proposalEditFormActions}
      editInstanceConfig={editProposalInstanceConfig}
      bulkActions={[
        {
          key: 'delete',
          condition: () => true,
          component: Delete,
          permission: FULL_ACCESS_PERMISSION,
        },
        {
          key: 'download',
          condition: () => true,
          component: Download,
          permission: FULL_ACCESS_PERMISSION,
        },
      ]}
      onEditFormClose={handleEditFormClose}
      // footerComponent={TableFooter}
    />
  );
};

export default Table;
