import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Dispatch, SetStateAction } from 'react';

const Caret = (props: {
  index: number;
  expandedIndices: Array<number>;
  setExpandedIndices: Dispatch<SetStateAction<Array<number>>>;
}) => {
  if (props.expandedIndices.includes(props.index)) {
    return (
      <Icon
        name="caret down"
        onClick={() =>
          props.setExpandedIndices((prevState) =>
            prevState.filter((idx) => idx !== props.index)
          )
        }
      />
    );
  }
  return (
    <Icon
      name="caret right"
      onClick={() =>
        props.setExpandedIndices((prevState) => prevState.concat([props.index]))
      }
    />
  );
};

export default Caret;
