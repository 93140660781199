import React, { useState } from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { Tab, TabProps } from 'semantic-ui-react';
import TeamMembersPane from '../pages/team/Pane';
import { UserContext } from '../pages/auth/UserContext';
import { hasPermission } from '../pages/auth/utils';
import {
  CLIENTS_PERMISSION,
  INVOICES_PERMISSION,
  PROPOSALS_PERMISSION,
  PROJECT_SETTINGS_PERMISSION,
  PROJECTS_PERMISSION,
  RESOURCE_SCHEDULE_PERMISSION,
  TEAM_PERMISSION,
} from '../pages/team/permission';
import { LocalStorage } from '../api/BrowserStorage';
import ClientsTable from '../pages/clients/Table';
import ProjectsTable from '../pages/projects/Table';
import {
  CLIENTS_MENU_ITEM,
  HOME_MENU_ITEM,
  INVOICES_MENU_ITEM,
  PROPOSALS_MENU_ITEMS,
  PROJECT_SCHEDULE_MENU_ITEM,
  PROJECT_SETTINGS_MENU_ITEM,
  PROJECTS_MENU_ITEM,
  RESOURCE_SCHEDULE_MENU_ITEM,
  TEAM_MENU_ITEM,
  CUSTOMISE_MENU_ITEM,
  SALARIES_MENU_ITEM,
  STATS_MENU_ITEM,
} from './constants';
import SettingsPage from '../pages/settings/SettingsPage';
import ResourceSchedule from '../pages/resourceSchedule/ResourceSchedule';
import ProjectSchedule from '../pages/projectSchedule/ProjectSchedule';
// import TimesheetTabs from '../pages/timesheets/TimesheetTabs';
import InvoicesTab from '../pages/invoices/InvoicesTab';
import TabPane from '../components/layout/TabPane';
import CustomiseTab from '../pages/customise/CustomiseTab';
import ProposalsTab from '../pages/proposals/ProposalsTab';
import HomeTab from '../pages/home/HomeTab';
import SalariesTab from '../pages/salaries/SalariesTab';
import StatsTab from '../pages/salaries/stats/StatsTab';

const defaultIndex = LocalStorage.loadState('activeAdminTab') || 0;

export const TabContext = React.createContext({
  activeIndex: defaultIndex,
  setActiveIndex: (
    index: string,
    componentProps: { filters?: { where?: any } }
  ) => {},
});

const Tabs = () => {
  const { user } = UserContext.useContainer();
  const [activeIndex, setActiveIndex] = useState<number | string | undefined>(
    defaultIndex
  );
  const [componentProps, setComponentProps] = useState({});

  const handleTabChange = (event: any, { activeIndex }: TabProps) => {
    setComponentProps({});
    LocalStorage.saveState('activeAdminTab', activeIndex);
    setActiveIndex(activeIndex);
  };

  const panes: {
    menuItem: string;
    render: () => React.ReactNode | undefined;
  }[] = [
    {
      menuItem: HOME_MENU_ITEM,
      render: () => <HomeTab />,
    },
  ];

  if (hasPermission(user, INVOICES_PERMISSION)) {
    panes.push({
      menuItem: INVOICES_MENU_ITEM,
      render: () => <InvoicesTab {...componentProps} />,
    });
  }

  if (hasPermission(user, PROPOSALS_PERMISSION)) {
    panes.push({
      menuItem: PROPOSALS_MENU_ITEMS,
      render: () => <ProposalsTab {...componentProps} />,
    });
  }

  if (hasPermission(user, PROJECTS_PERMISSION)) {
    panes.push({
      menuItem: PROJECTS_MENU_ITEM,
      render: () => (
        <TabPane>
          <ProjectsTable {...componentProps} />
        </TabPane>
      ),
    });
  }

  if (hasPermission(user, CLIENTS_PERMISSION)) {
    panes.push({
      menuItem: CLIENTS_MENU_ITEM,
      render: () => (
        <TabPane>
          <ClientsTable {...componentProps} />
        </TabPane>
      ),
    });
  }

  if (hasPermission(user, TEAM_PERMISSION)) {
    panes.push({ menuItem: TEAM_MENU_ITEM, render: () => <TeamMembersPane /> });
  }

  if (hasPermission(user, RESOURCE_SCHEDULE_PERMISSION)) {
    panes.push({
      menuItem: RESOURCE_SCHEDULE_MENU_ITEM,
      render: () => (
        <TabPane>
          <ResourceSchedule />
        </TabPane>
      ),
    });
  }

  panes.push({
    menuItem: PROJECT_SCHEDULE_MENU_ITEM,
    render: () => (
      <TabPane>
        <ProjectSchedule />
      </TabPane>
    ),
  });

  if (hasPermission(user, SALARIES_MENU_ITEM)) {
    panes.push({
      menuItem: SALARIES_MENU_ITEM,
      render: () => (
        <TabPane>
          <SalariesTab />
        </TabPane>
      ),
    });
  }

  panes.push({
    menuItem: STATS_MENU_ITEM,
    render: () => (
      <TabPane>
        <StatsTab />
      </TabPane>
    ),
  });

  if (hasPermission(user, PROJECT_SETTINGS_PERMISSION)) {
    panes.push({
      menuItem: PROJECT_SETTINGS_MENU_ITEM,
      render: () => (
        <TabPane>
          <SettingsPage />
        </TabPane>
      ),
    });
  }

  panes.push({
    menuItem: CUSTOMISE_MENU_ITEM,
    render: () => <CustomiseTab />,
  });

  const updateActiveIndex = (
    menuItem: string | number,
    componentProps = {}
  ) => {
    let index;
    if (typeof menuItem === 'number') {
      index = menuItem;
    } else {
      index = panes.findIndex((pane) => pane.menuItem === menuItem);
    }

    setActiveIndex(index);
    LocalStorage.saveState('activeAdminTab', index);
    setComponentProps(componentProps);
  };

  const dropdownOptions = panes.map((pane, index) => ({
    key: pane.menuItem,
    value: index,
    text: pane.menuItem,
  }));

  return (
    <>
      <TabContext.Provider
        value={{
          activeIndex,
          setActiveIndex: updateActiveIndex,
        }}
      >
        {/*Menu opener for mobile screens*/}
        <Menu
          tabular
          inverted={true}
          className="navigation-menu-opener"
          style={{ background: 'transparent' }}
        >
          <Dropdown
            text="Menu"
            options={dropdownOptions}
            value={activeIndex}
            onChange={(event, { value }) => {
              if (typeof value === 'number') {
                updateActiveIndex(value);
              }
            }}
          />
        </Menu>
        {/* Menu for browser screens */}
        <Tab
          menu={{
            inverted: true,
            attached: true,
            tabular: true,
            className: 'navigation-menu',
          }}
          panes={panes}
          onTabChange={handleTabChange}
          activeIndex={activeIndex}
        />
      </TabContext.Provider>
    </>
  );
};

export default Tabs;
