import React, { MouseEvent, ReactNode, useMemo } from 'react';
import { getByAccessor } from '../../../utils/componentUtils';
import { Event as StyledEvent } from './Calendar.styled';
import tinycolor from 'tinycolor2';
import { dateDiffInDays } from '../../../utils/dateUtils';
import { hasPermission } from '../../../pages/auth/utils';
import { UserContext } from '../../../pages/auth/UserContext';
import { BaseMilestone } from '../types';

type Props<Milestone extends BaseMilestone> = {
  milestone: Milestone;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  milestoneNameAccessor:
    | string
    | string[]
    | ((milestone: Milestone) => ReactNode);
  columnStart: number;
  rowStart: number;
  skipWeekends: boolean;
  currentDate: Date;
  maxDays: number;
  editPermission?: string;
};

const CalendarMilestone = <Milestone extends BaseMilestone>(
  props: Props<Milestone>
) => {
  const {
    milestone,
    onClick,
    milestoneNameAccessor,
    columnStart,
    rowStart,
    currentDate,
    skipWeekends,
    maxDays,
    editPermission,
  } = props;

  const { user } = UserContext.useContainer();

  const milestoneColor = milestone.color;

  const milestoneColorDarker = tinycolor.mix(milestoneColor, '#000', 20);

  const diagonalColor = tinycolor
    .mix(milestoneColorDarker, '#494949', 20)
    .toHexString();

  const days = useMemo(
    () =>
      dateDiffInDays(
        new Date(milestone.endDatetime),
        new Date(milestone.datetime) < currentDate
          ? currentDate
          : new Date(milestone.datetime),
        skipWeekends
      ),
    [milestone, skipWeekends, currentDate]
  );

  const editable = useMemo(() => {
    return !(editPermission && !hasPermission(user, editPermission));
  }, [user, editPermission]);

  return (
    <StyledEvent
      key={milestone.id}
      color={milestoneColorDarker.toHexString()}
      span={days < maxDays ? days : maxDays}
      columnStart={columnStart}
      rowStart={rowStart}
      onClick={onClick}
      data-calendar-event="milestone"
      isPending={false}
      isRejected={false}
      editable={editable}
      title={getByAccessor(milestone, milestoneNameAccessor)}
      diagonalColor={diagonalColor}
      hasEventInfoComponent={false}
    >
      <span data-calendar-event="event">
        {getByAccessor(milestone, milestoneNameAccessor)}
      </span>
    </StyledEvent>
  );
};

export default CalendarMilestone;
