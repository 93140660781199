import React, { useEffect, useState } from 'react';
import useAPIRequest from '../../hooks/useAPIRequest';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import styled from 'styled-components';
import { Modal } from '../../components/dialogs';
import { TEAM_MEMBER_ROLE_OPTIONS } from '../team/constants';
import { Image } from 'semantic-ui-react';

const MiniProfileModal = styled(Modal)`
  height: 200px;
  max-height: 90%;
  background-color: #0084ff !important;
  border-radius: 20px !important;
  box-shadow: none;
  font-family: MarkOT-Medium, sans-serif;
`;

const PersonalData = styled.div`
  width: 65%;
  border-radius: 20px;
  text-align: right;
  padding: 30px 30px 20px 0;
  font-size: 1.2em;

  p {
    color: #fff;
    margin-bottom: 0;
  }
`;

const MiniProfile = ({
  teamMemberId,
  onClose,
}: {
  teamMemberId: string;
  onClose: () => void;
}) => {
  const {
    data: teamMemberResponse,
    performRequest: getTeamMember,
  } = useAPIRequest(TeamMemberResource.findById);

  useEffect(() => {
    getTeamMember(teamMemberId);
  }, [getTeamMember, teamMemberId]);

  const [userProfilePictureUrl, setUserProfilePictureUrl] = useState<
    string | null
  >(null);

  const { performRequest: getProfilePicture } = useAPIRequest(
    TeamMemberResource.fetchProfilePicture
  );

  useEffect(() => {
    getProfilePicture(teamMemberId).then((res) => {
      if (res?.data) {
        setUserProfilePictureUrl(
          window.URL.createObjectURL(new Blob([res.data]))
        );
      }
    });
  }, [getProfilePicture, teamMemberId]);

  const teamMember = teamMemberResponse?.data;

  return (
    <MiniProfileModal defaultOpen className="mini-profile" onClose={onClose}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            width: '35%',
            borderRadius: '20px',
            padding: '30px 0 20px 30px',
          }}
        >
          {userProfilePictureUrl && (
            <Image
              src={userProfilePictureUrl}
              size="small"
              title="Click to upload a new profile picture"
              style={{ borderRadius: '10px', cursor: 'pointer' }}
            />
          )}
          {!userProfilePictureUrl && (
            <div
              style={{
                width: '150px',
                height: '150px',
                borderRadius: '10px',
                backgroundColor: '#14432f',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                fontSize: '2rem',
                fontFamily: 'MarkOT-Medium, sans-serif',
              }}
            >
              {teamMember?.initials}
            </div>
          )}
        </div>
        <PersonalData>
          <>
            <p>{teamMember?.name}</p>
            <p>
              {
                TEAM_MEMBER_ROLE_OPTIONS.find(
                  (role) => role.value === teamMember?.role
                )?.text
              }
            </p>
            <p>{teamMember?.email}</p>
            <p>{teamMember?.telephone}</p>
          </>
        </PersonalData>
      </div>
    </MiniProfileModal>
  );
};

export default MiniProfile;
