import React, { useContext } from 'react';
import { Table as SemanticUITable } from 'semantic-ui-react';
import styled from 'styled-components';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {} & React.ComponentProps<typeof SemanticUITable>;

const StyledTable = styled(SemanticUITable)`
  &.ui.compact.table th {
    padding: 0.2em 0.4em;
  }

  &.ui.compact.table {
    font-size: 12px;
  }
`;

const Table = ({ children, ...rest }: Props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <StyledTable
      celled
      selectable
      compact
      striped
      inverted={theme === themes.dark}
      {...rest}
    >
      {children}
    </StyledTable>
  );
};

export default Table;
