import { Button } from 'semantic-ui-react';
import React, { SetStateAction, useContext, Dispatch } from 'react';
import { addMonths, subMonths } from 'date-fns';
import styled from 'styled-components';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

const monthFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'long',
});

const MonthTitle = styled.span`
  font-size: 1.4rem;
  font-family: MarkOT-Medium, sans-serif;
`;

const StyledDatePickerButton = styled(Button)`
  &.ui.basic.button,
  &.ui.basic.button:focus,
  &.ui.basic.button:hover {
    box-shadow: none !important;
  }
`;

const MonthSelector = ({
  month,
  setMonth,
  disablePrevious = false,
  disableNext = false,
}: {
  month: Date;
  setMonth: Dispatch<SetStateAction<Date>>;
  disablePrevious?: boolean;
  disableNext?: boolean;
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <StyledDatePickerButton
        basic
        icon="caret left"
        size="massive"
        inverted={theme === themes.dark}
        onClick={() => setMonth((currState) => subMonths(currState, 1))}
        disabled={disablePrevious}
      />
      <MonthTitle>{monthFormatter.format(month)}</MonthTitle>
      <StyledDatePickerButton
        basic
        icon="caret right"
        size="massive"
        onClick={() => setMonth((currState) => addMonths(currState, 1))}
        inverted={theme === themes.dark}
        disabled={disableNext}
      />
    </>
  );
};

export default MonthSelector;
