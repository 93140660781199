import React, { useContext, useEffect, useMemo } from 'react';
import { Accordion } from 'semantic-ui-react';
import TeamMembersSettings from './TeamMembersSettings';
import ProjectSettingResource from '../../api/resources/ProjectSettingResource';
import useAPIRequest from '../../hooks/useAPIRequest';
import { ProjectSetting } from './types';
import ErrorMessage from '../../components/ErrorMessage';
import ProjectsSettings from './projects/ProjectsSettings';
import FinanceSettings from './finances/FinanceSettings';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import ProposalsSettings from './proposals/ProposalsSettings';
import ProjectStagesSettings from './projectStages/ProjectStagesSettings';
import OccupationRateSettings from './occupation/OccupationRateSettings';
import SalariesSettings from './salaries/SalariesSettings';
import { PROJECT_STAGES_ROLES } from './keys';

const SettingsPage = () => {
  const {
    data: settings,
    error,
    loading,
    performRequest: getSettings,
  } = useAPIRequest(ProjectSettingResource.list);

  const projectsModuleSettings = useMemo(
    () =>
      settings
        ? settings.data.filter(
            (projectSetting: ProjectSetting) =>
              projectSetting.module === 'projects'
          )
        : [],
    [settings]
  );

  const rootSettingsPanels = [
    {
      key: 'team-member-settings',
      title: 'Team Members',
      content: {
        content: (
          <TeamMembersSettings
            loading={loading}
            settings={
              settings
                ? settings.data.filter(
                    (projectSetting: ProjectSetting) =>
                      projectSetting.module === 'team-members'
                  )
                : []
            }
          />
        ),
      },
    },
    {
      key: 'project-settings',
      title: 'Projects',
      content: {
        content: (
          <ProjectsSettings
            loading={loading}
            reloadSettings={getSettings}
            settings={projectsModuleSettings}
          />
        ),
      },
    },
    {
      key: 'project-stages-settings',
      title: 'Project stages',
      content: {
        content: (
          <ProjectStagesSettings
            loading={loading}
            reloadSettings={getSettings}
            settings={projectsModuleSettings}
          />
        ),
      },
    },
    {
      key: 'financial-settings',
      title: 'Finances',
      content: {
        content: (
          <FinanceSettings
            loading={loading}
            reloadSettings={getSettings}
            settings={
              settings
                ? settings.data.filter(
                    (projectSetting: ProjectSetting) =>
                      projectSetting.module === 'finances'
                  )
                : []
            }
          />
        ),
      },
    },
    {
      key: 'proposals-settings',
      title: 'Proposals',
      content: {
        content: (
          <ProposalsSettings
            loading={loading}
            reloadSettings={getSettings}
            settings={
              settings
                ? settings.data.filter(
                    (projectSetting: ProjectSetting) =>
                      projectSetting.module === 'proposals'
                  )
                : []
            }
          />
        ),
      },
    },
    {
      key: 'occupation-rate-settings',
      title: 'Occupation Rate',
      content: {
        content: (
          <OccupationRateSettings
            loading={loading}
            reloadSettings={getSettings}
            settings={
              settings
                ? settings.data.filter(
                    (projectSetting: ProjectSetting) =>
                      projectSetting.module === 'occupation-rate'
                  )
                : []
            }
          />
        ),
      },
    },
    {
      key: 'salaries-settings',
      title: 'Salaries',
      content: {
        content: (
          <SalariesSettings
            loading={loading}
            reloadSettings={getSettings}
            roleOptions={
              settings?.data?.find(
                (setting: ProjectSetting) =>
                  setting.key === PROJECT_STAGES_ROLES
              )?.value
            }
            settings={
              settings
                ? settings.data.filter(
                    (projectSetting: ProjectSetting) =>
                      projectSetting.module === 'salaries'
                  )
                : []
            }
          />
        ),
      },
    },
  ];

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <ErrorMessage error={error} />
      {settings && (
        <Accordion
          exclusive={false}
          panels={rootSettingsPanels}
          styled
          inverted={theme === themes.dark}
        />
      )}
    </>
  );
};

export default SettingsPage;
