import { Id } from '../types/base';
import Api from './Api';

abstract class BaseNestedCRUDResource {
  public abstract parentIdentifier: string;
  public abstract objectIdentifier: string;

  getPath = (id: Id) => {
    return this.parentIdentifier + `/${id}/` + this.objectIdentifier;
  };

  list = async (parentId: Id, filters?: {} | null) => {
    return await Api.client.get(this.getPath(parentId), { params: filters });
  };

  create = async (parentId: Id, data?: any) => {
    return await Api.client.post(this.getPath(parentId), data);
  };

  updateAll = async (parentId: Id, data?: any, filters?: {}) => {
    return await Api.client.patch(this.getPath(parentId), data, {
      params: filters,
    });
  };

  deleteAll = async (parentId: Id, filters?: {}) => {
    return await Api.client.delete(this.getPath(parentId), { params: filters });
  };
}

export default BaseNestedCRUDResource;
