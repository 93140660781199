import React, { useEffect, useState } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import {
  SelectInputField,
  DateInputField,
  ColorPickerField,
  NumberInputField,
} from '../../../components/forms/fields';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { SelectOptions } from '../../../types/forms';
import ProjectResource from '../../../api/resources/projects/ProjectResource';
import { Project } from '../../projects/types';
import useAPIRequest from '../../../hooks/useAPIRequest';
import { getProjectFullName } from '../../projects/utils';
import { UserContext } from '../../auth/UserContext';
import { hasPermission } from '../../auth/utils';
import { MANAGE_HOLIDAYS_PERMISSION } from '../../team/permission';
import {
  HOLIDAY_STATUS_OPTIONS,
  HOLIDAY_STATUS_PENDING,
} from '../../resourceSchedule/constants';
import get from 'lodash/get';

const EventFormFields = (props: FormFieldsComponentTypeProps) => {
  const { watch, setValue, reset, instance } = props;
  const [showHours, setShowHours] = useState(
    props.instance.hours ? props.instance?.hours < 8 : false
  );
  const projectId = watch('projectId');

  const [projectOptions, setProjectOptions] = useState<SelectOptions>([]);

  const {
    loading: loadingHolidays,
    data: holidayData,
    performRequest: getHolidays,
  } = useAPIRequest(ProjectResource.getHolidayTypes);

  const [project, setProject] = useState<Project | null>(null);

  const { user } = UserContext.useContainer();

  useEffect(() => {
    reset();

    const initials = get(instance, 'event');
    setTimeout(() => {
      if (initials) {
        Object.keys(initials).forEach((key) => {
          setValue(key, initials[key], { shouldDirty: true });
        });
      }
    }, 0);
  }, [instance, reset, setValue]);

  useEffect(() => {
    getHolidays();
  }, [getHolidays]);

  useEffect(() => {
    if (holidayData && projectId) {
      const project = holidayData.data.find(
        (project: Project) => project.id === projectId
      );
      if (project?.eventColor) {
        setValue('color', project.eventColor);
      } else {
        setValue('color', '#b3b3b3');
      }

      if (project?.isHoliday && !project?.isHolidayForAllUsers) {
        setValue('status', HOLIDAY_STATUS_PENDING);
      }

      setProject(project);
    }
  }, [projectId, holidayData, setValue]);

  useEffect(() => {
    if (holidayData) {
      setProjectOptions(
        holidayData.data
          .filter((project: Project) => !project.isHolidayForAllUsers)
          .map((instance: Project) => ({
            key: instance.id,
            value: instance.id,
            text: getProjectFullName(instance),
          }))
      );
    }
  }, [holidayData]);

  useEffect(() => {
    if (showHours) {
      setValue('hours', 8);
    } else {
      setValue('hours', undefined);
    }
    // eslint-disable-next-line
  }, [showHours]);

  return (
    <>
      <Form.Group>
        <SelectInputField
          options={projectOptions}
          rules={{
            required: { value: true, message: 'Please select a project.' },
          }}
          name="projectId"
          label={'Holiday'}
          loading={loadingHolidays}
          search
          width="8"
          {...props}
        />
        <ColorPickerField
          label="Color"
          width="8"
          name="color"
          defaultValue={'#b3b3b3'}
          {...props}
        />
      </Form.Group>
      <Form.Group>
        <DateInputField
          label="Start Date"
          name="start"
          placeholder="Start Date"
          dateOnly
          rules={{
            required: { value: true, message: 'Please enter a date.' },
          }}
          {...props}
          width="8"
        />
        <DateInputField
          width="8"
          label="End Date"
          name="end"
          placeholder="End Date"
          dateOnly
          rules={{
            required: { value: true, message: 'Please enter a date.' },
          }}
          {...props}
        />
      </Form.Group>
      <Form.Group>
        {project?.isHoliday &&
          !project?.isHolidayForAllUsers &&
          hasPermission(user, MANAGE_HOLIDAYS_PERMISSION) && (
            <SelectInputField
              options={HOLIDAY_STATUS_OPTIONS}
              rules={{
                required: { value: true, message: 'Please select a status.' },
              }}
              name="status"
              label="Status"
              search
              defaultValue={HOLIDAY_STATUS_PENDING}
              width="6"
              {...props}
            />
          )}
        {showHours && (
          <NumberInputField
            width="6"
            label="Hours"
            name="hours"
            rules={{
              min: {
                value: 0,
                message: 'The mininum value for this field is zero',
              },
              max: {
                value: 8,
                message: ' The maximum value for this field is 8.',
              },
            }}
            {...props}
          />
        )}
      </Form.Group>
      <Form.Group>
        <Form.Field inline>
          <label>All day</label>
          <Checkbox
            checked={!showHours}
            onChange={(event, { checked }) => setShowHours(!checked)}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default EventFormFields;
