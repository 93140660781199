import BaseCRUDResource from '../../BaseCRUDResource';
import Api from '../../Api';
import get from 'lodash/get';

class ProjectScheduleEventResource extends BaseCRUDResource {
  objectIdentifier = 'project-schedule-events';

  getLastByProjectId = async (projectId: string) => {
    return await Api.client.get(
      `${this.objectIdentifier}/get-last-by-project/${projectId}`
    );
  };

  list = async (filters?: {} | null) => {
    const teamMemberId = get(filters, 'filter.where.and[1].teamMemberId');
    return await Api.client.get(this.objectIdentifier, {
      params: { ...filters, teamMemberId },
    });
  };

  addUserToBroadRole = async (eventId: string, teamMemberId: string) => {
    return await Api.client.patch(
      `${this.objectIdentifier}/add-user-broad-role/${eventId}/${teamMemberId}`
    );
  };

  removeUserRoles = async (eventId: string, teamMemberId: string) => {
    return await Api.client.patch(
      `${this.objectIdentifier}/remove-user-roles/${eventId}/${teamMemberId}`
    );
  };
}

export default new ProjectScheduleEventResource();
