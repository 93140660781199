import React from 'react';
import Row from '../../../components/tables/Row';
import Cell from '../../../components/tables/Cell';
import Decimal from 'decimal.js-light';
import { ResourceScheduleEvent } from '../../resourceSchedule/ResourceSchedule';
import { calculateEventDays } from '../docs/DocsTable';

type Props = {
  items: ResourceScheduleEvent[];
  subset: boolean;
};

const TableFooter = (props: Props) => {
  const { items } = props;

  const amount = items.reduce((acc, curr) => {
    return acc.add(new Decimal(calculateEventDays(curr)));
  }, new Decimal(0));

  return (
    <>
      {items.length ? (
        <Row>
          <Cell colSpan={3} textAlign="right">
            <strong>Total:</strong>{' '}
          </Cell>
          <Cell>
            <strong>{amount.toDecimalPlaces(2).toString()}</strong>
          </Cell>
          <Cell />
        </Row>
      ) : null}
    </>
  );
};

export default TableFooter;
