import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import {
  EmailInputField,
  PasswordInputField,
} from '../../components/forms/fields';
import { useForm } from 'react-hook-form';
import {
  FormSegment,
  LoginButton,
  LoginPageFooter,
  LoginPageWrapper,
  LogoWrapper,
} from './LoginPage.styled';
import { Credentials } from './types';
import AuthResource from '../../api/resources/AuthResource';
import footer from '../../assets/LoginPage_Footer.png';
import Loader from '../../components/Loader';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage';
import { UserContext } from './UserContext';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import Logo from '../../components/LogoImage';

const LoginPage = () => {
  const formMethods = useForm<Credentials>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const userContext = UserContext.useContainer();
  const { clearError } = userContext;

  useEffect(() => {
    clearError();
  }, [clearError]);

  const onSubmit = async (data: Credentials) => {
    setLoading(true);
    setError(null);
    try {
      await AuthResource.login(data);
      setLoading(false);
      history.push('/admin');
      await userContext.performRequest();
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  const { theme } = useContext(ThemeContext);

  return (
    <LoginPageWrapper>
      <FormSegment>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Loader active={loading} />
        <ErrorMessage error={error} />
        <Form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          inverted={theme === themes.dark}
          className="login-form"
        >
          <EmailInputField
            inline={false}
            rules={{
              required: { value: true, message: 'Please enter an email.' },
            }}
            placeholder="Email"
            name="email"
            preventDefaultOnEnter={false}
            submitHandler={onSubmit}
            {...formMethods}
          />
          <PasswordInputField
            inline={false}
            required
            name="password"
            placeholder="Password"
            preventDefaultOnEnter={false}
            rules={{
              required: { value: true, message: 'Please enter a password.' },
            }}
            submitHandler={onSubmit}
            {...formMethods}
          />
          <LoginButton type="submit" positive size="big">
            Login
          </LoginButton>
        </Form>
      </FormSegment>
      <LoginPageFooter>
        <img src={footer} alt="cactus footer" style={{ width: '100vw' }} />
      </LoginPageFooter>
    </LoginPageWrapper>
  );
};

export default LoginPage;
