import styled from 'styled-components';
import React, { ReactNode } from 'react';

const GreenLabel = styled.div`
  min-height: 100px;
  height: min-content;
  width: 100%;
  color: #fff;
  background-color: #00a650 !important;
  border-radius: 20px !important;
  box-shadow: none;
  font-family: MarkOT-Medium, sans-serif;
  font-size: 1.1rem;
  position: relative;

  :hover .hover-text {
    display: block;
  }
`;

const BlueLabel = styled.div`
  border-radius: 20px !important;
  background-color: #0084ff;
  height: 100%;
  min-height: 100px;
  clip-path: polygon(0 0, 0 115%, 100% -25%, 100% 0);
  font-family: MarkOT-Medium, sans-serif;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
`;

const Value = styled.div`
  text-align: right;
  position: absolute;
  bottom: 5px;
  width: 100%;
  font-size: 2rem;
  padding-right: 5px;
  font-weight: bold;
`;

const Label = styled.div`
  padding-right: 10px;
  text-align: right;
  position: absolute;
  top: 5px;
  right: 0;
  width: 30%;
`;

const TextOnHover = styled.div`
  text-align: left;
  display: none;
  position: absolute;
  width: 68%;
  top: 5px;
  padding-left: 5px;
  font-size: 0.9rem;
`;

const BlueGreenLabel = ({
  label,
  value,
  hoverText,
}: {
  label: ReactNode;
  value: ReactNode;
  hoverText?: string;
}) => {
  return (
    <GreenLabel className="green-label">
      <BlueLabel>
        <div style={{ visibility: 'hidden' }}>{hoverText}</div>
      </BlueLabel>
      {hoverText && (
        <TextOnHover className="hover-text">{hoverText}</TextOnHover>
      )}
      <Label style={{ width: hoverText ? '30%' : '100%' }}>{label}</Label>
      <Value>{value}</Value>
    </GreenLabel>
  );
};

export default BlueGreenLabel;
