import React from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ui.fluid.selection {
    padding: 0.5em;
    font-size: 0.9em;
    min-height: 0;
  }

  .dropdown .dropdown.icon,
  .ui.selection.dropdown > .dropdown.icon {
    padding: 0.9em 0.66em;
  }

  .ui.dropdown .menu > .item {
    padding: 0.4em 0.5em !important;
    font-size: 0.96em;
  }
`;

const FormsFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .ui.fluid.selection {
    display: block;
  }

  .divider.text {
    vertical-align: middle;
  }
`;

type Props = {
  filters: React.ReactNode;
  actions: React.ReactNode;
  forms: React.ReactNode;
};

const TableActionsWrapper = ({ actions, filters, forms }: Props) => {
  return (
    <MainWrapper>
      <div>{actions}</div>
      <FormsFilterWrapper>
        <div>{forms}</div>
        <div>{filters}</div>
      </FormsFilterWrapper>
    </MainWrapper>
  );
};

export default TableActionsWrapper;
