import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { Project } from '../../projects/types';
import InvoiceResource from '../../../api/resources/invoices/InvoiceResource';
import { InvoiceContainer, OpenedInvoiceContext } from '../InvoicesTab';
import { useContainer } from 'unstated-next';

type Props = {
  selected: Project[];
  reload: () => Promise<void>;
};

const AddInvoiceToProject = (props: Props) => {
  const { selected } = props;

  const openedInvoiceContext = useContext(OpenedInvoiceContext);
  const invoiceTableContainer = useContainer(InvoiceContainer);

  const handleCreateNewInstance = async () => {
    const newInvoiceData = { projectId: selected[0].id };
    const response = await InvoiceResource.create(newInvoiceData);
    await invoiceTableContainer.list();
    openedInvoiceContext.setOpenedInvoiceId(response.data.id);
  };

  return (
    <Button
      positive
      size="huge"
      content="Add invoice"
      onClick={() => handleCreateNewInstance()}
    />
  );
};

export default AddInvoiceToProject;
