import BaseCRUDResource from '../../BaseCRUDResource';
import Api from '../../Api';
import { Id } from '../../../types/base';

class TeamMemberResource extends BaseCRUDResource {
  objectIdentifier = 'team-members';

  listForSchedules = async (filters?: {} | null) => {
    return await Api.client.get('team-members-schedules', { params: filters });
  };

  uploadOwnProfilePicture = async (formData: FormData) => {
    return await Api.client.post(
      `${this.objectIdentifier}/upload-own-profile-picture`,
      formData
    );
  };

  updateOwnData = async (data: {
    name?: string;
    telephone?: string;
    password?: string;
  }) => {
    return await Api.client.patch('update-own-data', data);
  };

  fetchOwnHolidaysData = async () => {
    return await Api.client.get(`${this.objectIdentifier}/own-holidays`);
  };

  fetchProfilePicture = async (id: Id) => {
    return await Api.client.get(
      `${this.objectIdentifier}/${id}/profile-picture`,
      { responseType: 'blob' }
    );
  };

  fetchOwnProfilePicture = async (useCache = true) => {
    return await Api.client.get(
      `${this.objectIdentifier}/own-profile-picture`,
      {
        responseType: 'blob',
        headers: {
          ...(useCache
            ? {}
            : {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0',
              }),
        },
      }
    );
  };
}

export default new TeamMemberResource();
