import React, { useRef } from 'react';
import EventInfoBox from '../../projectSchedule/EventInfoBox';
import DeleteAction from '../../../components/actions/DeleteAction';
import Segment from '../../../components/layout/Segment';

const MyPlanEventInfoBox = ({
  event,
  handleDeleteEvent,
}: {
  event: any;
  handleDeleteEvent?: (id: string) => void;
}) => {
  const mountNode = useRef<HTMLDivElement>(document.querySelector('#root'));

  return (
    <Segment style={{ borderRadius: 0, marginTop: 0 }} className=".handle">
      <EventInfoBox event={event} />
      {handleDeleteEvent && (
        <DeleteAction
          callback={() => handleDeleteEvent(event.id)}
          mountNode={mountNode}
          confirmText={`Are you sure you want to delete the stage from your plan?`}
        />
      )}
    </Segment>
  );
};

export default MyPlanEventInfoBox;
