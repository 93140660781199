import React from 'react';
import { Table } from 'semantic-ui-react';

type Props = {} & React.ComponentProps<typeof Table.Cell>;

const Cell = ({ children, ...rest }: Props) => {
  return <Table.Cell {...rest}>{children}</Table.Cell>;
};

export default Cell;
