import React, { useCallback, useContext } from 'react';
import { Form } from 'semantic-ui-react';
import { TextAreaField } from '../../../components/forms/fields';
import { useForm } from 'react-hook-form';
import { Project } from '../types';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectResource from '../../../api/resources/projects/ProjectResource';

type Props = {
  instance: Project;
};

const EditNoteFormFields = (props: Props) => {
  const { instance } = props;
  const { performRequest: updateProject } = useAPIRequest(
    ProjectResource.updateById
  );
  const formMethods = useForm<{ note: string }>({
    defaultValues: { note: instance.note },
  });
  const { getValues } = formMethods;
  const { theme } = useContext(ThemeContext);

  const onSubmit = useCallback(() => {
    updateProject(instance.id, { note: getValues('note') });
  }, [updateProject, instance.id, getValues]);

  return (
    <>
      <Form inverted={theme === themes.dark}>
        <Form.Group>
          <TextAreaField
            activateInputOnClick
            width="16"
            name="note"
            rows={2}
            {...formMethods}
            submitHandler={onSubmit}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default EditNoteFormFields;
