import React, { SyntheticEvent, useContext } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {
  appliedFilter: any;
  onChange: (filters: { where: any }) => void;
};

const projectStatusFilterOptions = [
  { key: 'live', value: 'live', text: 'Live' },
  { key: 'quoted', value: 'quoted', text: 'Quoted' },
  { key: 'completed', value: 'completed', text: 'Completed' },
  { key: 'all', value: 'all', text: 'All' },
  { key: 'lost', value: 'lost', text: 'Lost' },
];

export const projectDefaultFilters = {
  isHoliday: false,
  isInHouse: false,
};

export const flowProjectFilters = {
  and: [
    {
      or: [
        {
          ...projectDefaultFilters,
        },
        {
          onlyFlow: true,
        },
      ],
    },
  ],
};

export const projectFilterMapping = {
  lost: { ...projectDefaultFilters, status: 5 },
  quoted: { ...projectDefaultFilters, status: 4 },
  completed: { ...projectDefaultFilters, status: 3 },
  live: {
    and: [{ or: [{ status: 2 }, { status: 1 }, { status: 4 }] }],
    ...projectDefaultFilters,
  },
  all: { ...projectDefaultFilters },
};

const Filters = (props: Props) => {
  const { appliedFilter, onChange } = props;
  const { theme } = useContext(ThemeContext);

  const handleChange = (e: SyntheticEvent, { value }: DropdownProps) => {
    if (value && typeof value === 'string') {
      onChange({ where: get(projectFilterMapping, value) });
    } else {
      onChange({ where: {} });
    }
  };

  const getValue = () => {
    for (let element in projectFilterMapping) {
      if (Object.prototype.hasOwnProperty.call(projectFilterMapping, element)) {
        if (isEqual(appliedFilter?.where, get(projectFilterMapping, element))) {
          return element;
        }
      }
    }

    return undefined;
  };

  return (
    <Dropdown
      className={clsx('secondary', theme === themes.dark && 'inverted')}
      style={{ minWidth: '120px' }}
      fluid
      selection
      options={projectStatusFilterOptions}
      value={getValue()}
      onChange={handleChange}
      text={!getValue() && appliedFilter?.where?.clientId ? 'By client' : ''}
    />
  );
};

export default Filters;
