import React, { ElementType, ReactNode, RefObject } from 'react';
import { Icon } from 'semantic-ui-react';
import ModalForm from '../../components/forms/ModalForm';
import { FormFieldsComponentType } from '../../components/forms/types';
import { Id } from '../../types/base';
import { UserContext } from '../../pages/auth/UserContext';
import { hasPermission } from '../../pages/auth/utils';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import styled from 'styled-components';
import { EditFormAction } from '../types';
import { Modal, ModalHeader, ModalContent } from '../../components/dialogs';

const EditFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    flex-basis: 33%;
  }
`;

const InstanceName = styled.div`
  text-align: center;
  line-height: 1.28571429em;
`;

type Props<DataItem extends { id: Id }> = {
  title: React.ReactNode;
  nameAccessor: ((dataItem: DataItem) => ReactNode) | keyof DataItem;
  formFields: FormFieldsComponentType;
  formId: string;
  nestedContent?: ElementType<{
    instance: DataItem;
    reload: () => Promise<void>;
  }>;
  handleEditInstance: (
    data: any
  ) => Promise<void> | Promise<AxiosResponse<any>>;
  instance: DataItem;
  trigger?: React.ReactNode;
  editFormActions?: EditFormAction<DataItem>[];
  reload: () => Promise<void>;
  activateInputOnClick?: boolean;
  onClose?: () => Promise<void> | void;
  defaultOpen: boolean;
  customForm?: (props: {
    instance: DataItem;
    reload: () => Promise<void>;
  }) => JSX.Element;
  mountNode: RefObject<HTMLDivElement | null>;
  scrolling?: boolean;
};

const EditForm = <DataItem extends { id: Id }>(props: Props<DataItem>) => {
  const {
    title,
    formFields,
    formId,
    handleEditInstance,
    instance,
    trigger,
    editFormActions,
    reload,
    nameAccessor,
    nestedContent: NestedContent,
    activateInputOnClick = false,
    onClose,
    defaultOpen,
    customForm: CustomForm,
    mountNode,
    scrolling,
  } = props;

  const { user } = UserContext.useContainer();

  const [open, setOpen] = React.useState(defaultOpen ?? false);

  const additionalActions =
    editFormActions?.map(
      ({ component: Component, condition, key, permission }) => {
        if (condition(instance) && hasPermission(user, permission)) {
          return (
            <Component
              key={key}
              selected={instance}
              reload={reload}
              mountNode={mountNode}
            />
          );
        }
        return null;
      }
    ) || null;

  if (formFields) {
    return (
      <ModalForm
        formId={formId}
        fields={formFields}
        activateInputOnClick={activateInputOnClick}
        defaultOpen={defaultOpen}
        scrolling={scrolling}
        header={
          <EditFormHeader>
            <div>
              <Icon name="edit outline" circular size="small" />
              {title}
            </div>
            <InstanceName>
              {typeof nameAccessor === 'function'
                ? nameAccessor(instance)
                : get(instance, nameAccessor)}
            </InstanceName>
            <div />
          </EditFormHeader>
        }
        trigger={trigger}
        submitData={handleEditInstance}
        instance={instance}
        nestedContent={
          NestedContent ? (
            <NestedContent instance={instance} reload={reload} />
          ) : undefined
        }
        additionalActions={additionalActions}
        onClose={onClose}
        modalProps={{
          mountNode,
        }}
      />
    );
  }

  if (CustomForm) {
    return (
      <Modal
        closeIcon
        trigger={trigger}
        className={`modal-${formId}`}
        open={open}
        onClose={() => {
          setOpen(false);
          if (onClose) onClose();
        }}
        mountNode={mountNode}
      >
        <ModalHeader>
          <EditFormHeader>
            <div>
              <Icon name="edit outline" circular size="small" />
              {title}
            </div>
            <InstanceName>
              {typeof nameAccessor === 'function'
                ? nameAccessor(instance)
                : get(instance, nameAccessor)}
            </InstanceName>
            <div />
          </EditFormHeader>
        </ModalHeader>
        <ModalContent style={{ padding: 0 }}>
          <CustomForm instance={instance} reload={reload} />
        </ModalContent>
      </Modal>
    );
  }
  return null;
};

export default EditForm;
