import React, { RefObject } from 'react';
import { Button } from 'semantic-ui-react';
import { Client } from '../types';
import Confirm from '../../../components/dialogs/Confirm';
import ClientResource from '../../../api/resources/clients/ClientResource';

type Props = {
  selected: Client | Client[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Archive = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const handleArchive = async () => {
    if (Array.isArray(selected)) {
      const filters = {
        where: { or: selected.map((client) => ({ id: client.id })) },
      };
      await ClientResource.updateAll({ archived: true }, filters);
      await reload();
    } else {
      await ClientResource.updateById(selected.id, { archived: true });
      await reload();
    }
  };

  return (
    <Confirm
      content={`Are you sure you want to archive the client${
        Array.isArray(selected) && selected.length > 1 ? 's' : ''
      }?`}
      trigger={
        <Button
          content="Archive"
          size={Array.isArray(selected) ? 'huge' : 'large'}
        />
      }
      onSubmit={handleArchive}
      mountNode={mountNode}
    />
  );
};

export default Archive;
