import React from 'react';
import { Table } from 'semantic-ui-react';

type Props = {} & React.ComponentProps<typeof Table.Footer>;

const Footer = ({ children }: Props) => {
  return <Table.Footer>{children}</Table.Footer>;
};

export default Footer;
