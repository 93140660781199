import React, { ReactNode, SyntheticEvent, useContext, useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { FormFieldsComponentType } from './types';
import { Id } from '../../types/base';
import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';
import { AxiosResponse } from 'axios';
import { Modal, ModalActions, ModalContent, ModalHeader } from '../dialogs';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import Segment from '../layout/Segment';

type Props<DataItem extends { id: Id }> = {
  header: ReactNode;
  formId: string;
  fields: FormFieldsComponentType;
  trigger?: ReactNode;
  submitData?: (data?: any) => Promise<void> | Promise<AxiosResponse<any>>;
  instance?: DataItem;
  additionalActions?: ReactNode;
  nestedContent?: ReactNode;
  activateInputOnClick?: boolean;
  onClose?: () => Promise<void> | void;
  fieldsProps?: any;
  defaultOpen?: boolean;
  modalProps?: React.ComponentProps<typeof Modal>;
  scrolling?: boolean;
};

const ModalForm = <DataItem extends { id: Id }>(props: Props<DataItem>) => {
  const {
    header,
    fields: FormFields,
    trigger,
    submitData,
    formId,
    instance,
    additionalActions,
    nestedContent = null,
    activateInputOnClick = false,
    onClose = () => {},
    defaultOpen = false,
    fieldsProps = {},
    modalProps,
  } = props;

  const [open, setOpen] = React.useState(defaultOpen);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setError(null);
    if (instance) {
      formMethods.reset({ ...instance });
    } else {
      formMethods.reset();
    }
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const formMethods = useForm({
    defaultValues: instance ? { ...instance } : {},
  });

  const { reset } = formMethods;

  useEffect(() => {
    //@ts-ignore //TODO: Handle the RTE field update
    if (instance && !instance.note) {
      reset({ ...instance });
    }
  }, [instance, reset]);

  const handleSubmitData = async (data: any) => {
    setLoading(true);
    setError(null);
    try {
      if (submitData) {
        await submitData(data);
      }
      setLoading(false);
      return true;
    } catch (e) {
      setLoading(false);
      if (e.response?.data.error.details) {
        for (let detail of e.response.data.error.details) {
          formMethods.setError(detail.path.substring(1, detail.path.length), {
            type: 'validation',
            message: detail.message,
          });
        }
      } else {
        setError(e);
      }
      return false;
    }
  };

  const onSubmit = async (data: any) => {
    const success = await handleSubmitData(data);
    if (success) {
      handleClose();
    }
  };

  const { theme } = useContext(ThemeContext);

  return (
    <Modal
      onClose={(e: SyntheticEvent) => {
        e.stopPropagation();
        handleClose();
      }}
      onOpen={handleOpen}
      open={open}
      trigger={trigger}
      className={`modal-${formId}`}
      {...modalProps}
    >
      <ModalHeader>{header}</ModalHeader>
      <ModalContent inverted={theme === themes.dark} style={{ padding: 0 }}>
        <Segment style={{ borderRadius: 0 }}>
          <Loader active={loading} />
          <ErrorMessage error={error} />
          <Form
            onSubmit={(e) => {
              if (e.target === e.currentTarget)
                return formMethods.handleSubmit(onSubmit)(e);
            }}
            id={formId}
            inverted={theme === themes.dark}
          >
            <FormFields
              {...formMethods}
              instance={instance}
              activateInputOnClick={activateInputOnClick}
              submitHandler={handleSubmitData}
              {...fieldsProps}
            />
          </Form>
          {nestedContent}
        </Segment>
      </ModalContent>
      <ModalActions
        mainActions={
          <>
            <Button
              content={activateInputOnClick ? 'Close' : 'Cancel'}
              onClick={handleClose}
              size="large"
            />
            {!activateInputOnClick ? (
              <Button
                positive
                size="large"
                type="submit"
                content="Submit"
                form={formId}
              />
            ) : null}
          </>
        }
        additionalActions={additionalActions}
      />
    </Modal>
  );
};

export default ModalForm;
