import React, {
  ChangeEvent,
  ComponentProps,
  ElementType,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Input,
  InputProps,
  ModalProps,
  SemanticWIDTHS,
  TableRowProps,
} from 'semantic-ui-react';

import Table from '../../components/tables/Table';
import Header from '../../components/tables/Header';
import Row from '../../components/tables/Row';
import HeaderCell from '../../components/tables/HeaderCell';
import Body from '../../components/tables/Body';
import Cell from '../../components/tables/Cell';
import { Id } from '../../types/base';
import { VerticalSeparator } from '../../components/VerticalSeparator';
import CreateForm from './CreateForm';
import EditForm from './EditForm';
import { Container } from 'unstated-next';
import useCRUDResource from '../../hooks/useCRUDResource';
import { FormFieldsComponentType } from '../../components/forms/types';
import { UserContext } from '../../pages/auth/UserContext';
import { hasPermission } from '../../pages/auth/utils';
import TableActionsWrapper from '../../components/tables/TableActionsWrapper';
import { FULL_ACCESS_PERMISSION } from '../../pages/team/permission';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import debounce from 'lodash/debounce';
import { SelectInputCellProps } from '../../components/tables/inputCells/SelectInputCell';
import NumberInputCell from '../../components/tables/inputCells/NumberInputCell';
import Footer from '../../components/tables/Footer';
import { EditFormAction, EditInstanceConfig } from '../types';
import ResourceTableRow from './ResourceTableRow';
import useClickOutside from '../../hooks/useClickOutside';
import isEmpty from 'lodash/isEmpty';
import { ButtonSizeProps } from '../../types/forms';
import TextInputCell from '../../components/tables/inputCells/TextInputCell';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import clsx from 'clsx';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

export type EditableFieldConfig<DataItem extends { id: Id }> = {
  valueAccessor: keyof DataItem;
  component:
    | {
        type: 'select';
        props: Omit<
          SelectInputCellProps<DataItem>,
          'onSubmit' | 'initialValue' | 'item' | 'title'
        >;
      }
    | {
        type: 'number';
        props?: Omit<
          ComponentProps<typeof NumberInputCell>,
          'onSubmit' | 'initialValue'
        >;
      }
    | {
        type: 'text';
        props?: Omit<
          ComponentProps<typeof TextInputCell>,
          'onSubmit' | 'initialValue'
        >;
      }
    | { type: 'checkbox' };
};

export type ColumnConfig<DataItem extends { id: Id }> = {
  key: string;
  label: ReactNode;
  accessor:
    | ((dataItem: DataItem, reload: () => Promise<void>) => ReactNode)
    | keyof DataItem;
  titleAccessor?: ((dataItem: DataItem) => ReactNode) | keyof DataItem;
  orderingField: string | null;
  sortTransformValue?: (value: any) => any;
  clickHandler?: (event: SyntheticEvent, item: DataItem) => void;
  sortKey?: string;
  stopPropagation?: boolean;
  permission?: string;
  editable?: EditableFieldConfig<DataItem>;
  cellWidth?: SemanticWIDTHS;
};

type Props<DataItem extends { id: Id }> = {
  draggableSort?: boolean;
  stateContainer: Container<ReturnType<typeof useCRUDResource>>;
  filterComponent?: ElementType<any>;
  secondaryFilter?: ElementType<any>;
  footerComponent?: ElementType<{ items: DataItem[]; subset: boolean }>;
  editFormActions?: EditFormAction<DataItem>[];
  pageFooter?: ElementType<{ items: DataItem[]; appliedFilter: any }>;
  bulkActions?: {
    key: string;
    component: (props: {
      selected: DataItem[];
      reload: () => Promise<void>;
      disabled?: boolean;
      mountNode: RefObject<HTMLDivElement | null>;
    }) => JSX.Element;
    condition?: (filters: any) => boolean;
    permission: string;
    showAsDisabled?: boolean;
    maxSelection?: number;
  }[];
  forms?: {
    key: string;
    component: ElementType<{
      selected: DataItem[];
      reload: () => Promise<void>;
      mountNode: RefObject<HTMLDivElement | null>;
    }>;
    permission: string;
  }[];
  columns: Array<ColumnConfig<DataItem>>;
  newInstanceConfig?: {
    title: React.ReactNode;
    formFields?: FormFieldsComponentType;
    formId: string;
    buttonSize?: ButtonSizeProps;
    customForm?: ReactNode;
    modalProps?: ModalProps;
  };
  editInstanceConfig?: EditInstanceConfig<DataItem>;
  infoBox?: ElementType<{
    dataItem: DataItem;
    trigger: ReactNode;
  }>;
  createPermission?: string;
  editPermission?: string;
  deletePermission?: string;
  onReload?: () => void;
  onEdit?: (id: Id, params: Partial<DataItem>) => void;
  onCreate?: () => void;
  onEditFormClose?: (instance: DataItem) => any;
  addSearch?: boolean;
  readOnly?: boolean;
  getRowAdditionalProps?: (instance: DataItem) => TableRowProps;
  editInstanceId?: Id;
  tableId?: string;
  footerComponentProps?: any;
};

// const PAGE_LIMIT = 100;

const ResourceTable = <DataItem extends { id: Id }>(props: Props<DataItem>) => {
  const {
    columns,
    newInstanceConfig,
    editInstanceConfig = {},
    stateContainer,
    filterComponent: Filters = null,
    secondaryFilter: SecondaryFilter = null,
    infoBox,
    editFormActions = [],
    forms = [],
    createPermission = FULL_ACCESS_PERMISSION,
    editPermission = FULL_ACCESS_PERMISSION,
    onEdit = () => {},
    onCreate = () => {},
    addSearch = true,
    footerComponent: FooterComponent = null,
    bulkActions,
    onReload = () => {},
    readOnly = false,
    pageFooter: PageFooter = null,
    onEditFormClose = () => {},
    getRowAdditionalProps = () => ({}),
    editInstanceId,
    tableId,
    footerComponentProps,
    draggableSort = false,
  } = props;

  const [sortState, setSortState] = useState<{
    column: null | string;
    direction: undefined | 'ascending' | 'descending';
  }>({ column: null, direction: undefined });

  const container = stateContainer.useContainer();
  const { user } = UserContext.useContainer();

  const [editInstance, setEditInstance] = useState<DataItem | null>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [ctrlPressed, setCtrlPressed] = useState<boolean>(false);
  const [shiftPressed, setShiftPressed] = useState<boolean>(false);

  const { theme } = useContext(ThemeContext);

  // const [activePage, setActivePage] = useState(1);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleSort = (column: ColumnConfig<DataItem>) => {
    if (column.orderingField) {
      let prevDirection = sortState.direction;
      let prevColumn = sortState.column;
      setSortState((prevState) => {
        if (prevState.column === column.orderingField) {
          return {
            ...prevState,
            direction:
              prevState.direction === 'ascending' ? 'descending' : 'ascending',
          };
        }
        return { column: column.orderingField, direction: 'ascending' };
      });
      container.sortData(
        {
          sortKey: column.sortKey,
          orderingField: column.orderingField,
          transformValue: column.sortTransformValue,
        },
        prevColumn === column.orderingField && prevDirection === 'ascending'
      );
    }
  };

  const handleCreateNewInstance = async (params: Omit<DataItem, 'id'>) => {
    await container.create(params);
    onReload();
    onCreate();
  };

  const handleEditInstance = async (id: Id, params: Partial<DataItem>) => {
    await container.updateById(id, params);
    onEdit(id, params);
    onReload();
  };

  const handleSearchChange = debounce(
    (event: ChangeEvent, { value }: InputProps) => {
      container.search(value);
    },
    400
  );

  const reload = async (resetSelection = true) => {
    if (resetSelection) {
      setSelectedRows([]);
    }
    await container.list();
    onReload();
  };

  useEffect(() => {
    if (editInstance && container.data) {
      setEditInstance(
        container.data.find((instance) => instance.id === editInstance.id) ||
          null
      );
    }
    // eslint-disable-next-line
  }, [container.data]);

  useEffect(() => {
    if (editInstanceId && container.data) {
      setEditInstance(
        container.data.find((instance) => instance.id === editInstanceId) ||
          null
      );
    }
    // eslint-disable-next-line
  }, [editInstanceId, editInstance]);

  useEffect(() => {
    container.list();
    // eslint-disable-next-line
  }, [container.filters]);

  const clearSelection = useCallback(() => {
    if (selectedRows.length) {
      setSelectedRows([]);
    }
  }, [selectedRows]);

  useClickOutside(wrapperRef, clearSelection);

  const shiftHandler = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey || event.key === 'Control') {
        setCtrlPressed(true);
      }

      if (event.shiftKey || event.key === 'Shift') {
        setShiftPressed(true);
        document.addEventListener('selectstart', shiftHandler);
      }
    },
    [shiftHandler]
  );

  const keyUpHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey || event.key === 'Control') {
        setCtrlPressed(false);
      }

      if (event.shiftKey || event.key === 'Shift') {
        setShiftPressed(false);

        document.removeEventListener('selectstart', shiftHandler);
      }
    },
    [shiftHandler]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', keyUpHandler);

    return () => {
      document.removeEventListener('keyup', keyUpHandler);
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler, keyUpHandler]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <>
      <ErrorMessage error={container.error} />
      <Loader active={container.loading} />
      {!isEmpty(editInstanceConfig) && editInstance && !readOnly && (
        <EditForm
          key={`edit-form-${editInstance?.id}`}
          {...editInstanceConfig}
          instance={editInstance}
          defaultOpen
          handleEditInstance={(data) =>
            handleEditInstance(editInstance.id, data)
          }
          reload={reload}
          //@ts-ignore
          editFormActions={editFormActions}
          onClose={() => {
            onEditFormClose(editInstance);
            setEditInstance(null);
            reload();
          }}
          modalProps={{
            mountNode: wrapperRef,
          }}
        />
      )}
      <TableActionsWrapper
        forms={
          <>
            {forms.map(({ key, component: Form, permission }) => {
              if (hasPermission(user, permission) && !readOnly) {
                return (
                  <React.Fragment key={key}>
                    <Form
                      selected={
                        selectedRows.length
                          ? container.data.filter((item) =>
                              selectedRows.includes(item.id)
                            )
                          : container.data
                      }
                      reload={reload}
                      mountNode={wrapperRef}
                    />
                    {!!container.data.length &&
                      (forms.length > 1 || Filters) && <VerticalSeparator />}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </>
        }
        filters={
          Filters && (
            <Filters
              appliedFilter={container.dropdownFilters}
              onChange={(filters: any) => {
                container.updateFilters(filters);
              }}
            />
          )
        }
        actions={
          <>
            {hasPermission(user, createPermission) &&
              !readOnly &&
              newInstanceConfig && (
                <>
                  <CreateForm
                    {...newInstanceConfig}
                    handleCreateNewInstance={handleCreateNewInstance}
                    modalProps={{
                      mountNode: wrapperRef,
                    }}
                  />
                  {bulkActions?.length && <VerticalSeparator />}
                </>
              )}
            {bulkActions &&
              bulkActions.map(
                ({
                  key,
                  component: Component,
                  permission,
                  condition,
                  showAsDisabled,
                  maxSelection,
                }) => {
                  if (
                    !readOnly &&
                    hasPermission(user, permission) &&
                    (!condition || condition(container.filters)) &&
                    (showAsDisabled || selectedRows.length > 0) &&
                    (!maxSelection || selectedRows.length <= maxSelection)
                  ) {
                    return (
                      <React.Fragment key={key}>
                        <Component
                          selected={container.data.filter((item) =>
                            selectedRows.includes(item.id)
                          )}
                          reload={reload}
                          disabled={selectedRows.length < 1}
                          mountNode={wrapperRef}
                        />
                        {(bulkActions.length > 1 ||
                          addSearch ||
                          SecondaryFilter) && <VerticalSeparator />}
                      </React.Fragment>
                    );
                  }
                  return null;
                }
              )}
            {SecondaryFilter && (
              <SecondaryFilter
                appliedFilter={container.dropdownFilters}
                onChange={(filters: any) => {
                  container.updateFilters(filters);
                }}
              />
            )}
            {addSearch && (
              <Input
                icon="search"
                placeholder="Search..."
                onChange={handleSearchChange}
                className={clsx(
                  'secondary',
                  theme === themes.dark && 'inverted'
                )}
              />
            )}
          </>
        }
      />
      <div ref={wrapperRef} style={{ marginTop: '1em' }}>
        <Table sortable id={tableId} inverted={theme === themes.dark} stackable>
          <Header>
            <Row>
              {draggableSort && <HeaderCell />}
              {columns.map((column) => {
                if (
                  !column.permission ||
                  hasPermission(user, column.permission)
                ) {
                  return (
                    <HeaderCell
                      key={`${column.key}-header`}
                      sorted={
                        sortState.column === column.orderingField
                          ? sortState.direction
                          : undefined
                      }
                      onClick={() => handleSort(column)}
                    >
                      {column.label}
                    </HeaderCell>
                  );
                }
                return null;
              })}
            </Row>
          </Header>
          <Body>
            <DndContext
              sensors={sensors}
              onDragEnd={({ active, over }) => {
                if (over && active.id !== over?.id) {
                  const activeIndex = container?.data.findIndex(
                    ({ id }) => id === active.id
                  );
                  const overIndex = container?.data.findIndex(
                    ({ id }) => id === over.id
                  );
                  container.onDragSort(
                    arrayMove(container?.data, activeIndex, overIndex)
                  );
                }
              }}
            >
              <SortableContext items={container?.data || []}>
                {container?.data?.map((item: DataItem, i) => (
                  <ResourceTableRow
                    key={item.id}
                    draggableSort={draggableSort}
                    getRowAdditionalProps={getRowAdditionalProps}
                    columns={columns}
                    item={item}
                    editPermission={editPermission}
                    infoBox={infoBox}
                    handleEditInstance={handleEditInstance}
                    reload={reload}
                    setEditInstance={setEditInstance}
                    selected={selectedRows.includes(item.id)}
                    selectRow={(id: Id) =>
                      setSelectedRows((prevState) => {
                        if (ctrlPressed) {
                          if (prevState.includes(id)) {
                            return prevState.filter((itemId) => itemId !== id);
                          }
                          return prevState.concat([id]);
                        }

                        if (shiftPressed) {
                          const lastIdx = container.data.findIndex(
                            (item) => item.id === id
                          );
                          if (prevState.length) {
                            const firstIdx = container.data.findIndex(
                              (item) => item.id === prevState[0]
                            );
                            if (firstIdx < lastIdx) {
                              return container.data
                                .filter(
                                  (item, idx) =>
                                    idx >= firstIdx && idx <= lastIdx
                                )
                                .map((item) => item.id);
                            } else {
                              return container.data
                                .filter(
                                  (item, idx) =>
                                    idx <= firstIdx && idx >= lastIdx
                                )
                                .map((item) => item.id);
                            }
                          }
                          return [id];
                        }
                        return [id];
                      })
                    }
                  />
                ))}
                {container.data.length === 0 && !container.loading && (
                  <Row>
                    <Cell
                      colSpan={columns.length.toString()}
                      textAlign="center"
                    >
                      No Results
                    </Cell>
                  </Row>
                )}
              </SortableContext>
            </DndContext>
          </Body>
          {FooterComponent && (
            <Footer>
              <FooterComponent
                {...footerComponentProps}
                subset={!!selectedRows.length}
                items={
                  selectedRows.length
                    ? container.data.filter((item) =>
                        selectedRows.includes(item.id)
                      )
                    : container.data
                }
              />
            </Footer>
          )}
        </Table>
      </div>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        {/*<Pagination*/}
        {/*activePage={activePage}*/}
        {/*onPageChange={(e, { activePage }) => {*/}
        {/*if (activePage) {*/}
        {/*const pageToNumber = Number(activePage);*/}
        {/*setActivePage(pageToNumber)}*/}
        {/*}*/}
        {/*}*/}
        {/*totalPages={5}*/}
        {/*/>*/}
      </div>
      {PageFooter && (
        <PageFooter
          items={container.data}
          appliedFilter={container.dropdownFilters}
        />
      )}
    </>
  );
};

export default ResourceTable;
