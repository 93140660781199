import React, { useContext, useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import styled from 'styled-components';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import DateFormatter from '../../../utils/dateUtils';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import clsx from 'clsx';

const StyledDatePicker = styled(SemanticDatepicker)`
  &.ui.input > input {
    border: 1px solid transparent;
  }

  &.ui.input > input::placeholder {
    color: rgba(116, 116, 116, 0.87);
  }
`;

type Props = {
  onChange: (date: Date | null | undefined) => void;
  startDate: Date;
  showSelectedWeek?: boolean;
  addWeek: () => void;
  removeWeek: () => void;
  numberOfWeeks: number;
};

const CalendarDatePicker = (props: Props) => {
  const {
    onChange,
    startDate,
    showSelectedWeek = true,
    removeWeek,
    addWeek,
    numberOfWeeks,
  } = props;

  const [datePopupOpen, setDatePopupOpen] = useState(false);

  const handleChange = (
    e: React.SyntheticEvent | undefined,
    { value }: SemanticDatepickerProps
  ) => {
    if (!Array.isArray(value) && value) {
      onChange(
        new Date(value.getFullYear(), value.getMonth(), value.getDate())
      );
      setDatePopupOpen(false);
    }
  };

  const lastDayOfWeek = new Date(startDate.getTime()).setDate(
    startDate.getDate() + 6
  );

  const setWeekBefore = () => {
    const weekBefore = new Date(startDate.getTime());
    weekBefore.setDate(weekBefore.getDate() - 7);
    onChange(weekBefore);
  };

  const setWeekAfter = () => {
    const weekAfter = new Date(startDate.getTime());
    weekAfter.setDate(weekAfter.getDate() + 7);
    onChange(weekAfter);
  };

  const { theme } = useContext(ThemeContext);

  return (
    <>
      {showSelectedWeek && (
        <>
          <strong> Week: </strong>
          {DateFormatter.format(startDate)} -{' '}
          {DateFormatter.format(lastDayOfWeek)}{' '}
        </>
      )}
      <Button icon="angle left" positive size="large" onClick={setWeekBefore} />

      <Popup
        pinned
        on="click"
        position="bottom center"
        inverted={theme === themes.dark}
        onOpen={() => setDatePopupOpen(true)}
        onClose={() => setDatePopupOpen(false)}
        open={datePopupOpen}
        content={
          <StyledDatePicker
            onChange={handleChange}
            clearable={false}
            placeholder="Choose date"
            date={startDate}
            value={undefined}
            firstDayOfWeek={1}
            inverted={theme === themes.dark}
            className={clsx('secondary', theme === themes.dark && 'inverted')}
            clearOnSameDateClick={false}
            inline={true}
          />
        }
        trigger={<Button content="Choose date" positive size="large" />}
      />
      <Button
        positive
        icon="minus"
        size="large"
        onClick={removeWeek}
        disabled={numberOfWeeks < 2}
      />
      <Button
        positive
        icon="plus"
        size="large"
        onClick={addWeek}
        disabled={numberOfWeeks > 12}
      />
      <Button positive icon="angle right" size="large" onClick={setWeekAfter} />
    </>
  );
};

export default CalendarDatePicker;
