import React, { SyntheticEvent, useState } from 'react';
import Cell from '../Cell';
import { Checkbox, CheckboxProps, SemanticWIDTHS } from 'semantic-ui-react';

type Props = {
  initialValue: boolean;
  width?: SemanticWIDTHS;
  onSubmit: (value: boolean) => Promise<void> | void;
};

const CheckboxInputCell = (props: Props) => {
  const { initialValue, onSubmit, width } = props;

  const [value, setValue] = useState<boolean | undefined>(initialValue);

  const handleChange = (
    e: SyntheticEvent<HTMLInputElement>,
    { checked }: CheckboxProps
  ) => {
    setValue(checked);
    onSubmit(checked || false);
  };

  return (
    <Cell width={width} onClick={(e: SyntheticEvent) => e.stopPropagation()}>
      <Checkbox checked={value} onChange={handleChange} />
    </Cell>
  );
};

export default CheckboxInputCell;
