import React, { useContext } from 'react';
import { Form, Select } from 'semantic-ui-react';
import { TeamMember } from '../team/types';
import { Id } from '../../types/base';
import styled from 'styled-components';
import clsx from 'clsx';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {
  teamMembers: TeamMember[];
  loading: boolean;
  teamMemberValue: Id;
  onChange: (id: Id) => void;
};

export const StyledTeamMemberSelector = styled(Form.Field)`
  &.field.inline {
    display: inline-flex;
    min-width: 200px;
  }

  &.field > .selection.dropdown,
  .ui.search.selection.dropdown > input.search {
    padding: 0.5em 0.45em !important;
    font-size: 0.96em;
    width: 100%;
    min-height: 0;
    line-height: 1.1em;
    color: inherit;
  }

  &.field > .selection.dropdown .dropdown.icon {
    padding: 0.66em;
  }

  &.field .ui.dropdown .menu > .item {
    padding: 0.4em 0.5em !important;
    font-size: 0.96em;
  }
`;

const TeamMemberSelector = (props: Props) => {
  const { teamMembers, teamMemberValue, onChange } = props;
  const { theme } = useContext(ThemeContext);

  const options = teamMembers
    ? teamMembers.map((teamMember) => ({
        value: teamMember.id,
        text: teamMember.name,
        key: teamMember.id,
      }))
    : [];

  return (
    <StyledTeamMemberSelector inline>
      <Select
        className={clsx('secondary', theme === themes.dark && 'inverted')}
        value={teamMemberValue}
        options={options}
        name="teamMemberId"
        search
        onChange={(event, { value }) => {
          if (value && typeof value === 'string') {
            onChange(value);
          }
        }}
      />
    </StyledTeamMemberSelector>
  );
};

export default TeamMemberSelector;
