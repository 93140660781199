import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { DateInputField } from '../../../components/forms/fields';

type Props = FormFieldsComponentTypeProps;

const ProjectTimeSpentSelectPeriodFormFields = (props: Props) => {
  return (
    <>
      <Form.Group widths="equal">
        <DateInputField label="From" name="from" dateOnly {...props} />
        <DateInputField label="To" name="to" dateOnly {...props} />
      </Form.Group>
    </>
  );
};

export default ProjectTimeSpentSelectPeriodFormFields;
