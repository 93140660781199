import Api from '../../Api';

class MonthlyProjectBonusResource {
  objectIdentifier = 'monthly-project-bonus';

  updateTeamMemberCoefficients = async (
    monthlyProjectBonusId: string,
    requestBody: {
      teamMemberId: string;
      coefficient: number;
    }
  ) => {
    return await Api.client.patch(
      `${this.objectIdentifier}/${monthlyProjectBonusId}/update-coefficient`,
      requestBody
    );
  };

  updateBigChiefsBonuses = async (
    monthlyProjectBonusId: string,
    requestBody: {
      bigChiefsBonuses: {
        [teamMemberId: string]: {
          bonus: number;
          bonusPercentage: number;
        };
      };
      bigChiefsBonus?: number;
      bigChiefsBonusPercentage?: number;
    }
  ) => {
    return await Api.client.patch(
      `${this.objectIdentifier}/${monthlyProjectBonusId}/update-big-chiefs-bonus`,
      requestBody
    );
  };

  updateProjectTimeSpentsDates = async (
    monthlyProjectBonusId: string,
    requestBody: {
      from?: string;
      to?: string;
    }
  ) => {
    return await Api.client.patch(
      `${this.objectIdentifier}/${monthlyProjectBonusId}/update-project-time-spent-dates`,
      requestBody
    );
  };

  updateTopDogsBonuses = async (
    monthlyProjectBonusId: string,
    requestBody: {
      topDogsBonus?: number;
      topDogsBonusPercentage?: number;
      topDogsBonuses: {
        [roleId: string]: {
          bonus: number;
          bonusPercentage: number;
          teamMembers: {
            [teamMemberId: string]: { bonus: number; bonusPercentage: number };
          };
        };
      };
    }
  ) => {
    return await Api.client.patch(
      `${this.objectIdentifier}/${monthlyProjectBonusId}/update-top-dogs-bonus`,
      requestBody
    );
  };
}

export default new MonthlyProjectBonusResource();
