import React from 'react';
import TabPane from '../../components/layout/TabPane';
import ThemeSliders from './ThemeSliders';

const CustomiseTab = () => {
  return (
    <TabPane>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ThemeSliders />
      </div>
    </TabPane>
  );
};

export default CustomiseTab;
