export const nameBGValidationpattern = {
  pattern: {
    value: /[а-яА-Я0-9.\-'",]/,
    message:
      'The name can consist only of cyrillic letters, numbers, hyphens, commas, dots and single or double quotes',
  },
};

export const addressBGValidationpattern = {
  pattern: {
    value: /[а-яА-Я0-9№_.\-'",]/,
    message:
      'The name can consist only of cyrillic letters, numbers, hyphens, dots, commas and single or double quotes',
  },
};

export const cityBGValidationPattern = {
  pattern: {
    value: /[а-яА-Я0-9№_.\-'",]/,
    message:
      'The name can consist only of cyrillic letters, numbers, hyphens, dots, commas and single or double quotes',
  },
};
