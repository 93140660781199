import React, {
  CSSProperties,
  KeyboardEvent,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { Form, Input, Ref } from 'semantic-ui-react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import get from 'lodash/get';
import { ErrorLabel, StyledFormField } from './Fields.styled';

type Props = {
  label: ReactNode;
  name: string;
  placeholder?: string;
  step?: number;
  rules?: ValidationRules;
  unit?: string;
  activateInputOnClick?: boolean;
  submitHandler: (data: any) => any;
  inline?: boolean;
  fluid?: boolean;
  readOnly?: boolean;
  style?: CSSProperties;
} & React.ComponentProps<typeof Form.Field> &
  UseFormMethods;

const NumberInputField = (props: Props) => {
  const {
    register,
    label,
    name,
    placeholder,
    step,
    rules = {},
    errors,
    clearErrors,
    setValue,
    watch,
    unit,
    activateInputOnClick,
    submitHandler,
    trigger,
    getValues,
    inline = true,
    fluid = true,
    loading,
    width,
    readOnly,
    style,
  } = props;

  const value =
    typeof watch(name) === 'number'
      ? watch(name)
      : watch(name)
      ? +watch(name)
      : null;

  const [touched, setTouched] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (e?.target.value) {
      setValue(name, +e.target.value);
      clearErrors(name);
    } else {
      setValue(name, null);
    }
    setTouched(true);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    register(name, rules);
  }, [register, name, rules]);

  React.useEffect(() => {
    setValue(name, value);
  }, [setValue, value, name]);

  const fieldError = get(errors, name);

  const handleBlur = async () => {
    if (activateInputOnClick) {
      const valid = await trigger(name);
      if (valid && touched) {
        submitHandler(getValues());
        setTouched(false);
      }
    }
  };

  const handleKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (activateInputOnClick) {
        const valid = await trigger(name);
        if (valid) {
          if (inputRef.current && inputRef.current.firstChild) {
            const input = inputRef.current.firstChild as HTMLInputElement;
            input.blur();
          }
        }
      }
    }
  };

  return (
    <StyledFormField
      activateInputOnClick={activateInputOnClick}
      error={!!fieldError}
      inline={inline}
      required={
        typeof rules.required === 'object'
          ? !!rules?.required?.value
          : !!rules?.required
      }
      width={width}
    >
      <label>{label}:</label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          ...style,
        }}
      >
        <Ref innerRef={inputRef}>
          <Input
            name={name}
            placeholder={placeholder}
            type="number"
            step={step}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            onKeyPress={handleKeyPress}
            label={
              unit
                ? { color: 'black', content: unit, inverted: true }
                : undefined
            }
            labelPosition={unit ? 'right' : undefined}
            fluid={fluid}
            loading={loading}
            readOnly={readOnly}
          />
        </Ref>
        {fieldError && (
          <ErrorLabel pointing={'above'}>{fieldError.message}</ErrorLabel>
        )}
      </div>
    </StyledFormField>
  );
};

export default NumberInputField;
