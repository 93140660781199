import Api from '../../Api';

class MonthlyProjectBonusNotesResource {
  objectIdentifier = 'monthly-project-bonus-notes';

  createOrUpdateMonthlyProjectBonusNote = async (monthlyProjectBonusNotes: {
    notes: string;
    monthlySalariesId: string;
    projectId: string;
  }) => {
    return await Api.client.post(
      `${this.objectIdentifier}/create-or-update`,
      monthlyProjectBonusNotes
    );
  };
}

export default new MonthlyProjectBonusNotesResource();
