import React, { ReactNode } from 'react';
import { Invoice } from './types';
import { InfoBoxContentContainer } from '../../components/InfoBoxContentContainer';
import { zeroPad } from './Table';
import { Modal } from '../../components/dialogs';
import Heading from '../../components/layout/Heading';
import Segment from '../../components/layout/Segment';

type Props = {
  dataItem: Invoice;
  trigger: ReactNode;
};

/* TODO */

const InfoBox = (props: Props) => {
  const { dataItem: invoice, trigger } = props;

  return (
    <Modal
      closeIcon
      trigger={trigger}
      content={
        <Segment style={{ padding: 0, borderRadius: 0 }}>
          <Heading as="h3">{zeroPad(invoice.number, 10)}</Heading>
          <InfoBoxContentContainer />
        </Segment>
      }
    />
  );
};

export default InfoBox;
