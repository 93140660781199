import { List, Ref } from 'semantic-ui-react';
import { getProjectFullName } from '../../projects/utils';
import DateFormatter, { toDateObject } from '../../../utils/dateUtils';
import React, { CSSProperties } from 'react';
import { ProjectScheduleEvent } from '../../projectSchedule/ProjectSchedule';
import { useDraggable } from '@dnd-kit/core';

export default function ProjectsPoolListItem({
  stage,
  onClick,
  style,
}: {
  stage: ProjectScheduleEvent;
  onClick: (stage: ProjectScheduleEvent) => void;
  style?: CSSProperties;
}) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: stage.id,
    data: {
      type: 'projects-pool-item',
    },
  });

  return (
    <Ref innerRef={setNodeRef}>
      <List.Item
        key={stage.id}
        style={{ cursor: 'pointer', ...style }}
        onClick={() => onClick(stage)}
        {...attributes}
        {...listeners}
      >
        <List.Icon name="bars" verticalAlign="middle" />
        <List.Content>
          <List.Header>
            <span style={{ fontWeight: 'bold' }}>
              {getProjectFullName(stage.project)}
            </span>{' '}
            <span className="stage-name">{stage.name}</span>
          </List.Header>
          <List.Description>
            {DateFormatter.format(toDateObject(stage.start))} -{' '}
            {stage.end && DateFormatter.format(toDateObject(stage.end))}
          </List.Description>
        </List.Content>
      </List.Item>
    </Ref>
  );
}
