import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  TextInputField,
  NumberInputField,
  SelectInputField,
} from '../../../components/forms/fields';
import { nameBGValidationpattern } from '../../../components/validationRules';
import { SelectOptions } from '../../../types/forms';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import { getProjectSettingsItemsKey } from '../../projects/projectItems/ProjectItemFormFields';
import { ITEM_VALUE } from '../../projects/constants';
import { ProjectSetting } from '../../settings/types';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

type Props = FormFieldsComponentTypeProps;

const ProposalEntryFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;

  const [itemOptions, setItemOptions] = useState<SelectOptions>([]);
  const {
    loading,
    data: response,
    performRequest: getItemTypes,
  } = useAPIRequest(ProjectSettingResource.list);

  useEffect(() => {
    const key = getProjectSettingsItemsKey(ITEM_VALUE);
    getItemTypes({
      filter: { where: { or: key.map((k) => ({ key: k })) } },
    });
  }, [getItemTypes]);

  useEffect(() => {
    if (response) {
      const options: {
        key: string;
        value: string;
        text: string;
      }[] = [];
      response.data.forEach((setting: ProjectSetting) => {
        setting.value.forEach((v: string) => {
          options.push({ key: v, text: v, value: v });
        });
      });
      const sortedOptions = options
        .slice()
        .sort((a, b) => a.text.localeCompare(b.text));
      setItemOptions(sortedOptions);
    }
  }, [response]);

  const handleAddition = (
    e: React.SyntheticEvent,
    { value }: DropdownProps
  ) => {
    if (value && typeof value === 'string') {
      setItemOptions((prevState) => {
        return [{ key: value, text: value, value }, ...prevState];
      });
    }
  };

  return (
    <>
      <Form.Group widths="equal">
        <SelectInputField
          label="Description"
          name="description"
          options={itemOptions}
          loading={loading}
          rules={{
            required: { value: true, message: 'Please specify a description.' },
          }}
          width="8"
          allowAdditions
          onAddItem={handleAddition}
          search
          {...formMethods}
        />
        <TextInputField
          label="Description (BG)"
          name="descriptionBG"
          rules={{
            required: {
              value: true,
              message: 'Please add a description in Bulgarian.',
            },
            ...nameBGValidationpattern,
          }}
          {...formMethods}
          width="8"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <NumberInputField
          label="Quantity"
          name="quantity"
          placeholder="0"
          rules={{
            min: { value: 0, message: 'Negative numbers are not allowed' },
          }}
          {...formMethods}
          width="4"
        />
        <NumberInputField
          label="Unit Cost"
          name="unitCost"
          placeholder="0.00"
          {...formMethods}
          width="4"
        />
      </Form.Group>
    </>
  );
};

export default ProposalEntryFormFields;
