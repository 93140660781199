import React from 'react';
import {
  ColumnConfig,
  default as ResourceTable,
} from '../../containers/resourceTable/ResourceTable';
import { Project } from '../projects/types';
import { getProjectFullName } from '../projects/utils';
import { CLIENTS_PERMISSION, FULL_ACCESS_PERMISSION } from '../team/permission';
import AddInvoiceToProject from './actions/AddInvoice';
import { ProjectsToBeInvoicedContainer } from './InvoicesTab';
import ProjectsToBeInvoicedFooter from './ProjectsToBeInvoicedFooter';
import { formatNumber } from '../../utils/numberUtils';
import Decimal from 'decimal.js-light';
import DateFormatter from '../../utils/dateUtils';

const ProjectsToBeInvoicedTable = () => {
  const config: ColumnConfig<Project>[] = [
    {
      label: 'Name',
      accessor: (project: Project) => getProjectFullName(project),
      orderingField: 'ref',
      key: 'name',
    },
    {
      label: 'Client',
      accessor: (project: Project) => project.client.name,
      orderingField: 'clientId',
      key: 'client',
      stopPropagation: true,
      permission: CLIENTS_PERMISSION,
    },
    {
      label: 'Start Date',
      accessor: (project: Project) =>
        DateFormatter.format(new Date(project.startDate)),
      orderingField: 'startDate',
      key: 'startDate',
    },
    {
      label: 'Deadline',
      accessor: (project: Project) =>
        project.deadline
          ? DateFormatter.format(new Date(project.deadline))
          : '',
      orderingField: 'deadline',
      key: 'deadline',
    },
    {
      label: 'Currency',
      accessor: 'currency',
      key: 'currency',
      orderingField: 'currency',
      cellWidth: '1',
    },
    {
      label: 'Amount Left',
      accessor: (project: Project) =>
        formatNumber(new Decimal(project.leftAmount || 0).toNumber()),
      sortTransformValue: (project: Project) => Number(project.leftAmount),
      key: 'leftAmount',
      orderingField: 'leftAmount',
      cellWidth: '1',
    },
    {
      label: '% Left',
      accessor: (project: Project) => `${project.percentageLeft}%`,
      sortTransformValue: (project: Project) => Number(project.percentageLeft),
      key: 'percentageLeft',
      orderingField: 'percentageLeft',
      cellWidth: '1',
    },
  ];

  return (
    <ResourceTable
      columns={config}
      addSearch={false}
      stateContainer={ProjectsToBeInvoicedContainer}
      bulkActions={[
        {
          key: 'add-invoice',
          condition: () => true,
          component: AddInvoiceToProject,
          permission: FULL_ACCESS_PERMISSION,
          maxSelection: 1,
        },
      ]}
      footerComponent={ProjectsToBeInvoicedFooter}
    />
  );
};

export default ProjectsToBeInvoicedTable;
