import { Divider, DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';
import * as React from 'react';
import { useContext } from 'react';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

const StyledDivider = styled(Divider)`
  &.ui.divider {
    margin-top: 1.4rem;
    margin-bottom: 1.3rem;
  }
`;

const FieldsDivider = (props: DividerProps) => {
  const { theme } = useContext(ThemeContext);

  return <StyledDivider inverted={theme === themes.dark} {...props} />;
};

export default FieldsDivider;
