import React, { useContext } from 'react';
import { Form } from 'semantic-ui-react';
import { ProjectSetting } from '../types';
import { PROJECT_CURRENCY_EXCHANGE_RATES } from '../keys';
import { useForm } from 'react-hook-form';
import { bgn, PROJECT_CURRENCY_OPTIONS } from '../../projects/constants';
import get from 'lodash/get';
import NumberInputField from '../../../components/forms/fields/NumberInputField';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

type Props = {
  settings: ProjectSetting[];
  loading: boolean;
  updateOrCreateSetting: (data: Omit<ProjectSetting, 'id'>) => Promise<void>;
  reloadSettings: () => Promise<void>;
};

const CurrencyExchangeRates = (props: Props) => {
  const { settings, loading, updateOrCreateSetting } = props;

  const exchangeRates =
    settings.find(({ key }) => key === PROJECT_CURRENCY_EXCHANGE_RATES)
      ?.value || {};

  const formMethods = useForm({
    defaultValues: PROJECT_CURRENCY_OPTIONS.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.value]: get(exchangeRates, curr.value) || '',
      }),
      {}
    ),
  });

  const { theme } = useContext(ThemeContext);

  return (
    <Form loading={loading} inverted={theme === themes.dark}>
      {PROJECT_CURRENCY_OPTIONS.filter((option) => option.value !== bgn).map(
        (option) => (
          <NumberInputField
            readOnly
            key={`${option.value}-currency-rate-edit`}
            inline
            label={option.value}
            name={option.value}
            submitHandler={(value) => {
              updateOrCreateSetting({
                key: PROJECT_CURRENCY_EXCHANGE_RATES,
                value: { ...exchangeRates, ...value },
                module: 'finances',
              });
            }}
            fluid={false}
            activateInputOnClick
            {...formMethods}
          />
        )
      )}
    </Form>
  );
};

export default CurrencyExchangeRates;
