import React, { RefObject } from 'react';
import { ProjectTimesheetWeek } from '../types';
import ProjectTimesheetWeekResource from '../../../api/resources/timesheets/ProjectTimesheetWeekResource';
import DeleteAction from '../../../components/actions/DeleteAction';

type Props = {
  selected: ProjectTimesheetWeek | ProjectTimesheetWeek[];
  onSubmit: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const DeleteProjectTimesheetWeek = (props: Props) => {
  const { selected, onSubmit, mountNode } = props;

  const handleDelete = async () => {
    if (Array.isArray(selected)) {
      const filters = {
        where: { or: selected.map((weekEntry) => ({ id: weekEntry.id })) },
      };
      await ProjectTimesheetWeekResource.deleteAll(filters);
      await onSubmit();
    } else {
      await ProjectTimesheetWeekResource.deleteById(selected.id);
      await onSubmit();
    }
  };

  return (
    <DeleteAction
      confirmText={
        Array.isArray(selected) && selected.length > 1
          ? 'Are you sure you want to delete the entries?'
          : 'Are you sure you want to delete the entry'
      }
      callback={handleDelete}
      mountNode={mountNode}
      buttonSize="huge"
    />
  );
};

export default DeleteProjectTimesheetWeek;
