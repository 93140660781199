import React, { useEffect } from 'react';
import DateFormatter, { toDateObject } from '../../../utils/dateUtils';
import {
  ResourceScheduleEvent,
  ResourceScheduleEventWithRelations,
} from '../../resourceSchedule/ResourceSchedule';
import { differenceInBusinessDays, endOfMonth, startOfMonth } from 'date-fns';
import { HOLIDAY_STATUS_APPROVED } from '../../resourceSchedule/constants';
import { UserContext } from '../../auth/UserContext';
import {
  ColumnConfig,
  default as ResourceTable,
} from '../../../containers/resourceTable/ResourceTable';
import { getProjectFullName } from '../../projects/utils';
import useCRUDResource from '../../../hooks/useCRUDResource';
import { createContainer } from 'unstated-next';
import {
  FULL_ACCESS_PERMISSION,
  RESOURCE_SCHEDULE_PERMISSION,
} from '../../team/permission';
import Filters from './Filters';
import { rangeFilterQuery } from '../../../components/calendar/utils';
import HolidayResourceSchedule from '../HolidayResourceSchedule';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectResource from '../../../api/resources/projects/ProjectResource';
import { Project } from '../../projects/types';
import Decimal from 'decimal.js-light';
import LeaveDocsResource from '../../../api/resources/timesheets/LeaveDocsResource';
import DownloadPDFAction from './actions/DownloadPDFAction';

const monthStart = startOfMonth(new Date());
const monthEnd = endOfMonth(new Date());

export const calculateEventDays = (event: ResourceScheduleEvent) => {
  const numberOfBusinessDays = new Decimal(
    Math.abs(
      differenceInBusinessDays(
        toDateObject(event.start),
        toDateObject(event.end)
      )
    ) + 1
  );

  const eventHours = event.hours ? new Decimal(event.hours) : new Decimal('8');
  const multiplier = eventHours.div(new Decimal('8'));

  return numberOfBusinessDays.mul(multiplier).toDecimalPlaces(2).toString();
};

export const DocsContainer = createContainer(useCRUDResource);

const DocsTable = () => {
  const { user } = UserContext.useContainer();

  const { data: projectsResponse, performRequest: getProjects } = useAPIRequest(
    ProjectResource.list
  );

  useEffect(() => {
    getProjects({
      filter: {
        where: { isHoliday: true, isHolidayForAllUsers: false },
        fields: { id: true },
      },
    });
  }, [getProjects]);

  const config: ColumnConfig<ResourceScheduleEventWithRelations>[] = [
    {
      label: 'Type',
      accessor: (event: ResourceScheduleEventWithRelations) => (
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          {getProjectFullName(event.project)}
        </span>
      ),
      key: 'type',
      orderingField: 'projectId',
    },
    {
      label: 'Employee',
      accessor: (event: ResourceScheduleEventWithRelations) => (
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          {event.teamMember.name}
        </span>
      ),
      key: 'employee',
      orderingField: 'projectId',
    },
    {
      label: 'From',
      accessor: (event: ResourceScheduleEventWithRelations) =>
        DateFormatter.format(toDateObject(event.start)),
      key: 'start',
      orderingField: 'start',
    },
    {
      label: 'To',
      accessor: (event: ResourceScheduleEventWithRelations) =>
        DateFormatter.format(toDateObject(event.end)),
      key: 'end',
      orderingField: 'end',
    },
    {
      label: 'Days',
      accessor: (event: ResourceScheduleEvent) =>
        Number(calculateEventDays(event)).toFixed(2),
      key: 'days',
      orderingField: 'end',
    },
  ];

  return user && projectsResponse ? (
    <DocsContainer.Provider
      initialState={{
        resource: LeaveDocsResource,
        defaultFilter: {
          where: {
            and: [
              {
                ...rangeFilterQuery(monthStart, monthEnd),
              },
              {
                or: projectsResponse.data.map((p: Pick<Project, 'id'>) => ({
                  projectId: p.id,
                })),
              },
              {
                status: HOLIDAY_STATUS_APPROVED,
              },
            ],
          },
          include: [{ relation: 'project' }, { relation: 'teamMember' }],
        },
        defaultOrder: 'start ASC',
      }}
    >
      <ResourceTable
        columns={config}
        addSearch={false}
        editPermission={RESOURCE_SCHEDULE_PERMISSION}
        createPermission={RESOURCE_SCHEDULE_PERMISSION}
        editInstanceConfig={{
          customForm: ({ instance }) => (
            <>
              {' '}
              <HolidayResourceSchedule defaultStartDate={instance.start} />{' '}
            </>
          ),
          title: 'Holiday Schedule',
          nameAccessor: () => '',
          formId: 'holiday-schedule-edit',
        }}
        stateContainer={DocsContainer}
        filterComponent={Filters}
        forms={[
          {
            key: 'leave-docs-download',
            component: DownloadPDFAction,
            permission: FULL_ACCESS_PERMISSION,
          },
        ]}
      />
    </DocsContainer.Provider>
  ) : null;
};

export default DocsTable;
