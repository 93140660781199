import React, { RefObject } from 'react';
import ProjectTimesheetWeekResource from '../../../api/resources/timesheets/ProjectTimesheetWeekResource';
import NewTimesheetEntryFormFields from '../forms/NewTimesheetEntryFormFields';
import { dateToString } from '../../../utils/dateUtils';
import CreateForm from '../../../containers/resourceTable/CreateForm';
import { Id } from '../../../types/base';

type Props = {
  teamMemberId: Id;
  weekFirstDay: Date;
  onSubmit: () => Promise<void> | void;
  mountNode: RefObject<HTMLDivElement | null>;
};

const CreateTimesheetEntry = (props: Props) => {
  const { teamMemberId, weekFirstDay, onSubmit, mountNode } = props;

  const handleClick = async (formData: {
    projectId: Id;
    projectTaskId?: Id;
  }) => {
    const data = {
      ...formData,
      teamMemberId: teamMemberId,
      firstDay: dateToString(weekFirstDay),
    };
    await ProjectTimesheetWeekResource.create(data);
    onSubmit();
  };

  return (
    <CreateForm
      title="Create Timesheet Entry"
      formFields={NewTimesheetEntryFormFields}
      formId="new-timesheet-entry"
      handleCreateNewInstance={handleClick}
      modalProps={{
        mountNode,
      }}
    />
  );
};

export default CreateTimesheetEntry;
