import React, { ReactNode } from 'react';
import { Client } from './types';
import ContactsTable from './contacts/ContactsTable';
import { CLIENT_BUSINESSTYPE_OPTIONS } from './constants';
import Attributes from '../../components/Attributes';
import { InfoBoxContentContainer } from '../../components/InfoBoxContentContainer';
import { Modal } from '../../components/dialogs';
import Heading from '../../components/layout/Heading';
import Segment from '../../components/layout/Segment';

type Props = {
  dataItem: Client;
  trigger: ReactNode;
};

const InfoBox = (props: Props) => {
  const { dataItem: client, trigger } = props;

  return (
    <Modal
      closeIcon
      trigger={trigger}
      content={
        <Segment style={{ borderRadius: 0, marginTop: 0 }} className=".handle">
          <Heading as="h3">{client.name}</Heading>
          <InfoBoxContentContainer>
            <Attributes
              attributes={[
                [
                  {
                    key: `${client.id}-website`,
                    icon: 'globe',
                    title: 'Website',
                    value: client.website && (
                      <a
                        href={client.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {client.website}
                      </a>
                    ),
                  },
                  {
                    key: `${client.id}-business`,
                    icon: 'briefcase',
                    title: 'Business Type',
                    value: CLIENT_BUSINESSTYPE_OPTIONS.find(
                      (type) => type.value === client.businessType
                    )?.text,
                  },
                  {
                    key: `${client.id}-telephones`,
                    icon: 'phone',
                    title: 'Telephones',
                    value: client.telephones,
                  },
                ],
                [
                  {
                    key: `${client.id}-note`,
                    icon: 'sticky note',
                    title: 'Note',
                    value: client.note && (
                      <div dangerouslySetInnerHTML={{ __html: client.note }} />
                    ),
                  },
                ],
              ]}
            />
            <Heading as="h4">Contacts</Heading>
            <ContactsTable clientId={client.id} />
          </InfoBoxContentContainer>
        </Segment>
      }
    />
  );
};

export default InfoBox;
