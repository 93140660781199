import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ChartMeta,
  ChartDataset,
} from 'chart.js';
import useAPIRequest from '../../hooks/useAPIRequest';
import InvoiceResource from '../../api/resources/invoices/InvoiceResource';
import get from 'lodash/get';
import { LocalStorage } from '../../api/BrowserStorage';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import Decimal from 'decimal.js-light';
import { ColorPickerField } from '../../components/forms/fields';
import { useForm } from 'react-hook-form';
import Heading from '../../components/layout/Heading';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const YEAR_DEFAULT_COLORS = {
  2016: '#297ed8',
  2017: '#d81d67',
  2018: '#7a2cd8',
  2019: '#20d865',
  2020: '#1bd0d0',
  2021: '#f6b26b',
  2022: '#82ca9d',
  2023: '#5967dc',
  cost: '#cc4125',
  margin: '#3b84ff',
  additional: '#2e2eca',
  expected: '#00000000',
  [new Date().getFullYear() - 1]: '#ca8531',
  [new Date().getFullYear()]: '#0b0',
};

const labelTexts = {
  margin: 'Target (Margin)',
  cost: 'Cost',
  additional: 'Target (Additional)',
  expected: 'Expected income',
};

const CombinedChart = () => {
  const { performRequest: getCombinedChartData } = useAPIRequest(
    InvoiceResource.getCombinedChartData
  );
  const formMethods = useForm({
    defaultValues:
      LocalStorage.loadState('combined-chart-colors') || YEAR_DEFAULT_COLORS,
  });

  const [chartColors, setChartColors] = useState(
    LocalStorage.loadState('combined-chart-colors') || YEAR_DEFAULT_COLORS
  );

  const [chartData, setChartData] = useState<any[]>([]);
  const [datasets, setDatasets] = useState<ChartDataset<'bar'>[]>([]);
  const [switchedOffKeys, setSwitchedOffKeys] = useState<string[]>(
    LocalStorage.loadState('combined-chart-switched-off-keys') || []
  );

  useEffect(() => {
    getCombinedChartData().then((res) => {
      setChartData(res.data);
    });
  }, [getCombinedChartData]);

  useEffect(() => {
    const newDatasets: ChartDataset<'bar'>[] = [];
    chartData?.forEach((monthData, idx) => {
      const sortable = Object.entries(monthData)
        .filter(
          ([key, v]) => typeof v === 'number' && !key.includes('monthOnly')
        )
        .sort(
          ([, a], [, b]) =>
            ((a as unknown) as number) - ((b as unknown) as number)
        );

      for (const item of sortable) {
        if (!switchedOffKeys.includes(item[0])) {
          newDatasets.push({
            label: item[0] as string,
            backgroundColor: get(chartColors, (item[0] as unknown) as string),
            hoverBackgroundColor: get(
              chartColors,
              (item[0] as unknown) as string
            ),
            hoverBorderColor: item[0] === 'expected' ? 'gray' : undefined,
            data: [...Array(idx), item[1]],
            borderColor: item[0] === 'expected' ? 'gray' : undefined,
          });
        } else {
          newDatasets.push({
            label: item[0] as string,
            backgroundColor: get(chartColors, (item[0] as unknown) as string),
            data: [...Array(idx), 0],
            borderColor:
              item[0] === new Date().getFullYear().toString() &&
              idx === new Date().getMonth()
                ? 'gray'
                : undefined,
          });
        }
      }
    });
    setDatasets(newDatasets);
  }, [chartColors, chartData, switchedOffKeys]);

  const averages = useMemo(() => {
    const result: { [x: string]: number } = {};

    if (chartData[11]) {
      Object.keys(chartData[11]).forEach((key) => {
        if (key.includes('monthOnly')) {
          let average = new Decimal(0);
          let divider = 0;
          chartData.forEach((month) => {
            if (month[key]) {
              divider += 1;
              average = new Decimal(average).add(new Decimal(month[key]));
            }
          });
          average = average.div(divider);
          result[key] = average.toNumber();
        }
      });
    }
    return result;
  }, [chartData]);

  const labels = chartData?.map((data) => data.month);

  useEffect(() => {
    LocalStorage.saveState('combined-chart-switched-off-keys', switchedOffKeys);
  }, [switchedOffKeys]);

  const { theme } = useContext(ThemeContext);

  const gridLinesColor =
    theme === themes.dark
      ? 'rgba(255, 255, 255, 0.5)'
      : 'rgba(117,117,117,0.35)';
  const expectedIncomeColor = theme === themes.dark ? '#dadada' : '#9f9f9f';

  return (
    <div
      style={{ width: '100%', marginTop: 20, textAlign: 'center' }}
      className="combined-chart-container"
    >
      <Heading as="h4">Annual Turnover</Heading>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px 10px',
          flexWrap: 'wrap',
          marginBottom: '10px',
        }}
      >
        {chartData[11] &&
          Object.entries(chartData[11])
            .filter(
              ([key, v]) => typeof v === 'number' && !key.includes('monthOnly')
            )
            .map((entry) => {
              return (
                <div key={entry[0]} style={{ height: '25px' }}>
                  <ColorPickerField
                    activateInputOnClick
                    hideDropdownIcon={true}
                    pointing="right"
                    label={
                      <span
                        style={{
                          lineHeight: '1.7em',
                          color: switchedOffKeys.includes(entry[0])
                            ? '#c5c5c5'
                            : theme === themes.dark
                            ? '#fff'
                            : '#000',
                          textDecoration: switchedOffKeys.includes(entry[0])
                            ? 'line-through'
                            : undefined,
                        }}
                        onClick={() => {
                          if (switchedOffKeys.includes(entry[0])) {
                            setSwitchedOffKeys((currState) =>
                              currState.filter((item) => item !== entry[0])
                            );
                          } else {
                            setSwitchedOffKeys((currState) =>
                              currState.concat([entry[0]])
                            );
                          }
                        }}
                      >
                        {get(labelTexts, entry[0]) || entry[0]}
                      </span>
                    }
                    name={entry[0]}
                    {...formMethods}
                    submitHandler={(data) => {
                      setChartColors(data);
                      LocalStorage.saveState('combined-chart-colors', data);
                    }}
                  />
                </div>
              );
            })}
      </div>
      <Bar
        width="100%"
        plugins={[
          {
            id: 'dashed-draw',
            afterDatasetDraw(
              chart: ChartJS<'bar'>,
              args: { index: number; meta: ChartMeta },
              options: any
            ) {
              args.meta.data.forEach(function (element) {
                // @ts-ignore
                if (element.options.borderColor === 'gray' && element.height) {
                  const borderWidth = 3;
                  const ctx = chart.ctx;
                  // @ts-ignore
                  const half = element.width / 2;
                  const left = element.x - half;
                  const right = element.x + half;
                  const top = element.y;
                  const width = right - left;
                  const height =
                    chart.chartArea.bottom - top + borderWidth / 2 - 1;
                  ctx.beginPath();
                  ctx.lineWidth = borderWidth;
                  ctx.strokeStyle = expectedIncomeColor;
                  ctx.setLineDash([5, 5]);
                  ctx.moveTo(left, top);
                  ctx.lineTo(left, top + height);
                  ctx.moveTo(left, top);
                  ctx.lineTo(left + width, top);
                  ctx.moveTo(left + width, top);
                  ctx.lineTo(left + width, top + height);
                  ctx.stroke();
                  ctx.save();
                }
              });
            },
          },
        ]}
        options={{
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              mode: 'point',
              intersect: false,
              callbacks: {
                label: (tooltipItem): string | string[] | void => {
                  console.log(tooltipItem);
                  const label =
                    get(labelTexts, tooltipItem?.dataset.label || '') ||
                    tooltipItem?.dataset.label;
                  const value = new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'BGN',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format((tooltipItem.raw as number) || 0);
                  const currentMonthOnly =
                    chartData[tooltipItem.dataIndex][
                      `${tooltipItem.dataset.label}_monthOnly`
                    ];
                  const currentMonthOnlyDisplayValue = new Intl.NumberFormat(
                    'en-GB',
                    {
                      style: 'currency',
                      currency: 'BGN',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  ).format((currentMonthOnly as number) || 0);
                  const average = new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'BGN',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(
                    (averages[
                      `${tooltipItem.dataset.label}_monthOnly`
                    ] as number) || 0
                  );
                  return [
                    label,
                    `Accumulated: ${value}`,
                    `${tooltipItem.label} ${label}: ${currentMonthOnlyDisplayValue}`,
                    `Monthly average: ${average}`,
                  ];
                },
              },
              filter: (item, index) => {
                return index === 0;
              },
            },
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                color: gridLinesColor,
              },
              ticks: {
                color: theme === themes.dark ? '#fff' : '#000',
              },
              border: {
                dash: [5, 5],
              },
            },
            y: {
              beginAtZero: true,
              stacked: false,
              border: {
                dash: [5, 5],
              },
              ticks: {
                color: theme === themes.dark ? '#fff' : '#000',
              },
              grid: {
                color: gridLinesColor,
              },
            },
          },
          animation: false,
        }}
        data={{
          labels,
          datasets,
        }}
      />
    </div>
  );
};

export default CombinedChart;
