export const DEFAULT_HOLIDAY_DAYS_KEY = 'team_members_annual_holiday_days';
export const TIMESHEET_REMINDER_PROJECT_MANAGER_KEY =
  'team_members_timesheet_reminder_project_manager';

export const DAILY_COST_PER_PERSON_KEY = 'finances_daily_cost_per_person';
export const WORK_DAYS_PER_YEAR = 'finances_work_days_per_year';
export const TARGET_MARGIN_KEY = 'team_members_target_margin_key';
export const TARGET_ADDITIONAL_KEY = 'team_members_target_additional_key';
export const PROJECT_ITEM_TYPES_IMAGES = 'project_items_image_types';
export const PROJECT_ITEM_TYPES_VIDEOS = 'project_items_video_types';
export const PROJECT_ITEM_TYPES_OTHER = 'project_items_other_types';
export const PROJECT_NEGATIVES_TYPES = 'project_negatives_types';
export const PROJECT_CURRENCY_EXCHANGE_RATES = 'projects_bgn_exchange_rates';
export const PROJECT_DEFAULT_BONUS_PERCENTAGE =
  'project_default_bonus_percentage';

export const PROJECT_ITEM_SUBTYPE_IMAGE = 'image';
export const PROJECT_ITEM_SUBTYPE_VIDEO = 'video';
export const PROJECT_ITEM_SUBTYPE_OTHER = 'other';

export const PROPOSAL_NOTES = 'proposals_notes';

export const PROJECT_STAGES_ROLES = 'project_stages_teamMemberRoles';
export const PROJECT_STAGES_LEADING_ROLE = 'project_stages_leadingRole';
export const PROJECT_STAGES_DEFAULT_ROLES = 'project_stages_defaultRoles';
export const PROJECT_STAGES_BROAD_ROLES = 'project_stages_broadRoles';

export const OCCUPATION_RATE_OVERHEAD_ROLES = 'occupation-rate_overheadRoles';
export const OCCUPATION_COEFFICIENT = 'occupation-rate_coefficient';
export const OCCUPATION_CAPACITY_PER_PERSON_PER_MONTH =
  'occupation-rate_capacityPerPersonPerMonth';
export const OCCUPATION_RATE_ANIMATION_SECONDS =
  'occupation-rate_animation-seconds';

export const SALARIES_OVERTIME_RATE = 'salaries_overtimeRate';
export const TOP_DOGS_BONUS_PERCENTAGE = 'salaries_topDogsBonus';
export const BIG_CHIEFS_BONUS_PERCENTAGE = 'salaries_bigChiefsBonus';
export const TOP_DOGS_DEFAULT_ROLES_KEY = 'salaries_topDogsRolesDefault';
