import React, { useContext } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {} & React.ComponentProps<typeof Modal.Header>;

const StyledModalHeader = styled(Modal.Header)`
  display: flex !important;
  align-items: center;

  ${(props) =>
    props.inverted
      ? `
    background: #1b1c1d !important;
    color: #fff !important;
  `
      : ''}
`;

const ModalHeader = (props: Props) => {
  const { children } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <StyledModalHeader inverted={theme === themes.dark} className="handle">
      {children}
    </StyledModalHeader>
  );
};

export default ModalHeader;
