import NumberInputField from '../../../components/forms/fields/NumberInputField';
import { PROJECT_DEFAULT_BONUS_PERCENTAGE } from '../keys';
import React from 'react';
import { Form } from '../../../components/forms/Form';
import { ProjectSetting } from '../types';
import { useForm } from 'react-hook-form';

type Props = {
  settings: ProjectSetting[];
  loading: boolean;
  updateOrCreateSetting: (data: Omit<ProjectSetting, 'id'>) => Promise<void>;
  reloadSettings: () => Promise<void>;
};

const ProjectBonusSetting = (props: Props) => {
  const { updateOrCreateSetting, loading, settings } = props;

  const formMethods = useForm({
    defaultValues: {
      [`${PROJECT_DEFAULT_BONUS_PERCENTAGE}_value`]:
        settings.find(
          (setting) => setting.key === PROJECT_DEFAULT_BONUS_PERCENTAGE
        )?.value || 0,
    },
  });

  return (
    <Form>
      <NumberInputField
        inline
        label="Default Project Bonus"
        name={`${PROJECT_DEFAULT_BONUS_PERCENTAGE}_value`}
        submitHandler={({
          [`${PROJECT_DEFAULT_BONUS_PERCENTAGE}_value`]: value,
        }) => {
          updateOrCreateSetting({
            key: PROJECT_DEFAULT_BONUS_PERCENTAGE,
            value,
            module: 'projects',
          });
        }}
        rules={{
          min: { value: 0, message: 'Please specify a positive number' },
        }}
        fluid={false}
        activateInputOnClick
        loading={loading}
        {...formMethods}
      />
    </Form>
  );
};

export default ProjectBonusSetting;
