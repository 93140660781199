import React, { useCallback, useEffect } from 'react';
import useAPIRequest from '../../hooks/useAPIRequest';
import { rangeFilterQuery } from './utils';
import { Id } from '../../types/base';
import CalendarComponent from './CalendarComponent';
import { BaseEvent, BaseMilestone, CalendarDataContainerProps } from './types';
import { get } from 'lodash';

/**
 * Component which handles the data fetching for the calendars
 */
const CalendarContainer = <
  Resource extends { id: Id },
  Event extends BaseEvent,
  Milestone extends BaseMilestone
>(
  props: CalendarDataContainerProps<Resource, Event, Milestone>
) => {
  const {
    resourceAPIResource,
    milestoneAPIResource,
    eventAPIResource,

    startDate,
    endDate,

    eventIncludeRelations = null,
    eventAdditionalFilters,

    resourceStartAccessor,
    resourceEndAccessor,
    resourceAdditionalFilters = {},
    resourceOrderBy,
    resourceAPIMethod = 'list',
    ...rest
  } = props;

  const {
    loading: loadingResources,
    data: resourcesResponse,
    performRequest: getResources,
    error: resourceAPIError,
  } = useAPIRequest(get(resourceAPIResource, resourceAPIMethod));

  const {
    loading: loadingEvents,
    data: eventsResponse,
    error: eventAPIError,
    performRequest: fetchEvents,
  } = useAPIRequest(eventAPIResource.list);

  const {
    data: milestonesResponse,
    error: milestonesAPIError,
    performRequest: fetchMilestones,
  } = useAPIRequest(milestoneAPIResource?.list);

  // Callback for loading the events within the given range
  const loadEvents = useCallback(() => {
    if (startDate && endDate) {
      fetchEvents({
        filter: {
          ...(eventIncludeRelations && { include: eventIncludeRelations }),
          where: {
            and: [
              {
                ...rangeFilterQuery(startDate, endDate),
              },
              {
                ...eventAdditionalFilters,
              },
            ],
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [
    fetchEvents,
    eventIncludeRelations,
    startDate,
    endDate,
    eventAdditionalFilters,
  ]);
  const loadMilestones = useCallback(() => {
    if (startDate && endDate) {
      fetchMilestones({
        filter: {
          where: {
            datetime: {
              between: [startDate.toISOString(), endDate.toISOString()],
            },
          },
        },
      });
    }
  }, [fetchMilestones, startDate, endDate]);

  // The effect handles the loading of the resources in case they also need
  // to be filtered by the start and end date
  // It makes a query every time the start or end date changes
  useEffect(() => {
    if (resourceStartAccessor && resourceEndAccessor) {
      getResources({
        filter: {
          order: resourceOrderBy,
          where: {
            ...rangeFilterQuery(
              startDate,
              endDate,
              resourceStartAccessor,
              resourceEndAccessor
            ),
            ...resourceAdditionalFilters,
          },
        },
      });
    }
  }, [
    startDate,
    endDate,
    getResources,
    resourceAdditionalFilters,
    resourceStartAccessor,
    resourceEndAccessor,
    resourceOrderBy,
  ]);

  // Effect which handles the fetching of the resources in the simpler case in which
  // they don't have to be filtered by start or end date
  useEffect(() => {
    if (!resourceStartAccessor && !resourceEndAccessor) {
      getResources({
        filter: { where: resourceAdditionalFilters, order: resourceOrderBy },
      });
    }
  }, [
    getResources,
    resourceStartAccessor,
    resourceEndAccessor,
    resourceAdditionalFilters,
    resourceOrderBy,
  ]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  useEffect(() => {
    loadMilestones();
  }, [loadMilestones]);

  const handleCreateEvent = useCallback(
    async (data: any) => {
      await eventAPIResource.create(data);
      loadEvents();

      if (resourceStartAccessor && resourceEndAccessor) {
        getResources({
          filter: {
            order: resourceOrderBy,
            where: {
              ...rangeFilterQuery(
                startDate,
                endDate,
                resourceStartAccessor,
                resourceEndAccessor
              ),
              ...resourceAdditionalFilters,
            },
          },
        });
      }
    },
    [
      eventAPIResource,
      loadEvents,
      resourceStartAccessor,
      resourceEndAccessor,
      getResources,
      resourceOrderBy,
      startDate,
      endDate,
      resourceAdditionalFilters,
    ]
  );

  const handleCreateMilestone = useCallback(
    async (data: any) => {
      if (milestoneAPIResource) {
        await milestoneAPIResource.create(data);
        loadMilestones();
      }
    },
    [loadMilestones, milestoneAPIResource]
  );

  const handleEditEvent = useCallback(
    async (id: Id, data: any) => {
      await eventAPIResource.updateById(id, data);
      loadEvents();

      if (resourceStartAccessor && resourceEndAccessor) {
        getResources({
          filter: {
            order: resourceOrderBy,
            where: {
              ...rangeFilterQuery(
                startDate,
                endDate,
                resourceStartAccessor,
                resourceEndAccessor
              ),
              ...resourceAdditionalFilters,
            },
          },
        });
      }
    },
    [
      eventAPIResource,
      loadEvents,
      resourceStartAccessor,
      resourceEndAccessor,
      getResources,
      resourceOrderBy,
      startDate,
      endDate,
      resourceAdditionalFilters,
    ]
  );

  const handleEditMilestone = useCallback(
    async (id: Id, data: any) => {
      if (milestoneAPIResource) {
        await milestoneAPIResource.updateById(id, data);
        loadMilestones();
      }
    },
    [loadMilestones, milestoneAPIResource]
  );

  const handleEditResource = useCallback(
    async (id: Id, data: any) => {
      await resourceAPIResource.updateById(id, data);
      if (!resourceStartAccessor && !resourceEndAccessor) {
        getResources({
          filter: { order: resourceOrderBy, where: resourceAdditionalFilters },
        });
      } else {
        getResources({
          filter: {
            order: resourceOrderBy,
            where: {
              ...rangeFilterQuery(
                startDate,
                endDate,
                resourceStartAccessor,
                resourceEndAccessor
              ),
              ...resourceAdditionalFilters,
            },
          },
        });
      }
    },
    [
      resourceAPIResource,
      resourceStartAccessor,
      resourceEndAccessor,
      resourceAdditionalFilters,
      endDate,
      getResources,
      startDate,
      resourceOrderBy,
    ]
  );

  const handleDeleteEvent = useCallback(
    async (id: Id) => {
      await eventAPIResource.deleteById(id);
      loadEvents();
    },
    [loadEvents, eventAPIResource]
  );

  const handleDeleteMilestone = useCallback(
    async (id: Id) => {
      if (milestoneAPIResource) {
        await milestoneAPIResource.deleteById(id);
        loadMilestones();
      }
    },
    [loadMilestones, milestoneAPIResource]
  );

  return (
    <CalendarComponent
      loading={loadingResources || loadingEvents}
      error={resourceAPIError || eventAPIError || milestonesAPIError}
      resources={resourcesResponse?.data ?? []}
      events={eventsResponse?.data ?? []}
      milestones={milestonesResponse?.data ?? []}
      handleEditMilestone={handleEditMilestone}
      handleEditEvent={handleEditEvent}
      handleCreateEvent={handleCreateEvent}
      handleCreateMilestone={handleCreateMilestone}
      handleDeleteEvent={handleDeleteEvent}
      handleDeleteMilestone={handleDeleteMilestone}
      handleEditResource={handleEditResource}
      onEditResourceInstanceClose={() => {
        loadEvents();
      }}
      {...rest}
    />
  );
};

export default CalendarContainer;
