import React from 'react';
import {
  ColumnConfig,
  default as ResourceTable,
} from '../../containers/resourceTable/ResourceTable';
import { Project } from '../projects/types';
import { getProjectFullName } from '../projects/utils';
import { CLIENTS_PERMISSION } from '../team/permission';
import { createContainer } from 'unstated-next';
import useCRUDResource from '../../hooks/useCRUDResource';
import ProjectsToBeInvoicedFooter from './ProjectsToBeInvoicedFooter';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import { formatNumber } from '../../utils/numberUtils';
import Decimal from 'decimal.js-light';
import DateFormatter from '../../utils/dateUtils';

export const PotentialProjectsContainer = createContainer(useCRUDResource);

const PotentialProjectsTable = () => {
  const config: ColumnConfig<Project>[] = [
    {
      label: 'Name',
      accessor: (project: Project) => getProjectFullName(project),
      orderingField: 'ref',
      key: 'name',
    },
    {
      label: 'Client',
      accessor: (project: Project) => project.client.name,
      orderingField: 'clientId',
      key: 'client',
      stopPropagation: true,
      permission: CLIENTS_PERMISSION,
    },
    {
      label: 'Start Date',
      accessor: (project: Project) =>
        DateFormatter.format(new Date(project.startDate)),
      orderingField: 'startDate',
      key: 'startDate',
    },
    {
      label: 'Deadline',
      accessor: (project: Project) =>
        project.deadline
          ? DateFormatter.format(new Date(project.deadline))
          : '',
      orderingField: 'deadline',
      key: 'deadline',
    },
    {
      label: 'Currency',
      accessor: 'currency',
      key: 'currency',
      orderingField: 'currency',
      cellWidth: '1',
    },
    {
      label: 'Amount',
      accessor: (project: Project) =>
        formatNumber(new Decimal(project.leftAmount || 0).toNumber()),
      sortTransformValue: (project: Project) => Number(project.leftAmount),
      key: 'leftAmount',
      orderingField: 'leftAmount',
      cellWidth: '1',
    },
    {
      label: 'Likelihood',
      accessor: (project: Project) => `${project.likelihood}%`,
      key: 'likelihood',
      orderingField: 'likelihood',
      cellWidth: '1',
      editable: {
        component: {
          type: 'number' as const,
          props: {
            unit: '%',
            unitPosition: 'right',
            rules: {
              min: {
                value: 0,
                message: 'Negative numbers are not allowed',
              },
              max: {
                value: 100,
                message: 'Entering a number bigger than 100 is not allowed',
              },
            },
          },
        },
        valueAccessor: 'likelihood' as const,
      },
    },
  ];

  return (
    <PotentialProjectsContainer.Provider
      initialState={{
        resource: ProjectResource,
        defaultFilter: {
          where: {
            status: 4,
            showInInvoices: true,
          },
          include: [
            {
              relation: 'client',
              scope: {
                fields: {
                  id: true,
                  name: true,
                },
              },
            },
          ],
        },
        defaultOrder: 'ref ASC',
      }}
    >
      <ResourceTable
        getRowAdditionalProps={(instance: Project) => {
          if (Number(instance.likelihood) >= 80) {
            return { positive: true };
          } else if (Number(instance.likelihood) >= 50) {
            return { warning: true };
          }
          return { negative: true };
        }}
        columns={config}
        addSearch={false}
        stateContainer={PotentialProjectsContainer}
        footerComponent={ProjectsToBeInvoicedFooter}
      />
    </PotentialProjectsContainer.Provider>
  );
};

export default PotentialProjectsTable;
