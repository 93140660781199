import React, { createContext, useState } from 'react';
import InvoicesTable from './Table';
import ProjectsToBeInvoicedTable from './ProjectsToBeInvoicedTable';
import { createContainer } from 'unstated-next';
import useCRUDResource from '../../hooks/useCRUDResource';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import { Id } from '../../types/base';
import InvoiceResource from '../../api/resources/invoices/InvoiceResource';
import MonthlyInvoicedChart from './MonthlyInvoicedChart';
import PotentialProjectsTable from './PotentialProjectsTable';
import TabPane from '../../components/layout/TabPane';
import Heading from '../../components/layout/Heading';
import CombinedChart from './CombinedChart';

type Props = {
  filters?: { where: any };
};

export const OpenedInvoiceContext = createContext<{
  invoiceId: Id;
  setOpenedInvoiceId: (id: Id) => any;
}>({
  invoiceId: '',
  setOpenedInvoiceId: () => {},
});
export const ProjectsToBeInvoicedContainer = createContainer(useCRUDResource);

export const InvoiceContainer = createContainer(useCRUDResource);

const InvoicesTab = (props: Props) => {
  const [openedInvoiceId, setOpenedInvoiceId] = useState('');
  const { filters } = props;

  return (
    <TabPane>
      <OpenedInvoiceContext.Provider
        value={{ invoiceId: openedInvoiceId, setOpenedInvoiceId }}
      >
        <InvoiceContainer.Provider
          initialState={{
            resource: InvoiceResource,
            defaultFilter: {
              where: filters?.where ? { ...filters.where } : { paid: false },
              include: [{ relation: 'project' }, { relation: 'client' }],
            },
            defaultOrder: 'number ASC',
            searchFields: ['number'],
            useSearch: true,
          }}
        >
          <ProjectsToBeInvoicedContainer.Provider
            initialState={{
              resource: ProjectResource,
              defaultFilter: {
                where: {
                  and: [
                    {
                      leftAmount: {
                        gt: 0,
                      },
                      showInInvoices: true,
                    },
                    {
                      or: [{ status: 2 }, { status: 1 }, { status: 3 }],
                    },
                  ],
                },
                include: [
                  {
                    relation: 'client',
                    scope: {
                      fields: {
                        id: true,
                        name: true,
                      },
                    },
                  },
                ],
              },
              defaultOrder: 'ref ASC',
            }}
          >
            <InvoicesTable {...props} />
            <Heading as="h4">
              Projects to be Invoiced (All amounts are excluding VAT)
            </Heading>
            <ProjectsToBeInvoicedTable />
            <Heading as="h4">
              Potential Projects (All amounts are excluding VAT){' '}
            </Heading>
            <PotentialProjectsTable />
          </ProjectsToBeInvoicedContainer.Provider>
        </InvoiceContainer.Provider>
      </OpenedInvoiceContext.Provider>
      <div style={{ display: 'flex', height: '600px', marginTop: '10px' }}>
        <div style={{ width: '50%' }}>
          <MonthlyInvoicedChart />
        </div>
        <div style={{ width: '50%' }}>
          <CombinedChart />
        </div>
        {/*<ExpectedIncomeChart />*/}
      </div>
    </TabPane>
  );
};

export default InvoicesTab;
