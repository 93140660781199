import React from 'react';
import { Button } from 'semantic-ui-react';
import ProjectTimesheetWeekResource from '../../../api/resources/timesheets/ProjectTimesheetWeekResource';
import { dateToString } from '../../../utils/dateUtils';
import { Id } from '../../../types/base';

type Props = {
  teamMemberId: Id;
  weekFirstDay: Date;
  onSubmit: () => Promise<void> | void;
};

const CopyPreviousWeek = (props: Props) => {
  const { teamMemberId, weekFirstDay, onSubmit } = props;

  const handleClick = async () => {
    await ProjectTimesheetWeekResource.copyLastWeek(
      dateToString(weekFirstDay),
      teamMemberId
    );
    onSubmit();
  };

  return (
    <Button
      positive
      size="huge"
      content="Copy last week"
      onClick={handleClick}
    />
  );
};

export default CopyPreviousWeek;
