import React, { RefObject } from 'react';
import { ProposalEntry } from '../types';
import DeleteAction from '../../../components/actions/DeleteAction';
import ProposalEntryResource from '../../../api/resources/proposals/ProposalEntryResource';

type Props = {
  selected: ProposalEntry[];
  reload: () => Promise<void>;
  disabled?: boolean;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, disabled, mountNode } = props;

  const handleDelete = async () => {
    const filters = {
      where: { or: selected.map((entry) => ({ id: entry.id })) },
    };
    await ProposalEntryResource.deleteAll(filters);
    await reload();
  };

  return (
    <DeleteAction
      disabled={disabled}
      buttonSize="large"
      confirmText="Are you sure you want to delete the entries?"
      callback={handleDelete}
      mountNode={mountNode}
    />
  );
};

export default Delete;
