import React, { useContext } from 'react';
import { Menu } from 'semantic-ui-react';
import tinycolor from 'tinycolor2';
import styled from 'styled-components';
import { ThemeContext } from '../../contexts/theme/ThemeContext';
import Logo from '../LogoImage';
import UserProfile from '../../pages/userProfile/UserProfile';

type HeaderProps = {
  gradientMainColor: string;
  gradientEndColor: string;
};

const StyledHeader = styled.header<HeaderProps>`
  padding: 0 0 0 2em;
  display: flex;
  justify-content: space-between;
  background: #14432f;
  background: ${(props) =>
    `linear-gradient(45deg, ${props.gradientMainColor} 0%, ${tinycolor(
      props.gradientMainColor
    )
      .setAlpha(tinycolor(props.gradientMainColor).getAlpha() / 2)
      .toRgbString()} 50%, ${tinycolor(props.gradientMainColor)
      .setAlpha(0)
      .toRgbString()} 100%)`};

  & .ui.toggle.checkbox {
    margin-right: 20px;
    align-self: center;
  }
  height: 180px;

  @media screen and (max-width: 960px) {
    padding: 0 1em;
  }
`;

const LogoWrapper = styled.div`
  margin-top: 13px;
  margin-bottom: 20px;
  display: flex;
  align-self: flex-start;
  align-items: center;
`;

const Header = () => {
  const { gradientMainColor } = useContext(ThemeContext);

  return (
    <div style={{ height: '180px', backgroundColor: '#1e1e1e' }}>
      <StyledHeader
        gradientMainColor={gradientMainColor}
        gradientEndColor={'#1e1e1e'}
      >
        <LogoWrapper>
          <Logo height={'60px'} centered={false} defaultInverted={true} />
        </LogoWrapper>
        <div
          style={{
            display: 'flex',
            alignSelf: 'flex-end',
            marginBottom: '8px',
          }}
        >
          <Menu tabular inverted style={{ background: 'transparent' }}>
            <UserProfile />
          </Menu>
        </div>
      </StyledHeader>
    </div>
  );
};

export default Header;
