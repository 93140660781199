import React, { ReactNode, RefObject, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { dateToString } from '../../../utils/dateUtils';
import get from 'lodash/get';
import ModalForm from '../../forms/ModalForm';
import { Id } from '../../../types/base';
import { FormFieldsComponentType } from '../../forms/types';
import { BaseEvent, BaseMilestone } from '../types';

type Props<
  Resource extends { id: Id },
  Event extends BaseEvent,
  Milestone extends BaseMilestone
> = {
  createItemData: {
    start: Date;
    end: Date;
    resource: Resource;
    defaultProps?: any;
  } | null;
  formFields: FormFieldsComponentType;
  eventResourceIdAccessor: string;
  milestoneResourceIdAccessor: string;
  onClose: () => void;
  onOpen: () => void;
  handleCreateEvent: (data: Partial<Event>) => Promise<void>;
  handleCreateMilestone: (data: Partial<Milestone>) => Promise<void>;
  resourceColorAccessor?: string;
  resourceIdAccessor?: 'id' | keyof Resource;
  mountNode: RefObject<HTMLDivElement | null>;
  label?: ReactNode;
};

const CreateEventOrMilestoneForm = <
  Resource extends { id: Id },
  Event extends BaseEvent,
  Milestone extends BaseMilestone
>(
  props: Props<Resource, Event, Milestone>
) => {
  const {
    createItemData,
    formFields,
    eventResourceIdAccessor,
    milestoneResourceIdAccessor,
    onClose,
    handleCreateEvent,
    handleCreateMilestone,
    resourceIdAccessor = 'id',
    resourceColorAccessor,
    mountNode,
    onOpen,
    label,
  } = props;

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  if (!createItemData) {
    return null;
  }

  return (
    <ModalForm
      key={createItemData.start.toISOString()}
      header={
        <>
          <Icon name="plus" color="green" circular inverted size="small" />
          {label || 'Create Event'}
        </>
      }
      defaultOpen
      formId={`create-new-event-${dateToString(createItemData.start)}`}
      fields={formFields}
      submitData={(formData) => {
        if (formData?.start && formData?.end) {
          return handleCreateEvent({
            ...formData,
            [eventResourceIdAccessor]: get(
              createItemData.resource,
              resourceIdAccessor
            ),
          });
        } else {
          return handleCreateMilestone({
            ...formData,
            [milestoneResourceIdAccessor]: get(
              createItemData.resource,
              resourceIdAccessor
            ),
          });
        }
      }}
      instance={{
        milestone: {
          datetime: createItemData.start.toISOString(),
          endDatetime: createItemData.end.toISOString(),
          color: resourceColorAccessor
            ? get(createItemData.resource, resourceColorAccessor)
            : '#b3b3b3',
        },
        event: {
          start: dateToString(createItemData.start),
          end: dateToString(createItemData.end),
          color: resourceColorAccessor
            ? get(createItemData.resource, resourceColorAccessor)
            : '#b3b3b3',
          resource: createItemData.resource,
          ...createItemData.defaultProps,
        },
        resourceId: get(createItemData.resource, resourceIdAccessor),
        id: 'resource-project-item',
      }}
      onClose={onClose}
      modalProps={{
        mountNode,
      }}
    />
  );
};

export default CreateEventOrMilestoneForm;
