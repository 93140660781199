import styled from 'styled-components';

export const itemTypeColors = {
  1: 'inherit',
  2: '#db2828',
};

export const ItemType = styled.span`
  font-weight: bold;
  color: ${(props) => props.color};
`;
