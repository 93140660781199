import { Id } from '../../../types/base';
import Api from '../../Api';

class MonthlySalariesResource {
  objectIdentifier = 'monthly-salaries';

  getObjectPath = (id: Id) => {
    return this.objectIdentifier + `/${id}`;
  };

  findByDate = async (date: string, filters?: any) => {
    return await Api.client.get(this.objectIdentifier + `/findByDate/${date}`, {
      params: filters,
    });
  };

  findByDateForStats = async (date: string, filters?: any) => {
    return await Api.client.get(
      this.objectIdentifier + `/stats/findByDate/${date}`,
      {
        params: filters,
      }
    );
  };

  addProjectToPerformanceComponent = async (
    monthlySalariesId: string,
    requestBody: { projectId: string }
  ) => {
    return await Api.client.patch(
      this.objectIdentifier +
        `/${monthlySalariesId}/add-project-to-performance-component/`,
      requestBody
    );
  };

  removeProjectFromPerformanceComponent = async (
    monthlySalariesId: string,
    requestBody: { projectId: string }
  ) => {
    return await Api.client.patch(
      this.objectIdentifier +
        `/${monthlySalariesId}/remove-project-from-performance-component/`,
      requestBody
    );
  };

  lock = async (monthlySalariesId: string) => {
    return await Api.client.patch(
      this.objectIdentifier + `/${monthlySalariesId}/lock`
    );
  };

  unlock = async (monthlySalariesId: string) => {
    return await Api.client.patch(
      this.objectIdentifier + `/${monthlySalariesId}/unlock`
    );
  };

  print = async (id: Id) => {
    return Api.client.post(
      `${this.objectIdentifier}/${id}/print`,
      {},
      { responseType: 'blob' }
    );
  };

  updateBonusField = async (
    monthlySalariesId: Id,
    teamMemberId: Id,
    bonus: number
  ) => {
    return Api.client.patch(
      `${this.objectIdentifier}/${monthlySalariesId}/update-bonus`,
      {
        teamMemberId,
        bonus,
      }
    );
  };

  getAverageSalaryComponent = async (date: string) => {
    return await Api.client.get(
      this.objectIdentifier + `/stats/average-salary-components/${date}`
    );
  };

  getOwnSalaryStats = async (date: string) => {
    return await Api.client.get(
      this.objectIdentifier + `/stats/own-salary/${date}`
    );
  };
}

export default new MonthlySalariesResource();
