import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  CheckboxField,
  DateInputField,
  SelectInputField,
  TextInputField,
} from '../../../components/forms/fields';
import { SelectOptions } from '../../../types/forms';
import ClientResource from '../../../api/resources/clients/ClientResource';
import useAPIRequest from '../../../hooks/useAPIRequest';
import { Client } from '../../clients/types';
import { PROJECT_CURRENCY_OPTIONS, PROJECT_STATUS_OPTIONS } from '../constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { nameBGValidationpattern } from '../../../components/validationRules';
import BonusInputField from '../BonusInputField';

type Props = FormFieldsComponentTypeProps;

const EditFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;
  const { setValue } = formMethods;
  const [clientOptions, setClientOptions] = useState<SelectOptions>([]);
  const {
    loading,
    data: response,
    error,
    performRequest: getClients,
  } = useAPIRequest(ClientResource.list);

  useEffect(() => {
    setValue('bonus', instance.bonus);
  }, [instance.bonus, setValue]);

  useEffect(() => {
    getClients({ where: { archived: false } });
  }, [getClients]);

  useEffect(() => {
    if (response) {
      setClientOptions(
        response.data.map((instance: Client) => ({
          key: instance.id,
          value: instance.id,
          text: instance.name,
        }))
      );
    }
  }, [response]);
  return (
    <>
      <ErrorMessage error={error} />
      <Form.Group>
        <TextInputField
          rules={{ required: { value: true, message: 'Please enter a name.' } }}
          label="Project Name"
          name="name"
          placeholder="Project name"
          width="7"
          {...formMethods}
        />
        {!instance.isInHouse && (
          <SelectInputField
            options={PROJECT_STATUS_OPTIONS}
            rules={{
              required: { value: true, message: 'Please select a status.' },
            }}
            name="status"
            label="Status"
            placeholder="Status"
            width="7"
            {...formMethods}
          />
        )}
      </Form.Group>
      {!instance.isInHouse ? (
        <>
          <Form.Group>
            <TextInputField
              rules={{
                required: { value: true, message: 'Please enter a name.' },
                ...nameBGValidationpattern,
              }}
              label="Project Name (BG)"
              name="nameBG"
              width="7"
              placeholder="Name (BG)"
              {...formMethods}
            />
            <DateInputField
              label="Start / End Date"
              name="startDate"
              dateOnly
              rules={{
                required: { value: true, message: 'Please select a date.' },
              }}
              width="6"
              {...formMethods}
            />
            <DateInputField
              name="deadline"
              dateOnly
              width="3"
              rules={{
                required: { value: true, message: 'Please select a date.' },
              }}
              {...formMethods}
            />
          </Form.Group>
          <Form.Group>
            <SelectInputField
              width="7"
              options={clientOptions}
              rules={{
                required: { value: true, message: 'Please select a client.' },
              }}
              name="clientId"
              label="Client"
              search
              loading={loading}
              placeholder="Client"
              {...formMethods}
            />
            <SelectInputField
              width="7"
              options={PROJECT_CURRENCY_OPTIONS}
              rules={{
                required: { value: true, message: 'Please select currency.' },
              }}
              name="currency"
              label="Currency"
              placeholder="Currency"
              search
              {...formMethods}
            />
          </Form.Group>
          <Form.Group>
            <CheckboxField
              width="7"
              label="Show In Invoices"
              name="showInInvoices"
              {...formMethods}
            />
            <BonusInputField
              projectId={instance.id}
              rules={{
                required: { value: true, message: 'Please specify the bonus.' },
                min: {
                  value: 0,
                  message: 'The bonus percentage should be 0 or bigger',
                },
                max: {
                  value: 100,
                  message: 'The bonus percentage cannot be bigger than 100',
                },
              }}
              {...formMethods}
            />
          </Form.Group>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginBottom: '10px',
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default EditFormFields;
