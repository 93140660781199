import React, { ReactNode, useContext } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {
  mainActions: ReactNode;
  additionalActions?: ReactNode;
} & React.ComponentProps<typeof Modal.Actions>;

const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${(props) =>
    props.inverted
      ? `
    background: #1b1c1d !important;
    color: #fff !important;`
      : ''}
`;

const ModalActions = (props: Props) => {
  const { mainActions, additionalActions } = props;

  const { theme } = useContext(ThemeContext);

  return (
    <StyledModalActions inverted={theme === themes.dark}>
      <div>{additionalActions}</div>
      <div>{mainActions}</div>
    </StyledModalActions>
  );
};

export default ModalActions;
