import React, { useEffect, useState } from 'react';
import Decimal from 'decimal.js-light';
import Row from '../../components/tables/Row';
import Cell from '../../components/tables/Cell';
import { bgn, PROJECT_CURRENCY_OPTIONS } from '../projects/constants';
import { Project } from '../projects/types';
import useAPIRequest from '../../hooks/useAPIRequest';
import ProjectSettingResource from '../../api/resources/ProjectSettingResource';
import { PROJECT_CURRENCY_EXCHANGE_RATES } from '../settings/keys';
import get from 'lodash/get';
import Caret from '../../components/tables/Caret';
import { formatNumber } from '../../utils/numberUtils';

type Props = {
  items: Project[];
  subset: boolean;
};

const ProjectsToBeInvoicedFooter = (props: Props) => {
  const { items, subset } = props;
  const [expandedIndices, setExpandedIndices] = useState<Array<number>>([]);

  const {
    data: response,
    loading,
    performRequest: fetchSetting,
  } = useAPIRequest(ProjectSettingResource.findByKey);

  useEffect(() => {
    fetchSetting(PROJECT_CURRENCY_EXCHANGE_RATES);
  }, [fetchSetting]);

  const getLeftAmountForCurrency = (currency: string) =>
    items
      .filter((item) => item.currency === currency)
      .reduce((acc, curr) => {
        return acc.add(new Decimal(curr.leftAmount || 0));
      }, new Decimal(0));

  const getBGLeftAmount = () =>
    items.reduce((acc, curr) => {
      if (curr.currency === bgn) {
        return acc.add(new Decimal(curr.leftAmount || 0));
      }

      if (response?.data?.value && get(response.data.value, curr.currency)) {
        const exchangeRate = get(response.data.value, curr.currency);
        const toBGN = new Decimal(curr.leftAmount || 0).mul(
          new Decimal(exchangeRate)
        );
        return acc.add(toBGN);
      }
      return acc.add(new Decimal(curr.leftAmount || 0));
    }, new Decimal(0));

  const availableCurrencies = PROJECT_CURRENCY_OPTIONS.filter((currency) => {
    return items.find((item) => item.currency === currency.value);
  });

  return (
    <>
      {!loading && items.length ? (
        <>
          <Row>
            <Cell />
            <Cell />
            <Cell />
            <Cell>
              <Caret
                index={0}
                expandedIndices={expandedIndices}
                setExpandedIndices={setExpandedIndices}
              />
              <strong>{subset ? 'Selected Amount' : 'Total Amount'}</strong>{' '}
            </Cell>
            <Cell>
              <strong>BGN</strong>
            </Cell>
            <Cell>
              <strong>{formatNumber(getBGLeftAmount().toNumber())}</strong>
            </Cell>
            <Cell />
          </Row>
          {expandedIndices.includes(0) && (
            <Row>
              <Cell />
              <Cell />
              <Cell />
              <Cell />
              <Cell>
                <strong>
                  {availableCurrencies.map((currency) => {
                    return <p key={currency.value}>{currency.text}</p>;
                  })}{' '}
                </strong>{' '}
              </Cell>
              <Cell>
                <strong>
                  {availableCurrencies.map((currency) => {
                    return (
                      <p key={currency.value}>
                        {formatNumber(
                          getLeftAmountForCurrency(currency.value).toNumber()
                        )}
                      </p>
                    );
                  })}
                </strong>
              </Cell>
              <Cell />
            </Row>
          )}
        </>
      ) : null}
    </>
  );
};

export default ProjectsToBeInvoicedFooter;
