import React, { ReactText, useContext, useState } from 'react';
import HomePageContainer from './HomePageContainer';
import { Dimmer, List } from 'semantic-ui-react';
import { ProjectScheduleEvent } from '../projectSchedule/ProjectSchedule';
import { ThemeContext } from '../../contexts/theme/ThemeContext';
import { Modal } from '../../components/dialogs';
import EventInfoBox from '../projectSchedule/EventInfoBox';
import ProjectsPoolListItem from './projectsPool/ProjectsPoolListItem';
import styled from 'styled-components';
import { useDroppable } from '@dnd-kit/core';

const ProjectsPoolList = styled(List)`
  &.ui.list > .item .description,
  &.ui.list .stage-name {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
  }

  &.ui.inverted.list > .item .description,
  &.ui.inverted.list .stage-name {
    color: rgba(255, 255, 255, 0.4);
  }
`;

export default function ProjectsPool({
  stages,
  draggedItem,
}: {
  stages: ProjectScheduleEvent[];
  draggedItem?: {
    id: ReactText;
    data: { current: any };
  } | null;
}) {
  const { setNodeRef, isOver } = useDroppable({
    id: 'projects-pool-droppable',
  });

  const [
    selectedStage,
    setSelectedStage,
  ] = useState<ProjectScheduleEvent | null>(null);

  const { theme } = useContext(ThemeContext);

  return (
    <>
      {selectedStage && (
        <Modal
          closeIcon
          defaultOpen
          onClose={() => {
            setSelectedStage(null);
          }}
          content={<EventInfoBox event={selectedStage} />}
        />
      )}
      <HomePageContainer title="projects pool" size="small" ref={setNodeRef}>
        {isOver && draggedItem?.data?.current?.type === 'calendar-event' && (
          <Dimmer
            active={true}
            style={{ borderRadius: '10px', opacity: 0.6 }}
          />
        )}
        <ProjectsPoolList
          divided
          relaxed
          inverted={theme === 'dark'}
          style={{
            paddingLeft: '3px',
            marginTop: 0,
          }}
        >
          {stages
            .filter((stage: ProjectScheduleEvent) => !stage.project.onlyFlow)
            .map((stage: ProjectScheduleEvent) => (
              <ProjectsPoolListItem
                key={stage.id}
                stage={stage}
                onClick={setSelectedStage}
                style={{
                  cursor: draggedItem ? 'grabbing' : 'pointer',
                }}
              />
            ))}
        </ProjectsPoolList>
      </HomePageContainer>
    </>
  );
}
