import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { TimesheetsActions } from '../TimesheetsTable';
import Table from '../../../components/tables/Table';
import Row from '../../../components/tables/Row';
import HeaderCell from '../../../components/tables/HeaderCell';
import Header from '../../../components/tables/Header';
import {
  dateToString,
  getFirstDayOfWeekByDate,
  getWeek,
} from '../../../utils/dateUtils';
import { Modal, ModalContent, ModalHeader } from '../../../components/dialogs';
import styled from 'styled-components';
import { TeamMember } from '../../team/types';
import Body from '../../../components/tables/Body';
import Cell from '../../../components/tables/Cell';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectTimesheetWeekResource from '../../../api/resources/timesheets/ProjectTimesheetWeekResource';
import Loader from '../../../components/Loader';
import { ProjectTimesheetWeekWithRelations, TimesheetEntry } from '../types';
import DownloadPDFReport from './DownloadPDFReport';
import Segment from '../../../components/layout/Segment';
import TimesheetDatePicker from '../TimesheetDatePicker';

type Props = {
  defaultFirstDay: Date;
  teamMembers: TeamMember[];
};

const StyledModalContent = styled(ModalContent)``;

const ReportTable = (props: Props) => {
  const { defaultFirstDay, teamMembers } = props;

  const {
    loading: loadingTimesheets,
    data: projectTimesheetWeekResponse,
    performRequest: getTimesheetWeek,
  } = useAPIRequest(ProjectTimesheetWeekResource.list);

  const [dateValue, setDateValue] = useState(defaultFirstDay);

  useEffect(() => {
    getTimesheetWeek({
      filter: {
        where: { firstDay: dateToString(dateValue) },
        include: [{ relation: 'timesheetEntries' }, { relation: 'project' }],
      },
    });
  }, [dateValue, getTimesheetWeek]);

  const week = getWeek(dateValue);

  return (
    <>
      <Loader active={loadingTimesheets} />
      <TimesheetsActions>
        <div>
          {projectTimesheetWeekResponse ? (
            <DownloadPDFReport
              data={projectTimesheetWeekResponse.data}
              firstDay={dateValue}
              teamMembers={teamMembers}
            />
          ) : null}
        </div>
        <div>
          <TimesheetDatePicker
            onChange={(date) => {
              if (date) {
                const firstWeekDay = getFirstDayOfWeekByDate(new Date(date));
                setDateValue(firstWeekDay);
              }
            }}
            startDate={dateValue}
          />
        </div>
      </TimesheetsActions>
      <Table>
        <Header>
          <Row>
            <HeaderCell width="3">Name</HeaderCell>
            {week.map((day, idx) => {
              return (
                <HeaderCell width="1" key={idx}>
                  {new Intl.DateTimeFormat('en', {
                    weekday: 'short',
                    month: 'short',
                    day: '2-digit',
                  }).format(day)}
                </HeaderCell>
              );
            })}
          </Row>
        </Header>
        <Body>
          {projectTimesheetWeekResponse &&
            teamMembers
              .filter((teamMember) => !teamMember.archived)
              .map((teamMember) => {
                const teamMemberTimesheets = projectTimesheetWeekResponse.data
                  .filter(
                    (
                      weenEntry: Omit<
                        ProjectTimesheetWeekWithRelations,
                        'projectTask' | 'teamMember'
                      >
                    ) => weenEntry.teamMemberId === teamMember.id
                  )
                  .reduce(
                    (
                      acc: TimesheetEntry[],
                      val: Omit<
                        ProjectTimesheetWeekWithRelations,
                        'projectTask' | 'teamMember'
                      >
                    ) => {
                      if (val.timesheetEntries) {
                        return [...acc, ...val.timesheetEntries];
                      }
                      return [...acc];
                    },
                    []
                  );
                return (
                  <Row key={teamMember.id}>
                    <Cell>{teamMember.name}</Cell>
                    {week.map((day, idx) => {
                      const value = teamMemberTimesheets
                        .filter(
                          (dayEntry: TimesheetEntry) =>
                            dayEntry.date.split('T')[0] === dateToString(day)
                        )
                        .reduce(
                          (a: number, b: TimesheetEntry) => a + +b.hours,
                          0
                        );
                      return (
                        <Cell key={`${teamMember.id}-${idx}`}>{value}</Cell>
                      );
                    })}
                  </Row>
                );
              })}
        </Body>
      </Table>
    </>
  );
};

const TimesheetsReport = (props: Props) => {
  return (
    <Modal
      closeIcon
      header={
        <ModalHeader>
          <Icon name="table" size="small" />
          Timesheets Report
        </ModalHeader>
      }
      content={
        <StyledModalContent style={{ padding: 0 }}>
          <Segment style={{ borderRadius: 0 }}>
            <ReportTable {...props} />
          </Segment>
        </StyledModalContent>
      }
      trigger={
        <Button
          size="huge"
          content="Report"
          onClick={(e) => e.preventDefault()}
        />
      }
    />
  );
};

export default TimesheetsReport;
