import React, { SyntheticEvent, useContext, useMemo } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import cloneDeep from 'lodash/cloneDeep';
import { rangeFilterQuery } from '../../../components/calendar/utils';
import { toDateObject } from '../../../utils/dateUtils';
import clsx from 'clsx';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

type Props = {
  appliedFilter: any;
  onChange: (filters: any) => void;
};

const currentYear = new Date().getFullYear();
const YEAR_OPTIONS = [
  {
    key: (currentYear - 1).toString(),
    value: currentYear - 1,
    text: (currentYear - 1).toString(),
  },
  {
    key: currentYear.toString(),
    value: currentYear,
    text: currentYear.toString(),
  },
  {
    key: (currentYear + 1).toString(),
    value: currentYear + 1,
    text: (currentYear + 1).toString(),
  },
  {
    key: (currentYear + 2).toString(),
    value: currentYear + 2,
    text: (currentYear + 2).toString(),
  },
];

const Filters = (props: Props) => {
  const { theme } = useContext(ThemeContext);
  const { appliedFilter, onChange } = props;

  const handleChange = (e: SyntheticEvent, { value }: DropdownProps) => {
    const filter = cloneDeep(appliedFilter);
    filter.where.and[0] = {
      ...rangeFilterQuery(
        toDateObject(`${value}-01-01`),
        toDateObject(`${value}-12-31`)
      ),
    };
    onChange(filter);
  };

  const value = useMemo(() => {
    return appliedFilter.where.and[0].or[0].start.between[0].split('-')[0];
  }, [appliedFilter]);

  return (
    <Dropdown
      className={clsx('secondary', theme === themes.dark && 'inverted')}
      size="small"
      style={{ minWidth: '120px' }}
      fluid
      selection
      options={YEAR_OPTIONS}
      text={value}
      onChange={handleChange}
    />
  );
};

export default Filters;
