import React, {
  FocusEvent,
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import Cell from '../Cell';
import {
  Input,
  InputProps,
  Popup,
  Ref,
  SemanticWIDTHS,
} from 'semantic-ui-react';
import { ValidationRules } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

type Props = {
  initialValue: string | null;
  onSubmit: (value: string) => Promise<void> | void;
  rules?: ValidationRules;
  width?: SemanticWIDTHS;
  disabled?: boolean;
};

const TextInputCell = (props: Props) => {
  const { initialValue, onSubmit, rules = {}, width, disabled } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>(initialValue ? initialValue : '');
  const touched = useRef(false);
  const [valid, setValid] = useState<{ valid: boolean; message: string }>({
    valid: true,
    message: '',
  });

  const [inputWidth, setInputWidth] = useState(0);
  const span = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setInputWidth(span.current?.offsetWidth ?? 20);
  }, [value]);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (touched.current) {
      const validationResult = validateInput(value);
      setValid((prevState) => ({ ...prevState, ...validationResult }));
      if (valid) {
        onSubmit(value);
        touched.current = false;
      }
    }
  };

  const validateInput = (input: string) => {
    if (isEmpty(rules)) {
      return { valid: true };
    }

    if (rules.required && input === '') {
      return {
        valid: false,
        message: get(rules.required, 'message' || 'This field is required'),
      };
    }

    return { valid: true };
  };

  const handleChange = (
    e: SyntheticEvent<HTMLInputElement>,
    { value }: InputProps
  ) => {
    setValid({ valid: true, message: '' });
    touched.current = true;
    setValue(value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && touched) {
      const validationResult = validateInput(value);
      setValid((prevState) => ({ ...prevState, ...validationResult }));
      if (validationResult.valid) {
        onSubmit(value);
        touched.current = false;
        if (inputRef.current) {
          //@ts-ignore
          inputRef.current?.firstChild?.blur();
        }
      }
    }
  };

  const { valid: isValid, message } = valid;

  return (
    <Cell negative={!isValid} width={width}>
      <span
        style={{ position: 'absolute', fontSize: '100%', opacity: 0 }}
        ref={span}
      >
        {value}
      </span>
      <Popup
        content={message}
        disabled={isValid}
        trigger={
          <Ref innerRef={inputRef}>
            <Input
              disabled={disabled}
              style={{ display: 'inline-block', width: inputWidth + 10 }}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyPress={handleKeyPress}
              onClick={(e: SyntheticEvent) => e.stopPropagation()}
            />
          </Ref>
        }
      />
    </Cell>
  );
};

export default TextInputCell;
