import React, { useEffect } from 'react';
import { ProjectSetting } from '../types';
import { useForm } from 'react-hook-form';
import { PROPOSAL_NOTES } from '../keys';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import NoteForm from './NoteForm';
import Loader from '../../../components/Loader';

type Props = {
  settings: ProjectSetting[];
  loading: boolean;
  reloadSettings: () => Promise<void>;
};

const ProposalsSettings = (props: Props) => {
  const { settings, loading, reloadSettings } = props;

  const settingKey = PROPOSAL_NOTES;

  const formMethods = useForm({
    defaultValues: {
      [`${settingKey}_add_new`]: '',
      itemTypes: [],
    },
  });

  const {
    loading: updateLoading,
    performRequest: updateOrCreateSetting,
  } = useAPIRequest(ProjectSettingResource.updateOrCreate);

  const notes = settings.find(({ key }) => key === settingKey);

  const { setValue } = formMethods;

  useEffect(() => {
    setValue(
      'notes',
      settings.find(({ key }) => key === settingKey)?.value || []
    );
  }, [settings, setValue, settingKey]);

  return (
    <div>
      <Loader active={loading || updateLoading} />
      <NoteForm
        loading={loading}
        onSubmit={async (data) => {
          let updatedValue = [];
          if (notes) {
            updatedValue = notes.value.concat([data]);
          } else {
            updatedValue = [data];
          }

          await updateOrCreateSetting({
            key: settingKey,
            value: JSON.stringify(updatedValue),
            module: 'proposals',
          });
          reloadSettings();
        }}
      />
      {notes?.value.map(
        (
          note: {
            id: string;
            bg: string;
            en: string;
            isInUse: boolean;
            defaultAddToProposal: boolean;
          },
          idx: number
        ) => (
          <React.Fragment key={idx}>
            <NoteForm
              loading={updateLoading}
              note={note}
              onDelete={async () => {
                const updatedValue = notes.value
                  .slice(0, idx)
                  .concat(notes.value.slice(idx + 1));

                await updateOrCreateSetting({
                  key: settingKey,
                  value: JSON.stringify(updatedValue),
                  module: 'proposals',
                });
                reloadSettings();
              }}
              onSubmit={async (data) => {
                let updatedValue = notes.value.slice();
                updatedValue[idx] = { ...data };

                await updateOrCreateSetting({
                  key: settingKey,
                  value: JSON.stringify(updatedValue),
                  module: 'proposals',
                });
                reloadSettings();
              }}
            />
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default ProposalsSettings;
