import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  BlobProvider,
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { ProjectItem } from '../types';
import isEqual from 'lodash/isEqual';
import { Button } from 'semantic-ui-react';
import openSansBold from '../../../assets/open-sans/OpenSans-Bold.ttf';
import openSans from '../../../assets/open-sans/OpenSans-Regular.ttf';
import logo from '../../../assets/logoblack_notext.png';
import { ITEM_VALUE, NEGATIVE_VALUE } from '../constants';
import { getSum } from './TableFooter';

type Props = {
  selected: ProjectItem[];
  reload: () => Promise<void>;
};

Font.register({ family: 'Open Sans', src: openSans });
Font.register({ family: 'Open Sans Bold', src: openSansBold });

const formatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
});

export const PDFStyles = StyleSheet.create({
  container: {
    padding: '30px',
    fontFamily: 'Open Sans',
  },
  created: {
    marginTop: 10,
    marginBottom: 15,
  },
  table: {
    fontFamily: 'Open Sans',
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: '#d9d9db',
  },
  tableRow: { margin: 'auto', flexDirection: 'row' },
  headerRow: { backgroundColor: '#f9fafb', flexDirection: 'row' },
  warningRow: { backgroundColor: '#fffaf3' },
  negativeRow: { backgroundColor: '#fdf6f6' },
  positiveRow: { backgroundColor: '#fbfdf5' },
  tableCol: {
    width: '16%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#d9d9db',
  },
  wideTableCol: {
    width: '52%',
  },
  budgetTableCol: {
    width: '84%',
  },
  tableCell: { marginLeft: 7, marginTop: 8, marginBottom: 8, fontSize: 10 },
  boldTextCell: { fontFamily: 'Open Sans Bold' },
  positiveTextCell: { color: '#2c662d' },
  negativeTextCell: { color: '#9f3a38' },
  warningTextCell: { color: '#573a08' },
  textCreated: {
    fontSize: 10,
  },
  projectData: {
    marginTop: 5,
    marginBottom: 5,
  },
  projectDataText: {
    fontSize: 10,
    fontFamily: 'Open Sans Bold',
  },
  headerText: {
    fontSize: 12,
    fontFamily: 'Open Sans Bold',
    marginTop: 13,
    marginLeft: 5,
  },
  logo: {
    width: 40,
    marginLeft: 30,
  },
  header: {
    marginTop: 20,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
  },
});

const ProjectItemDocumentRow = ({
  item,
  negative = false,
  warning = false,
  positive = false,
  minus = false,
}: {
  item: ProjectItem;
  negative?: boolean;
  warning?: boolean;
  positive?: boolean;
  minus?: boolean;
}) => {
  const cellStyles = Object.assign(
    {},
    PDFStyles.tableCell,
    negative ? PDFStyles.negativeTextCell : {},
    positive ? PDFStyles.positiveTextCell : {},
    warning ? PDFStyles.warningTextCell : {}
  );

  const rowStyles = Object.assign(
    {},
    PDFStyles.tableRow,
    negative ? PDFStyles.negativeRow : {},
    positive ? PDFStyles.positiveRow : {},
    warning ? PDFStyles.warningRow : {}
  );

  return (
    <View style={rowStyles}>
      <View style={{ ...PDFStyles.tableCol, ...PDFStyles.wideTableCol }}>
        <Text style={cellStyles}>{item.name}</Text>
      </View>
      <View style={PDFStyles.tableCol}>
        <Text style={cellStyles}>{item.items}</Text>
      </View>
      <View style={PDFStyles.tableCol}>
        <Text style={cellStyles}>{item.costPerItem}</Text>
      </View>
      <View style={PDFStyles.tableCol}>
        <Text style={{ ...cellStyles, ...PDFStyles.boldTextCell }}>
          {minus && '-'}
          {+item.items * +item.costPerItem}
        </Text>
      </View>
    </View>
  );
};

const ProjectItemsDocument = ({
  items,
  name,
  projectRef,
}: {
  items: ProjectItem[];
  name: string;
  projectRef: string;
}) => {
  const projectItems = items.filter((item) => item.type === ITEM_VALUE);
  const negatives = items.filter((item) => item.type === NEGATIVE_VALUE);

  const budget = getSum(projectItems) - getSum(negatives);

  return (
    <Document>
      <Page>
        <View style={PDFStyles.header}>
          <Image src={logo} style={PDFStyles.logo} />
          <Text style={PDFStyles.headerText}>Project Items List</Text>
        </View>
        <View style={PDFStyles.container}>
          <View style={PDFStyles.projectData}>
            <Text style={PDFStyles.projectDataText}>
              Project Number: {projectRef}
            </Text>
          </View>
          <View style={PDFStyles.projectData}>
            <Text style={PDFStyles.projectDataText}>Project Name: {name}</Text>
          </View>
          <View style={PDFStyles.created}>
            <Text style={PDFStyles.textCreated}>
              Created: {formatter.format(new Date())}
            </Text>
          </View>
          <View style={PDFStyles.table}>
            <View style={{ ...PDFStyles.tableRow, ...PDFStyles.headerRow }}>
              <View
                style={{ ...PDFStyles.tableCol, ...PDFStyles.wideTableCol }}
              >
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  Name
                </Text>
              </View>
              <View style={PDFStyles.tableCol}>
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  Items
                </Text>
              </View>
              <View style={PDFStyles.tableCol}>
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  Cost Per Item
                </Text>
              </View>
              <View style={PDFStyles.tableCol}>
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  Subtotal
                </Text>
              </View>
            </View>
            {projectItems.map((item) => (
              <ProjectItemDocumentRow key={item.id} item={item} />
            ))}
            {negatives.map((item) => (
              <ProjectItemDocumentRow
                key={item.id}
                item={item}
                negative
                minus
              />
            ))}
            <View style={PDFStyles.tableRow}>
              <View
                style={{ ...PDFStyles.tableCol, ...PDFStyles.budgetTableCol }}
              >
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  Total
                </Text>
              </View>
              <View style={PDFStyles.tableCol}>
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  {budget}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  return isEqual(prevProps.selected, nextProps.selected);
}

const DownloadLink = (props: {
  blob: Blob | null;
  url: string | null;
  loading: boolean;
  filename: string;
  onClick: () => any;
}) => {
  const { loading, url, filename, onClick } = props;
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.click();
      onClick();
    }
  }, [url, onClick]);

  return loading ? (
    <span>Loading PDF...</span>
  ) : url ? (
    <a ref={ref} href={url} download={filename}>
      {' '}
      Download PDF{' '}
    </a>
  ) : null;
};

const DownloadPDFAction = React.memo((props: Props) => {
  const { selected } = props;

  const [pdfRequested, setPDFRequested] = useState(false);
  const setNoPDFRequested = useCallback(() => setPDFRequested(false), []);

  return selected.length ? (
    <div>
      {!pdfRequested && (
        <Button
          size="large"
          content="Download PDF"
          onClick={() => setPDFRequested(true)}
        />
      )}
      {pdfRequested && (
        <BlobProvider
          document={
            <ProjectItemsDocument
              items={selected}
              name={selected[0].projectName || ''}
              projectRef={selected[0].projectRef || ''}
            />
          }
        >
          {({ blob, url, loading, error }) => (
            <DownloadLink
              blob={blob}
              url={url}
              loading={loading}
              onClick={setNoPDFRequested}
              filename={`${selected[0].projectRef}_${selected[0].projectName}_PROJECT_ITEMS`}
            />
          )}
        </BlobProvider>
      )}
    </div>
  ) : null;
}, areEqual);

export default DownloadPDFAction;
