import React, { ReactNode } from 'react';
import ModalForm from '../../components/forms/ModalForm';
import { Button } from 'semantic-ui-react';
import { FormFieldsComponentType } from '../../components/forms/types';
import { ButtonSizeProps } from '../../types/forms';
import { Modal, ModalContent, ModalHeader } from '../../components/dialogs';

type Props = {
  title: ReactNode;
  formFields?: FormFieldsComponentType;
  formId: string;
  handleCreateNewInstance: (data: any) => Promise<void>;
  trigger?: ReactNode;
  buttonSize?: ButtonSizeProps;
  customForm?: ReactNode;
  modalProps?: React.ComponentProps<typeof Modal>;
};

const CreateForm = (props: Props) => {
  const {
    title,
    formFields,
    formId,
    handleCreateNewInstance,
    trigger = null,
    buttonSize = 'huge',
    customForm,
    modalProps,
  } = props;

  if (formFields) {
    return (
      <ModalForm
        formId={formId}
        fields={formFields}
        header={<>{title}</>}
        trigger={
          trigger || (
            <Button
              positive
              size={buttonSize}
              content="New"
              onClick={(e) => e.preventDefault()}
            />
          )
        }
        submitData={handleCreateNewInstance}
        modalProps={modalProps}
      />
    );
  } else if (customForm) {
    return (
      <Modal
        closeIcon
        trigger={
          trigger || (
            <Button
              positive
              size={buttonSize}
              content="New"
              onClick={(e) => e.preventDefault()}
            />
          )
        }
        className={`modal-${formId}`}
        {...modalProps}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalContent style={{ padding: 0 }}>{customForm}</ModalContent>
      </Modal>
    );
  }

  return null;
};

export default CreateForm;
