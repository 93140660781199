import React, { useState } from 'react';
import { InvoiceWithRelations } from './types';
import Decimal from 'decimal.js-light';
import Row from '../../components/tables/Row';
import Cell from '../../components/tables/Cell';
import { PROJECT_CURRENCY_OPTIONS } from '../projects/constants';
import Caret from '../../components/tables/Caret';
import { formatNumber } from '../../utils/numberUtils';

type Props = {
  items: InvoiceWithRelations[];
  subset: boolean;
};

const TableFooter = (props: Props) => {
  const { items, subset } = props;
  const [expandedIndices, setExpandedIndices] = useState<Array<number>>([]);

  const getAmountExcludingVATForCurrency = (currency: string) =>
    items
      .filter((item) => item.currency === currency)
      .reduce((acc, curr) => {
        if (curr.credit) {
          return acc.sub(new Decimal(curr.amountExcludingVAT || 0));
        }
        return acc.add(new Decimal(curr.amountExcludingVAT || 0));
      }, new Decimal(0));

  const getAmountWithVATForCurrency = (currency: string) =>
    items
      .filter((item) => item.currency === currency)
      .reduce((acc, curr) => {
        if (curr.credit) {
          return acc.sub(new Decimal(curr.amountWithVAT || 0));
        }
        return acc.add(new Decimal(curr.amountWithVAT || 0));
      }, new Decimal(0));

  const getVATAmountForCurrency = (currency: string) =>
    items
      .filter((item) => item.currency === currency)
      .reduce((acc, curr) => {
        if (curr.credit) {
          return acc.sub(new Decimal(curr.VATAmount || 0));
        }
        return acc.add(new Decimal(curr.VATAmount || 0));
      }, new Decimal(0));

  const getBGVATAmount = () =>
    items.reduce((acc, curr) => {
      if (curr.credit) {
        return acc.sub(new Decimal(curr.VATAmountBG || 0));
      }
      return acc.add(new Decimal(curr.VATAmountBG || 0));
    }, new Decimal(0));

  const getBGAmountWithVAT = () =>
    items.reduce((acc, curr) => {
      if (curr.credit) {
        return acc.sub(new Decimal(curr.amountWithVATBG || 0));
      }
      return acc.add(new Decimal(curr.amountWithVATBG || 0));
    }, new Decimal(0));

  const getBGAmountExcludingVAT = () =>
    items.reduce((acc, curr) => {
      if (curr.credit) {
        return acc.sub(new Decimal(curr.amountExcludingVATBG || 0));
      }
      return acc.add(new Decimal(curr.amountExcludingVATBG || 0));
    }, new Decimal(0));

  const availableCurrencies = PROJECT_CURRENCY_OPTIONS.filter((currency) => {
    return items.find((item) => item.currency === currency.value);
  });

  return (
    <>
      {items.length ? (
        <>
          <Row>
            <Cell />
            <Cell />
            <Cell>
              <Caret
                index={0}
                expandedIndices={expandedIndices}
                setExpandedIndices={setExpandedIndices}
              />
              <strong>{subset ? 'Selected Amount' : 'Total Amount'}</strong>{' '}
            </Cell>
            <Cell>
              <strong>{formatNumber(getBGVATAmount().toNumber())}</strong>
            </Cell>
            <Cell>
              <strong>BGN</strong>
            </Cell>
            <Cell>
              <strong>
                {formatNumber(getBGAmountExcludingVAT().toNumber())}
              </strong>
            </Cell>
            <Cell>
              <strong>{formatNumber(getBGAmountWithVAT().toNumber())}</strong>
            </Cell>
            <Cell />
            <Cell />
          </Row>
          {expandedIndices.includes(0) && (
            <Row>
              <Cell />
              <Cell />
              <Cell />
              <Cell>
                <strong>
                  {availableCurrencies.map((currency) => {
                    return (
                      <p key={currency.value}>
                        {formatNumber(
                          getVATAmountForCurrency(currency.value).toNumber()
                        )}
                      </p>
                    );
                  })}
                </strong>
              </Cell>
              <Cell>
                <strong>
                  {availableCurrencies.map((currency) => {
                    return <p key={currency.value}>{currency.text}</p>;
                  })}{' '}
                </strong>{' '}
              </Cell>
              <Cell>
                <strong>
                  {availableCurrencies.map((currency) => {
                    return (
                      <p key={currency.value}>
                        {formatNumber(
                          getAmountExcludingVATForCurrency(
                            currency.value
                          ).toNumber()
                        )}
                      </p>
                    );
                  })}
                </strong>
              </Cell>
              <Cell>
                <strong>
                  {availableCurrencies.map((currency) => {
                    return (
                      <p key={currency.value}>
                        {formatNumber(
                          getAmountWithVATForCurrency(currency.value).toNumber()
                        )}
                      </p>
                    );
                  })}
                </strong>
              </Cell>
              <Cell />
              <Cell />
            </Row>
          )}
        </>
      ) : null}
    </>
  );
};

export default TableFooter;
