import React, { useCallback, useEffect } from 'react';
import { Form, Icon, Popup } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { INVOICE_NOTES_OPTIONS, INVOICE_TYPE_OPTIONS } from '../constants';
import {
  CheckboxField,
  NumberInputField,
  DateInputField,
  TextInputField,
  SelectInputField,
} from '../../../components/forms/fields';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ClientResource from '../../../api/resources/clients/ClientResource';
import { ClientContact, CompanyInvoiceData } from '../../clients/types';
import { cloneDeep } from 'lodash';
import { useWatch } from 'react-hook-form';
import { dateToString } from '../../../utils/dateUtils';

type Props = FormFieldsComponentTypeProps;

const EditInvoiceFormFields = (props: Props) => {
  const { instance, getValues, ...formMethods } = props;
  const { clientId } = instance;

  const {
    loading: loadingClient,
    data: clientResponse,
    performRequest: getClient,
  } = useAPIRequest(ClientResource.findById);

  useEffect(() => {
    if (clientId) {
      getClient(clientId, {
        filter: {
          include: [
            { relation: 'companyInvoiceData' },
            { relation: 'contacts' },
          ],
        },
      });
    }
  }, [clientId, getClient]);

  const contactId = useWatch({
    name: 'clientContact.id',
    control: formMethods.control,
  });
  const addressId = useWatch({
    name: 'clientInvoiceData.id',
    control: formMethods.control,
  });

  const getValuesOverride = useCallback(() => {
    const values = cloneDeep({ ...getValues() });

    if (!instance.paid && values.paid) {
      values.paidDate = dateToString(new Date());
    }
    if (clientResponse?.data) {
      if (values.clientInvoiceData?.id) {
        values.clientInvoiceData = clientResponse?.data.companyInvoiceData?.find(
          (c: CompanyInvoiceData) => c.id === values.clientInvoiceData?.id
        );
      } else {
        values.clientInvoiceData = {};
      }

      if (values.clientContact?.id) {
        values.clientContact = clientResponse?.data.contacts?.find(
          (c: ClientContact) => c.id === values.clientContact?.id
        );
      } else {
        values.clientContact = {};
      }
    }
    return values;
  }, [clientResponse, getValues, instance.paid]);

  const fieldProps = { ...formMethods, getValues: getValuesOverride };

  return (
    <>
      <div id="invoice-edit-main-fields">
        <Form.Group widths="equal">
          <SelectInputField
            options={INVOICE_TYPE_OPTIONS}
            rules={{
              required: { value: true, message: 'Please select a type.' },
            }}
            name="type"
            label="Invoice"
            placeholder="Invoice"
            {...fieldProps}
          />
          <TextInputField
            rules={{
              required: { value: true, message: 'Please enter a number.' },
              pattern: {
                value: /[0-9]{10}/,
                message:
                  'Please enter a valid number which consists of 10 digits',
              },
            }}
            label="Invoice No"
            name="number"
            placeholder="Number"
            {...fieldProps}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <DateInputField
            width="8"
            name="issueDate"
            label="Issue Date"
            placeholder="Issue Date"
            dateOnly
            rules={{
              required: { value: true, message: 'Please select a date.' },
            }}
            {...fieldProps}
          />
          <NumberInputField
            width="8"
            rules={{
              required: {
                value: true,
                message: 'Please enter the exchange rate.',
              },
            }}
            label={`${instance.currency} to BGN`}
            name="exchangeRateToBGN"
            placeholder="Exchange Rate to BGN"
            {...fieldProps}
          />
        </Form.Group>
        <Form.Group>
          <SelectInputField
            loading={loadingClient}
            options={clientResponse?.data.companyInvoiceData?.map(
              (companyInvoiceData: CompanyInvoiceData) => ({
                value: companyInvoiceData.id,
                text: companyInvoiceData.label,
                key: companyInvoiceData.id,
              })
            )}
            label="Invoice Address"
            placeholder={'Please choose an address'}
            name="clientInvoiceData.id"
            rules={{
              required: {
                value: true,
                message: 'Please select one of the entries.',
              },
            }}
            className={!addressId ? 'warning' : ''}
            icon={
              !addressId ? (
                <Popup
                  trigger={
                    <Icon
                      className="warning-icon"
                      name="warning sign"
                      color="orange"
                      style={{ marginLeft: '.8em' }}
                    />
                  }
                  content="The client address is missing. Please keep in mind that the printed invoice will be incomplete."
                />
              ) : undefined
            }
            width="8"
            warning
            {...fieldProps}
          />
          <SelectInputField
            loading={loadingClient}
            options={clientResponse?.data.contacts?.map(
              (contact: ClientContact) => ({
                value: contact.id,
                text: `${contact.name}`,
                key: contact.id,
              })
            )}
            label="Client Contact"
            name="clientContact.id"
            placeholder={'Please choose a contact'}
            search
            rules={{
              required: {
                value: true,
                message: 'Please select a contact.',
              },
            }}
            width="8"
            className={!contactId ? 'warning' : ''}
            icon={
              !contactId ? (
                <Popup
                  trigger={
                    <Icon
                      className="warning-icon"
                      name="warning sign"
                      color="orange"
                      style={{ marginLeft: '.8em' }}
                    />
                  }
                  content="The client name is missing. Please keep in mind that the printed invoice will be incomplete."
                />
              ) : undefined
            }
            {...fieldProps}
          />
        </Form.Group>
        <Form.Group>
          <NumberInputField
            width="8"
            name="VAT"
            label="VAT"
            placeholder="VAT"
            {...fieldProps}
          />
          <NumberInputField
            width="8"
            rules={{
              required: { value: true, message: 'Please enter a number.' },
            }}
            label="Payable Within"
            name="payableWithin"
            placeholder="Payable Within"
            {...fieldProps}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <TextInputField
            label="Text Row (BG)"
            name="textRowBG"
            placeholder="Text Row (BG)"
            {...fieldProps}
          />
          <TextInputField
            label="Text Row (EN)"
            name="textRowEN"
            placeholder="Text Row (EN)"
            {...fieldProps}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <CheckboxField label="Paid" name="paid" {...fieldProps} />
          <CheckboxField label="Credit" name="credit" {...fieldProps} />
        </Form.Group>
      </div>
      <Form.Group>
        <SelectInputField
          width="16"
          labelStyle={{ alignSelf: 'flex-start', minWidth: '45px' }}
          options={INVOICE_NOTES_OPTIONS}
          name="notes"
          label={<span style={{ fontWeight: 700 }}>Notes</span>}
          placeholder="Notes"
          multiple
          selectStyle={{
            backgroundColor: 'inherit',
          }}
          {...fieldProps}
        />
      </Form.Group>
    </>
  );
};

export default EditInvoiceFormFields;
