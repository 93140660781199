import BaseCRUDResource from '../../BaseCRUDResource';
import Api from '../../Api';

class InvoiceEntryResource extends BaseCRUDResource {
  objectIdentifier = 'invoice-entries';

  createFromProjectItems = async (data: {
    percent?: number;
    invoiceId: string;
    projectItemIds: string[];
  }) => {
    return Api.client.post(
      `${this.objectIdentifier}/create-from-project-item/`,
      data
    );
  };
}

export default new InvoiceEntryResource();
