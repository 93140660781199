import React, { ReactNode, useContext } from 'react';
import { Feed } from 'semantic-ui-react';
import styled from 'styled-components';
import { ThemeContext, themes } from '../contexts/theme/ThemeContext';

type AttributesContainerProps = {
  inverted: boolean;
};

const AttributesContainer = styled.div<AttributesContainerProps>`
  display: flex;

  & div.ui.feed:not(:first-child) {
    margin-left: 3rem;
    margin-top: 0;
  }

  .ui.feed > .event > .content .summary {
    margin-top: 0.1em;
  }

  ${(props) =>
    props.inverted &&
    `
    .ui.feed > .event > .label .icon {
      color: #fff;
    }
    
    .ui.feed > .event > .content .date {
      color: #fff;
    }
    
    .ui.feed > .event > .content .summary {
      color: #fff;
    }
  `}
`;

type Props = {
  attributes: {
    value: ReactNode;
    title: ReactNode;
    key: string;
    icon: string;
  }[][];
};

const Attributes = ({ attributes }: Props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <AttributesContainer inverted={theme === themes.dark}>
      {attributes.map((col, idx) => (
        <Feed
          key={idx}
          style={{ width: attributes.length === 1 ? '100%' : 'auto' }}
        >
          {col.map(({ value, key, title, icon }) =>
            value ? (
              <Feed.Event key={key}>
                <Feed.Label icon={icon} />
                <Feed.Content
                  date={<span style={{ opacity: '0.7' }}>{title}</span>}
                  summary={value}
                />
              </Feed.Event>
            ) : null
          )}
        </Feed>
      ))}
    </AttributesContainer>
  );
};

export default Attributes;
