import axios, { AxiosInstance } from 'axios';
import { LocalStorage } from './BrowserStorage';

class Api {
  client: AxiosInstance;
  config: { authToken: string | null };

  constructor() {
    const defaultUrl = `${window.location.protocol}//${window.location.hostname}:3000`;

    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_BASEURL || defaultUrl,
    });

    this.config = {
      authToken: LocalStorage.loadState('authToken') || null,
    };

    this.client.interceptors.request.use((request) => {
      if (this.config.authToken) {
        request.headers = {
          Authorization: `Bearer ${this.config.authToken}`,
          ...request.headers,
        };
      }

      return request;
    });

    // Interceptor which handles the error response for blob type
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error.request.responseType === 'blob' &&
          error.response?.data instanceof Blob &&
          error.response?.data.type &&
          error.response?.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
              if (reader.result && typeof reader.result === 'string') {
                error.response.data = JSON.parse(reader.result);
                resolve(Promise.reject(error));
              }
            };

            reader.onerror = () => {
              reject(error);
            };

            reader.readAsText(error.response.data);
          });
        }

        return Promise.reject(error);
      }
    );
  }

  setAuthToken(authToken: string) {
    LocalStorage.saveState('authToken', authToken);
    this.config.authToken = authToken;
  }

  deleteAuthToken() {
    LocalStorage.removeState('authToken');
    this.config.authToken = null;
  }
}

export default new Api();
