import React, { ReactText, useEffect, useMemo, useState } from 'react';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import { Project } from '../projects/types';
import Calendar from '../../components/calendar/Calendar';
import { Id } from '../../types/base';
import { getProjectFullName } from '../projects/utils';
import { projectDefaultFilters } from '../projects/Filters';
import useAPIRequest from '../../hooks/useAPIRequest';
import ResourceScheduleProjectEventResource from '../../api/resources/resourceSchedule/ResourceScheduleProjectEventResource';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import { ResourceScheduleEvent } from '../resourceSchedule/ResourceSchedule';
import { LocalStorage } from '../../api/BrowserStorage';
import { UserContext } from '../auth/UserContext';
import get from 'lodash/get';
import OwnHolidayFormFields from './forms/OwnHolidayFormFields';
import HomePageContainer from './HomePageContainer';
import { useDroppable } from '@dnd-kit/core';
import { Dimmer } from 'semantic-ui-react';
import MyPlanEventInfoBox from './myPlan/MyPlanEventInfoBox';
import MyPlanProjectScheduleEventResource from '../../api/resources/projectSchedule/MyPlanProjectScheduleEventResource';
import ProjectScheduleMilestoneEventResource from '../../api/resources/projectSchedule/ProjectScheduleMilestoneEventResource';
import { TimeFormatter } from '../../utils/dateUtils';
import { Milestone } from '../projectSchedule/ProjectSchedule';
import PersonalNoteFormFields from './personalNotes/PersonalNoteFormFields';
import PersonalNotesResource from '../../api/resources/personalNotes/PersonalNotesResource';

type ProjectScheduleEvent = {
  id: Id;
  start: string;
  end: string;
  order: number;
  project: Project;
  color: string;
  name: string;
  nameTemplate: string;
  roles: string[];
  teamMemberRoles: { [x: string]: string[] };
};

const eventIncludeRelations = [{ relation: 'project' }];
const flowResourceOrderBy = ['onlyFlow DESC', 'ref ASC'];

const MyPlan = ({
  addedStage,
  onDatesChange,
  draggedItem,
}: {
  addedStage?: string;
  onDatesChange: (start: Date, end: Date) => void;
  draggedItem?: {
    id: ReactText;
    data: { current: any };
  } | null;
}) => {
  const {
    data: leaveResponse,
    performRequest: getLeave,
    error,
  } = useAPIRequest(ProjectResource.list);

  const { isOver, setNodeRef } = useDroppable({
    id: 'droppable-my-plan',
  });

  const { user } = UserContext.useContainer();

  const [leaveIds, setLeaveIds] = useState(
    LocalStorage.loadState('holidayProjectIds')
  );

  useEffect(() => {
    const performRequest = async () => {
      if (!leaveIds && !leaveResponse && !error) {
        const res = await getLeave({
          filter: { where: { isHoliday: true }, fields: { id: true } },
        });
        LocalStorage.saveState('holidayProjectIds', res.data);
        setLeaveIds(res.data);
      }
    };
    performRequest();
  }, [getLeave, leaveIds, error, leaveResponse]);

  const eventAdditionalFilters = useMemo(() => {
    return {
      or: leaveIds?.map((p: Pick<Project, 'id'>) => ({
        projectId: p.id,
      })),
    };
  }, [leaveIds]);

  const resourceAdditionalFilters = useMemo(() => {
    return {
      id: get(user, 'teamMemberId'),
    };
  }, [user]);

  const flowEventAdditionalFilters = useMemo(() => {
    return {
      teamMemberId: get(user, 'teamMemberId'),
    };
    //eslint-disable-next-line
  }, [user, addedStage]);

  const flowProjectFilters = useMemo(() => {
    return {
      and: [
        {
          or: [
            {
              ...projectDefaultFilters,
            },
            {
              onlyFlow: false,
            },
          ],
        },
      ],
    };
    //eslint-disable-next-line
  }, [addedStage]);

  return (
    <div style={{ marginTop: '10px' }}>
      <HomePageContainer title="my plan" size="large" ref={setNodeRef}>
        {isOver &&
          draggedItem?.data?.current?.type === 'projects-pool-item' && (
            <Dimmer
              active={true}
              style={{ borderRadius: '10px', opacity: 0.6 }}
            />
          )}
        {leaveIds && (
          <Calendar
            onDatesChange={onDatesChange}
            topCalendar={{
              calendarId: 'my-plan-holidays',
              resourceAllowedEditsIds: [
                (get(user, 'teamMemberId') as unknown) as string,
              ],
              editEventFormFields: OwnHolidayFormFields,
              createEventFormFields: OwnHolidayFormFields,
              eventIncludeRelations,
              eventAPIResource: ResourceScheduleProjectEventResource,
              eventAdditionalFilters,
              resourceAPIResource: TeamMemberResource,
              resourceAPIMethod: 'listForSchedules',
              resourceAdditionalFilters,
              resourceNameAccessor: () => {
                return 'my holidays';
              },
              eventResourceIdAccessor: 'teamMemberId',
              eventColorAccessor: ['project', 'eventColor'],
              eventNameAccessor: (event: ResourceScheduleEvent) =>
                getProjectFullName(event.project),
              copyEvent: (event: ResourceScheduleEvent) => ({
                start: event.start,
                end: event.end,
                color: event.project.eventColor,
                teamMemberId: event.teamMemberId,
                projectId: event.project.id,
              }),
            }}
            middleCalendar={{
              calendarId: 'my-plan-notes',
              resourceAllowedEditsIds: [
                (get(user, 'teamMemberId') as unknown) as string,
              ],
              editEventFormFields: PersonalNoteFormFields,
              createEventFormFields: PersonalNoteFormFields,
              eventAPIResource: PersonalNotesResource,
              resourceAPIResource: TeamMemberResource,
              resourceAPIMethod: 'listForSchedules',
              resourceAdditionalFilters,
              resourceNameAccessor: () => {
                return 'my notes';
              },
              eventResourceIdAccessor: 'teamMemberId',
              eventColorAccessor: 'color',
              eventNameAccessor: 'name',
              copyEvent: (event: ResourceScheduleEvent) => ({
                start: event.start,
                end: event.end,
                color: event.project.eventColor,
                teamMemberId: event.teamMemberId,
              }),
              editEventLabel: 'Edit note',
              createEventLabel: 'Create note',
            }}
            bottomCalendar={{
              // draggableEvent: true,
              calendarId: 'my-plan-projects',
              resourceNameAccessor: (project: Project) =>
                getProjectFullName(project),
              resourceStartAccessor: 'startDate',
              resourceEndAccessor: 'deadline',
              resourceColorAccessor: 'eventColor',
              resourceAdditionalFilters: flowProjectFilters,
              eventIncludeRelations,
              filterEmptyRows: true,
              readOnly: true,
              editPermission: 'non-editable',
              eventAPIResource: MyPlanProjectScheduleEventResource,
              eventAdditionalFilters: flowEventAdditionalFilters,
              resourceAPIResource: ProjectResource,
              resourceOrderBy: flowResourceOrderBy,
              milestoneAPIResource: ProjectScheduleMilestoneEventResource,
              milestoneNameAccessor: (milestone: Milestone) => {
                return `${milestone.name}${
                  milestone.datetime &&
                  `- ${TimeFormatter.format(new Date(milestone.datetime))}`
                }`;
              },
              milestoneResourceIdAccessor: 'projectId',
              eventResourceIdAccessor: 'projectId',
              eventColorAccessor: 'color',
              eventNameAccessor: 'name',
              copyEvent: (event: ProjectScheduleEvent) => ({
                start: event.start,
                end: event.end,
                color: event.color,
                name: event.name,
                projectId: event.project.id,
                nameTemplate: event.nameTemplate,
                roles: event.roles,
                teamMemberRoles: event.teamMemberRoles,
              }),
              eventInfoComponent: MyPlanEventInfoBox,
            }}
          />
        )}
      </HomePageContainer>
    </div>
  );
};

export default MyPlan;
