import React, { RefObject, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import ModalForm from '../../../components/forms/ModalForm';
import { ProjectItem } from '../../projects/types';
import { ProjectItemsInvoiceContext } from './ProjectItemsInvoiceTable';
import InvoiceEntryResource from '../../../api/resources/invoices/InvoiceEntryResource';
import AddItemToInvoiceFormFields from './AddItemToInvoiceFormFields';
import { useContainer } from 'unstated-next';
import { InvoiceEntriesContainer } from '../Table';

type Props = {
  selected: ProjectItem[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const AddItemToInvoice = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const invoice = useContext(ProjectItemsInvoiceContext);
  const invoiceEntriesContainer = useContainer(InvoiceEntriesContainer);

  const handleCreateNewInstance = async (data: { percent: number }) => {
    if (invoice?.id) {
      const newEntryData = {
        ...data,
        invoiceId: invoice?.id,
        projectItemIds: selected.map((item) => item.id),
      };
      await InvoiceEntryResource.createFromProjectItems(newEntryData);
      await reload();
      await invoiceEntriesContainer.list();
    }
  };

  return (
    <ModalForm
      formId="add-project-item-to-project"
      fields={AddItemToInvoiceFormFields}
      header={<>Part of Subtotal to Use</>}
      trigger={
        <Button
          positive
          size="large"
          content="Add to invoice"
          onClick={(e) => e.preventDefault()}
          disabled={selected.length < 1}
        />
      }
      submitData={handleCreateNewInstance}
      modalProps={{
        mountNode,
      }}
    />
  );
};

export default AddItemToInvoice;
