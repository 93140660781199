import React, { useContext } from 'react';
import logo from '../assets/CACTUS_LOGO_black.png';
import logo_inverted from '../assets/CACTUS_LOGO_white.png';
import { ThemeContext, themes } from '../contexts/theme/ThemeContext';
import styled from 'styled-components';

type LogoTitleProps = {
  centered: boolean;
  inverted: boolean;
};

const LogoTitle = styled.h1<LogoTitleProps>`
  text-transform: lowercase;
  ${(props) => props.centered && 'margin-top: -1vh;'}
  font-family: MarkOT-Medium, Lato, sans-serif;
  display: inline;
  font-size: 3.5rem;
  font-weight: normal;
  letter-spacing: -2px;
  color: ${(props) => (props.inverted ? '#fff' : '#000')};

  @media screen and (max-width: 520px) {
    font-size: 2rem;
    margin-top: 0;
  }

  @media screen and (max-width: 820px) {
    font-size: 2.5rem;
    margin-top: 0;
  }
`;

type LogoImageProps = {
  height: string;
};

const LogoImage = styled.img<LogoImageProps>`
  min-height: 50px;
  max-height: 90px;
  height: ${(props) => props.height};
`;

const Logo = ({
  height = '7vh',
  centered = true,
  defaultInverted = false,
}: {
  height?: string;
  centered?: boolean;
  defaultInverted?: boolean;
}) => {
  const { theme } = useContext(ThemeContext);
  const inverted = theme === themes.dark || defaultInverted;
  const host = window.location.hostname;
  return (
    <>
      <LogoImage
        src={inverted ? logo_inverted : logo}
        alt="logo"
        height={height}
      />
      <LogoTitle centered={centered} inverted={inverted}>
        {host?.startsWith('cactus-dev') ? 'Cactus DEV' : 'Cactus'}
      </LogoTitle>
    </>
  );
};

export default Logo;
