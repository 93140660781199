import React, { useContext } from 'react';
import { CheckboxField, NumberInputField } from '../../components/forms/fields';
import { Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import useAPIRequest from '../../hooks/useAPIRequest';
import ProjectSettingResource from '../../api/resources/ProjectSettingResource';
import { ProjectSetting } from './types';
import {
  DEFAULT_HOLIDAY_DAYS_KEY,
  TIMESHEET_REMINDER_PROJECT_MANAGER_KEY,
} from './keys';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {
  settings: ProjectSetting[];
  loading: boolean;
};

const TeamMembersSettings = (props: Props) => {
  const { settings, loading } = props;

  const {
    loading: updateLoading,
    performRequest: updateOrCreateSetting,
  } = useAPIRequest(ProjectSettingResource.updateOrCreate);

  const formMethods = useForm({
    defaultValues: {
      [`${TIMESHEET_REMINDER_PROJECT_MANAGER_KEY}_value`]:
        settings.find((setting) => setting.key === DEFAULT_HOLIDAY_DAYS_KEY)
          ?.value || false,
      [`${DEFAULT_HOLIDAY_DAYS_KEY}_value`]:
        settings.find((setting) => setting.key === DEFAULT_HOLIDAY_DAYS_KEY)
          ?.value || 22,
    },
  });

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Form inverted={theme === themes.dark}>
        <CheckboxField
          label="Send timesheet reminder emails to project managers"
          name={`${TIMESHEET_REMINDER_PROJECT_MANAGER_KEY}_value`}
          submitHandler={({
            [`${TIMESHEET_REMINDER_PROJECT_MANAGER_KEY}_value`]: value,
          }) => {
            updateOrCreateSetting({
              key: TIMESHEET_REMINDER_PROJECT_MANAGER_KEY,
              value,
              module: 'team-members',
            });
          }}
          activateInputOnClick
          {...formMethods}
        />
        <NumberInputField
          inline
          label="Annual Holiday Days (default)"
          name={`${DEFAULT_HOLIDAY_DAYS_KEY}_value`}
          submitHandler={({ [`${DEFAULT_HOLIDAY_DAYS_KEY}_value`]: value }) => {
            updateOrCreateSetting({
              key: DEFAULT_HOLIDAY_DAYS_KEY,
              value,
              module: 'team-members',
            });
          }}
          fluid={false}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />
      </Form>
    </>
  );
};

export default TeamMembersSettings;
