import { dateToString } from '../../utils/dateUtils';

/**
 * The function generates a filter query for events between two dates
 *
 * @param {Date} startDate: Start date
 * @param {Date} endDate: End date
 * @param {string} startAccessor: Accessor for the "start date" property of the model (defaults to "start")
 * @param {string} endAccessor: Accessor for the "end date" property of the model (defaults to "end")
 * @returns {Object} The generated LB filter query
 */
export const rangeFilterQuery = (
  startDate: Date,
  endDate: Date,
  startAccessor = 'start',
  endAccessor = 'end'
) => {
  return {
    or: [
      {
        [startAccessor]: {
          between: [dateToString(startDate), dateToString(endDate)],
        },
      },
      {
        [endAccessor]: {
          between: [dateToString(startDate), dateToString(endDate)],
        },
      },
      {
        [startAccessor]: {
          lt: dateToString(startDate),
        },
        [endAccessor]: {
          gt: dateToString(endDate),
        },
      },
    ],
  };
};
