import TeamMemberAvatar from '../resourceSchedule/TeamMemberAvatar';
import React from 'react';
import styled from 'styled-components';
import { Menu, Ref, Image } from 'semantic-ui-react';
import { TeamMember } from '../team/types';

const TeamMemberName = styled.div`
  color: #fff !important;
  width: min-content;
  text-transform: capitalize;
  font-size: 0.9em;
  &:hover {
    color: #fff !important;
  }
`;

const MenuItem = styled(Menu.Item)`
  background: transparent;
  padding: 2px 12px 4px 4px !important;
  border-radius: 10px !important;
  margin-right: 20px;
  cursor: pointer;

  &.item > .label {
    margin-left: 0 !important;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.04) !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.89);
    color: #fff !important;
  }
`;

const UserProfileButton = React.forwardRef<
  HTMLDivElement,
  {
    teamMember: TeamMember;
    onClick: () => void;
    userProfilePictureUrl: string | null;
  }
>(({ teamMember, onClick, userProfilePictureUrl }, ref) => {
  return (
    <Ref innerRef={ref}>
      <MenuItem onClick={onClick}>
        {userProfilePictureUrl ? (
          <Image
            src={userProfilePictureUrl}
            circular
            style={{ height: '3em', marginRight: '9px' }}
          />
        ) : (
          <TeamMemberAvatar entity={teamMember} size="big" initialsOnly />
        )}
        <TeamMemberName>{teamMember.name}</TeamMemberName>
      </MenuItem>
    </Ref>
  );
});

export default UserProfileButton;
