import { RefObject, useCallback, useEffect } from 'react';

const useClickOutside = (
  nodeRef: RefObject<HTMLElement>,
  onClickOutside: (event: MouseEvent) => any
) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (
        e.target instanceof Element &&
        !(e.target instanceof HTMLButtonElement) &&
        !(e.target instanceof HTMLInputElement) &&
        !e.target.classList.contains('delete') &&
        !e.target.parentElement?.classList.contains('checkbox')
      ) {
        if (nodeRef.current && nodeRef.current.contains(e.target)) {
          return;
        }
        onClickOutside(e);
      }
    },
    [onClickOutside, nodeRef]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};

export default useClickOutside;
