export const CLIENT_BUSINESSTYPE_OPTIONS = [
  { key: 'architects', text: 'Architects', value: 1 },
  { key: 'creative-agency', text: 'Creative Agency', value: 2 },
  { key: 'agency', text: 'Agency', value: 3 },
  { key: 'developer', text: 'Developer', value: 4 },
  { key: 'company', text: 'Company', value: 5 },
];

export const CONTACT_TITLE_OPTIONS = [
  { key: 'mr', text: 'Mr.', value: 'Mr.' },
  { key: 'mrs', text: 'Mrs.', value: 'Mrs.' },
  { key: 'ms', text: 'Ms.', value: 'Ms.' },
];
