import { useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { Id } from '../types/base';
import useIsMounted from './useIsMounted';

const useCRUDResource = (initialState: any) => {
  const resource = initialState && initialState?.resource;
  const defaultFilter = initialState && initialState?.defaultFilter;
  const defaultOrder = initialState && initialState?.defaultOrder;
  const searchFields = initialState && initialState?.searchFields;
  const useSearch = initialState && initialState?.useSearch;

  if (!resource) {
    throw new Error('');
  }

  const isMounted = useIsMounted();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [filters, setFilters] = useState(defaultFilter);
  const [order, setOrder] = useState(defaultOrder);

  const [dropdownFilters, setDropdownFilter] = useState({
    where: defaultFilter.where || {},
  });
  const [searchValue, setSearchValue] = useState('');

  const list = async () => {
    if (searchFields && isMounted) {
      setLoading(true);
    }
    try {
      const response = await resource.list({
        filter: { ...filters, order: order },
      });
      isMounted && setData(response.data);
    } catch (e) {
      isMounted && setError(e);
    }
    isMounted && setLoading(false);
  };

  const create = async (params: {}) => {
    await resource.create(params);
    await list();
  };

  const updateById = async (id: Id, params: {}) => {
    await resource.updateById(id, params);
    const updatedInstance = await resource.findById(id);
    setData((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return { ...item, ...updatedInstance.data };
        }
        return item;
      })
    );
  };

  const fetchUpdatedInstance = async (id: Id) => {
    const updatedInstance = await resource.findById(id);
    setData((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return { ...item, ...updatedInstance.data };
        }
        return item;
      })
    );
  };

  const deleteByIds = async (ids: Id[]) => {
    const filters = {
      where: { or: ids.map((id) => ({ id })) },
    };
    await resource.deleteAll(filters);
    await list();
  };

  const updateFilters = (filters: any) => {
    setDropdownFilter(filters);
  };

  useEffect(() => {
    if (searchValue) {
      if (useSearch) {
        setFilters((prevState: any) => ({
          ...prevState,
          where: {},
          search: searchValue,
        }));
      } else {
        setFilters((prevState: any) => ({
          ...prevState,
          where: {
            and: [
              // { ...dropdownFilters.where },
              {
                or: searchFields.map((field: string) => ({
                  [field]: { ilike: `%${searchValue}%` },
                })),
              },
            ],
          },
        }));
      }
    } else {
      setFilters((prevState: any) => {
        if (isEqual(prevState.where, dropdownFilters.where)) {
          return prevState;
        }
        return {
          ...prevState,
          where: dropdownFilters.where,
          search: undefined,
        };
      });
    }
  }, [dropdownFilters, searchValue, searchFields, useSearch]);

  const search = (value: string) => {
    setSearchValue(value);
  };

  const sortData = (
    {
      orderingField,
      sortKey,
      transformValue,
    }: {
      orderingField: string;
      sortKey?: string;
      transformValue?: (value: any) => any;
    },
    reverse = false
  ) => {
    const iteratee = (item: any) => {
      const value = sortKey ? get(item, sortKey) : get(item, orderingField);
      if (transformValue) {
        return transformValue(item);
      }
      if (typeof value === 'string') {
        return value.toLowerCase();
      }
      return value;
    };
    if (reverse) {
      setData(sortBy(data, iteratee).reverse());
      setOrder(`${orderingField} DESC`);
    } else {
      setData(sortBy(data, iteratee));
      setOrder(`${orderingField} ASC`);
    }
  };

  const onDragSort = (newData: any) => {
    if (isMounted) {
      setData(newData);
    }
  };

  return {
    filters,
    updateFilters,
    data,
    list,
    create,
    fetchUpdatedInstance,
    updateById,
    deleteByIds,
    error,
    loading,
    sortData,
    search,
    dropdownFilters,
    onDragSort,
  };
};

export default useCRUDResource;
