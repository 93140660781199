import React, { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import {
  TEAM_MEMBER_EMPLOYMENT_TYPE_OPTIONS,
  TEAM_MEMBER_ROLE_OPTIONS,
} from '../constants';
import {
  CheckboxField,
  TextInputField,
  EmailInputField,
  DateInputField,
  PasswordInputField,
  NumberInputField,
  SelectInputField,
} from '../../../components/forms/fields';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import LeaveAllowanceFields from './LeaveAllowanceFields';
import FieldsDivider from '../../../components/forms/FieldsDivider';
import { nameBGValidationpattern } from '../../../components/validationRules';

type Props = FormFieldsComponentTypeProps;

const CreateFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;
  const { setValue } = formMethods;

  useEffect(() => {
    setValue('minimalBonus', 400);
  }, [setValue]);

  return (
    <>
      {/*<FieldsDivider horizontal content="Name & Contact Details" />*/}
      <Form.Group widths="equal">
        <TextInputField
          rules={{ required: { value: true, message: 'Please enter a name.' } }}
          label="Name"
          name="name"
          placeholder="First Name Surname"
          {...formMethods}
        />
        <TextInputField
          rules={{
            required: {
              value: true,
              message: 'Please enter a valid name in Bulgarian.',
            },
            ...nameBGValidationpattern,
          }}
          label="Name (BG)"
          name="nameBG"
          placeholder="Собствено Фамилно"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <TextInputField
          rules={{
            required: {
              value: true,
              message: 'Please enter the initials.',
            },
          }}
          label="Initials"
          name="initials"
          placeholder="Initials"
          {...formMethods}
        />
        <TextInputField
          rules={{
            required: {
              value: true,
              message: 'Please enter a telephone number.',
            },
            pattern: {
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
              message: 'Please enter a valid phone number',
            },
          }}
          label="Telephone"
          name="telephone"
          placeholder="Mobile"
          {...formMethods}
        />
      </Form.Group>
      <FieldsDivider horizontal content="Account" />
      <Form.Group widths="equal">
        <EmailInputField
          rules={{
            required: { value: true, message: 'Please enter an email' },
          }}
          placeholder="Email"
          name="email"
          label="Email"
          {...formMethods}
        />
        <PasswordInputField
          name="password"
          label="Password"
          placeholder="Password"
          rules={{
            required: { value: true, message: 'Please enter a password.' },
          }}
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <CheckboxField label="Full Access" name="fullAccess" {...formMethods} />
      </Form.Group>
      <FieldsDivider horizontal content="Employment" />
      <Form.Group widths="equal">
        <SelectInputField
          label="Role"
          options={TEAM_MEMBER_ROLE_OPTIONS}
          name="role"
          placeholder="Role"
          rules={{
            required: { value: true, message: 'Please select a role.' },
          }}
          {...formMethods}
        />
        <SelectInputField
          label="Employment Type"
          options={TEAM_MEMBER_EMPLOYMENT_TYPE_OPTIONS}
          name="employmentType"
          placeholder="Employment Type"
          rules={{
            required: {
              value: true,
              message: 'Please select an employment type.',
            },
          }}
          {...formMethods}
        />
      </Form.Group>
      <Form.Group>
        <DateInputField
          width="8"
          label="Start Date"
          name="startDate"
          placeholder="Start Date"
          dateOnly
          rules={{ required: { value: true, message: 'Please enter a date.' } }}
          {...formMethods}
        />
      </Form.Group>
      <FieldsDivider horizontal content="Leave Allowance" />
      <LeaveAllowanceFields {...formMethods} instance={null} />
      <FieldsDivider horizontal content="Salary" />
      <Form.Group>
        <NumberInputField
          label="Cost"
          name="cost"
          placeholder="Cost"
          fluid={false}
          width="8"
          rules={{
            required: { value: true, message: 'Please enter the cost.' },
          }}
          {...formMethods}
        />
        <NumberInputField
          fluid={false}
          label="Productivity"
          name="productivity"
          width="8"
          placeholder="Productivity"
          rules={{
            required: {
              value: true,
              message: 'Please enter the productivity rate.',
            },
          }}
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <NumberInputField
          label="Base Salary"
          name="baseSalary"
          rules={{
            required: { value: true, message: 'Please enter the base salary.' },
          }}
          {...formMethods}
        />
        <NumberInputField
          label="Bank Card Salary"
          name="salaryBankCard"
          rules={{
            required: {
              value: true,
              message: 'Please enter the bank card salary.',
            },
          }}
          {...formMethods}
        />
        <NumberInputField
          label="Bonus Threshold"
          name="minimalBonus"
          rules={{
            required: { value: true, message: 'Please enter the base salary.' },
          }}
          {...formMethods}
        />
      </Form.Group>
    </>
  );
};

export default CreateFormFields;
