import React, { useEffect } from 'react';
import Header from '../../components/layout/Header';
import MainContent from '../../components/layout/MainContent';
import Tabs from '../../containers/Tabs';
import { UserContext } from '../auth/UserContext';
import { Redirect } from 'react-router';
import Loader from '../../components/Loader';
import Segment from '../../components/layout/Segment';
import Footer from '../../components/layout/Footer';

const AdminPage = () => {
  const userContext = UserContext.useContainer();

  useEffect(() => {
    if (!userContext.user) {
      userContext.performRequest();
      userContext.profilePictureRequest.performRequest();
    }
    // eslint-disable-next-line
  }, [userContext.user]);

  if (userContext.error) {
    return <Redirect to="/login" />;
  }

  if (userContext.loading) {
    return <Loader />;
  }

  if (userContext.user) {
    return (
      <Segment style={{ minHeight: '100vh', padding: 0 }}>
        <Header />
        <MainContent>
          <Tabs />
        </MainContent>
        <Footer />
      </Segment>
    );
  }

  return null;
};

export default AdminPage;
