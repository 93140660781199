import BaseCRUDResource from '../../BaseCRUDResource';
import Api from '../../Api';
import { Id } from '../../../types/base';

class ProjectResource extends BaseCRUDResource {
  objectIdentifier = 'projects';

  getHolidayTypes = async (filters?: {} | null) => {
    return await Api.client.get('holidayOptions', { params: filters });
  };

  list = async (filters?: any) => {
    let params = { ...filters };
    if (filters?.filter?.search) {
      const { search, ...rest } = filters.filter;
      params = {
        search: search,
        filter: rest,
      };
    }
    return Api.client.get(this.objectIdentifier, {
      params: params,
    });
  };

  getProjectsForSalaries = (monthlySalaryId: Id) => {
    return Api.client.get(`/projects-for-salaries/${monthlySalaryId}`);
  };

  getMonthlyImagesPerPerson = async (date: string) => {
    return await Api.client.get(
      this.objectIdentifier + `/stats/monthly-images-per-person/${date}`
    );
  };

  getAverageImagePrice = async (date: string) => {
    return await Api.client.get(
      this.objectIdentifier + `/stats/average-image-price/${date}`
    );
  };
}

export default new ProjectResource();
