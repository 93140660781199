import React, { useRef, useState } from 'react';
import { TeamMember } from '../team/types';
import styled from 'styled-components';
import UserProfilePictureUpload from './UserProfilePictureUpload';
import UserDataReadOnly from './userData/UserDataReadOnly';
import UserDataEditForm from './userData/UserDataEditForm';
import Api from '../../api/Api';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../auth/UserContext';

const Container = styled.div`
  border-radius: 20px !important;
  background-color: #0084ff;
  height: 45%;
  clip-path: polygon(0 0, 0 95%, 100% 65%, 100% 0);
  font-family: MarkOT-Medium, sans-serif;
  color: #fff;
  display: flex;
`;

const PersonalData = styled.div`
  width: 65%;
  border-radius: 20px;
  text-align: right;
  padding: 30px 30px 20px 0;
  font-size: 1.2em;

  & p,
  & .ui.form > p {
    margin-top: 0;
    margin-bottom: 0;
  }

  & .ui.form .field {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`;

const UserData = ({
  teamMember,
  refetchTeamMember,
}: {
  teamMember: TeamMember;
  refetchTeamMember: () => void;
}) => {
  const { setUser } = UserContext.useContainer();
  const history = useHistory();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [ownDataEditMode, setOwnDataEditMode] = useState(false);

  const handleLogout = () => {
    Api.deleteAuthToken();
    setUser(null);
    history.push('/login');
  };

  return (
    <Container ref={containerRef}>
      <div
        style={{
          width: '35%',
          borderRadius: '20px',
          padding: '30px 0 20px 30px',
        }}
      >
        <UserProfilePictureUpload teamMember={teamMember} />
        <div style={{ marginTop: 10, padding: 10, fontSize: '1.2em' }}>
          {ownDataEditMode ? (
            <>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (formRef.current) {
                    //@ts-ignore
                    formRef.current?.handleSubmit();
                  }
                }}
              >
                update
              </span>{' '}
              /{' '}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => setOwnDataEditMode(false)}
              >
                cancel
              </span>{' '}
            </>
          ) : (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setOwnDataEditMode(true)}
            >
              edit profile
            </span>
          )}
          <p style={{ cursor: 'pointer' }} onClick={() => handleLogout()}>
            logout
          </p>
        </div>
      </div>
      <PersonalData>
        {ownDataEditMode ? (
          <UserDataEditForm
            teamMember={teamMember}
            formRef={formRef}
            onSubmit={() => {
              refetchTeamMember();
              setOwnDataEditMode(false);
            }}
          />
        ) : (
          <UserDataReadOnly teamMember={teamMember} />
        )}
      </PersonalData>
    </Container>
  );
};

export default UserData;
