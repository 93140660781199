import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  TextInputField,
  NumberInputField,
} from '../../../components/forms/fields';
import { nameBGValidationpattern } from '../../../components/validationRules';

type Props = FormFieldsComponentTypeProps;

const InvoiceEntryFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;

  return (
    <>
      <Form.Group widths="equal">
        <TextInputField
          label="Description"
          name="description"
          rules={{
            required: { value: true, message: 'Please add a description.' },
          }}
          {...formMethods}
          width="8"
        />
        <TextInputField
          label="Description (BG)"
          name="descriptionBG"
          rules={{
            required: {
              value: true,
              message: 'Please add a description in Bulgarian.',
            },
            ...nameBGValidationpattern,
          }}
          {...formMethods}
          width="8"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <NumberInputField
          label="Quantity"
          name="quantity"
          placeholder="0"
          rules={{
            min: { value: 0, message: 'Negative numbers are not allowed' },
          }}
          {...formMethods}
          width="4"
        />
        <NumberInputField
          label="Rate"
          name="rate"
          placeholder="0.00"
          {...formMethods}
          width="4"
        />
      </Form.Group>
    </>
  );
};

export default InvoiceEntryFormFields;
