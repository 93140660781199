import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { subMonths } from 'date-fns';
import groupBy from 'lodash/groupBy';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import { dateToString } from '../../../utils/dateUtils';
import MonthlySalariesResource from '../../../api/resources/salaries/MonthlySalariesResource';
import { OCCUPATION_RATE_OVERHEAD_ROLES } from '../../settings/keys';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';
import { MonthlySalariesFindByDateResponse } from '../types';
import { TeamMember } from '../../team/types';
import MonthSelector from '../MonthSelector';
import ProjectsForSalariesTable from '../projectsForSalaries/ProjectsForSalariesTable';
import AverageSalaryComponentGraph from './AverageSalaryComponentGraph';
import AdditionalStats from './AdditionalStats';
import ProjectResource from '../../../api/resources/projects/ProjectResource';
import { UserContext } from '../../auth/UserContext';
import { hasPermission } from '../../auth/utils';
import { FULL_ACCESS_PERMISSION } from '../../team/permission';
import Decimal from 'decimal.js-light';

const END_DAY_TO_SHOW_PREVIOUS_MONTH_SALARIES = 15;

const FixedComponentText =
  'Fixed Component: This section represents the fixed part of the salary structure, which remains constant throughout the year and is determined based on the agreed threshold discussed with the employer.';

const TotalSalaryText =
  'Total Compensation: This section represents the overall salary, combining the fixed and performance-based components. It reflects the total amount of compensation an employee receives, taking into account both the fixed and the variable elements of the salary structure';

const PerformanceComponentText =
  'Performance Component: This section represents the variable part of the salary structure, which is determined based on the monthly amount of work done by the employee. Each employee receives a percentage of their salary based on their individial performance.';
const SalariesTab = () => {
  const [month, setMonth] = useState<Date>(
    new Date().getDate() <= END_DAY_TO_SHOW_PREVIOUS_MONTH_SALARIES
      ? subMonths(new Date().setDate(15), 1)
      : new Date(new Date().setDate(15))
  );

  const { user } = UserContext.useContainer();

  const {
    data: settingsResponse,
    performRequest: fetchSetting,
  } = useAPIRequest(ProjectSettingResource.findByKey);

  const {
    data: monthlySalariesResponse,
    performRequest: getMonthlySalaries,
  } = useAPIRequest(MonthlySalariesResource.findByDateForStats);

  const {
    data: averageSalariesResponse,
    performRequest: getAverageSalaryComponents,
  } = useAPIRequest(MonthlySalariesResource.getAverageSalaryComponent);

  const {
    data: monthlyImagesPerPersonResponse,
    performRequest: getMonthlyImagesPerPerson,
  } = useAPIRequest(ProjectResource.getMonthlyImagesPerPerson);

  const {
    data: averageImageCostResponse,
    performRequest: getAverageImageCost,
  } = useAPIRequest(ProjectResource.getAverageImagePrice);

  useEffect(() => {
    getAverageSalaryComponents(dateToString(subMonths(month, 1)));
  }, [getAverageSalaryComponents, month]);

  useEffect(() => {
    getMonthlyImagesPerPerson(dateToString(subMonths(month, 1)));
  }, [getMonthlyImagesPerPerson, month]);

  useEffect(() => {
    if (hasPermission(user, FULL_ACCESS_PERMISSION)) {
      getAverageImageCost(dateToString(month));
    }
  }, [getAverageImageCost, month, user]);

  const fetchSalaries = useCallback(() => {
    getMonthlySalaries(dateToString(month), {
      filter: {
        include: [
          {
            relation: 'teamMemberMonthlySalaries',
          },
          { relation: 'monthlyProjectBonuses' },
          { relation: 'monthlyProjectBonusNotes' },
        ],
      },
    });
  }, [getMonthlySalaries, month]);

  useEffect(() => {
    fetchSetting(OCCUPATION_RATE_OVERHEAD_ROLES);
  }, [fetchSetting]);

  useEffect(() => {
    fetchSalaries();
  }, [fetchSalaries]);

  const {
    data: teamMembersResponse,
    performRequest: getTeamMembers,
  } = useAPIRequest(TeamMemberResource.list);

  const fetchTeamMembers = useCallback(() => {
    getTeamMembers({ filter: { order: ['role ASC', 'name ASC'] } });
  }, [getTeamMembers]);

  useEffect(() => {
    fetchTeamMembers();
  }, [fetchTeamMembers]);

  const responseData = monthlySalariesResponse?.data as MonthlySalariesFindByDateResponse;

  const salariesById = useMemo(() => {
    return groupBy(
      responseData?.monthlySalaries?.teamMemberMonthlySalaries || [],
      'teamMemberId'
    );
  }, [responseData]);

  const filteredTeamMembers: TeamMember[] = useMemo(() => {
    const teamMembers = teamMembersResponse?.data || [];
    return teamMembers.filter(
      (teamMember: TeamMember) =>
        salariesById[teamMember.id] &&
        !settingsResponse?.data.value.includes(teamMember.role)
    );
  }, [teamMembersResponse, salariesById, settingsResponse]);

  const activeTeamMembers = useMemo(() => {
    const teamMembers = teamMembersResponse?.data || [];
    return teamMembers.filter((teamMember: TeamMember) => !teamMember.archived);
  }, [teamMembersResponse]);

  const averageImagesPerPerson = useMemo(() => {
    const sumActiveTeamMembers = activeTeamMembers.reduce(
      (acc: Decimal, teamMember: TeamMember) => {
        return acc.add(teamMember?.averageImgCount ?? 0);
      },
      new Decimal(0)
    );
    return sumActiveTeamMembers.div(activeTeamMembers.length).toNumber();
  }, [activeTeamMembers]);

  return (
    <>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <MonthSelector
          month={month}
          setMonth={setMonth}
          disableNext={!responseData?.hasNext}
          disablePrevious={!responseData?.hasPrevious}
        />
      </div>
      <div>
        {responseData && (
          <ProjectsForSalariesTable
            monthlySalaries={responseData}
            teamMembers={filteredTeamMembers}
            refetchSalaries={fetchSalaries}
            asStats={true}
          />
        )}
      </div>
      <div>
        {averageSalariesResponse?.data && (
          <>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gridAutoRows: '1fr',
                gap: '1rem',
                paddingRight: '0.8rem',
              }}
            >
              <AverageSalaryComponentGraph
                month={subMonths(month, 1)}
                heading="Overall Salary"
                chartData={averageSalariesResponse.data.totalSalary}
                summaryLabel={'Average Monthly Salary'}
                hoverText={TotalSalaryText}
              />
              <AverageSalaryComponentGraph
                month={subMonths(month, 1)}
                heading="Performance Based Component"
                chartData={averageSalariesResponse.data.performanceComponent}
                summaryLabel={'Average Performance Component'}
                hoverText={PerformanceComponentText}
              />
              <AverageSalaryComponentGraph
                month={subMonths(month, 1)}
                heading="Fixed Component"
                chartData={averageSalariesResponse.data.base}
                summaryLabel={'Average Fixed Component'}
                hoverText={FixedComponentText}
              />
            </div>
            <AdditionalStats
              averageSalariesResponse={averageSalariesResponse}
              imagesPerPersonResponse={monthlyImagesPerPersonResponse}
              averageImageCostResponse={averageImageCostResponse}
              averageImagesPerPerson={averageImagesPerPerson}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SalariesTab;
