import React, { ChangeEvent, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Modal, ModalContent, ModalHeader } from '../../components/dialogs';
import { Button, Image } from 'semantic-ui-react';
import placeholderImage from '../../assets/avatar-g6fed2df86_640.png';
import { TeamMember } from '../team/types';
import useAPIRequest from '../../hooks/useAPIRequest';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import { UserContext } from '../auth/UserContext';
import Compressor from 'compressorjs';

const UserProfilePictureUpload = ({
  teamMember,
}: {
  teamMember: TeamMember;
}) => {
  const {
    userProfilePictureUrl,
    profilePictureRequest,
  } = UserContext.useContainer();
  const { performRequest: uploadOwnProfilePicture } = useAPIRequest(
    TeamMemberResource.uploadOwnProfilePicture
  );

  const [newAvatarUrl, setNewAvatarUrl] = useState('');
  const [newFileName, setNewFileName] = useState('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState(false);

  const [cropper, setCropper] = useState<any>();

  const getCropData = async () => {
    if (cropper) {
      setOpen(false);
      await fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          // const file = new File([blob], newFileName);
          new Compressor(blob, {
            quality: 0.8,
            maxWidth: 400,
            success(file: File | Blob) {
              const formData = new FormData();
              formData.append('file', file, newFileName);
              uploadOwnProfilePicture(formData).then((res) => {
                profilePictureRequest.performRequest(false);
              });
            },
          });
        });
    }
  };

  const getNewAvatarUrl = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.target.files) {
      setNewFileName(e.target.files[0].name);
      setNewAvatarUrl(URL.createObjectURL(e.target.files[0]));
      setOpen(true);
    }
  };

  return (
    <>
      <input
        id="file-input"
        ref={fileInputRef}
        hidden
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={getNewAvatarUrl}
      />
      <Modal
        open={open}
        trigger={
          <div>
            {userProfilePictureUrl && (
              <label htmlFor="file-input">
                <Image
                  src={userProfilePictureUrl}
                  size="small"
                  title="Click to upload a new profile picture"
                  style={{ borderRadius: '10px', cursor: 'pointer' }}
                />
              </label>
            )}
            {!userProfilePictureUrl && (
              <label htmlFor="file-input">
                <Image
                  src={placeholderImage}
                  size="small"
                  title="Click to upload a profile picture"
                  style={{ borderRadius: '10px', cursor: 'pointer' }}
                />
              </label>
            )}
          </div>
        }
      >
        <ModalHeader>Upload new profile picture</ModalHeader>
        <ModalContent>
          <Cropper
            src={newAvatarUrl}
            style={{ maxHeight: 400 }}
            aspectRatio={4 / 4}
            background={false}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            guides={false}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '15px',
            }}
          >
            <Button onClick={() => setOpen(false)} size="big">
              Cancel
            </Button>
            <Button positive onClick={getCropData} size="big">
              Upload
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserProfilePictureUpload;
