import React, { useMemo, useState } from 'react';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import NewEventFormFields from './forms/NewEventFormFields';
import ResourceScheduleProjectEventResource from '../../api/resources/resourceSchedule/ResourceScheduleProjectEventResource';
import EventFormFields from './forms/EventFormFields';
import MilestoneFormFields from './forms/MilestoneFormFields';
import { Project } from '../projects/types';
import { Id } from '../../types/base';
import Calendar from '../../components/calendar/Calendar';
import ResourceScheduleMilestoneResourse from '../../api/resources/resourceSchedule/ResourceScheduleMilestoneResourse';
import { getProjectFullName } from '../projects/utils';
import { UserContext } from '../auth/UserContext';
import { hasPermission } from '../auth/utils';
import {
  FULL_ACCESS_PERMISSION,
  MANAGE_HOLIDAYS_PERMISSION,
} from '../team/permission';
import { TeamMember } from '../team/types';
import TeamMemberAvatar from './TeamMemberAvatar';
import get from 'lodash/get';
import { TimeFormatter } from '../../utils/dateUtils';
import { Milestone } from '../projectSchedule/ProjectSchedule';
import MiniProfile from '../userProfile/MiniProfile';

export type ResourceScheduleEvent = {
  id: Id;
  start: string;
  end: string;
  order: number;
  project: Project;
  teamMemberId: Id;
  status?: number;
  hours?: number;
};

export type ResourceScheduleEventWithRelations = {
  id: Id;
  start: string;
  end: string;
  order: number;
  project: Project;
  teamMemberId: Id;
  status?: number;
  teamMember: TeamMember;
};

type Props = {
  holidayOnly?: boolean;
};

const TeamMemberNameCell = ({ teamMember }: { teamMember: TeamMember }) => {
  const [profileOpen, setProfileOpen] = useState(false);

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => setProfileOpen(true)}>
      <TeamMemberAvatar entity={teamMember} /> {teamMember.name.split(' ')[0]}
      {profileOpen && (
        <MiniProfile
          teamMemberId={teamMember.id}
          onClose={() => setProfileOpen(false)}
        />
      )}
    </div>
  );
};

const ResourceSchedule = ({ holidayOnly = false }: Props) => {
  const { user } = UserContext.useContainer();

  const eventAdditionalFilters = useMemo(() => {
    if (holidayOnly) {
      if (!hasPermission(user, MANAGE_HOLIDAYS_PERMISSION)) {
        return { isHoliday: true, isHolidayForAllUsers: false };
      }
      return { isHoliday: true };
    }

    return {};
  }, [user, holidayOnly]);

  const allowedEditsIds = useMemo(() => {
    if (hasPermission(user, FULL_ACCESS_PERMISSION)) {
      return undefined;
    }

    const teamMemberId = get(user, 'teamMemberId');
    if (teamMemberId) {
      return [teamMemberId];
    }
  }, [user]);

  return (
    <>
      <Calendar
        topCalendar={{
          calendarId: 'resources',
          milestoneResourceIdAccessor: 'teamMemberId',
          milestoneNameAccessor: (milestone: Milestone) => {
            return `${milestone.name}${
              milestone.datetime &&
              `- ${TimeFormatter.format(new Date(milestone.datetime))}`
            }`;
          },
          milestoneAPIResource: ResourceScheduleMilestoneResourse,
          editMilestoneFormFields: MilestoneFormFields,
          editEventFormFields: EventFormFields,
          eventIncludeRelations: [{ relation: 'project' }],
          eventAPIResource: ResourceScheduleProjectEventResource,
          resourceAPIResource: TeamMemberResource,
          resourceAdditionalFilters: { archived: false },
          resourceAPIMethod: 'listForSchedules',
          createEventFormFields: NewEventFormFields,
          eventResourceIdAccessor: 'teamMemberId',
          eventColorAccessor: ['project', 'eventColor'],
          eventNameAccessor: (event: ResourceScheduleEvent) =>
            getProjectFullName(event.project),
          eventAdditionalFilters: eventAdditionalFilters,
          resourceOrderBy: ['role ASC', 'name ASC'],
          copyEvent: (event) => ({
            start: event.start,
            end: event.end,
            color: event.project.eventColor,
            teamMemberId: event.teamMemberId,
            projectId: event.project.id,
          }),
          resourceNameAccessor: (teamMember: TeamMember) => {
            return <TeamMemberNameCell teamMember={teamMember} />;
          },
          resourceAllowedEditsIds: allowedEditsIds,
        }}
      />
    </>
  );
};

ResourceSchedule.defaultProps = {
  holidayOnly: false,
};

export default ResourceSchedule;
