import React, { useCallback, useContext, useState } from 'react';
import ResourceTable, {
  ColumnConfig,
} from '../../containers/resourceTable/ResourceTable';
import { Project } from './types';
import { PROJECT_STATUS_OPTIONS } from './constants';
import CreateFormFields from './forms/CreateFormFields';
import EditFormFields from './forms/EditFormFields';
import useCRUDResource from '../../hooks/useCRUDResource';
import { createContainer, useContainer } from 'unstated-next';
import {
  CLIENTS_PERMISSION,
  FULL_ACCESS_PERMISSION,
  INVOICES_PERMISSION,
} from '../team/permission';
import Delete from './actions/Delete';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import Filters, { projectFilterMapping } from './Filters';
import DateFormatter from '../../utils/dateUtils';
import { UserContext } from '../auth/UserContext';
import EditForm from '../../containers/resourceTable/EditForm';
import {
  editFormActions as clientEditFormActions,
  editInstanceConfig as clientEditInstanceConfig,
} from '../clients/Table';
import ClientResource from '../../api/resources/clients/ClientResource';
import { hasPermission } from '../auth/utils';
import ClientInfoBox from '../../pages/clients/InfoBox';
import { Client } from '../clients/types';
import { getProjectFullName } from './utils';
import { TabContext } from '../../containers/Tabs';
import Decimal from 'decimal.js-light';
import ProjectTimeSpentTable from './projectTimeSpent/ProjectTimeSpentTable';
import { Id } from '../../types/base';
import { INVOICES_MENU_ITEM } from '../../containers/constants';
import ProjectItemsAndTasks from './ProjectItemsAndTasks';

export const ProjectsContainer = createContainer(useCRUDResource);

type Props = {
  filters?: {
    where?: any;
  };
};

export const projectCreateInstanceConfig = {
  title: 'Create project',
  formFields: CreateFormFields,
  formId: 'projects-create-form',
};

const ProjectItemsAndTasksEditForm = ({ instance }: { instance: Project }) => {
  const projectsContainer = useContainer(ProjectsContainer);

  const onItemsReload = useCallback(async () => {
    await projectsContainer.fetchUpdatedInstance(instance.id);
  }, [instance.id, projectsContainer]);

  if (instance.isInHouse) {
    return null;
  }

  return (
    <ProjectItemsAndTasks instance={instance} onItemsReload={onItemsReload} />
  );
};

export const projectEditInstanceConfig = {
  title: 'Edit project',
  formFields: EditFormFields,
  formId: 'projects-edit-form',
  nameAccessor: 'name' as const,
  activateInputOnClick: true,
  nestedContent: ProjectItemsAndTasksEditForm,
};

export const projectEditInFlowConfig = {
  title: 'Edit project',
  formFields: EditFormFields,
  formId: 'projects-edit-form-flow',
  nameAccessor: 'name' as const,
  activateInputOnClick: true,
  nestedContent: ProjectItemsAndTasks,
};

export const projectEditFormActions = [
  {
    key: 'delete',
    component: Delete,
    permission: FULL_ACCESS_PERMISSION,
    condition: (project: Project) => !project.hasInvoices,
  },
];

const Table = (props: Props) => {
  const { filters = {} } = props;

  const { user } = UserContext.useContainer();
  const { setActiveIndex } = useContext(TabContext);

  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedTimeSpent, setSelectedTimeSpent] = useState<Id | null>(null);

  const handleClientClick = useCallback(async (project: Project) => {
    const res = await ClientResource.findById(project.client.id, {
      filter: {
        include: [
          { relation: 'contacts' },
          { relation: 'companyInvoiceData' },
          { relation: 'projects' },
        ],
      },
    });
    setSelectedClient(res.data);
  }, []);

  const config: ColumnConfig<Project>[] = [
    {
      label: 'Name',
      accessor: (project: Project) => getProjectFullName(project),
      orderingField: 'ref',
      key: 'name',
    },
    {
      label: 'Status',
      accessor: (project: Project) =>
        PROJECT_STATUS_OPTIONS.find((status) => status.value === project.status)
          ?.text,
      orderingField: 'status',
      key: 'status',
    },
    {
      label: 'Spent',
      accessor: (project: Project) => {
        const budget = new Decimal(project.budgetBG ?? 0);

        if (budget.equals(0)) {
          return <span>N/A</span>;
        }

        return (
          <span style={{ textDecoration: 'underline' }}>
            {new Decimal(project.budgetSpentPercentage ?? 0).toFixed(2)}%
          </span>
        );
      },
      orderingField: 'budgetSpentPercentage',
      sortTransformValue: (project) => {
        const budget = new Decimal(project.budgetBG ?? 0);

        if (budget.equals(0)) {
          return -1;
        }

        return Number(project.budgetSpentPercentage ?? 0);
      },
      key: 'budgetSpentPercentage',
      stopPropagation: true,
      clickHandler: (e, project) => {
        e.stopPropagation();
        setSelectedTimeSpent(project.id);
      },
    },
    {
      label: 'Start Date',
      accessor: (project: Project) =>
        DateFormatter.format(new Date(project.startDate)),
      orderingField: 'startDate',
      key: 'startDate',
    },
    {
      label: 'Deadline',
      accessor: (project: Project) =>
        project.deadline
          ? DateFormatter.format(new Date(project.deadline))
          : '',
      orderingField: 'deadline',
      key: 'deadline',
    },
    {
      label: 'Client',
      accessor: (project: Project, reload: () => Promise<void>) => (
        <div style={{ cursor: 'pointer', display: 'block', color: '#4183c4' }}>
          {project.client.name}
        </div>
      ),
      clickHandler: (e, project) => {
        e.stopPropagation();
        handleClientClick(project);
      },
      orderingField: 'clientId',
      key: 'client',
      stopPropagation: true,
      permission: CLIENTS_PERMISSION,
    },
    {
      label: 'Has Invoices',
      accessor: (project: Project) =>
        project.hasInvoices ? (
          <span
            style={{ color: '#4183c4', display: 'block' }}
            onClick={() =>
              setActiveIndex(INVOICES_MENU_ITEM, {
                filters: { where: { projectId: project.id } },
              })
            }
          >
            View
          </span>
        ) : (
          'No'
        ),
      key: 'has-invoices',
      orderingField: null,
      stopPropagation: true,
      permission: INVOICES_PERMISSION,
    },
  ];

  return (
    <ProjectsContainer.Provider
      initialState={{
        resource: ProjectResource,
        defaultFilter: {
          where: projectFilterMapping.live,
          include: [
            {
              relation: 'client',
              scope: {
                fields: {
                  name: true,
                  id: true,
                },
              },
            },
          ],
          ...filters,
        },
        defaultOrder: ['ref ASC'],
        searchFields: ['name', 'ref'],
        useSearch: true,
      }}
    >
      {selectedClient && hasPermission(user, FULL_ACCESS_PERMISSION) && (
        <EditForm
          defaultOpen
          {...clientEditInstanceConfig}
          instance={selectedClient}
          handleEditInstance={(data) =>
            ClientResource.updateById(selectedClient.id, data)
          }
          reload={async () => {
            setSelectedClient(null);
          }}
          //@ts-ignore
          editFormActions={clientEditFormActions}
          onClose={() => {
            setSelectedClient(null);
          }}
        />
      )}
      {selectedClient && !hasPermission(user, FULL_ACCESS_PERMISSION) && (
        <ClientInfoBox
          dataItem={selectedClient}
          trigger={
            <span
              onClick={(e) => e.stopPropagation()}
              style={{ cursor: 'pointer', display: 'block' }}
            >
              {selectedClient.name}
            </span>
          }
        />
      )}
      {selectedTimeSpent && (
        <ProjectTimeSpentTable
          projectId={selectedTimeSpent}
          onClose={() => setSelectedTimeSpent(null)}
        />
      )}
      <ResourceTable
        columns={config}
        newInstanceConfig={projectCreateInstanceConfig}
        editInstanceConfig={projectEditInstanceConfig}
        stateContainer={ProjectsContainer}
        filterComponent={Filters}
        editFormActions={projectEditFormActions}
        bulkActions={[
          {
            key: 'delete',
            component: Delete,
            permission: FULL_ACCESS_PERMISSION,
          },
        ]}
      />
    </ProjectsContainer.Provider>
  );
};

export default Table;
