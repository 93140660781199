import React, { SyntheticEvent, useContext, useMemo } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import cloneDeep from 'lodash/cloneDeep';
import { rangeFilterQuery } from '../../../components/calendar/utils';
import { toDateObject } from '../../../utils/dateUtils';
import { endOfMonth, startOfMonth } from 'date-fns';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import clsx from 'clsx';

type Props = {
  appliedFilter: any;
  onChange: (filters: any) => void;
};

const currentYear = new Date().getFullYear();
const YEAR_OPTIONS = [
  {
    key: (currentYear - 1).toString(),
    value: currentYear - 1,
    text: (currentYear - 1).toString(),
  },
  {
    key: currentYear.toString(),
    value: currentYear,
    text: currentYear.toString(),
  },
  {
    key: (currentYear + 1).toString(),
    value: currentYear + 1,
    text: (currentYear + 1).toString(),
  },
  {
    key: (currentYear + 2).toString(),
    value: currentYear + 2,
    text: (currentYear + 2).toString(),
  },
];

function monthsForLocale(
  localeName = 'en-GB',
  monthFormat = 'long'
): Array<string> {
  const format = new Intl.DateTimeFormat(localeName, { month: monthFormat })
    .format;
  return [...Array(12).keys()].map((m) => format(new Date(Date.UTC(2021, m))));
}

const currentMonth = new Date().getMonth() + 1;
const MONTH_OPTIONS = monthsForLocale().map((month, index) => ({
  key: month,
  value: (index + 1).toString().padStart(2, '0'),
  text: month,
}));

const Filters = (props: Props) => {
  const { appliedFilter, onChange } = props;
  const { theme } = useContext(ThemeContext);

  const yearValue = useMemo(() => {
    return appliedFilter.where.and[0].or[0].start.between[0].split('-')[0];
  }, [appliedFilter]);

  const monthValue = useMemo(() => {
    return appliedFilter.where.and[0].or[0].start.between[0].split('-')[1];
  }, [appliedFilter]);

  const handleYearChange = (e: SyntheticEvent, { value }: DropdownProps) => {
    const filter = cloneDeep(appliedFilter);
    const paddedValue =
      monthValue?.toString().padStart(2, '0') ||
      currentMonth.toString().padStart(2, '0');

    const monthStart = startOfMonth(toDateObject(`${value}-${paddedValue}-01`));
    const monthEnd = endOfMonth(toDateObject(`${value}-${paddedValue}-01`));

    filter.where.and[0] = {
      ...rangeFilterQuery(monthStart, monthEnd),
    };
    onChange(filter);
  };

  const handleMonthChange = (e: SyntheticEvent, { value }: DropdownProps) => {
    const filter = cloneDeep(appliedFilter);

    const monthStart = startOfMonth(toDateObject(`${yearValue}-${value}-01`));
    const monthEnd = endOfMonth(toDateObject(`${yearValue}-${value}-01`));
    filter.where.and[0] = {
      ...rangeFilterQuery(monthStart, monthEnd),
    };
    onChange(filter);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Dropdown
        className={clsx('secondary', theme === themes.dark && 'inverted')}
        size="small"
        style={{ minWidth: '120px' }}
        fluid
        selection
        options={MONTH_OPTIONS}
        value={monthValue}
        onChange={handleMonthChange}
      />
      <Dropdown
        className={clsx('secondary', theme === themes.dark && 'inverted')}
        size="small"
        style={{ minWidth: '120px' }}
        fluid
        selection
        options={YEAR_OPTIONS}
        text={yearValue}
        onChange={handleYearChange}
      />
    </div>
  );
};

export default Filters;
