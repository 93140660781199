import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  Area,
  Line,
} from 'recharts';
import { eachMonthOfInterval, isSameMonth, subMonths } from 'date-fns';
import { toDateObject } from '../../../utils/dateUtils';
import { get } from 'lodash';

const formatMonth = new Intl.DateTimeFormat('en-GB', { month: 'short' }).format;

export const formatBGNSummary = new Intl.NumberFormat('BG', {
  style: 'currency',
  currency: 'BGN',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;

const labels = {
  average: 'Average',
  base: 'Base Salary',
  performanceComponent: 'Performance Component',
  total: 'Total Salary',
};

const YearSalaryChart = ({
  data,
  averageSalary,
  month,
}: {
  data: {
    [key: string]: {
      base?: number;
      performanceComponent?: number;
      total?: number;
    };
  };
  averageSalary: number;
  month: Date;
}) => {
  const firstMonth = subMonths(month, 11);

  const chartData = eachMonthOfInterval({
    start: firstMonth,
    end: month,
  }).map((month) => {
    const monthKey = Object.keys(data).find((key) =>
      isSameMonth(toDateObject(key), month)
    );
    return {
      monthLabel: formatMonth(month),
      average: averageSalary,
      base: monthKey ? data[monthKey].base : 0,
      total: monthKey ? data[monthKey].total : 0,
      performanceComponent: monthKey ? data[monthKey].performanceComponent : 0,
    };
  });

  const tooltipFormatter = (value: number, name: string) => {
    return [
      `${new Intl.NumberFormat('BG', {
        style: 'currency',
        currency: 'BGN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value)}`,
      get(labels, name),
    ];
  };

  return (
    <ResponsiveContainer width={'100%'} height={250}>
      <ComposedChart
        margin={{ top: 25, right: 20, bottom: 5, left: 0 }}
        data={chartData}
      >
        <Tooltip
          formatter={tooltipFormatter}
          labelStyle={{
            fontFamily: 'Lato, sans-serif',
            color: '#6c6c6c',
            textAlign: 'center',
            fontSize: '0.9em',
          }}
          contentStyle={{
            textTransform: 'none',
          }}
          itemStyle={{
            fontWeight: 'normal',
            fontSize: '0.9em',
            fontFamily: 'Lato, sans-serif',
            color: '#6c6c6c',
          }}
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="monthLabel" tick={{ fill: '#fff' }} />
        <YAxis tick={{ fill: '#fff' }} />
        <Area dataKey="base" fill="#0084ff" stroke="#fff" dot={true} />
        <Area dataKey="total" fill="#0084ff80" stroke="#fff" dot={true} />
        <Line dataKey="average" stroke="#fff" strokeDasharray="4 4" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default YearSalaryChart;
