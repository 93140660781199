import React, { useContext, useEffect, useState } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import get from 'lodash/get';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import EventFormFields from './EventFormFields';
import MilestoneFormFields from './MilestoneFormFields';
import { HolidayScheduleContext } from '../../timesheets/HolidayResourceSchedule';

type Props = FormFieldsComponentTypeProps;

const NewEventFormFields = (props: Props) => {
  const { reset, setValue, instance } = props;
  const isSameDay = instance.event.start === instance.event.end;
  const [type, setType] = useState(isSameDay ? 'milestone' : 'event');

  const holidayContext = useContext(HolidayScheduleContext);

  useEffect(() => {
    reset();

    const initials = get(instance, type);
    setTimeout(() => {
      Object.keys(initials).forEach((key) => {
        setValue(key, initials[key], { shouldDirty: true });
      });
    }, 0);
  }, [type, instance, reset, setValue]);

  return (
    <>
      {!holidayContext && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {[
            { value: 'event', label: 'Event' },
            { value: 'milestone', label: 'Milestone' },
          ].map((choice, idx) => (
            <Form.Field key={idx} style={{ marginRight: '1em' }}>
              <Radio
                label={choice.label}
                name="resource-schedule-event-type"
                value={choice.value}
                checked={choice.value === type}
                onChange={(event, { checked }) => {
                  if (checked) {
                    setType(choice.value);
                  }
                }}
              />
            </Form.Field>
          ))}
        </div>
      )}
      {type === 'event' ? (
        <EventFormFields {...props} />
      ) : (
        <MilestoneFormFields {...props} />
      )}
    </>
  );
};

export default NewEventFormFields;
