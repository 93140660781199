import React, { RefObject } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Id } from '../../../types/base';
import ModalForm from '../../../components/forms/ModalForm';
import StatementFormFields from '../forms/StatementFormFields';
import ProjectTimesheetWeekResource from '../../../api/resources/timesheets/ProjectTimesheetWeekResource';
import { dateToString } from '../../../utils/dateUtils';

type Props = {
  teamMemberId: Id;
  firstWeekDay: Date;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Statement = (props: Props) => {
  const { teamMemberId, firstWeekDay, mountNode } = props;

  const lastWeekDay = new Date(firstWeekDay.getTime());
  lastWeekDay.setDate(firstWeekDay.getDate() + 6);

  const createStatement = async ({
    from,
    to,
    currentTeamMemberOnly,
  }: {
    from: Date | string;
    to: Date | string;
    currentTeamMemberOnly: boolean;
  }) => {
    const fromDate =
      typeof from === 'string'
        ? dateToString(new Date(from))
        : dateToString(from);
    const toDate =
      typeof to === 'string' ? dateToString(new Date(to)) : dateToString(to);
    const res = await ProjectTimesheetWeekResource.generateStatement(
      fromDate,
      toDate,
      currentTeamMemberOnly ? teamMemberId : undefined
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = `TIMESHEET_STATEMENT_${fromDate}_to_${toDate}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <ModalForm
      header={
        <>
          <Icon name="download" size="small" />
          Timesheet Statement Range
        </>
      }
      formId="timesheet-statement-form"
      fields={StatementFormFields}
      trigger={<Button size="huge" content="Statement" />}
      submitData={createStatement}
      instance={{
        id: 'timesheet-statement',
        from: firstWeekDay,
        to: lastWeekDay,
      }}
      modalProps={{
        mountNode,
      }}
    />
  );
};

export default Statement;
