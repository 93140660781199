import React, {
  CSSProperties,
  KeyboardEvent,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { Form, Label } from 'semantic-ui-react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import get from 'lodash/get';
import { ErrorLabel, StyledFormField } from './Fields.styled';

type Props = {
  rules?: ValidationRules;
  label?: string;
  name: string;
  placeholder?: string;
  activateInputOnClick?: boolean;
  submitHandler: (data: any) => any;
  style?: CSSProperties;
  preventDefaultOnEnter?: boolean;
  title?: string;
  helperText?: ReactNode;
} & React.ComponentProps<typeof Form.Field> &
  UseFormMethods;

const TextInputField = (props: Props) => {
  const {
    register,
    label,
    name,
    placeholder,
    rules,
    errors,
    activateInputOnClick,
    trigger,
    getValues,
    submitHandler,
    clearErrors,
    preventDefaultOnEnter = true,
    inline = true,
    fieldStyle = {},
    width,
    className,
    autoComplete = 'off',
    helperText,
    title,
  } = props;

  const fieldError = get(errors, name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [touched, setTouched] = useState(false);

  const handleBlur = async () => {
    if (activateInputOnClick) {
      const valid = await trigger(name);
      if (valid && touched) {
        await submitHandler(getValues());
        setTouched(false);
      }
    }
  };

  const handleKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (preventDefaultOnEnter || activateInputOnClick) {
        e.preventDefault();
      }

      if (activateInputOnClick) {
        const valid = await trigger(name);
        if (valid) {
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }
      }
    }
  };

  const handleChange = () => {
    setTouched(true);
    if (fieldError) {
      clearErrors(name);
    }
  };

  return (
    <StyledFormField
      error={!!fieldError}
      required={
        typeof rules?.required === 'object'
          ? !!rules?.required?.value
          : !!rules?.required
      }
      activateInputOnClick={activateInputOnClick}
      inline={inline}
      style={fieldStyle}
      width={width}
      className={className || ''}
      title={title}
    >
      {label && <label>{label}:</label>}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <input
          name={name}
          ref={(ref) => {
            inputRef.current = ref;
            register(ref, rules);
          }}
          placeholder={placeholder || label}
          onKeyPress={handleKeyPress}
          onBlur={handleBlur}
          onChange={handleChange}
          autoComplete={autoComplete}
        />
        {fieldError && (
          <ErrorLabel pointing="above">{fieldError.message}</ErrorLabel>
        )}
        {helperText && (
          <Label className="helperTextLabel" pointing="above">
            {helperText}
          </Label>
        )}
      </div>
    </StyledFormField>
  );
};

export default TextInputField;
