import BaseCRUDResource from '../../BaseCRUDResource';
import Api from '../../Api';
import { ResourceScheduleEvent } from '../../../pages/resourceSchedule/ResourceSchedule';
import { toDateObject } from '../../../utils/dateUtils';
import { isAfter, isBefore } from 'date-fns';

class LeaveDocsResource extends BaseCRUDResource {
  objectIdentifier = 'resource-schedule-project-events';

  list = async (filters?: any | null) => {
    const [start, end] = filters?.filter.where?.and[0].or[0].start.between;
    const res = await Api.client.get(this.objectIdentifier, {
      params: filters,
    });
    const data = res.data.map((event: ResourceScheduleEvent) => {
      let eventStart = event.start;
      let eventEnd = event.end;
      if (isBefore(toDateObject(event.start), toDateObject(start))) {
        eventStart = start;
      }

      if (isAfter(toDateObject(event.end), toDateObject(end))) {
        eventEnd = end;
      }
      return { ...event, start: eventStart, end: eventEnd };
    });
    return { ...res, data };
  };
}

export default new LeaveDocsResource();
