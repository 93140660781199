import React, {
  forwardRef,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Cell from '../Cell';
import {
  DropdownProps,
  Ref,
  Select as SemanticUISelect,
  SelectProps,
  SemanticWIDTHS,
} from 'semantic-ui-react';
import { SelectOptions, SelectValue } from '../../../types/forms';
import { Id } from '../../../types/base';
import styled from 'styled-components';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

export type SelectInputCellProps<DataItem extends { id: Id }> = {
  item: DataItem;
  initialValue: SelectValue;
  onSubmit: (value: SelectValue) => Promise<void> | void;
  options: SelectOptions | ((item: DataItem) => SelectOptions);
  multiple?: boolean;
  search?: boolean;
  width?: SemanticWIDTHS;
  allowAdditions?: boolean;
  additionLabel?: string;
  disabled?: boolean;
  title?: string;
};

const StyledSelect = styled(
  forwardRef<HTMLDivElement, SelectProps>((props, ref) => (
    <Ref innerRef={ref}>
      <SemanticUISelect {...props} />
    </Ref>
  ))
)`
  &.ui.dropdown > .divider.text {
    padding-right: 5px;
  }

  ${(props) =>
    props.options.length === 0 &&
    `
      .dropdown.icon {
        display: none !important;
      }
  `}

  &.ui.selection.dropdown {
    padding: 0.5em 0.7em;
  }

  &.ui.dropdown:not(:focus-within) .dropdown.icon {
    display: none;
  }

  &.ui.selection.active.dropdown,
  &.ui.selection.active.dropdown:hover {
    box-shadow: none;
    min-width: 200px;
  }

  &.ui.selection.dropdown .dropdown.icon {
    top: 50%;
    right: 0;
    padding: 0;
  }

  &.ui.selection.active.dropdown .menu {
    border-color: rgba(34, 36, 38, 0.1);
  }

  &.ui.selection.dropdown .menu > .item {
    padding: 0.4em 0.5em !important;
    font-size: 0.96em;
  }

  ${(props) =>
    props.inverted &&
    `
    &.ui.selection.dropdown .menu > .item {
       color: #fff;
       background-color: #1e1e1e;
    }
    
    &.ui.selection.dropdown .menu > .item:hover {
       background-color: #3d3d3d;
    }
    
    &.ui.selection.active.dropdown .menu {
      border-color: #343434;
    }
  `}
`;

const SelectInputCell = <DataItem extends { id: Id }>(
  props: SelectInputCellProps<DataItem>
) => {
  const {
    item,
    initialValue,
    onSubmit,
    options: optionsProp,
    search = false,
    multiple = false,
    width,
    allowAdditions,
    additionLabel,
    disabled,
    title,
  } = props;

  const { theme } = useContext(ThemeContext);

  const [value, setValue] = useState<SelectValue>(initialValue);
  const [options, setOptions] = useState<SelectOptions>(
    typeof optionsProp === 'function' ? optionsProp(item) : optionsProp
  );
  const selectRef = useRef<HTMLDivElement>(null);

  const handleChange = (
    e: SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    setValue(value);
    onSubmit(value);
    selectRef.current?.blur();
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleAddition = (e: SyntheticEvent, { value }: SelectProps) => {
    if (value) {
      setOptions((prevState) => [
        {
          key: value.toString(),
          text: value.toString(),
          value: value.toString(),
        },
        ...prevState,
      ]);
    }
  };

  return (
    <Cell
      title={title}
      width={width}
      // onClick={(e: SyntheticEvent) => e.stopPropagation()}
      style={{ padding: 0 }}
    >
      <Ref innerRef={selectRef}>
        <StyledSelect
          value={value}
          onChange={handleChange}
          options={options}
          search={search}
          multiple={multiple}
          placeholder=""
          disabled={disabled || options.length === 0}
          text={!options.some((opt) => opt.value === value) && value}
          allowAdditions={allowAdditions}
          onAddItem={handleAddition}
          additionLabel={additionLabel}
          inverted={theme === themes.dark}
        />
      </Ref>
    </Cell>
  );
};

export default SelectInputCell;
