import React from 'react';
import TabPane from '../../components/layout/TabPane';
import Table from './Table';

const Pane = () => {
  return (
    <TabPane>
      <Table />
    </TabPane>
  );
};

export default Pane;
