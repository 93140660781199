export const INVOICE_TYPE_OPTIONS = [
  { key: 'original', text: 'Original', value: 'Original' },
  { key: 'proforma', text: 'Proforma', value: 'Proforma' },
];

const TEXT_INVOICE_DUE_DATE =
  'All invoices are payable in CCC within NN WWW of the invoice date.';
const TEXT_BANK_CHARGES =
  'All bank charges for the transfer and commissions must be paid by the client.';
const TEXT_CLIENT_COPY_DISTRIBUTE_RIGHT =
  'The client may not print, copy or distribute the work until the invoice has been paid in full.';
const TEXT_LEGAL_GROUNDS_2 =
  'Legal Grounds: reverse charge in accordance with Article 86, Paragraph 3 and Article 21, Paragraph 2 from VATA.';
const TEXT_LEGAL_GROUNDS_5 =
  'Legal Grounds: reverse charge in accordance with Article 86, Paragraph 3 and Article 21, Paragraph 5 from VATA.';

export const INVOICE_NOTES_OPTIONS = [
  {
    key: 'due-date',
    text: TEXT_INVOICE_DUE_DATE,
    value: TEXT_INVOICE_DUE_DATE,
  },
  {
    key: 'bank-charges',
    text: TEXT_BANK_CHARGES,
    value: TEXT_BANK_CHARGES,
  },
  {
    key: 'copy-distribute',
    text: TEXT_CLIENT_COPY_DISTRIBUTE_RIGHT,
    value: TEXT_CLIENT_COPY_DISTRIBUTE_RIGHT,
  },
  {
    key: 'legal-grounds-2',
    text: TEXT_LEGAL_GROUNDS_2,
    value: TEXT_LEGAL_GROUNDS_2,
  },
  {
    key: 'legal-grounds-5',
    text: TEXT_LEGAL_GROUNDS_5,
    value: TEXT_LEGAL_GROUNDS_5,
  },
];
