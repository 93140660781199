export const TEAM_MEMBER_ROLE_OPTIONS = [
  { key: 'studio-director', text: 'Studio Director', value: 1 },
  { key: 'project-manager', text: 'Project Manager', value: 2 },
  {
    key: 'post-production-director',
    text: 'Post-Production Director',
    value: 3,
  },
  { key: 'senior-artist', text: 'Senior 3D Artist', value: 4 },
  { key: '3d-artist', text: '3D Artist', value: 5 },
  { key: 'mid-artist', text: 'Intermediate 3D Artist', value: 6 },
  { key: 'junior-artist', text: 'Junior 3D Artist', value: 7 },
  { key: 'illustrator', text: 'Illustrator', value: 8 },
  { key: 'motion-graphic-artist', text: 'Motion Graphic Artist', value: 9 },
  { key: 'programmer', text: 'Programmer', value: 10 },
];

export const TEAM_MEMBER_FREELANCE_EMPLOYMENT_TYPE = 3;

export const TEAM_MEMBER_EMPLOYMENT_TYPE_OPTIONS = [
  { key: 'full-time', text: 'Full Time', value: 1 },
  { key: 'part-time', text: 'Part Time', value: 2 },
  {
    key: 'freelance',
    text: 'Freelance',
    value: TEAM_MEMBER_FREELANCE_EMPLOYMENT_TYPE,
  },
];
