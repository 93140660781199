import React from 'react';
import { ProjectSetting } from '../types';
import { useForm } from 'react-hook-form';
import {
  OCCUPATION_CAPACITY_PER_PERSON_PER_MONTH,
  OCCUPATION_COEFFICIENT,
  OCCUPATION_RATE_ANIMATION_SECONDS,
  OCCUPATION_RATE_OVERHEAD_ROLES,
} from '../keys';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import Loader from '../../../components/Loader';
import { Form } from '../../../components/forms/Form';
import NumberInputField from '../../../components/forms/fields/NumberInputField';
import { SelectInputField } from '../../../components/forms/fields';
import { TEAM_MEMBER_ROLE_OPTIONS } from '../../team/constants';

type Props = {
  settings: ProjectSetting[];
  loading: boolean;
  reloadSettings: () => Promise<void>;
};

const OccupationRateSettings = (props: Props) => {
  const { settings, loading, reloadSettings } = props;

  const formMethods = useForm({
    defaultValues: {
      [`${OCCUPATION_COEFFICIENT}_value`]:
        settings.find((setting) => setting.key === OCCUPATION_COEFFICIENT)
          ?.value || 1,
      [`${OCCUPATION_CAPACITY_PER_PERSON_PER_MONTH}_value`]:
        settings.find(
          (setting) => setting.key === OCCUPATION_CAPACITY_PER_PERSON_PER_MONTH
        )?.value || 4,
      [`${OCCUPATION_RATE_OVERHEAD_ROLES}_value`]:
        settings.find(
          (setting) => setting.key === OCCUPATION_RATE_OVERHEAD_ROLES
        )?.value || [],
      [`${OCCUPATION_RATE_ANIMATION_SECONDS}_value`]:
        settings.find(
          (setting) => setting.key === OCCUPATION_RATE_ANIMATION_SECONDS
        )?.value || 4,
    },
  });

  const {
    loading: updateLoading,
    performRequest: updateOrCreateSetting,
  } = useAPIRequest(ProjectSettingResource.updateOrCreate);

  return (
    <div>
      <Loader active={loading || updateLoading} />
      <Form>
        <NumberInputField
          inline
          label="Coefficient"
          name={`${OCCUPATION_COEFFICIENT}_value`}
          submitHandler={({ [`${OCCUPATION_COEFFICIENT}_value`]: value }) => {
            updateOrCreateSetting({
              key: OCCUPATION_COEFFICIENT,
              value,
              module: 'occupation-rate',
            });
          }}
          rules={{
            min: { value: 1, message: 'Please specify a positive number' },
          }}
          fluid={false}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />
        <NumberInputField
          inline
          label="Capacity per person (Images)"
          name={`${OCCUPATION_CAPACITY_PER_PERSON_PER_MONTH}_value`}
          submitHandler={({
            [`${OCCUPATION_CAPACITY_PER_PERSON_PER_MONTH}_value`]: value,
          }) => {
            updateOrCreateSetting({
              key: OCCUPATION_CAPACITY_PER_PERSON_PER_MONTH,
              value,
              module: 'occupation-rate',
            });
          }}
          rules={{
            min: { value: 1, message: 'Please specify a positive number' },
          }}
          fluid={false}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />
        <NumberInputField
          inline
          label="Animation seconds per image"
          name={`${OCCUPATION_RATE_ANIMATION_SECONDS}_value`}
          submitHandler={({
            [`${OCCUPATION_RATE_ANIMATION_SECONDS}_value`]: value,
          }) => {
            updateOrCreateSetting({
              key: OCCUPATION_RATE_ANIMATION_SECONDS,
              value,
              module: 'occupation-rate',
            });
          }}
          fluid={false}
          rules={{
            min: { value: 1, message: 'Please specify a positive number' },
          }}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />
        <SelectInputField
          activateInputOnClick
          multiple={true}
          label="Overhead Roles"
          name={`${OCCUPATION_RATE_OVERHEAD_ROLES}_value`}
          options={TEAM_MEMBER_ROLE_OPTIONS}
          submitHandler={async (data) => {
            await updateOrCreateSetting({
              key: OCCUPATION_RATE_OVERHEAD_ROLES,
              value: JSON.stringify(
                data[`${OCCUPATION_RATE_OVERHEAD_ROLES}_value`]
              ),
              module: 'occupation-rate',
            });
            reloadSettings();
          }}
          {...formMethods}
        />
      </Form>
    </div>
  );
};

export default OccupationRateSettings;
