import React, { KeyboardEvent, ReactNode, useRef, useState } from 'react';
import {
  Form,
  Ref,
  Select as SemanticUISelect,
  Dropdown,
  Label,
  Icon,
} from 'semantic-ui-react';
import {
  Control,
  Controller,
  UseFormMethods,
  ValidationRules,
} from 'react-hook-form';
import get from 'lodash/get';
import { SelectValue } from '../../../types/forms';
import { ErrorLabel, StyledFormField } from './Fields.styled';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

type Props = {
  rules?: ValidationRules;
  options: Array<{ key: string; value: string | number; text: ReactNode }>;
  control: Control;
  label: ReactNode;
  name: string;
  onChange?: (value: SelectValue) => void;
  placeholder?: string;
  search?: boolean;
  multiple?: boolean;
  loading?: boolean;
  defaultValue?: SelectValue;
  activateInputOnClick?: boolean;
  submitHandler: (data: any) => any;
  clearable?: boolean;
  labelStyle?: React.CSSProperties;
  fieldStyle?: React.CSSProperties;
  icon?: ReactNode;
  selectStyle?: React.CSSProperties;
  allowAdditions?: boolean;
  onAddItem?: (
    event: React.KeyboardEvent<HTMLElement>,
    data: DropdownProps
  ) => void;
  closeOnChange?: boolean;
} & React.ComponentProps<typeof Form.Field> &
  UseFormMethods;

const SelectInputField = (props: Props) => {
  const {
    options,
    control,
    label,
    name,
    errors,
    rules,
    trigger,
    submitHandler,
    getValues,
    onChange: onChangeProp = () => {},
    placeholder = '',
    inline = true,
    search = false,
    multiple = false,
    loading = false,
    defaultValue = '',
    activateInputOnClick = false,
    width,
    clearable = false,
    labelStyle,
    icon,
    className,
    selectStyle,
    allowAdditions,
    onAddItem,
    fieldStyle,
    closeOnChange = !multiple,
  } = props;

  const fieldError = get(errors, name);
  const selectRef = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState(false);

  const handleChange = async (
    value: SelectValue,
    onChange: (value: SelectValue) => void
  ) => {
    onChange(value);
    onChangeProp(value);
    if (activateInputOnClick && selectRef.current && (!multiple || !opened)) {
      selectRef.current.blur();
      const valid = await trigger(name);
      if (valid) {
        submitHandler(getValues());
      }
    }
  };

  const handleClose = async () => {
    setOpened(false);
    if (activateInputOnClick && selectRef.current && multiple) {
      selectRef.current.blur();
      const valid = await trigger(name);
      if (valid) {
        submitHandler(getValues());
      }
    }
  };

  return (
    <StyledFormField
      activateInputOnClick={activateInputOnClick}
      required={
        typeof rules?.required === 'object'
          ? !!rules?.required?.value
          : !!rules?.required
      }
      width={width}
      inline={inline}
      className={className}
      style={fieldStyle}
    >
      <label style={labelStyle}>{label}:</label>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => {
            return (
              <Ref innerRef={selectRef}>
                <SemanticUISelect
                  allowAdditions={allowAdditions}
                  onAddItem={onAddItem}
                  onOpen={() => {
                    setOpened(true);
                  }}
                  value={value || getValues(name)}
                  error={!!fieldError}
                  closeOnChange={closeOnChange}
                  onChange={(event, data) => {
                    handleChange(data.value, onChange);
                  }}
                  placeholder={placeholder}
                  options={options}
                  search={search}
                  multiple={multiple}
                  loading={loading}
                  clearable={clearable}
                  onKeyPress={(e: KeyboardEvent<HTMLSelectElement>) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                  onClose={handleClose}
                  icon={icon}
                  style={selectStyle}
                  renderLabel={(item, index, defaultLabelProps) => {
                    const { onRemove, as, ...rest } = defaultLabelProps;
                    return (
                      <Label
                        as="div"
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          maxWidth: '500px',
                        }}
                        title={item.text}
                        {...rest}
                      >
                        <Icon
                          name="times"
                          onClick={(
                            event: React.MouseEvent<HTMLElement, MouseEvent>
                          ) => onRemove?.(event, defaultLabelProps)}
                        />
                        {item.text}{' '}
                      </Label>
                    );
                  }}
                >
                  {(!options || options?.length === 0) && !search ? (
                    <Dropdown.Menu>
                      <div className="message">No options available</div>
                    </Dropdown.Menu>
                  ) : null}
                </SemanticUISelect>
              </Ref>
            );
          }}
          defaultValue={defaultValue}
          name={name}
          rules={rules}
        />
        {fieldError && (
          <ErrorLabel pointing="above">{fieldError.message}</ErrorLabel>
        )}
      </div>
    </StyledFormField>
  );
};

export default SelectInputField;
