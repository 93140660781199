import React, { useEffect, useState } from 'react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectResource from '../../../api/resources/projects/ProjectResource';
import { SelectInputField } from '../../../components/forms/fields';
import { Project, ProjectWithRelations } from '../../projects/types';
import { SelectValue } from '../../../types/forms';
import { getProjectFullName } from '../../projects/utils';
import InvoiceClientFormFields from '../../invoices/fields/InvoiceClientFormFields';
import { projectDefaultFilters } from '../../projects/Filters';

type Props = FormFieldsComponentTypeProps;

const CreateProposalFormFields = (props: Props) => {
  const {
    loading: loadingProjects,
    data: projectsResponse,
    performRequest: getProjects,
  } = useAPIRequest(ProjectResource.list);

  const [projectId, setProjectId] = useState<SelectValue>(undefined);
  const [clientId, setClientId] = useState<string | undefined>(undefined);

  useEffect(() => {
    getProjects({
      filter: {
        where: {
          and: [{ or: [{ status: 2 }, { status: 1 }, { status: 4 }] }],
          ...projectDefaultFilters,
        },
      },
    });
  }, [getProjects]);

  useEffect(() => {
    if (projectId) {
      const clientId = projectsResponse?.data.find(
        (p: Project) => p.id === projectId
      )?.clientId;
      if (clientId) {
        setClientId(clientId);
      }
    }
  }, [projectId, projectsResponse]);

  return (
    <>
      {projectsResponse && (
        <SelectInputField
          loading={loadingProjects}
          options={projectsResponse.data.map(
            (project: Omit<ProjectWithRelations, 'projectItems'>) => ({
              value: project.id,
              text: getProjectFullName(project),
              key: project.id,
            })
          )}
          label="Project"
          name="projectId"
          search
          onChange={(value) => setProjectId(value)}
          rules={{
            required: {
              value: true,
              message: 'Please select a project.',
            },
          }}
          {...props}
        />
      )}
      {projectId && clientId && (
        <InvoiceClientFormFields clientId={clientId} {...props} />
      )}
    </>
  );
};

export default CreateProposalFormFields;
