import React from 'react';
import { ItemType, itemTypeColors } from './Projects.styled';

export const PROJECT_STATUS_QUOTED = 4;

export const PROJECT_STATUS_OPTIONS = [
  { key: 'in-progress', text: 'In Progress', value: 1 },
  { key: 'on-hold', text: 'On hold', value: 2 },
  { key: 'completed', text: 'Completed', value: 3 },
  { key: 'quoted', text: 'Quoted', value: PROJECT_STATUS_QUOTED },
  { key: 'lost', text: 'Lost', value: 5 },
];

const aud = 'AUD';
export const bgn = 'BGN';
const usd = 'USD';
const gbp = 'GBP';
const eur = 'EUR';

export const PROJECT_CURRENCY_OPTIONS = [
  { key: 'eur', text: eur, value: eur },
  { key: 'gbp', text: gbp, value: gbp },
  { key: 'usd', text: usd, value: usd },
  { key: 'bgn', text: bgn, value: bgn },
  { key: 'aud', text: aud, value: aud },
];

export const getCurrencyUnit = (currency: string) => {
  switch (currency) {
    case usd:
      return '$';
    case eur:
      return '€';
    case aud:
      return 'AU$';
    case gbp:
      return '£';
    case bgn:
      return 'lv';
    default:
      throw new Error('Unknown unit');
  }
};

export const ITEM_VALUE = 1;
export const NEGATIVE_VALUE = 2;

export const STUDIO_OPTION = 'studio';
export const STUDIO_TEXT = 'Studio';

export const PROJECT_ITEM_TYPES = [
  {
    key: 'item',
    text: <ItemType color={itemTypeColors[ITEM_VALUE]}> Item </ItemType>,
    value: ITEM_VALUE,
  },
  {
    key: 'negative',
    text: (
      <ItemType color={itemTypeColors[NEGATIVE_VALUE]}> Negative </ItemType>
    ),
    value: NEGATIVE_VALUE,
  },
];
