import { RefObject } from 'react';
import React from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useSize = (target: RefObject<HTMLDivElement>) => {
  const [size, setSize] = React.useState<
    Partial<DOMRectReadOnly> | undefined
  >();

  React.useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export default useSize;
