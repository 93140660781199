import React from 'react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { NumberInputField } from '../../../components/forms/fields';

type Props = FormFieldsComponentTypeProps;

const AddItemToInvoiceFormFields = (props: Props) => {
  return (
    <>
      <NumberInputField
        label="Please enter percent"
        name="percent"
        placeholder="the rest of available"
        width="16"
        step={0.001}
        {...props}
      />
    </>
  );
};

export default AddItemToInvoiceFormFields;
