import React, { useContext } from 'react';
import { Form } from 'semantic-ui-react';
import { TARGET_ADDITIONAL_KEY, TARGET_MARGIN_KEY } from '../keys';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import { useForm } from 'react-hook-form';
import { ProjectSetting } from '../types';
import NumberInputField from '../../../components/forms/fields/NumberInputField';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

type Props = {
  settings: ProjectSetting[];
  loading: boolean;
};

const Targets = (props: Props) => {
  const { settings, loading } = props;

  const {
    loading: updateLoading,
    performRequest: updateOrCreateSetting,
  } = useAPIRequest(ProjectSettingResource.updateOrCreate);

  const formMethods = useForm({
    defaultValues: {
      [`${TARGET_MARGIN_KEY}_value`]:
        settings.find((setting) => setting.key === TARGET_MARGIN_KEY)?.value ||
        0,
      [`${TARGET_ADDITIONAL_KEY}_value`]:
        settings.find((setting) => setting.key === TARGET_ADDITIONAL_KEY)
          ?.value || 0,
    },
  });

  const { theme } = useContext(ThemeContext);

  return (
    <Form inverted={theme === themes.dark}>
      <NumberInputField
        inline
        label="Target (margin)"
        name={`${TARGET_MARGIN_KEY}_value`}
        submitHandler={({ [`${TARGET_MARGIN_KEY}_value`]: value }) => {
          updateOrCreateSetting({
            key: TARGET_MARGIN_KEY,
            value,
            module: 'finances',
          });
        }}
        fluid={false}
        unit="%"
        activateInputOnClick
        loading={loading || updateLoading}
        {...formMethods}
      />
      <NumberInputField
        inline
        label="Target (additional)"
        name={`${TARGET_ADDITIONAL_KEY}_value`}
        submitHandler={({ [`${TARGET_ADDITIONAL_KEY}_value`]: value }) => {
          updateOrCreateSetting({
            key: TARGET_ADDITIONAL_KEY,
            value,
            module: 'finances',
          });
        }}
        fluid={false}
        activateInputOnClick
        unit="%"
        loading={loading || updateLoading}
        {...formMethods}
      />
    </Form>
  );
};

export default Targets;
