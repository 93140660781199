import React, { ReactNode } from 'react';
import { List } from 'semantic-ui-react';
import { Id } from '../types/base';
import { EditFormAction, EditInstanceConfig } from './types';
import EditForm from './resourceTable/EditForm';
import get from 'lodash/get';

type Props<DataItem extends { id: Id }> = {
  items: DataItem[] | undefined;
  editInstanceConfig: EditInstanceConfig<DataItem>;
  reload: () => Promise<void>;
  handleEdit: (data: Partial<DataItem>, id: Id) => Promise<void>;
  editFormActions: EditFormAction<DataItem>[];
  noItemsMessage: ReactNode;
  headerAccessor: ((dataItem: DataItem) => ReactNode) | keyof DataItem;
  descriptionAccessor: ((dataItem: DataItem) => ReactNode) | keyof DataItem;
};

const EditableList = <DataItem extends { id: Id }>(props: Props<DataItem>) => {
  const {
    items,
    editInstanceConfig,
    reload,
    editFormActions,
    noItemsMessage,
    headerAccessor,
    descriptionAccessor,
    handleEdit,
  } = props;

  return (
    <List
      relaxed
      style={{
        paddingLeft: '3px',
        marginTop: 0,
        maxHeight: 300,
        overflow: 'auto',
      }}
    >
      {items ? (
        items.map((item, idx) => (
          <EditForm
            key={`${item.id || idx}-edit-from`}
            {...editInstanceConfig}
            instance={item}
            handleEditInstance={(data) => handleEdit(data, item.id)}
            reload={reload}
            //@ts-ignore
            editFormActions={editFormActions}
            trigger={
              <List.Item key={item.id} style={{ marginLeft: '17px' }}>
                <List.Content>
                  <List.Header style={{ color: '#4183c4', cursor: 'pointer' }}>
                    {typeof headerAccessor === 'function'
                      ? headerAccessor(item)
                      : get(item, headerAccessor)}
                  </List.Header>
                  {typeof descriptionAccessor === 'function'
                    ? descriptionAccessor(item)
                    : get(item, descriptionAccessor)}
                </List.Content>
              </List.Item>
            }
          />
        ))
      ) : (
        <span style={{ marginLeft: '17px' }}>{noItemsMessage}</span>
      )}
    </List>
  );
};

export default EditableList;
