import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from 'semantic-ui-react';
import openSansBold from '../../assets/open-sans/OpenSans-Bold.ttf';
import openSans from '../../assets/open-sans/OpenSans-Regular.ttf';
import {
  BlobProvider,
  default as ReactPDF,
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import get from 'lodash/get';
import { Id } from '../../types/base';

Font.register({ family: 'Open Sans', src: openSans });
Font.register({ family: 'Open Sans Bold', src: openSansBold });

export const PDFStyles = StyleSheet.create({
  container: {
    padding: '30px',
    fontFamily: 'Open Sans',
  },
  created: {
    marginTop: 10,
    marginBottom: 15,
  },
  table: {
    marginTop: 5,
    fontFamily: 'Open Sans',
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: '#d9d9db',
  },
  tableRow: { flexDirection: 'row' },
  headerRow: { backgroundColor: '#f9fafb' },
  warningRow: { backgroundColor: '#fffaf3' },
  negativeRow: { backgroundColor: '#fdf6f6' },
  positiveRow: { backgroundColor: '#fbfdf5' },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#d9d9db',
  },
  tableCell: { marginLeft: 7, marginTop: 8, marginBottom: 8, fontSize: 7 },
  boldTextCell: { fontFamily: 'Open Sans Bold' },
  positiveTextCell: { color: '#2c662d' },
  negativeTextCell: { color: '#9f3a38' },
  warningTextCell: { color: '#573a08' },
  main: {
    marginTop: 5,
    marginBottom: 5,
  },
  mainText: {
    fontSize: 10,
    fontFamily: 'Open Sans Bold',
  },
  headerText: {
    fontSize: 12,
    fontFamily: 'Open Sans Bold',
    marginTop: 13,
    marginLeft: 5,
  },
  logo: {
    width: 40,
    marginLeft: 30,
  },
  header: {
    marginTop: 20,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
  },
});

export type PDFActionTableConfig<DataItem extends { id: Id }> = {
  label: string;
  accessor: keyof DataItem | ((item: DataItem) => string) | string[];
  defaultValue?: string;
  cellType?: 'warning' | 'positive' | 'negative';
  cellStyle?: ReactPDF.Style;
  textStyle?: ReactPDF.Style;
}[];

type PDFItem<DataItem extends { id: Id }> =
  | {
      type: 'view';
      content: PDFItem<DataItem>[];
      style: ReactPDF.Style;
    }
  | {
      type: 'image';
      src: string;
      style: ReactPDF.Style;
    }
  | {
      type: 'text';
      content: string;
      style: ReactPDF.Style;
    }
  | {
      type: 'table';
      data: DataItem[];
      config: PDFActionTableConfig<DataItem>;
    };

export type PDFDocumentProps<DataItem extends { id: Id }> = {
  document: {
    pages: {
      items: PDFItem<DataItem>[];
      key: string;
    }[];
  };
};

const PDFDocument = <DataItem extends { id: Id }>(
  props: PDFDocumentProps<DataItem>
) => {
  const { document } = props;

  const renderItems = (items: PDFItem<DataItem>[]) => {
    return items.map((item) => {
      if (item.type === 'image') {
        return <Image src={item.src} style={item.style} />;
      } else if (item.type === 'text') {
        return <Text style={item.style}>{item.content}</Text>;
      } else if (item.type === 'view') {
        return <View style={item.style}>{renderItems(item.content)}</View>;
      } else if (item.type === 'table') {
        return (
          <View style={PDFStyles.table}>
            <View style={{ ...PDFStyles.tableRow, ...PDFStyles.headerRow }}>
              {item.config.map((entry, idx) => (
                <View
                  key={idx}
                  style={{
                    width: `${100 / item.config.length}%`,
                    ...PDFStyles.tableCol,
                  }}
                >
                  <Text
                    style={{
                      ...PDFStyles.tableCell,
                      ...PDFStyles.boldTextCell,
                    }}
                  >
                    {entry.label}
                  </Text>
                </View>
              ))}
            </View>
            {item.data.map((entry) => {
              return (
                <View key={entry.id} style={{ ...PDFStyles.tableRow }}>
                  {item.config.map((c, idx) => {
                    const cellTextStyles = Object.assign(
                      {},
                      PDFStyles.tableCell,
                      c.cellType === 'negative'
                        ? PDFStyles.negativeTextCell
                        : {},
                      c.cellType === 'positive'
                        ? PDFStyles.positiveTextCell
                        : {},
                      c.cellType === 'warning' ? PDFStyles.warningTextCell : {}
                    );

                    const {
                      cellStyle = {},
                      textStyle = {},
                      accessor,
                      defaultValue = '',
                    } = c;
                    const value =
                      typeof accessor === 'function'
                        ? accessor(entry)
                        : get(entry, accessor);
                    return (
                      <View
                        key={`${entry.id}-${idx}`}
                        style={{
                          width: `${100 / item.config.length}%`,
                          ...PDFStyles.tableCol,
                          ...cellStyle,
                        }}
                      >
                        <Text style={{ ...cellTextStyles, ...textStyle }}>
                          {value ?? defaultValue}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Document>
      {document.pages.map((page) => (
        <Page key={page.key}>{renderItems(page.items)}</Page>
      ))}
    </Document>
  );
};

type Props = {
  filename: string;
  documentProps: ComponentProps<typeof PDFDocument>;
};

const DownloadLink = (props: {
  blob: Blob | null;
  url: string | null;
  loading: boolean;
  filename: string;
  onClick: () => any;
}) => {
  const { loading, url, filename, onClick } = props;
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.click();
      onClick();
    }
  }, [url, onClick]);

  return loading ? (
    <span>Loading PDF...</span>
  ) : url ? (
    <a ref={ref} href={url} download={filename}>
      {' '}
      Download PDF{' '}
    </a>
  ) : null;
};

const DownloadPDFAction = (props: Props) => {
  const { filename, documentProps } = props;

  const [pdfRequested, setPDFRequested] = useState(false);
  const setNoPDFRequested = useCallback(() => setPDFRequested(false), []);

  return (
    <>
      {!pdfRequested && (
        <Button
          size="large"
          content="Download PDF"
          onClick={() => setPDFRequested(true)}
        />
      )}
      {pdfRequested && (
        <BlobProvider document={<PDFDocument {...documentProps} />}>
          {({ blob, url, loading, error }) => (
            <DownloadLink
              blob={blob}
              url={url}
              loading={loading}
              onClick={setNoPDFRequested}
              filename={filename}
            />
          )}
        </BlobProvider>
      )}
    </>
  );
};

export default DownloadPDFAction;
