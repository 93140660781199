import { ReactNode } from 'react';
import get from 'lodash/get';

export const getByAccessor = <Item extends object>(
  item: Item,
  accessor:
    | string
    | string[]
    | ((item: Item) => ReactNode | string)
    | keyof Item
) => {
  if (typeof accessor === 'function') {
    return accessor(item);
  }

  return get(item, accessor);
};
