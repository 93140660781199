import React, { RefObject } from 'react';
import { Invoice } from '../types';
import InvoiceResource from '../../../api/resources/invoices/InvoiceResource';
import DeleteAction from '../../../components/actions/DeleteAction';
import { useContainer } from 'unstated-next';
import { ProjectsToBeInvoicedContainer } from '../InvoicesTab';

type Props = {
  selected: Invoice | Invoice[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const projectsToBeInvoicedContainer = useContainer(
    ProjectsToBeInvoicedContainer
  );

  const handleDelete = async () => {
    if (Array.isArray(selected)) {
      const filters = {
        where: { or: selected.map((client) => ({ id: client.id })) },
      };
      await InvoiceResource.deleteAll(filters);
      await reload();
    } else {
      await InvoiceResource.deleteById(selected.id);
      await reload();
    }
    await projectsToBeInvoicedContainer.list();
  };

  return (
    <DeleteAction
      confirmText={`Are you sure you want to delete the invoice${
        Array.isArray(selected) && selected.length > 1 ? 's' : ''
      }?`}
      callback={handleDelete}
      mountNode={mountNode}
      buttonSize={Array.isArray(selected) ? 'huge' : 'large'}
    />
  );
};

export default Delete;
