import React, { useContext } from 'react';
import { Modal as SemanticUIModal } from 'semantic-ui-react';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {} & React.ComponentProps<typeof SemanticUIModal>;

const ModalContent = (props: Props) => {
  const { children, className = '', ...rest } = props;

  const { theme } = useContext(ThemeContext);
  const classes = theme === themes.dark ? `inverted ${className}` : className;

  return (
    <SemanticUIModal.Content className={classes} {...rest}>
      {children}
    </SemanticUIModal.Content>
  );
};

export default ModalContent;
