import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import MonthlySalariesResource from '../../../api/resources/salaries/MonthlySalariesResource';
import styled from 'styled-components';
import { format } from 'date-fns';
import { toDateObject } from '../../../utils/dateUtils';

type Props = {
  monthlySalariesId: string;
  month: string;
};

const StyledDownloadButton = styled(Button)`
  &.ui.basic.button,
  &.ui.basic.button:focus,
  &.ui.basic.button:hover {
    box-shadow: none !important;
  }
`;

const Download = (props: Props) => {
  const { monthlySalariesId } = props;

  const [loading, setLoading] = useState(false);
  const handleDownload = async () => {
    let res;
    setLoading(true);

    try {
      res = await MonthlySalariesResource.print(monthlySalariesId);
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }

    if (res) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const a = document.createElement('a');

      a.style.display = 'none';
      a.href = url;
      document.body.appendChild(a);
      a.download = `COSMOSCUBE_SALARIES-${format(
        toDateObject(props.month),
        'yyyy-MMM'
      )}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <StyledDownloadButton
      loading={loading}
      basic
      icon="download"
      size="massive"
      inverted
      title="Download PDF"
      onClick={handleDownload}
    />
  );
};

export default Download;
