import React, { useEffect } from 'react';
import { MonthlyProjectBonus } from '../types';
import { TeamMember } from '../../team/types';
import { Project } from '../../projects/types';
import useAPIRequest from '../../../hooks/useAPIRequest';
import MonthlyProjectBonusResource from '../../../api/resources/salaries/MonthlyProjectBonusResource';
import { useForm } from 'react-hook-form';
import { Form } from '../../../components/forms/Form';
import { PROJECT_STAGES_ROLES } from '../../settings/keys';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import PercentageInputField from './PercentageInputField';
import Heading from '../../../components/layout/Heading';
import Decimal from 'decimal.js-light';
import { cloneDeep } from 'lodash';

type TopDogsUpdateForm = Pick<
  MonthlyProjectBonus,
  'topDogsBonuses' | 'topDogsBonus' | 'topDogsBonusPercentage'
>;

const TopDogsEditValuesForm = ({
  monthlyProjectBonusEntry,
  project,
  teamMembers,
  onSubmit,
  locked,
}: {
  monthlyProjectBonusEntry: MonthlyProjectBonus;
  teamMembers: TeamMember[];
  project?: Project;
  onSubmit: () => void;
  locked: boolean;
}) => {
  const { performRequest: updateTopDogsBonuses } = useAPIRequest(
    MonthlyProjectBonusResource.updateTopDogsBonuses
  );

  const { data: response, performRequest: fetchSettings } = useAPIRequest(
    ProjectSettingResource.findByKey
  );

  useEffect(() => {
    fetchSettings(PROJECT_STAGES_ROLES);
  }, [fetchSettings]);

  const formMethods = useForm<TopDogsUpdateForm>({
    defaultValues: {
      topDogsBonus: monthlyProjectBonusEntry.topDogsBonus,
      topDogsBonusPercentage: monthlyProjectBonusEntry.topDogsBonusPercentage,
      topDogsBonuses: monthlyProjectBonusEntry.topDogsBonuses,
    },
  });

  const allRoles = response?.data?.value;

  const handleTotalPercentageUpdate = async (values: TopDogsUpdateForm) => {
    const newTotalBonus = values.topDogsBonus;

    if (newTotalBonus) {
      const newBonuses: MonthlyProjectBonus['topDogsBonuses'] = {};

      for (const roleId of Object.keys(
        monthlyProjectBonusEntry.topDogsBonuses
      )) {
        const rolePercentage =
          monthlyProjectBonusEntry.topDogsBonuses[roleId].bonusPercentage;
        const roleBonus = new Decimal(rolePercentage)
          .mul(new Decimal(newTotalBonus))
          .div(100)
          .toNumber();

        newBonuses[roleId] = {
          bonusPercentage: rolePercentage,
          bonus: roleBonus,
          teamMembers: {},
        };

        for (const teamMemberId of Object.keys(
          monthlyProjectBonusEntry.topDogsBonuses[roleId].teamMembers
        )) {
          const teamMemberPercentage =
            monthlyProjectBonusEntry.topDogsBonuses[roleId].teamMembers[
              teamMemberId
            ].bonusPercentage;
          const teamMemberBonus = new Decimal(teamMemberPercentage)
            .mul(roleBonus)
            .div(100)
            .toDecimalPlaces(2)
            .toNumber();
          newBonuses[roleId].teamMembers[teamMemberId] = {
            bonusPercentage: teamMemberPercentage,
            bonus: teamMemberBonus,
          };
        }
      }
      formMethods.setValue('topDogsBonuses', newBonuses);
      await updateTopDogsBonuses(monthlyProjectBonusEntry.id, {
        ...values,
        topDogsBonuses: newBonuses,
      });
      onSubmit();
    }
  };

  const handleTeamMemberPercentageUpdate = async (
    teamMemberId: string,
    roleId: string,
    values: TopDogsUpdateForm
  ) => {
    const newBonuses: MonthlyProjectBonus['topDogsBonuses'] = cloneDeep({
      ...monthlyProjectBonusEntry.topDogsBonuses,
    });
    newBonuses[roleId].teamMembers[teamMemberId] = {
      ...values.topDogsBonuses[roleId].teamMembers[teamMemberId],
    };
    newBonuses[roleId].teamMembers[teamMemberId].bonus = new Decimal(
      values.topDogsBonuses[roleId].teamMembers[teamMemberId].bonus
    )
      .toDecimalPlaces(2)
      .toNumber();

    const roleBonus = newBonuses[roleId].bonus;
    const percentageDifference =
      values.topDogsBonuses[roleId].teamMembers[teamMemberId].bonusPercentage -
      monthlyProjectBonusEntry.topDogsBonuses[roleId].teamMembers[teamMemberId]
        .bonusPercentage;

    const otherTeamMembers = Object.keys(
      monthlyProjectBonusEntry.topDogsBonuses[roleId].teamMembers
    ).filter((id) => id !== teamMemberId);

    for (const otherTeamMemberId of otherTeamMembers) {
      const diff = new Decimal(percentageDifference).div(
        new Decimal(otherTeamMembers.length)
      );
      const teamMemberPercentage = new Decimal(
        monthlyProjectBonusEntry.topDogsBonuses[roleId].teamMembers[
          otherTeamMemberId
        ].bonusPercentage
      )
        .sub(diff)
        .toNumber();
      const teamMemberBonus = new Decimal(teamMemberPercentage)
        .mul(new Decimal(roleBonus))
        .div(100)
        .toDecimalPlaces(2)
        .toNumber();
      newBonuses[roleId].teamMembers[otherTeamMemberId] = {
        bonusPercentage: teamMemberPercentage,
        bonus: teamMemberBonus,
      };
    }
    formMethods.setValue('topDogsBonuses', newBonuses);
    await updateTopDogsBonuses(monthlyProjectBonusEntry.id, {
      topDogsBonuses: newBonuses,
    });
    onSubmit();
  };

  return (
    <Form
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px',
      }}
      id="top-dogs-edit-form"
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <div style={{ flexBasis: '33%', flexGrow: 0 }} />
        <PercentageInputField
          budget={Number(project?.budgetForBonus) ?? 0}
          percentage={monthlyProjectBonusEntry.topDogsBonusPercentage}
          name="topDogsBonus"
          bonusFieldName="topDogsBonusPercentage"
          label={'top dogs total bonus'}
          submitHandler={(values) => {
            handleTotalPercentageUpdate(values);
          }}
          activateInputOnClick
          useRounding
          disabled={locked}
          {...formMethods}
        />
      </div>
      {monthlyProjectBonusEntry.topDogsBonuses &&
        Object.keys(monthlyProjectBonusEntry.topDogsBonuses).map((roleId) => {
          return (
            <div
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                style={{
                  flexBasis: '33%',
                  flexGrow: 0,
                  textAlign: 'right',
                  paddingRight: 20,
                  paddingTop: 5,
                }}
              >
                <Heading as="h5" style={{ display: 'inline-block' }}>
                  {
                    allRoles?.find(
                      (r: { id: string; name: string }) => r.id === roleId
                    )?.name
                  }
                  :
                </Heading>
              </div>
              <div style={{ flexBasis: '33%', flexGrow: 0 }}>
                {teamMembers
                  .filter(
                    (teamMember) =>
                      monthlyProjectBonusEntry.topDogsBonuses[roleId]
                        .teamMembers?.[teamMember.id]
                  )
                  .map((teamMember) => {
                    return (
                      <div key={teamMember.id}>
                        <PercentageInputField
                          budget={
                            monthlyProjectBonusEntry.topDogsBonuses[roleId]
                              .bonus
                          }
                          label={teamMember.name}
                          name={`topDogsBonuses.${roleId}.teamMembers.${teamMember.id}.bonus`}
                          bonusFieldName={`topDogsBonuses.${roleId}.teamMembers.${teamMember.id}.bonusPercentage`}
                          percentage={
                            monthlyProjectBonusEntry.topDogsBonuses[roleId]
                              .teamMembers[teamMember.id].bonusPercentage
                          }
                          submitHandler={(values) => {
                            handleTeamMemberPercentageUpdate(
                              teamMember.id,
                              roleId,
                              values
                            );
                          }}
                          activateInputOnClick
                          disabled={locked}
                          {...formMethods}
                        />
                      </div>
                    );
                  })}
              </div>
              <div />
            </div>
          );
        })}
    </Form>
  );
};

export default TopDogsEditValuesForm;
