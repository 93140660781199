import React from 'react';
import { Table } from 'semantic-ui-react';

type Props = {} & React.ComponentProps<typeof Table.Header>;

const Header = ({ children }: Props) => {
  return <Table.Header>{children}</Table.Header>;
};

export default Header;
