import React, { useContext } from 'react';
import { Segment as SemanticUISegment } from 'semantic-ui-react';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {} & React.ComponentProps<typeof SemanticUISegment>;

const Segment = (props: Props) => {
  const { theme } = useContext(ThemeContext);

  const { children, ...rest } = props;

  return (
    <SemanticUISegment inverted={theme === themes.dark} {...rest}>
      {children}
    </SemanticUISegment>
  );
};

export default Segment;
