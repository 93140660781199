import styled from 'styled-components';

export const VerticalSeparator = styled.div`
  display: inline-block;
  border-right: 1px solid #969494;
  margin-left: 9px;
  margin-right: 11px;
  height: 15px;
  margin-bottom: -3px;
`;
