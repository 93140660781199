import React, { RefObject } from 'react';
import { ResourceScheduleEvent } from '../../../resourceSchedule/ResourceSchedule';
import ResourceScheduleProjectEventResource from '../../../../api/resources/resourceSchedule/ResourceScheduleProjectEventResource';
import DeleteAction from '../../../../components/actions/DeleteAction';

type Props = {
  selected: ResourceScheduleEvent | ResourceScheduleEvent[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const handleDelete = async () => {
    if (Array.isArray(selected)) {
      const filters = {
        where: { or: selected.map((client) => ({ id: client.id })) },
      };
      await ResourceScheduleProjectEventResource.deleteAll(filters);
      await reload();
    } else {
      await ResourceScheduleProjectEventResource.deleteById(selected.id);
      await reload();
    }
  };

  return (
    <DeleteAction
      confirmText={`Are you sure you want to delete the request${
        Array.isArray(selected) && selected.length > 1 ? 's' : ''
      }?`}
      callback={handleDelete}
      mountNode={mountNode}
    />
  );
};

export default Delete;
