import React, { useState } from 'react';
import {
  MonthlySalariesFindByDateResponse,
  TeamMemberMonthlySalary,
} from '../types';
import { TeamMember } from '../../team/types';
import TeamMemberSalaryTable from './TeamMemberSalaryTable';
import styled from 'styled-components';
import { GRADIENT_DEFAULT_COLOR } from '../../../contexts/theme/ThemeContext';
import tinycolor from 'tinycolor2';
import LockAction from './LockAction';
import UnlockAction from './UnlockAction';
import Download from './DownloadPDF';
import TotalCashLabel from './TotalCashLabel';
import EditForm from '../../../containers/resourceTable/EditForm';
import EditFormFields from '../../team/forms/EditFormFields';
import useAPIRequest from '../../../hooks/useAPIRequest';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';
import { toDateObject } from '../../../utils/dateUtils';
import { endOfMonth, isAfter, subMonths } from 'date-fns';
import ShowOverheadRolesAction from './ShowOverheadRolesAction';
import ShowTeamMemberSalaries from './ShowLockActions';
import { LocalStorage } from '../../../api/BrowserStorage';
import SalariesChronology from '../../team/forms/SalariesChronology';

const Wrapper = styled.div`
  margin-bottom: 20px;
  background-color: #1e1e1e;
  border-radius: 20px !important;

  .salaries-actions-container {
    background: ${`linear-gradient(45deg, ${GRADIENT_DEFAULT_COLOR} 0%, ${tinycolor(
      GRADIENT_DEFAULT_COLOR
    )
      .setAlpha(tinycolor(GRADIENT_DEFAULT_COLOR).getAlpha() / 2)
      .toRgbString()} 50%, ${tinycolor(GRADIENT_DEFAULT_COLOR)
      .setAlpha(0)
      .toRgbString()} 100%)`};

    border-radius: 20px !important;
    padding: 5px 10px 30px;
  }

  .team-member-salaries-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 5px;
  }
`;

const teamMemberEditConfig = {
  title: 'Edit team member',
  formFields: EditFormFields,
  formId: 'team-members-edit-form',
  nameAccessor: 'name' as const,
  activateInputOnClick: true,
  nestedContent: SalariesChronology,
};

const TeamMemberSalariesList = ({
  salariesById,
  teamMembers,
  monthlySalaries,
  refetchSalaries,
  refetchTeamMembers,
  teamMemberNotes,
  showOverheadRoles,
  toggleOverheadRoles,
}: {
  monthlySalaries: MonthlySalariesFindByDateResponse;
  salariesById: { [x: string]: TeamMemberMonthlySalary[] };
  teamMembers: TeamMember[];
  refetchSalaries: () => void;
  refetchTeamMembers: () => void;
  teamMemberNotes: { teamMemberId: string; note: string }[];
  showOverheadRoles: boolean;
  toggleOverheadRoles: () => void;
}) => {
  const [showTeamMemberSalaries, setShowTeamMemberSalaries] = useState<boolean>(
    LocalStorage.loadState('showTeamMemberSalaries') || false
  );
  const [editTeamMember, setEditTeamMember] = useState<TeamMember | null>(null);
  const { performRequest: updateTeamMember } = useAPIRequest(
    TeamMemberResource.updateById
  );

  return (
    <Wrapper>
      {editTeamMember && (
        // @ts-ignore
        <EditForm
          key={`edit-form-${editTeamMember?.id}`}
          {...teamMemberEditConfig}
          instance={editTeamMember}
          defaultOpen
          handleEditInstance={async (data) => {
            await updateTeamMember(editTeamMember.id, data);
          }}
          onClose={() => {
            setEditTeamMember(null);
            refetchSalaries();
            refetchTeamMembers();
          }}
        />
      )}
      <div className="salaries-actions-container">
        {monthlySalaries && (
          <div>
            {isAfter(
              endOfMonth(toDateObject(monthlySalaries.monthlySalaries.month)),
              subMonths(new Date(), 1)
            ) && (
              <ShowTeamMemberSalaries
                showTeamMemberSalaries={showTeamMemberSalaries}
                toggleTeamMemberSalaries={() => {
                  LocalStorage.saveState(
                    'showTeamMemberSalaries',
                    !showTeamMemberSalaries
                  );
                  setShowTeamMemberSalaries((prevState) => !prevState);
                }}
              />
            )}
            {monthlySalaries?.monthlySalaries.locked ? (
              <>
                {isAfter(
                  endOfMonth(
                    toDateObject(monthlySalaries.monthlySalaries.month)
                  ),
                  subMonths(new Date(), 1)
                ) && (
                  <UnlockAction
                    monthlySalaries={monthlySalaries}
                    onUnlock={() => refetchSalaries()}
                  />
                )}
                <Download
                  month={monthlySalaries.monthlySalaries.month}
                  monthlySalariesId={monthlySalaries.monthlySalaries.id}
                />
              </>
            ) : (
              <LockAction
                monthlySalaries={monthlySalaries}
                onLock={() => refetchSalaries()}
              />
            )}
            <ShowOverheadRolesAction
              showOverheadRoles={showOverheadRoles}
              toggleOverheadRoles={toggleOverheadRoles}
            />
          </div>
        )}
        <div className="team-member-salaries-container">
          {showTeamMemberSalaries &&
            monthlySalaries?.monthlySalaries &&
            teamMembers.map((teamMember: TeamMember) => {
              return (
                <TeamMemberSalaryTable
                  key={`${teamMember.id}-${monthlySalaries.monthlySalaries.id}`}
                  teamMember={teamMember}
                  teamMemberSalary={salariesById[teamMember.id][0]}
                  refetchSalaries={refetchSalaries}
                  refetchTeamMembers={refetchTeamMembers}
                  monthlySalariesId={monthlySalaries?.monthlySalaries.id}
                  locked={monthlySalaries?.monthlySalaries.locked}
                  onTeamMemberClick={() => {
                    setEditTeamMember(teamMember);
                  }}
                  teamMemberNote={
                    teamMemberNotes.find(
                      (note) => note.teamMemberId === teamMember.id
                    )?.note
                  }
                />
              );
            })}
        </div>
        {showTeamMemberSalaries && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '15px',
            }}
          >
            <TotalCashLabel
              monthlySalaries={monthlySalaries}
              teamMembers={teamMembers}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default TeamMemberSalariesList;
