import React, { RefObject } from 'react';
import { CompanyInvoiceData } from '../types';
import DeleteAction from '../../../components/actions/DeleteAction';
import CompanyInvoiceDataResource from '../../../api/resources/clients/CompanyInvoiceDataResource';

type Props = {
  selected: CompanyInvoiceData;
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const handleDelete = async () => {
    await CompanyInvoiceDataResource.deleteById(selected.id);
    await reload();
  };

  return (
    <DeleteAction
      confirmText="Are you sure you want to delete the entry?"
      callback={handleDelete}
      mountNode={mountNode}
    />
  );
};

export default Delete;
