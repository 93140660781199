import React, { RefObject, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { ProjectItem } from '../../projects/types';
import { useContainer } from 'unstated-next';
import { ProposalEntriesContainer } from '../Table';
import ProposalEntryResource from '../../../api/resources/proposals/ProposalEntryResource';
import { ProjectItemsProposalContext } from './ProjectItemsProposalTable';

type Props = {
  selected: ProjectItem[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const AddItemToProposal = (props: Props) => {
  const { selected, reload } = props;

  const proposal = useContext(ProjectItemsProposalContext);
  const invoiceEntriesContainer = useContainer(ProposalEntriesContainer);

  const handleCreateNewInstance = async (data: { percent: number }) => {
    if (proposal?.id) {
      const newEntryData = {
        ...data,
        proposalId: proposal?.id,
        projectItemIds: selected.map((item) => item.id),
      };
      await ProposalEntryResource.createFromProjectItems(newEntryData);
      await reload();
      await invoiceEntriesContainer.list();
    }
  };

  return (
    <Button
      positive
      size="large"
      content="Add to proposal"
      disabled={selected.length < 1}
      onClick={(event) => {
        event.preventDefault();
        handleCreateNewInstance({ percent: 100 });
      }}
    />
  );
};

export default AddItemToProposal;
