import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import React from 'react';

const StyledButton = styled(Button)`
  &.ui.basic.button,
  &.ui.basic.button:focus,
  &.ui.basic.button:hover {
    box-shadow: none !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`;

const ShowTeamMemberSalaries = ({
  showTeamMemberSalaries,
  toggleTeamMemberSalaries,
}: {
  showTeamMemberSalaries: boolean;
  toggleTeamMemberSalaries: () => void;
}) => {
  return showTeamMemberSalaries ? (
    <StyledButton
      basic
      icon="caret up"
      size="big"
      inverted
      title="Hide team member salaries"
      onClick={toggleTeamMemberSalaries}
    />
  ) : (
    <StyledButton
      basic
      icon="caret down"
      size="big"
      inverted
      title="Show team member salaries"
      onClick={toggleTeamMemberSalaries}
    />
  );
};

export default ShowTeamMemberSalaries;
