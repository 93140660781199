import React from 'react';
import ResourceTable from '../../../containers/resourceTable/ResourceTable';
import { createContainer } from 'unstated-next';
import { FULL_ACCESS_PERMISSION } from '../../team/permission';
import DeleteInvoiceDataEntry from './DeleteInvoiceDataEntry';
import CompanyInvoiceDataFormFields from './CompanyInvoiceDataFormFields';
import useNestedCRUDResource from '../../../hooks/useNestedCrudResource';
import { Id } from '../../../types/base';
import ClientInvoiceDataResource from '../../../api/resources/clients/ClientInvoiceDataResource';

type Props = {
  clientId: Id;
};

export const invoiceDataCreateInstanceConfig = {
  title: 'Create new entry',
  formFields: CompanyInvoiceDataFormFields,
  formId: 'company-invoice-data-create-form',
};

export const invoiceDataEditInstanceConfig = {
  title: 'Edit the invoice data',
  formFields: CompanyInvoiceDataFormFields,
  formId: 'company-invoice-data-edit-form',
  nameAccessor: 'name' as const,
  activateInputOnClick: true,
};

export const invoiceDataEditFormActions = [
  {
    key: 'delete',
    condition: () => true,
    component: DeleteInvoiceDataEntry,
    permission: FULL_ACCESS_PERMISSION,
  },
];

const CompanyInvoiceDataTable = (props: Props) => {
  const { clientId } = props;

  const config = [
    {
      label: 'Name',
      accessor: 'name' as const,
      key: 'name',
      orderingField: 'name',
    },
    {
      label: 'Identification Number',
      accessor: 'identificationNumber' as const,
      key: 'identificationNumber',
      orderingField: 'identificationNumber',
    },
    {
      label: 'Name (BG)',
      accessor: 'nameBG' as const,
      key: 'nameBG',
      orderingField: 'nameBG',
    },
    {
      label: 'Country (BG)',
      accessor: 'countryBG' as const,
      key: 'countryBG',
      orderingField: 'countryBG',
    },
    {
      label: 'City (BG)',
      accessor: 'cityBG' as const,
      key: 'cityBG',
      orderingField: 'cityBG',
    },
    {
      label: 'Address (BG)',
      accessor: 'addressBG' as const,
      key: 'addressBG',
      orderingField: 'addressBG',
    },
  ];

  const CompanyInvoiceDataContainer = createContainer(useNestedCRUDResource);

  return (
    <CompanyInvoiceDataContainer.Provider
      initialState={{
        resource: ClientInvoiceDataResource,
        parentId: clientId,
        searchFields: ['name'],
      }}
    >
      <ResourceTable
        addSearch={false}
        columns={config}
        newInstanceConfig={invoiceDataCreateInstanceConfig}
        editInstanceConfig={invoiceDataEditInstanceConfig}
        stateContainer={CompanyInvoiceDataContainer}
        editFormActions={invoiceDataEditFormActions}
      />
    </CompanyInvoiceDataContainer.Provider>
  );
};

export default CompanyInvoiceDataTable;
