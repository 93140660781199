import React, { RefObject } from 'react';
import { Project } from '../types';
import DeleteAction, {
  DeleteButton,
} from '../../../components/actions/DeleteAction';
import ProjectResource from '../../../api/resources/projects/ProjectResource';
import Confirm from '../../../components/dialogs/Confirm';

type Props = {
  selected: Project | Project[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const handleDelete = async () => {
    if (Array.isArray(selected)) {
      const filters = {
        where: { or: selected.map((project) => ({ id: project.id })) },
      };
      await ProjectResource.deleteAll(filters);
      await reload();
    } else {
      await ProjectResource.deleteById(selected.id);
      await reload();
    }
  };

  return Array.isArray(selected) &&
    selected.some((project) => project.hasInvoices) ? (
    <Confirm
      content="The projects cannot be deleted because some of them have invoices. Please delete the invoices first."
      trigger={DeleteButton}
    />
  ) : (
    <DeleteAction
      callback={handleDelete}
      confirmText="Are you sure you want to delete the project?"
      mountNode={mountNode}
      buttonSize={Array.isArray(selected) ? 'huge' : 'large'}
    />
  );
};

export default Delete;
