import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import groupBy from 'lodash/groupBy';
import { Id } from '../../../types/base';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectResource from '../../../api/resources/projects/ProjectResource';
import { ModalHeader, Modal, ModalContent } from '../../../components/dialogs';
import ErrorMessage from '../../../components/ErrorMessage';
import Loader from '../../../components/Loader';
import Table from '../../../components/tables/Table';
import Header from '../../../components/tables/Header';
import Row from '../../../components/tables/Row';
import HeaderCell from '../../../components/tables/HeaderCell';
import { getProjectFullName } from '../utils';
import Decimal from 'decimal.js-light';
import {
  Project,
  ProjectTimeSpent,
  ProjectTimeSpentWithRelations,
} from '../types';
import Cell from '../../../components/tables/Cell';
import ProjectTimesheetWeekResource from '../../../api/resources/timesheets/ProjectTimesheetWeekResource';
import { TimesheetEntry } from '../../timesheets/types';
import Body from '../../../components/tables/Body';
import { toDateObject } from '../../../utils/dateUtils';
import Caret from '../../../components/tables/Caret';
import { formatNumber } from '../../../utils/numberUtils';
import ProjectTimeSpentSelectPeriod from './ProjectTimeSpentSelectPeriod';
import Heading from '../../../components/layout/Heading';
import { isWithinInterval, maxTime, minTime } from 'date-fns';
import ProjectTimeSpentResource from '../../../api/resources/projects/ProjectTimeSpentResource';

type Props = {
  projectId: Id;
  onClose: () => void;
  initialPeriod?: {
    from?: string;
    to?: string;
    id: string;
  };
  onSetPeriod?: (period: { from?: string; to?: string }) => void;
  disableSelectPeriod?: boolean;
  mountNode?: RefObject<HTMLDivElement | null>;
};

const DateFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

const TeamMemberTimeSpent = (props: {
  timesheetEntries: TimesheetEntry[];
  teamMemberTotalTimeSpent: ProjectTimeSpent;
}) => {
  const { timesheetEntries, teamMemberTotalTimeSpent } = props;
  const groupedByDate = groupBy(timesheetEntries, 'date');

  return (
    <Body>
      {Object.keys(groupedByDate)
        .sort((a, b) => {
          return new Date(b).getTime() - new Date(a).getTime();
        })
        .map((date) => {
          const entries = groupedByDate[date].filter(
            (entry) => Number(entry.hours) !== 0
          );

          const hours = entries.reduce((acc, curr) => {
            return acc.add(new Decimal(curr.hours));
          }, new Decimal(0));
          const productivityHours = entries.reduce((acc, curr) => {
            return acc.add(new Decimal(curr.productivityHours ?? 0));
          }, new Decimal(0));
          const cost = entries.reduce((acc, curr) => {
            return acc.add(new Decimal(curr.cost ?? 0));
          }, new Decimal(0));

          const days = hours.div(8);

          const productivityHoursDiff = Number(
            teamMemberTotalTimeSpent.productivityHours
          )
            ? productivityHours.div(
                new Decimal(teamMemberTotalTimeSpent.productivityHours)
              )
            : new Decimal(0);

          const bonusForHours = new Decimal(teamMemberTotalTimeSpent.bonus).mul(
            productivityHoursDiff
          );

          const costDiff = Number(teamMemberTotalTimeSpent.cost)
            ? cost.div(new Decimal(teamMemberTotalTimeSpent.cost))
            : new Decimal(0);

          const percentage = new Decimal(
            teamMemberTotalTimeSpent.spentPercentage
          ).mul(costDiff);

          if (Number(hours) === 0) {
            return null;
          }
          return (
            <Row key={date}>
              <Cell style={{ paddingLeft: '2em' }} textAlign="left">
                {DateFormatter.format(toDateObject(date))}
              </Cell>
              <Cell>{percentage.toFixed(2)}%</Cell>
              <Cell>{days.toFixed(2)}d</Cell>
              <Cell>{hours.toFixed(2)}h</Cell>
              <Cell>{formatNumber(cost.toNumber())} lv</Cell>
              <Cell>{formatNumber(bonusForHours.toNumber())} lv</Cell>
            </Row>
          );
        })}
    </Body>
  );
};

const ProjectTimeSpentTable = ({
  projectId,
  onClose,
  initialPeriod,
  onSetPeriod,
  disableSelectPeriod = false,
  mountNode,
}: Props) => {
  const [expandedIndices, setExpandedIndices] = useState<Array<number>>([]);

  const [period, setPeriod] = useState<{
    from?: string;
    to?: string;
    id: string;
  } | null>(initialPeriod || null);
  const [projectForPeriod, setProjectForPeriod] = useState<Project | null>(
    null
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  const {
    loading,
    data: projectResponse,
    performRequest: getProject,
    error,
  } = useAPIRequest(ProjectResource.findById);

  const {
    loading: loadingTimesheets,
    data: timesheetsResponse,
    performRequest: getTimesheets,
    error: timesheetsError,
  } = useAPIRequest(ProjectTimesheetWeekResource.list);

  const { performRequest: fetchProjectTimeSpent } = useAPIRequest(
    ProjectTimeSpentResource.findByPeriod
  );

  useEffect(() => {
    if (period !== null) {
      fetchProjectTimeSpent({ ...period, projectId }).then((response) => {
        setProjectForPeriod(response.data);
      });
    }
  }, [period, fetchProjectTimeSpent, projectId]);

  useEffect(() => {
    getProject(projectId, {
      filter: {
        include: [
          {
            relation: 'projectTimeSpents',
            scope: {
              include: [
                {
                  relation: 'teamMember',
                  scope: {
                    fields: {
                      id: true,
                      name: true,
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    });
  }, [projectId, getProject]);

  const project = projectForPeriod || projectResponse?.data;

  useEffect(() => {
    getTimesheets({
      filter: {
        where: {
          projectId,
        },
        include: [{ relation: 'timesheetEntries' }],
      },
    });
  }, [getTimesheets, projectId]);

  const timesheetsPerTeamMember = useMemo(() => {
    if (timesheetsResponse?.data) {
      const timesheetsMap: { [x: string]: TimesheetEntry[] } = {};
      for (let week of timesheetsResponse?.data) {
        if (
          !Object.prototype.hasOwnProperty.call(
            timesheetsMap,
            week.teamMemberId
          )
        ) {
          timesheetsMap[week.teamMemberId] = [];
        }
        const entries =
          (period
            ? week.timesheetEntries?.filter((entry: TimesheetEntry) =>
                isWithinInterval(toDateObject(entry.date), {
                  start: period.from ? toDateObject(period.from) : minTime,
                  end: period.to ? toDateObject(period.to) : maxTime,
                })
              )
            : week.timesheetEntries) || [];
        timesheetsMap[week.teamMemberId].push(...entries);
      }
      return timesheetsMap;
    }
    return {};
  }, [timesheetsResponse, period]);

  return (
    <Modal closeIcon defaultOpen onClose={onClose} mountNode={mountNode}>
      <ModalHeader>
        <>
          <Icon name="clock" size="small" />
          Project Time Spent
        </>
      </ModalHeader>
      <ModalContent style={{ padding: '.5rem' }}>
        <ErrorMessage error={error || timesheetsError} />
        <Loader active={loading || loadingTimesheets} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
          }}
          ref={containerRef}
        >
          <div style={{ marginBottom: '15px' }}>
            <Heading as="h5" style={{ marginLeft: '8px' }}>
              Current Period:{' '}
              {period && (period.from || period.to)
                ? `${
                    period.from
                      ? DateFormatter.format(toDateObject(period.from))
                      : 'Project start'
                  } - ${
                    period.to
                      ? DateFormatter.format(toDateObject(period.to))
                      : 'ongoing'
                  }`
                : 'All time'}
            </Heading>
            {project && !disableSelectPeriod && (
              <ProjectTimeSpentSelectPeriod
                mountNode={containerRef}
                setPeriod={(newPeriod) => {
                  setPeriod(newPeriod);
                  onSetPeriod?.(newPeriod);
                }}
                projectStartDate={project.startDate}
                period={period}
              />
            )}
          </div>
          {project && (
            <Table style={{ marginTop: '0px', textAlign: 'center' }}>
              <Header>
                <Row>
                  <HeaderCell textAlign="left" collapsing>
                    <Caret
                      index={0}
                      expandedIndices={expandedIndices}
                      setExpandedIndices={setExpandedIndices}
                    />
                    {getProjectFullName(project)}
                  </HeaderCell>
                  <HeaderCell collapsing>{`${new Decimal(
                    project.budgetSpentPercentage ?? 0
                  ).toFixed(2)}%`}</HeaderCell>
                  <HeaderCell collapsing>{`${new Decimal(project.hours ?? 0)
                    .div(8)
                    .toDecimalPlaces(2)
                    .toFixed(2)}d`}</HeaderCell>
                  <HeaderCell collapsing>{`${new Decimal(
                    project.hours ?? 0
                  ).toFixed(2)}h`}</HeaderCell>
                  <HeaderCell collapsing>{`Cost: ${formatNumber(
                    new Decimal(project.cost ?? 0).toNumber()
                  )} lv`}</HeaderCell>
                  <HeaderCell collapsing>{`Bonus: ${formatNumber(
                    new Decimal(project.bonus ?? 0).toNumber()
                  )} lv`}</HeaderCell>
                </Row>
              </Header>
              {project?.projectTimeSpents
                ?.filter((item: ProjectTimeSpentWithRelations) => {
                  return !new Decimal(item.hours).equals(0);
                })
                .map((item: ProjectTimeSpentWithRelations, idx: number) => {
                  return (
                    <React.Fragment key={item.id}>
                      <Body>
                        <Row>
                          <Cell textAlign="left">
                            <Caret
                              index={idx + 1}
                              expandedIndices={expandedIndices}
                              setExpandedIndices={setExpandedIndices}
                            />
                            {item.teamMember.name}
                          </Cell>
                          <Cell>{`${new Decimal(item.spentPercentage).toFixed(
                            2
                          )}%`}</Cell>
                          <Cell>{`${new Decimal(item.days).toFixed(2)}d`}</Cell>
                          <Cell>{`${new Decimal(item.hours).toFixed(
                            2
                          )}h`}</Cell>
                          <Cell>{`${formatNumber(
                            new Decimal(item.cost).toNumber()
                          )} lv`}</Cell>
                          <Cell>{`${formatNumber(
                            new Decimal(item.bonus).toNumber()
                          )} lv`}</Cell>
                        </Row>
                      </Body>
                      {expandedIndices.includes(idx + 1) &&
                        timesheetsPerTeamMember[item.teamMember.id] && (
                          <TeamMemberTimeSpent
                            timesheetEntries={
                              timesheetsPerTeamMember[item.teamMember.id]
                            }
                            teamMemberTotalTimeSpent={item}
                          />
                        )}
                    </React.Fragment>
                  );
                })}
            </Table>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ProjectTimeSpentTable;
