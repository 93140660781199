import React, {
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, Input, InputProps, Ref } from 'semantic-ui-react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import get from 'lodash/get';
import {
  ErrorLabel,
  StyledFormField,
} from '../../../components/forms/fields/Fields.styled';
import { floor10 } from '../../../utils/numberUtils';
import styled from 'styled-components';
import Decimal from 'decimal.js-light';
import calculateTextSize from '../../../utils/calculateTextSize';
import { set } from 'lodash';

type Props = {
  budget: number;
  label: string;
  name: string;
  bonusFieldName: string;
  percentage: number;
  useRounding?: boolean;
  placeholder?: string;
  step?: number;
  rules?: ValidationRules;
  activateInputOnClick?: boolean;
  submitHandler: (data: any) => any;
  inline?: boolean;
  fluid?: boolean;
} & React.ComponentProps<typeof Form.Field> &
  UseFormMethods;

const StyledInput = styled(
  forwardRef<HTMLDivElement, InputProps>(
    ({ unit, unitPosition, ...rest }, ref) => (
      <Ref innerRef={ref}>
        <Input {...rest} />
      </Ref>
    )
  )
)`
  &.ui.input::after {
    ${(props) => `
      content: '${props.value ? props.unit : ''}';
      position: absolute;
      top: .47em;
      font-size: 0.96em;
      font-weight: 800;
      left: ${
        props.unit !== '%'
          ? `${calculateTextSize(props.value.toString()).width + 17}px`
          : `${calculateTextSize(props.value.toString()).width + 17 || 10}px`
      };
    `}
`;

const BonusInputField = (props: Props) => {
  const {
    label,
    useRounding = false,
    budget,
    percentage,
    register,
    step,
    activateInputOnClick,
    rules = {},
    errors,
    setError,
    clearErrors,
    setValue,
    watch,
    submitHandler,
    trigger,
    getValues,
    width,
    name,
    bonusFieldName,
    disabled,
  } = props;

  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldError = get(errors, name);

  const value =
    typeof watch(name) === 'number'
      ? watch(name)
      : watch(name)
      ? +watch(name)
      : '';

  useEffect(() => {
    register(name, rules);
  }, [register, name, rules]);

  useEffect(() => {
    setValue(name, value);
  }, [setValue, value, name]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (e?.target.value) {
      setValue(name, +e.target.value);
      clearErrors(name);
    } else {
      setValue(name, '');
    }
  };

  const handleBlur = async () => {
    setFocused(false);
    if (activateInputOnClick) {
      const valid = await trigger(name);

      if (value < 0 || value > 100) {
        setError(name, {
          type: 'manual',
          message: 'The value should be between 0 and 100',
        });
        if (inputRef.current && inputRef.current.firstChild) {
          const input = inputRef.current.firstChild as HTMLInputElement;
          input.focus();
        }
        return;
      }
      let bonusValue = new Decimal(value)
        .div(100)
        .mul(new Decimal(budget))
        .toDecimalPlaces(2)
        .toNumber();
      if (useRounding) {
        bonusValue = floor10(bonusValue, 1);
      }

      setValue(name, bonusValue);
      if (valid) {
        const valuesToSubmit = set({ ...getValues() }, bonusFieldName, value);
        submitHandler(valuesToSubmit);
      }
    }
  };

  const handleKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (activateInputOnClick) {
        const valid = await trigger(name);
        if (valid) {
          if (inputRef.current && inputRef.current.firstChild) {
            const input = inputRef.current.firstChild as HTMLInputElement;
            input.blur();
          }
        }
      }
    }
  };

  const handleFocus = async (e: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    setValue(name, percentage);
  };

  return (
    <StyledFormField
      inline
      activateInputOnClick={true}
      error={!!fieldError}
      required={
        typeof rules.required === 'object'
          ? !!rules?.required?.value
          : !!rules?.required
      }
      width={width}
    >
      <label>{label}</label>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StyledInput
          ref={inputRef}
          name={name}
          type="number"
          step={step}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          onKeyPress={handleKeyPress}
          unit={focused ? '%' : ''}
          disabled={disabled}
        />
        {fieldError && (
          <ErrorLabel pointing="above">{fieldError.message}</ErrorLabel>
        )}
      </div>
    </StyledFormField>
  );
};

export default BonusInputField;
