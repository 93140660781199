import React, { useState } from 'react';
import CalendarDateRangeController from './dateRangeController/CalendarDateRangeController';
import CalendarContainer from './CalendarContainer';
import { BaseEvent, BaseMilestone, CalendarDataContainerProps } from './types';
import { Id } from '../../types/base';

type Props<
  TopCalendarResource extends { id: Id },
  TopCalendarEvent extends BaseEvent,
  TopCalendarMilestone extends BaseMilestone,
  MiddleCalendarResource extends { id: Id },
  MiddleCalendarEvent extends BaseEvent,
  MiddleCalendarMilestone extends BaseMilestone,
  BottomCalendarResource extends { id: Id },
  BottomCalendarEvent extends BaseEvent,
  BottomCalendarMilestone extends BaseMilestone
> = {
  showOccupationRate?: boolean;
  topCalendar: Omit<
    CalendarDataContainerProps<
      TopCalendarResource,
      TopCalendarEvent,
      TopCalendarMilestone
    >,
    'startDate' | 'endDate' | 'days'
  >;
  middleCalendar?: Omit<
    CalendarDataContainerProps<
      MiddleCalendarResource,
      MiddleCalendarEvent,
      MiddleCalendarMilestone
    >,
    'startDate' | 'endDate' | 'days'
  >;
  bottomCalendar?: Omit<
    CalendarDataContainerProps<
      BottomCalendarResource,
      BottomCalendarEvent,
      BottomCalendarMilestone
    >,
    'startDate' | 'endDate' | 'days'
  >;
  defaultStartDate?: string;
  onDatesChange?: (start: Date, end: Date) => void;
};

const Calendar = <
  TopCalendarResource extends { id: Id },
  TopCalendarEvent extends BaseEvent,
  TopCalendarMilestone extends BaseMilestone,
  MiddleCalendarResource extends { id: Id },
  MiddleCalendarEvent extends BaseEvent,
  MiddleCalendarMilestone extends BaseMilestone,
  BottomCalendarResource extends { id: Id },
  BottomCalendarEvent extends BaseEvent,
  BottomCalendarMilestone extends BaseMilestone
>(
  props: Props<
    TopCalendarResource,
    TopCalendarEvent,
    TopCalendarMilestone,
    MiddleCalendarResource,
    MiddleCalendarEvent,
    MiddleCalendarMilestone,
    BottomCalendarResource,
    BottomCalendarEvent,
    BottomCalendarMilestone
  >
) => {
  const {
    defaultStartDate,
    topCalendar,
    bottomCalendar,
    middleCalendar,
  } = props;
  const [days, setDays] = useState<Date[]>([]);

  return (
    <>
      <CalendarDateRangeController
        onDatesChange={(dates) => {
          setDays(dates);
          if (dates.length) {
            props.onDatesChange?.(days[0], days[days.length - 1]);
          }
        }}
        defaultStartDate={defaultStartDate}
        id={topCalendar.calendarId}
      />
      {days.length && (
        <>
          <CalendarContainer
            startDate={days[0]}
            endDate={days[days.length - 1]}
            days={days}
            showOccupationRate={props.showOccupationRate}
            {...topCalendar}
            bottomDivider={Boolean(bottomCalendar)}
          />
          {middleCalendar && (
            <CalendarContainer
              startDate={days[0]}
              endDate={days[days.length - 1]}
              days={days}
              hideHeader
              {...middleCalendar}
            />
          )}
          {bottomCalendar && (
            <CalendarContainer
              startDate={days[0]}
              endDate={days[days.length - 1]}
              days={days}
              hideHeader
              {...bottomCalendar}
            />
          )}
        </>
      )}
    </>
  );
};

export default Calendar;
