import React from 'react';
import { getCurrencyUnit, PROJECT_ITEM_TYPES } from '../../projects/constants';
import { ProposalEntry, ProposalWithRelations } from '../types';
import { formatBudgetCurrency } from '../../projects/projectItems/TableFooter';
import { roundToTwo } from '../../../utils/numberUtils';
import { FULL_ACCESS_PERMISSION } from '../../team/permission';
import Delete from './DeleteEntry';
import ResourceTable from '../../../containers/resourceTable/ResourceTable';
import TableFooter from './TableFooter';
import { ProposalEntriesContainer } from '../Table';
import ProposalEntryFormFields from './ProposalEntryFormFields';

type Props = {
  proposal: ProposalWithRelations;
  onReload?: () => void;
};

export const ProposalContext = React.createContext<{
  currency: string;
  proposal: ProposalWithRelations | null;
}>({ currency: 'lv', proposal: null });

const Table = (props: Props) => {
  const { proposal } = props;
  const proposalCurrenry = proposal.currency;

  const config = [
    {
      label: 'Ref',
      accessor: (proposalEntry: ProposalEntry) =>
        PROJECT_ITEM_TYPES.find(
          (item) => item.value === proposalEntry.projectItem?.type
        )?.text || 'Sum',
      key: 'ref',
      orderingField: 'projectItemId',
    },
    {
      label: 'Description',
      accessor: 'description' as const,
      key: 'description',
      orderingField: 'description',
      editable: {
        component: {
          type: 'select' as const,
          props: {
            allowAdditions: true,
            search: true,
            additionLabel: 'Description: ',
            options: (item: ProposalEntry) => {
              const options = [];
              if (item.description) {
                options.push({
                  text: item.description,
                  value: item.description,
                  key: item.description,
                });
              }

              if (
                item.projectItem?.name &&
                item.projectItem?.name !== item.description
              ) {
                options.push({
                  text: item.projectItem?.name,
                  value: item.projectItem?.name,
                  key: item.projectItem?.name,
                });
              }
              return options;
            },
          },
        },
        valueAccessor: 'description' as const,
      },
      cellWidth: '5' as const,
    },
    {
      label: 'Description (BG)',
      accessor: 'descriptionBG' as const,
      key: 'descriptionBG',
      orderingField: 'descriptionBG',
      editable: {
        component: {
          type: 'text' as const,
        },
        valueAccessor: 'descriptionBG' as const,
      },
      cellWidth: '5' as const,
    },
    {
      label: "Q'ty",
      accessor: 'quantity' as const,
      key: 'quantity',
      orderingField: 'quantity',
      editable: {
        component: {
          type: 'number' as const,
          props: {
            rules: {
              min: { value: 0, message: 'Negative numbers are not allowed' },
            },
          },
        },
        valueAccessor: 'quantity' as const,
      },
    },
    {
      label: 'Unit Cost',
      accessor: 'unitCost' as const,
      key: 'unitCost',
      orderingField: 'unitCost',
      editable: {
        component: {
          type: 'number' as const,
          props: {
            unit: getCurrencyUnit(proposalCurrenry),
            unitPosition:
              getCurrencyUnit(proposalCurrenry) === 'lv' ? 'right' : 'left',
          },
        },
        valueAccessor: 'unitCost' as const,
      },
    },
    {
      label: 'Total',
      accessor: (proposalEntry: ProposalEntry) =>
        formatBudgetCurrency(
          getCurrencyUnit(proposalCurrenry),
          roundToTwo(+(proposalEntry?.total || 0))
        ),
      key: 'total',
      orderingField: 'total',
    },
  ];

  const createInstanceConfig = {
    title: 'Create new proposal entry',
    formFields: ProposalEntryFormFields,
    formId: 'proposal-entry-create-form',
    buttonSize: 'large' as const,
  };

  return (
    <ProposalContext.Provider value={{ currency: proposalCurrenry, proposal }}>
      <ResourceTable
        draggableSort
        tableId="invoice-entries"
        addSearch={false}
        footerComponent={TableFooter}
        columns={config}
        newInstanceConfig={createInstanceConfig}
        stateContainer={ProposalEntriesContainer}
        bulkActions={[
          {
            key: 'delete',
            component: Delete,
            permission: FULL_ACCESS_PERMISSION,
            showAsDisabled: true,
          },
        ]}
      />
    </ProposalContext.Provider>
  );
};

export default Table;
