import React, { SyntheticEvent, useContext } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import clsx from 'clsx';

type Props = {
  appliedFilter: any;
  onChange: (filters: any) => void;
};

const archivedFilterOptions = [
  { key: 'active', value: 'active', text: 'Active' },
  { key: 'archived', value: 'archived', text: 'Archived' },
  { key: 'all', value: 'all', text: 'All' },
];

const Filters = (props: Props) => {
  const { appliedFilter, onChange } = props;
  const { theme } = useContext(ThemeContext);

  const handleChange = (e: SyntheticEvent, { value }: DropdownProps) => {
    switch (value) {
      case 'active':
        onChange({ where: { archived: false } });
        return;
      case 'archived':
        onChange({ where: { archived: true } });
        return;
      default:
        onChange({ where: {} });
    }
  };

  const getValue = () => {
    if (appliedFilter?.where?.archived === true) {
      return 'archived';
    }

    if (appliedFilter?.where?.archived === false) {
      return 'active';
    }

    return 'all';
  };

  return (
    <Dropdown
      className={clsx('secondary', theme === themes.dark && 'inverted')}
      style={{ minWidth: '120px' }}
      fluid
      selection
      options={archivedFilterOptions}
      value={getValue()}
      onChange={handleChange}
    />
  );
};

export default Filters;
