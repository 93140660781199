import { MonthlySalariesFindByDateResponse } from '../types';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import useAPIRequest from '../../../hooks/useAPIRequest';
import MonthlySalariesResource from '../../../api/resources/salaries/MonthlySalariesResource';
import { Confirm } from '../../../components/dialogs';

const StyledLockButton = styled(Button)`
  &.ui.basic.button,
  &.ui.basic.button:focus,
  &.ui.basic.button:hover {
    box-shadow: none !important;
  }
`;

const UnlockAction = ({
  monthlySalaries,
  onUnlock,
}: {
  monthlySalaries: MonthlySalariesFindByDateResponse;
  onUnlock: () => void;
}) => {
  const { performRequest: unlockSalaries } = useAPIRequest(
    MonthlySalariesResource.unlock
  );

  const handleSubmit = async () => {
    try {
      await unlockSalaries(monthlySalaries.monthlySalaries.id);
      onUnlock();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Confirm
      content={'Are you sure you want to unlock the salaries for this month?'}
      trigger={
        <StyledLockButton
          basic
          icon="lock"
          size="massive"
          inverted
          title="Unlock the salaries"
        />
      }
      onSubmit={handleSubmit}
    />
  );
};

export default UnlockAction;
