import ResourceTable, {
  ColumnConfig,
} from '../../containers/resourceTable/ResourceTable';
import { Project } from '../projects/types';
import { getProjectFullName } from '../projects/utils';
import { CLIENTS_PERMISSION, FULL_ACCESS_PERMISSION } from '../team/permission';
import DateFormatter from '../../utils/dateUtils';
import { PROJECT_STATUS_OPTIONS } from '../projects/constants';
import React from 'react';
import { CurrentProjectsContainer } from './ProposalsTab';
import AddProposalToProject from './actions/AddProposalToProject';

const CurrentProjectsTable = () => {
  const config: ColumnConfig<Project>[] = [
    {
      label: 'Name',
      accessor: (project: Project) => getProjectFullName(project),
      orderingField: 'ref',
      key: 'name',
    },
    {
      label: 'Client',
      accessor: (project: Project) => project.client.name,
      orderingField: 'clientId',
      key: 'client',
      stopPropagation: true,
      permission: CLIENTS_PERMISSION,
    },
    {
      label: 'Status',
      accessor: (project: Project) =>
        PROJECT_STATUS_OPTIONS.find((status) => status.value === project.status)
          ?.text,
      orderingField: 'status',
      key: 'status',
    },
    {
      label: 'Start Date',
      accessor: (project: Project) =>
        DateFormatter.format(new Date(project.startDate)),
      orderingField: 'startDate',
      key: 'startDate',
    },
    {
      label: 'Deadline',
      accessor: (project: Project) =>
        project.deadline
          ? DateFormatter.format(new Date(project.deadline))
          : '',
      orderingField: 'deadline',
      key: 'deadline',
    },
    {
      label: 'Currency',
      accessor: 'currency',
      key: 'currency',
      orderingField: 'currency',
      cellWidth: '1',
    },
  ];

  return (
    <ResourceTable
      columns={config}
      addSearch={false}
      stateContainer={CurrentProjectsContainer}
      bulkActions={[
        {
          key: 'add-proposal',
          condition: () => true,
          component: AddProposalToProject,
          permission: FULL_ACCESS_PERMISSION,
          maxSelection: 1,
        },
      ]}
    />
  );
};

export default CurrentProjectsTable;
