import BaseCRUDResource from '../../BaseCRUDResource';
import Api from '../../Api';

class ProjectTimeSpentResource extends BaseCRUDResource {
  objectIdentifier = 'project-time-spent';

  findByPeriod = async (filter: {
    from?: string;
    to?: string;
    projectId: string;
  }) => {
    return await Api.client.get('project-time-spents-for-period', {
      params: { filter: filter },
    });
  };
}

export default new ProjectTimeSpentResource();
