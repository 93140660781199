import Api from '../../Api';

class OccupationRateResource {
  getOccupationRate = async (from: string, to: string) => {
    return await Api.client.get('get-occupation-rate', {
      params: { from, to },
    });
  };
}

export default new OccupationRateResource();
