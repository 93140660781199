import Api from '../../Api';
import { TimesheetEntry } from '../../../pages/timesheets/types';

class TimesheetEntryResource {
  objectIdentifier = 'timesheet-entries';

  updateOrCreate = (data: TimesheetEntry | Omit<TimesheetEntry, 'id'>) => {
    return Api.client.post(`${this.objectIdentifier}/update-or-create`, data);
  };
}

export default new TimesheetEntryResource();
