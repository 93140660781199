import React, { useCallback, useEffect } from 'react';
import ResourceTable from '../../../containers/resourceTable/ResourceTable';
import { Id } from '../../../types/base';
import { ProjectItem } from '../types';
import ProjectItemFormFields, {
  getProjectSettingsItemsKey,
} from './ProjectItemFormFields';
import { useContainer } from 'unstated-next';
import { FULL_ACCESS_PERMISSION } from '../../team/permission';
import Delete from './DeleteItem';
import { roundToTwo } from '../../../utils/numberUtils';
import { getCurrencyUnit } from '../constants';
import TableFooter, { formatBudgetCurrency } from './TableFooter';
import DownloadPDFAction from './DownloadPDFAction';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import useAPIRequest from '../../../hooks/useAPIRequest';
import {
  PROJECT_ITEM_TYPES_IMAGES,
  PROJECT_ITEM_TYPES_VIDEOS,
  PROJECT_NEGATIVES_TYPES,
} from '../../settings/keys';
import { SelectOptions } from '../../../types/forms';
import { ProjectSetting } from '../../settings/types';
import {
  ProjectItemsContainer,
  ProjectTasksContainer,
} from '../ProjectItemsAndTasks';
import { format } from 'date-fns';

type Props = {
  projectId: Id;
  projectCurrency: string;
  onReload?: () => void;
  readOnly?: boolean;
};

const projectItemsSettingsKeys = [
  PROJECT_NEGATIVES_TYPES,
  PROJECT_ITEM_TYPES_VIDEOS,
  PROJECT_ITEM_TYPES_IMAGES,
];

const Table = (props: Props) => {
  const { projectCurrency, onReload, readOnly } = props;

  const { data: response, performRequest: getItemTypes } = useAPIRequest(
    ProjectSettingResource.list
  );

  useEffect(() => {
    getItemTypes({
      filter: {
        where: { or: projectItemsSettingsKeys.map((k) => ({ key: k })) },
      },
    });
  }, [getItemTypes]);

  const getOptions = (keys: string[]) => {
    const options: SelectOptions = [];

    if (response) {
      response.data.forEach((setting: ProjectSetting) => {
        if (keys.includes(setting.key)) {
          setting.value.forEach((v: string) => {
            options.push({ key: v, text: v, value: v });
          });
        }
      });
    }
    return options;
  };

  const config = [
    {
      label: 'Bonus',
      key: 'includedInBonus',
      accessor: 'includedInBonus' as const,
      orderingField: 'includedInBonus',
      editable: {
        valueAccessor: 'includedInBonus' as const,
        component: { type: 'checkbox' as const },
      },
      cellWidth: '1' as const,
    },
    {
      label: 'Name',
      accessor: 'name' as const,
      key: 'name',
      orderingField: 'name',
      editable: !readOnly
        ? {
            component: {
              type: 'select' as const,
              props: {
                options: (item: ProjectItem) =>
                  getOptions(getProjectSettingsItemsKey(item.type)),
              },
            },
            valueAccessor: 'name' as const,
          }
        : undefined,
      cellWidth: '7' as const,
      titleAccessor: (item: ProjectItem) =>
        item.createDate
          ? `Created on ${format(new Date(item.createDate), 'dd/MMM/yyyy')}`
          : '',
    },
    {
      label: 'Invoiced',
      accessor: (projectItem: ProjectItem) => (
        <span
          style={{
            color:
              parseInt(projectItem.invoicedPercentage ?? '0') > 100
                ? '#db2828'
                : parseInt(projectItem.invoicedPercentage ?? '0') === 100
                ? '#21ba45'
                : 'inherit',
          }}
        >{`${projectItem.invoicedPercentage ?? (0).toFixed(2)} %`}</span>
      ),
      key: 'invoiced',
      orderingField: null,
    },
    {
      label: '# Items',
      accessor: (projectItem: ProjectItem) => projectItem.items,
      key: 'items',
      orderingField: null,
      editable: !readOnly
        ? {
            component: {
              type: 'number' as const,
              props: {
                rules: {
                  min: {
                    value: 0,
                    message: 'Negative numbers are not allowed',
                  },
                },
              },
            },
            valueAccessor: 'items' as const,
          }
        : undefined,
    },
    {
      label: 'Cost per Item',
      accessor: (projectItem: ProjectItem) => projectItem.costPerItem,
      key: 'costPerItem',
      orderingField: 'costPerItem',
      editable: !readOnly
        ? {
            component: {
              type: 'number' as const,
              props: {
                unit: getCurrencyUnit(projectCurrency),
                unitPosition:
                  getCurrencyUnit(projectCurrency) === 'lv' ? 'right' : 'left',
                rules: {
                  min: {
                    value: 0,
                    message: 'Negative numbers are not allowed',
                  },
                },
              },
            },
            valueAccessor: 'costPerItem' as const,
          }
        : undefined,
    },
    {
      label: 'Subtotal',
      accessor: (projectItem: ProjectItem) =>
        formatBudgetCurrency(
          getCurrencyUnit(projectItem.projectCurrency),
          roundToTwo(+projectItem.items * +projectItem.costPerItem)
        ),
      key: 'subtotal',
      orderingField: null,
    },
  ];

  const createInstanceConfig = {
    title: 'Create new item',
    formFields: ProjectItemFormFields,
    formId: 'project-item-create-form',
    buttonSize: 'large' as const,
  };

  const projectTasksContainer = useContainer(ProjectTasksContainer);

  const handleReload = useCallback(async () => {
    await projectTasksContainer.list();
    if (onReload) {
      onReload();
    }
  }, [onReload, projectTasksContainer]);

  return response ? (
    <ResourceTable
      readOnly={readOnly}
      addSearch={false}
      footerComponent={TableFooter}
      footerComponentProps={{
        context: 'project',
      }}
      columns={config}
      newInstanceConfig={createInstanceConfig}
      stateContainer={ProjectItemsContainer}
      forms={[
        {
          key: 'project-items-pdf-download',
          component: DownloadPDFAction,
          permission: FULL_ACCESS_PERMISSION,
        },
      ]}
      bulkActions={[
        {
          key: 'delete',
          component: Delete,
          permission: FULL_ACCESS_PERMISSION,
          showAsDisabled: true,
        },
      ]}
      onReload={handleReload}
    />
  ) : null;
};

export default Table;
