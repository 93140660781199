import React, { useEffect, useState } from 'react';
import { Message } from 'semantic-ui-react';
import { AxiosError } from 'axios';

type Props = {
  error: AxiosError | null;
};

const ErrorMessage = ({ error }: Props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
  }, [error]);

  if (!error) {
    return null;
  }

  return visible ? (
    <Message negative onDismiss={() => setVisible(false)}>
      <Message.Header>{error.message}</Message.Header>
      <p>{error.response?.data.error.message}</p>
    </Message>
  ) : null;
};

export default ErrorMessage;
