import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import { v4 as uuidv4 } from 'uuid';
import { CheckboxField, TextAreaField } from '../../../components/forms/fields';
import { Button, Form } from 'semantic-ui-react';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import Confirm from '../../../components/dialogs/Confirm';

const NoteForm = ({
  loading,
  note,
  onSubmit,
  onDelete,
}: {
  loading: boolean;
  note?: {
    id: string;
    bg: string;
    en: string;
    defaultAddToProposal: boolean;
    isInUse?: boolean;
  };
  onSubmit: (data: {
    id: string;
    en: string;
    bg: string;
    defaultAddToProposal: boolean;
    isInUse: boolean;
  }) => Promise<any>;
  onDelete?: () => any;
}) => {
  const { theme } = useContext(ThemeContext);

  const formMethods = useForm<{
    id: string;
    bg: string;
    en: string;
    defaultAddToProposal: boolean;
  }>({
    defaultValues: {
      en: note?.en || '',
      bg: note?.bg || '',
      defaultAddToProposal: note?.defaultAddToProposal || false,
    },
  });

  return (
    <div
      style={{
        backgroundColor: theme === 'dark' ? '#1a1a1a' : '#fff',
        borderRadius: '5px',
        padding: 10,
        margin: 10,
      }}
    >
      <Form
        loading={loading}
        inverted={theme === themes.dark}
        onSubmit={formMethods.handleSubmit(async (data) => {
          await onSubmit({
            ...data,
            isInUse: note?.isInUse || false,
            id: note?.id || uuidv4(),
          });
          if (!note) {
            formMethods.reset();
          }
        })}
      >
        <TextAreaField
          label="Note (EN text)"
          name={'en'}
          submitHandler={() => {}}
          rows={2}
          rules={{
            required: {
              value: true,
              message: 'Please enter a bulgarian text for the note.',
            },
          }}
          {...formMethods}
        />{' '}
        <TextAreaField
          label="Note (BG text)"
          name={'bg'}
          submitHandler={() => {}}
          rows={2}
          rules={{
            required: {
              value: true,
              message: 'Please enter a bulgarian text for the note.',
            },
          }}
          {...formMethods}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CheckboxField
            label={'Add to proposal by default'}
            name="defaultAddToProposal"
            submitHandler={() => {}}
            {...formMethods}
          />
          <div>
            {note && (
              <Confirm
                content="Are you sure you want to delete the note?"
                trigger={
                  <Button
                    inverted={theme === themes.dark}
                    icon="trash"
                    basic
                    disabled={note.isInUse}
                    title={
                      note.isInUse
                        ? 'The note cannot be deleted because it is used already in proposals'
                        : 'Delete'
                    }
                    size="large"
                    style={{
                      verticalAlign: 'bottom',
                    }}
                  />
                }
                onSubmit={onDelete}
              />
            )}
            <Button
              inverted={theme === themes.dark}
              basic
              icon={note ? 'save' : 'plus'}
              content={note ? 'Save' : 'Add note'}
              style={{
                verticalAlign: 'text-top',
                fontSize: '1.1em',
              }}
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default NoteForm;
