import React, { useCallback, useState } from 'react';
import 'semantic-ui-less/semantic.less';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import './App.css';
import LoginPage from './pages/auth/LoginPage';
import AdminPage from './pages/admin/AdminPage';
import { Redirect } from 'react-router';
import { UserContext } from './pages/auth/UserContext';
import { LocalStorage } from './api/BrowserStorage';
import {
  GRADIENT_DEFAULT_COLOR,
  LOCAL_STORAGE_GRADIENT_KEY,
  LOCAL_STORAGE_THEME_KEY,
  ThemeContext,
  themes,
} from './contexts/theme/ThemeContext';

function App() {
  const [theme, setTheme] = useState<string>(
    LocalStorage.loadState(LOCAL_STORAGE_THEME_KEY) ?? themes.dark
  );
  const [gradientMainColor, setGradientMainColor] = useState<string>(
    LocalStorage.loadState(LOCAL_STORAGE_GRADIENT_KEY) ?? GRADIENT_DEFAULT_COLOR
  );

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => {
      if (prevTheme === themes.light) {
        LocalStorage.saveState(LOCAL_STORAGE_THEME_KEY, themes.dark);
        return themes.dark;
      }
      LocalStorage.saveState(LOCAL_STORAGE_THEME_KEY, themes.light);
      return themes.light;
    });
  }, []);

  const setGradientColor = useCallback((color) => {
    setGradientMainColor(color);
    LocalStorage.saveState(LOCAL_STORAGE_GRADIENT_KEY, color);
  }, []);

  return (
    <Router>
      <ThemeContext.Provider
        value={{
          theme,
          toggleTheme,
          gradientMainColor,
          setGradientMainColor: setGradientColor,
        }}
      >
        <UserContext.Provider>
          <Switch>
            <Route path="/admin">
              <AdminPage />
            </Route>
            <Route path="/" exact>
              <LoginPage />
            </Route>
            <Route path="*">
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            </Route>
          </Switch>
        </UserContext.Provider>
      </ThemeContext.Provider>
    </Router>
  );
}

export default App;
