import React, { useEffect, useState } from 'react';
import { TeamMember } from '../team/types';
import { Image, Label, LabelProps } from 'semantic-ui-react';
import useAPIRequest from '../../hooks/useAPIRequest';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';

type Props = {
  entity: TeamMember;
  size?: LabelProps['size'];
  color?: LabelProps['color'];
  initialsOnly?: boolean;
  profilePictureOnly?: boolean;
};

const TeamMemberAvatar = (props: Props) => {
  const { initials } = props.entity;
  const [userProfilePictureUrl, setUserProfilePictureUrl] = useState<
    string | null
  >(null);

  const { performRequest: getProfilePicture } = useAPIRequest(
    TeamMemberResource.fetchProfilePicture
  );

  useEffect(() => {
    if (!props.initialsOnly) {
      getProfilePicture(props.entity.id).then((res) => {
        if (res?.data) {
          setUserProfilePictureUrl(
            window.URL.createObjectURL(new Blob([res.data]))
          );
        }
      });
    }
  }, [getProfilePicture, props.entity.id, props.initialsOnly]);

  return userProfilePictureUrl ? (
    <Image
      src={userProfilePictureUrl}
      circular
      style={{ height: '2.5em', marginRight: '9px', display: 'inline' }}
    />
  ) : !props.profilePictureOnly ? (
    <Label
      className="team-member-avatar"
      color={props.color || 'green'}
      circular
      size={props.size}
    >
      {initials}
    </Label>
  ) : null;
};

export default TeamMemberAvatar;
