import BaseCRUDResource from '../../BaseCRUDResource';
import Api from '../../Api';
import { Invoice } from '../../../pages/invoices/types';
import { Id } from '../../../types/base';

class InvoiceResource extends BaseCRUDResource {
  objectIdentifier = 'invoices';

  list = async (filters?: any | null) => {
    let params = { ...filters };
    if (filters?.filter?.search) {
      const { search, ...rest } = filters.filter;
      params = {
        search: search,
        filter: rest,
      };
    }
    const res = await Api.client.get(this.objectIdentifier, {
      params: params,
    });
    return {
      ...res,
      data: res.data.map((invoice: Invoice) => ({
        ...invoice,
        exchangeRateToBGN: +invoice.exchangeRateToBGN,
      })),
    };
  };

  findById = async (id: Id, filters?: any) => {
    const res = await Api.client.get(this.getObjectPath(id), {
      params: filters,
    });
    return {
      ...res,
      data: { ...res.data, exchangeRateToBGN: +res.data.exchangeRateToBGN },
    };
  };

  print = async (id: Id) => {
    return Api.client.post(
      `${this.objectIdentifier}/print/${id}`,
      {},
      { responseType: 'blob' }
    );
  };

  printAndDownload = async (id: Id) => {
    return Api.client.post(
      `${this.objectIdentifier}/printAndDownload/${id}`,
      {},
      { responseType: 'blob' }
    );
  };

  download = async (invoices: Array<number>) => {
    return Api.client.post(
      `${this.objectIdentifier}/download/`,
      { invoices },
      { responseType: 'blob' }
    );
  };

  getExpectedIncomeData = async () => {
    return Api.client.get('expected-income');
  };

  getCombinedChartData = async () => {
    return Api.client.get('combined-chart');
  };
}

export default new InvoiceResource();
