import React, { createContext, useState } from 'react';
import { createContainer } from 'unstated-next';
import TabPane from '../../components/layout/TabPane';
import useCRUDResource from '../../hooks/useCRUDResource';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import Heading from '../../components/layout/Heading';
import CurrentProjectsTable from './CurrentProjectsTable';
import { projectDefaultFilters } from '../projects/Filters';
import ProposalResource from '../../api/resources/proposals/ProposalResource';
import { Id } from '../../types/base';
import ProposalsTable from './Table';

export const CurrentProjectsContainer = createContainer(useCRUDResource);
export const ProposalContainer = createContainer(useCRUDResource);

export const OpenedProposalContext = createContext<{
  proposalId: Id;
  setOpenedProposalId: (id: Id) => any;
}>({
  proposalId: '',
  setOpenedProposalId: () => {},
});

type Props = {
  filters?: { where: any };
};

const ProposalsTab = (props: Props) => {
  const [openedProposalId, setOpenedProposalId] = useState('');

  return (
    <TabPane>
      <OpenedProposalContext.Provider
        value={{
          proposalId: openedProposalId,
          setOpenedProposalId: setOpenedProposalId,
        }}
      >
        <ProposalContainer.Provider
          initialState={{
            resource: ProposalResource,
            defaultFilter: {
              include: [{ relation: 'project' }, { relation: 'client' }],
              where: {
                liveProjectsOnly: true,
              },
            },
            defaultOrder: 'sequence ASC',
            useSearch: true,
          }}
        >
          <CurrentProjectsContainer.Provider
            initialState={{
              resource: ProjectResource,
              defaultFilter: {
                where: {
                  and: [{ or: [{ status: 2 }, { status: 1 }, { status: 4 }] }],
                  ...projectDefaultFilters,
                },
                include: [
                  {
                    relation: 'client',
                    scope: {
                      fields: {
                        id: true,
                        name: true,
                      },
                    },
                  },
                ],
              },
              defaultOrder: 'ref ASC',
            }}
          >
            <ProposalsTable />
            <Heading as="h4" style={{ marginTop: 20 }}>
              Current Projects
            </Heading>
            <CurrentProjectsTable />
          </CurrentProjectsContainer.Provider>
        </ProposalContainer.Provider>
      </OpenedProposalContext.Provider>
    </TabPane>
  );
};

export default ProposalsTab;
