import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import cloneDeep from 'lodash/cloneDeep';
import useAPIRequest from '../../../hooks/useAPIRequest';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';
import { SelectOptions } from '../../../types/forms';
import { TeamMember } from '../../team/types';
import { StyledTeamMemberSelector } from '../TeamMemberSelector';
import clsx from 'clsx';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

type Props = {
  appliedFilter: any;
  onChange: (filters: any) => void;
};

const TeamMemberFilter = (props: Props) => {
  const { appliedFilter, onChange } = props;
  const { theme } = useContext(ThemeContext);

  const handleChange = (e: SyntheticEvent, { value }: DropdownProps) => {
    const filter = cloneDeep(appliedFilter);
    filter.where.and[2] = { teamMemberId: value };
    onChange(filter);
  };

  const value = useMemo(() => {
    return appliedFilter.where.and[2].teamMemberId;
  }, [appliedFilter]);

  const [options, setOptions] = useState<SelectOptions>([]);

  const {
    loading: loadingTeamMembers,
    data: teamMembersResponse,
    performRequest: getTeamMembers,
  } = useAPIRequest(TeamMemberResource.list);

  useEffect(() => {
    getTeamMembers({ filter: { where: { archived: false } } });
  }, [getTeamMembers]);

  useEffect(() => {
    const options = teamMembersResponse?.data
      ? teamMembersResponse.data.map((teamMember: TeamMember) => ({
          value: teamMember.id,
          text: teamMember.name,
          key: teamMember.id,
        }))
      : [];
    setOptions(options);
  }, [teamMembersResponse]);

  return (
    <StyledTeamMemberSelector inline>
      <Dropdown
        className={clsx('secondary', theme === themes.dark && 'inverted')}
        size="small"
        style={{ minWidth: '120px' }}
        fluid
        selection
        loading={loadingTeamMembers}
        search
        options={options}
        value={value}
        onChange={handleChange}
      />
    </StyledTeamMemberSelector>
  );
};

export default TeamMemberFilter;
