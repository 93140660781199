import React from 'react';
import { TeamMember, Versionable } from '../types';
import { Button, Icon } from 'semantic-ui-react';
import Table from '../../../components/tables/Table';
import Header from '../../../components/tables/Header';
import Row from '../../../components/tables/Row';
import HeaderCell from '../../../components/tables/HeaderCell';
import Body from '../../../components/tables/Body';
import Cell from '../../../components/tables/Cell';
import get from 'lodash/get';
import DateFormatter, { toDateObject } from '../../../utils/dateUtils';
import { Modal, ModalContent, ModalHeader } from '../../../components/dialogs';
import useAPIRequest from '../../../hooks/useAPIRequest';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/ErrorMessage';

type Props = {
  selected: TeamMember;
  reload: () => Promise<void>;
};

const CostProductivityTable = ({
  label,
  valueAccessor,
  data,
}: {
  label: string;
  valueAccessor: string;
  data: Versionable[];
}) => {
  return (
    <Table style={{ width: '40%', marginTop: '0px', marginBottom: '0px' }}>
      <Header>
        <Row>
          <HeaderCell collapsing>Start date</HeaderCell>
          <HeaderCell collapsing>End date</HeaderCell>
          <HeaderCell collapsing>{label}</HeaderCell>
        </Row>
      </Header>
      <Body>
        {data.map((item) => (
          <Row>
            <Cell>
              {item.startDate
                ? DateFormatter.format(toDateObject(item.startDate))
                : '-'}
            </Cell>
            <Cell>
              {item.endDate
                ? DateFormatter.format(toDateObject(item.endDate))
                : '-'}
            </Cell>
            <Cell>{get(item, valueAccessor)}</Cell>
          </Row>
        ))}
      </Body>
    </Table>
  );
};

const CostProductivity = (props: Props) => {
  const { selected } = props;

  const {
    loading,
    data: response,
    performRequest: getTeamMember,
    error,
  } = useAPIRequest(TeamMemberResource.findById);

  const handleOpen = () => {
    getTeamMember(selected.id, {
      filter: {
        include: [
          { relation: 'costs', scope: { order: ['version ASC'] } },
          { relation: 'productivities', scope: { order: ['version ASC'] } },
        ],
      },
    });
  };

  return (
    <Modal
      closeIcon
      onOpen={handleOpen}
      trigger={<Button size="large" content="Cost History" />}
    >
      <ModalHeader>
        <>
          <Icon name="history" size="small" />
          Cost/Productivity History
        </>
      </ModalHeader>
      <ModalContent>
        <ErrorMessage error={error} />
        <Loader active={loading} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'baseline',
          }}
        >
          {response?.data?.costs && (
            <CostProductivityTable
              label="Cost"
              valueAccessor="cost"
              data={response.data.costs}
            />
          )}
          {response?.data?.productivities && (
            <CostProductivityTable
              label="Productivity"
              valueAccessor="productivity"
              data={response.data.productivities}
            />
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CostProductivity;
