import React, { useContext } from 'react';
import {
  CalendarWeeksRow,
  DateLabelCell,
  ResourceHeaderCell,
  WeekLabel,
} from './Calendar.styled';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import OccupationRate from '../../../pages/projectSchedule/OccupationRate';

type Props = {
  numberOfDaysInWeek: number;
  dates: Array<Date>;
  calendarBodyWidth?: number;
  showOccupationRate?: boolean;
  occupationRate: number[];
};

const DateFormatter = new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: 'short',
});

const DayFormatter = new Intl.DateTimeFormat('en-GB', {
  weekday: 'short',
});

const DateFormatterLong = new Intl.DateTimeFormat('en-GB', {
  weekday: 'long',
  month: 'long',
  day: '2-digit',
  year: 'numeric',
});

const DateFormatterShort = new Intl.DateTimeFormat('bg', {
  day: 'numeric',
});

const WeekLabelFormatterShort = new Intl.DateTimeFormat('bg', {
  day: '2-digit',
  month: 'numeric',
});

const formatDate = (date: Date) => (
  <span>
    <strong>{DayFormatter.format(date)}</strong>, {DateFormatter.format(date)}
  </span>
);
const formatDateShort = (date: Date) => DateFormatterShort.format(date);
const formatWeekLabelShort = (date: Date) =>
  WeekLabelFormatterShort.format(date);

const formatDateInWeek = (date: Date) =>
  new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(date);

export const getCellWidths = (calendarWidth: number, daysAmount: number) => {
  if (calendarWidth < 750) {
    return { resourceCellWidth: '180px', dateCellWidth: 22 };
  }

  const resourceCellWidthPercentage = (100 / (daysAmount + 1)) * 2.7;
  const resourceCellWidth =
    resourceCellWidthPercentage > 5 ? `${resourceCellWidthPercentage}%` : '5%';

  let dateCellComputedWidth = calendarWidth
    ? (((100 - resourceCellWidthPercentage) / 100) * calendarWidth) / daysAmount
    : 1;

  const dateCellWidth =
    dateCellComputedWidth < 22 ? 22 : dateCellComputedWidth - 1;

  return { resourceCellWidth, dateCellWidth };
};

const CalendarHeader = ({
  numberOfDaysInWeek,
  dates,
  calendarBodyWidth,
  showOccupationRate,
  occupationRate,
}: Props) => {
  const { resourceCellWidth, dateCellWidth } = getCellWidths(
    calendarBodyWidth ?? 0,
    dates.length
  );
  const numberOfWeeks = Math.floor(dates.length / numberOfDaysInWeek);

  const dayFormatterFunction =
    dates.length > 27 || dateCellWidth < 40 ? formatDateShort : formatDate;
  const weekLabelFormatterFunction =
    dates.length <= 52 ? formatDateInWeek : formatWeekLabelShort;

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <CalendarWeeksRow
        numberOfDays={numberOfWeeks * numberOfDaysInWeek}
        resourceCellWidth={resourceCellWidth}
        dateCellSize={dateCellWidth}
      >
        <ResourceHeaderCell inverted={theme === themes.dark} />
        {[...Array(numberOfWeeks)].map((n, idx) => (
          <WeekLabel
            key={idx}
            columnStart={idx * numberOfDaysInWeek + 2}
            span={numberOfDaysInWeek}
            isLastWeek={idx === numberOfWeeks - 1}
            inverted={theme === themes.dark}
          >
            {weekLabelFormatterFunction(dates[idx * numberOfDaysInWeek])} -{' '}
            {weekLabelFormatterFunction(
              dates[idx * numberOfDaysInWeek + numberOfDaysInWeek - 1]
            )}
          </WeekLabel>
        ))}
      </CalendarWeeksRow>
      <CalendarWeeksRow
        numberOfDays={numberOfWeeks * numberOfDaysInWeek}
        resourceCellWidth={resourceCellWidth}
        dateCellSize={dateCellWidth}
      >
        <ResourceHeaderCell inverted={theme === themes.dark} />
        {dates.map((date, idx) => (
          <DateLabelCell
            key={idx}
            borderRight={idx === dates.length - 1}
            isLastDayOfWeek={
              (numberOfWeeks === 5
                ? date.getDay() === 5
                : date.getDay() === 0) && idx !== dates.length - 1
            }
            inverted={theme === themes.dark}
          >
            <div title={DateFormatterLong.format(date)}>
              {dayFormatterFunction(date)}
            </div>
          </DateLabelCell>
        ))}
      </CalendarWeeksRow>
      {showOccupationRate && (
        <OccupationRate
          occupationRate={occupationRate}
          resourceCellWidth={resourceCellWidth}
          numberOfWeeks={numberOfWeeks}
          numberOfDaysInWeek={numberOfDaysInWeek}
          dateCellWidth={dateCellWidth}
        />
      )}
    </>
  );
};

export default CalendarHeader;
