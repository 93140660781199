import Api from '../../Api';

class TeamMemberSalariesResource {
  objectIdentifier = 'team-member-monthly-salaries';

  list = async (filters?: {} | null) => {
    return await Api.client.get(this.objectIdentifier, { params: filters });
  };
}

export default new TeamMemberSalariesResource();
