import React, { RefObject, useContext } from 'react';
import { MonthlyProjectBonus } from '../types';
import { Accordion, Icon } from 'semantic-ui-react';
import { ModalContent, ModalHeader, Modal } from '../../../components/dialogs';
import { Project } from '../../projects/types';
import styled from 'styled-components';
import { TeamMember } from '../../team/types';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import Heading from '../../../components/layout/Heading';
import Segment from '../../../components/layout/Segment';
import BigChiefsEditValuesForm from './BigChiefsEditValuesForm';
import TopDogsEditValuesForm from './TopDogsEditValuesForm';

const TopDogsHeader = styled.div`
  display: flex;
  width: 100%;
  line-height: 1.28571429em;

  & > div {
    flex-basis: 33%;
  }
`;

const TopDogsModal = ({
  monthlyProjectBonusEntry,
  project,
  onClose,
  teamMembers,
  onSubmit,
  locked,
  mountNode,
  asStats,
}: {
  monthlyProjectBonusEntry: MonthlyProjectBonus;
  teamMembers: TeamMember[];
  project?: Project;
  onClose: () => void;
  onSubmit: () => void;
  locked: boolean;
  mountNode?: RefObject<HTMLDivElement | null>;
  asStats: boolean;
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Modal defaultOpen closeIcon onClose={onClose} mountNode={mountNode}>
      <ModalHeader>
        <TopDogsHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="chess queen" size="large" />
            top dogs
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {project?.name}
          </div>
        </TopDogsHeader>
      </ModalHeader>
      <ModalContent>
        <Accordion
          defaultActiveIndex={[0, 1]}
          exclusive={false}
          inverted={theme === themes.dark}
          panels={[
            {
              key: 'top-dogs',
              title: {
                content: (
                  <Heading as="h5" style={{ display: 'inline-block' }}>
                    Top Dogs
                  </Heading>
                ),
              },
              content: {
                content: (
                  <Segment style={{ padding: 0 }}>
                    <TopDogsEditValuesForm
                      monthlyProjectBonusEntry={monthlyProjectBonusEntry}
                      teamMembers={teamMembers}
                      project={project}
                      onSubmit={onSubmit}
                      locked={locked || asStats}
                    />
                  </Segment>
                ),
              },
            },
            ...(!asStats
              ? [
                  {
                    key: 'big-chiefs',
                    title: {
                      content: (
                        <Heading as="h5" style={{ display: 'inline-block' }}>
                          Big Chiefs
                        </Heading>
                      ),
                    },
                    content: {
                      content: (
                        <Segment style={{ padding: 0 }}>
                          <BigChiefsEditValuesForm
                            monthlyProjectBonusEntry={monthlyProjectBonusEntry}
                            teamMembers={teamMembers}
                            project={project}
                            onSubmit={onSubmit}
                            locked={locked}
                          />
                        </Segment>
                      ),
                    },
                  },
                ]
              : []),
          ]}
        />
      </ModalContent>
    </Modal>
  );
};

export default TopDogsModal;
