import React, { RefObject } from 'react';
import { ClientContact } from '../types';
import ContactsResource from '../../../api/resources/clients/ContactsResource';
import DeleteAction from '../../../components/actions/DeleteAction';

type Props = {
  selected: ClientContact;
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Delete = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const handleDelete = async () => {
    await ContactsResource.deleteById(selected.id);
    await reload();
  };

  return (
    <DeleteAction
      confirmText="Are you sure you want to delete the contact?"
      callback={handleDelete}
      mountNode={mountNode}
    />
  );
};

export default Delete;
