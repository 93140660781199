import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  BlobProvider,
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import isEqual from 'lodash/isEqual';
import { Button } from 'semantic-ui-react';
import openSansBold from '../../../../assets/open-sans/OpenSans-Bold.ttf';
import openSans from '../../../../assets/open-sans/OpenSans-Regular.ttf';
import logo from '../../../../assets/logoblack_notext.png';
import { ResourceScheduleEventWithRelations } from '../../../resourceSchedule/ResourceSchedule';
import { getProjectFullName } from '../../../projects/utils';
import DateFormatter, { toDateObject } from '../../../../utils/dateUtils';
import { calculateEventDays, DocsContainer } from '../DocsTable';

type Props = {
  selected: ResourceScheduleEventWithRelations[];
  reload: () => Promise<void>;
};

Font.register({ family: 'Open Sans', src: openSans });
Font.register({ family: 'Open Sans Bold', src: openSansBold });

const formatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZoneName: 'short',
});

const targetMonthFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'long',
});

const targetMonthFileNameFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: '2-digit',
});

export const PDFStyles = StyleSheet.create({
  container: {
    padding: '30px',
    fontFamily: 'Open Sans',
  },
  created: {
    marginTop: 2,
    marginBottom: 15,
  },
  table: {
    fontFamily: 'Open Sans',
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: '#d9d9db',
  },
  tableRow: { margin: 'auto', flexDirection: 'row' },
  headerRow: { backgroundColor: '#f9fafb', flexDirection: 'row' },
  warningRow: { backgroundColor: '#fffaf3' },
  negativeRow: { backgroundColor: '#fdf6f6' },
  positiveRow: { backgroundColor: '#fbfdf5' },
  tableCol: {
    width: '16%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#d9d9db',
  },
  wideTableCol: {
    width: '44%',
  },
  narrowTableCol: {
    width: '8%',
  },
  tableCell: { marginLeft: 5, marginTop: 6, marginBottom: 6, fontSize: 8 },
  boldTextCell: { fontFamily: 'Open Sans Bold' },
  positiveTextCell: { color: '#2c662d' },
  negativeTextCell: { color: '#9f3a38' },
  warningTextCell: { color: '#573a08' },
  textCreated: {
    fontSize: 10,
  },
  projectData: {
    marginTop: 5,
    marginBottom: 5,
  },
  projectDataText: {
    fontSize: 10,
    fontFamily: 'Open Sans Bold',
  },
  headerText: {
    fontSize: 10,
    fontFamily: 'Open Sans Bold',
    marginTop: 13,
    marginLeft: 5,
  },
  logo: {
    width: 40,
    marginLeft: 30,
  },
  header: {
    marginTop: 15,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
  },
});

const LeaveDocumentRow = ({
  item,
}: {
  item: ResourceScheduleEventWithRelations;
}) => {
  return (
    <View style={PDFStyles.tableRow}>
      <View style={PDFStyles.tableCol}>
        <Text style={PDFStyles.tableCell}>
          {getProjectFullName(item.project)}
        </Text>
      </View>
      <View style={{ ...PDFStyles.tableCol, ...PDFStyles.wideTableCol }}>
        <Text style={PDFStyles.tableCell}>{item.teamMember.name}</Text>
      </View>
      <View style={PDFStyles.tableCol}>
        <Text style={PDFStyles.tableCell}>
          {DateFormatter.format(toDateObject(item.start))}
        </Text>
      </View>
      <View style={PDFStyles.tableCol}>
        <Text style={PDFStyles.tableCell}>
          {DateFormatter.format(toDateObject(item.end))}
        </Text>
      </View>
      <View style={{ ...PDFStyles.tableCol, ...PDFStyles.narrowTableCol }}>
        <Text style={PDFStyles.tableCell}>
          {Number(calculateEventDays(item)).toFixed(2)}
        </Text>
      </View>
    </View>
  );
};

const LeaveDocsDocument = ({
  items,
  targetMonth,
}: {
  items: ResourceScheduleEventWithRelations[];
  targetMonth: string;
}) => {
  return (
    <Document>
      <Page>
        <View style={PDFStyles.header}>
          <Image src={logo} style={PDFStyles.logo} />
          <Text style={PDFStyles.headerText}>Holidays Monthly Report</Text>
        </View>
        <View style={PDFStyles.container}>
          <View style={PDFStyles.projectData}>
            <Text style={PDFStyles.projectDataText}>
              Target Month: {targetMonth}
            </Text>
          </View>
          <View style={PDFStyles.created}>
            <Text style={PDFStyles.textCreated}>
              Created: {formatter.format(new Date())}
            </Text>
          </View>
          <View style={PDFStyles.table}>
            <View style={{ ...PDFStyles.tableRow, ...PDFStyles.headerRow }}>
              <View style={PDFStyles.tableCol}>
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  Type
                </Text>
              </View>
              <View
                style={{ ...PDFStyles.tableCol, ...PDFStyles.wideTableCol }}
              >
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  Employee
                </Text>
              </View>
              <View style={PDFStyles.tableCol}>
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  From
                </Text>
              </View>
              <View style={PDFStyles.tableCol}>
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  To
                </Text>
              </View>
              <View
                style={{ ...PDFStyles.tableCol, ...PDFStyles.narrowTableCol }}
              >
                <Text
                  style={{ ...PDFStyles.tableCell, ...PDFStyles.boldTextCell }}
                >
                  Days
                </Text>
              </View>
            </View>
            {items.map((item) => (
              <LeaveDocumentRow key={item.id} item={item} />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  return isEqual(prevProps.selected, nextProps.selected);
}

const DownloadLink = (props: {
  blob: Blob | null;
  url: string | null;
  loading: boolean;
  filename: string;
  onClick: () => any;
}) => {
  const { loading, url, filename, onClick } = props;
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.click();
      onClick();
    }
  }, [url, onClick]);

  return loading ? (
    <span>Loading PDF...</span>
  ) : url ? (
    <a ref={ref} href={url} download={filename}>
      {' '}
      Download PDF{' '}
    </a>
  ) : null;
};

const DownloadPDFAction = React.memo((props: Props) => {
  const { selected } = props;
  const container = DocsContainer.useContainer();

  const start = container.filters?.where?.and[0].or[0].start?.between[0];
  const targetMonth = start
    ? targetMonthFormatter.format(toDateObject(start))
    : '';
  const fileNameTargetMonth = start
    ? targetMonthFileNameFormatter.format(toDateObject(start))
    : '';

  const [pdfRequested, setPDFRequested] = useState(false);
  const setNoPDFRequested = useCallback(() => setPDFRequested(false), []);

  return selected.length ? (
    <>
      {!pdfRequested && (
        <Button
          size="huge"
          content="Download PDF"
          onClick={() => setPDFRequested(true)}
        />
      )}
      {pdfRequested && (
        <BlobProvider
          document={
            <LeaveDocsDocument items={selected} targetMonth={targetMonth} />
          }
        >
          {({ blob, url, loading, error }) => (
            <DownloadLink
              blob={blob}
              url={url}
              loading={loading}
              onClick={setNoPDFRequested}
              filename={`${fileNameTargetMonth}_HOLIDAYS`}
            />
          )}
        </BlobProvider>
      )}
    </>
  ) : null;
}, areEqual);

export default DownloadPDFAction;
