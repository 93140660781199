import PercentageInputField from './PercentageInputField';
import { Form } from '../../../components/forms/Form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { MonthlyProjectBonus } from '../types';
import { TeamMember } from '../../team/types';
import { Project } from '../../projects/types';
import Decimal from 'decimal.js-light';
import useAPIRequest from '../../../hooks/useAPIRequest';
import MonthlyProjectBonusResource from '../../../api/resources/salaries/MonthlyProjectBonusResource';

type BigChiefsUpdateForm = Pick<
  MonthlyProjectBonus,
  'bigChiefsBonuses' | 'bigChiefsBonus' | 'bigChiefsBonusPercentage'
>;

const BigChiefsEditValuesForm = ({
  monthlyProjectBonusEntry,
  project,
  teamMembers,
  onSubmit,
  locked,
}: {
  monthlyProjectBonusEntry: MonthlyProjectBonus;
  teamMembers: TeamMember[];
  project?: Project;
  onSubmit: () => void;
  locked: boolean;
}) => {
  const { performRequest: updateBigChiefsBonuses } = useAPIRequest(
    MonthlyProjectBonusResource.updateBigChiefsBonuses
  );

  const formMethods = useForm<BigChiefsUpdateForm>({
    defaultValues: {
      bigChiefsBonus: monthlyProjectBonusEntry.bigChiefsBonus,
      bigChiefsBonusPercentage:
        monthlyProjectBonusEntry.bigChiefsBonusPercentage,
      bigChiefsBonuses: monthlyProjectBonusEntry.bigChiefsBonuses,
    },
  });

  const handleTotalPercentageUpdate = async (values: BigChiefsUpdateForm) => {
    const newTotalBonus = values.bigChiefsBonus;

    if (newTotalBonus) {
      const newBonuses: MonthlyProjectBonus['bigChiefsBonuses'] = {};

      for (const teamMemberId of Object.keys(
        monthlyProjectBonusEntry.bigChiefsBonuses
      )) {
        const teamMemberPercentage =
          monthlyProjectBonusEntry.bigChiefsBonuses[teamMemberId]
            .bonusPercentage;
        const teamMemberBonus = new Decimal(teamMemberPercentage)
          .mul(new Decimal(newTotalBonus))
          .div(100)
          .toDecimalPlaces(2)
          .toNumber();
        newBonuses[teamMemberId] = {
          bonusPercentage: teamMemberPercentage,
          bonus: teamMemberBonus,
        };
      }
      formMethods.setValue('bigChiefsBonuses', newBonuses);
      await updateBigChiefsBonuses(monthlyProjectBonusEntry.id, {
        ...values,
        bigChiefsBonuses: newBonuses,
      });
      onSubmit();
    }
  };

  const handleSingleTeamMemberUpdate = async (
    teamMemberId: string,
    values: BigChiefsUpdateForm
  ) => {
    const totalBonus = values.bigChiefsBonus;
    const newBonuses: MonthlyProjectBonus['bigChiefsBonuses'] = {};

    newBonuses[teamMemberId] = { ...values.bigChiefsBonuses[teamMemberId] };
    newBonuses[teamMemberId].bonus = new Decimal(
      values.bigChiefsBonuses[teamMemberId].bonus
    )
      .toDecimalPlaces(2)
      .toNumber();
    const percentageDifference =
      newBonuses[teamMemberId].bonusPercentage -
      monthlyProjectBonusEntry.bigChiefsBonuses[teamMemberId].bonusPercentage;

    const otherTeamMembers = Object.keys(
      monthlyProjectBonusEntry.bigChiefsBonuses
    ).filter((id) => id !== teamMemberId);

    for (teamMemberId of otherTeamMembers) {
      const diff = new Decimal(percentageDifference).div(
        new Decimal(otherTeamMembers.length)
      );
      const teamMemberPercentage = new Decimal(
        monthlyProjectBonusEntry.bigChiefsBonuses[teamMemberId].bonusPercentage
      )
        .sub(diff)
        .toNumber();
      const teamMemberBonus = new Decimal(teamMemberPercentage)
        .mul(new Decimal(totalBonus))
        .div(100)
        .toDecimalPlaces(2)
        .toNumber();
      newBonuses[teamMemberId] = {
        bonusPercentage: teamMemberPercentage,
        bonus: teamMemberBonus,
      };
    }

    formMethods.setValue('bigChiefsBonuses', newBonuses);
    await updateBigChiefsBonuses(monthlyProjectBonusEntry.id, {
      bigChiefsBonuses: newBonuses,
    });
    onSubmit();
  };

  return (
    <Form
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      id="big-chiefs-edit-form"
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <div style={{ flexBasis: '33%', flexGrow: 0 }} />
        <PercentageInputField
          budget={Number(project?.budgetForBonus) ?? 0}
          percentage={monthlyProjectBonusEntry.bigChiefsBonusPercentage}
          name="bigChiefsBonus"
          bonusFieldName="bigChiefsBonusPercentage"
          label={'big chiefs total bonus'}
          submitHandler={(values) => {
            handleTotalPercentageUpdate(values);
          }}
          activateInputOnClick
          useRounding
          disabled={locked}
          {...formMethods}
        />
      </div>
      {teamMembers
        .filter(
          (teamMember) =>
            monthlyProjectBonusEntry.bigChiefsBonuses?.[teamMember.id]
        )
        .map((teamMember) => {
          return (
            <div
              key={teamMember.id}
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <div style={{ flexBasis: '33%', flexGrow: 0 }} />
              <PercentageInputField
                budget={monthlyProjectBonusEntry.bigChiefsBonus}
                label={teamMember.name}
                name={`bigChiefsBonuses.${teamMember.id}.bonus`}
                bonusFieldName={`bigChiefsBonuses.${teamMember.id}.bonusPercentage`}
                percentage={
                  monthlyProjectBonusEntry.bigChiefsBonuses[teamMember.id]
                    .bonusPercentage
                }
                submitHandler={(values) => {
                  handleSingleTeamMemberUpdate(teamMember.id, values);
                }}
                activateInputOnClick
                disabled={locked}
                {...formMethods}
              />
            </div>
          );
        })}
    </Form>
  );
};

export default BigChiefsEditValuesForm;
