import React, { useContext, useEffect } from 'react';
import { Accordion, Checkbox, Form } from 'semantic-ui-react';
import {
  DateInputField,
  ColorPickerField,
  TextInputField,
  TextAreaField,
  SelectInputField,
} from '../../../components/forms/fields';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import {
  PROJECT_STAGES_BROAD_ROLES,
  PROJECT_STAGES_DEFAULT_ROLES,
  PROJECT_STAGES_LEADING_ROLE,
  PROJECT_STAGES_ROLES,
} from '../../settings/keys';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';
import { ProjectSetting } from '../../settings/types';
import ProjectScheduleEventResource from '../../../api/resources/projectSchedule/ProjectScheduleEventResource';
import DateFormatter, { toDateObject } from '../../../utils/dateUtils';
import { endOfDay, isBefore } from 'date-fns';
import FlowProjectItemsTable from './FlowProjectItemsTable';
import ProjectProjectItemResource from '../../../api/resources/projects/ProjectProjectItemResource';
import { createContainer } from 'unstated-next';
import useNestedCRUDResource from '../../../hooks/useNestedCrudResource';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';
import Heading from '../../../components/layout/Heading';
import Segment from '../../../components/layout/Segment';

export const ProjectItemsContainer = createContainer(useNestedCRUDResource);

type Props = FormFieldsComponentTypeProps;

const EventFormFields = (props: Props) => {
  const { data: response, performRequest: fetchSettings } = useAPIRequest(
    ProjectSettingResource.list
  );

  const { performRequest: fetchLastEvent } = useAPIRequest(
    ProjectScheduleEventResource.getLastByProjectId
  );

  const {
    loading: loadingTeamMembers,
    data: teamMembersResponse,
    performRequest: getTeamMembers,
  } = useAPIRequest(TeamMemberResource.list);

  useEffect(() => {
    fetchSettings({ where: { module: 'projects' } });
  }, [fetchSettings]);

  const { instance, setValue, watch, register } = props;

  const endDate = watch('end');
  const projectItemIds = watch('projectItemIds');

  const createStageProjectDeadline = instance?.event?.resource?.deadline;
  const editStageProjectDeadline = instance?.project?.deadline;

  const isOnlyFlowProject =
    instance?.event?.resource.onlyFlow || instance?.project?.onlyFlow;

  useEffect(() => {
    register('projectItemIds');
    if (isOnlyFlowProject) {
      setValue('projectItemIds', []);
    }
  }, [register, isOnlyFlowProject, setValue]);

  useEffect(() => {
    getTeamMembers({
      filter: { where: { archived: false }, order: 'name ASC' },
    }).then(() => {
      if (instance.event) {
        fetchLastEvent(instance.resourceId).then((res) => {
          if (res?.data?.teamMemberRoles) {
            Object.keys(res.data.teamMemberRoles).forEach((key) => {
              setValue(`teamMemberRoles.${key}`, res.data.teamMemberRoles[key]);
            });
          }
        });
      }
    });
  }, [getTeamMembers, fetchLastEvent, instance, setValue]);

  const teamMembersOptions =
    teamMembersResponse?.data?.map((teamMember: any) => ({
      value: teamMember.id,
      text: teamMember.name,
      key: teamMember.id,
    })) || [];

  const allRoles = response?.data?.find(
    (setting: ProjectSetting) => setting.key === PROJECT_STAGES_ROLES
  )?.value;
  const leadingRole = response?.data?.find(
    (setting: ProjectSetting) => setting.key === PROJECT_STAGES_LEADING_ROLE
  )?.value;
  const allDefaultRoles = response?.data?.find(
    (setting: ProjectSetting) => setting.key === PROJECT_STAGES_DEFAULT_ROLES
  )?.value;
  const broadRoles = response?.data?.find(
    (setting: ProjectSetting) => setting.key === PROJECT_STAGES_BROAD_ROLES
  )?.value;

  const defaultRolesWithoutBroadRoles = allDefaultRoles?.filter(
    (role: string) => !broadRoles?.includes(role)
  );
  const defaultBroadRoles = allDefaultRoles?.filter((role: string) =>
    broadRoles?.includes(role)
  );

  const { theme } = useContext(ThemeContext);

  const roleFields = leadingRole
    ? [leadingRole, ...defaultRolesWithoutBroadRoles]
    : defaultRolesWithoutBroadRoles;
  const roleFieldRows = roleFields?.length
    ? Math.ceil(roleFields.length / 2)
    : 0;

  return (
    <>
      <Form.Group>
        <TextInputField
          label="Name Template"
          name="nameTemplate"
          placeholder="Name"
          width="8"
          rules={{
            required: { value: true, message: 'Please enter a name.' },
          }}
          title={
            'Available placeholders: {#items} - number of project items, {lead} - team member(s) with lead role.'
          }
          {...props}
        />
        <ColorPickerField
          label="Color"
          width="8"
          name="color"
          defaultValue={'#b3b3b3'}
          {...props}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <DateInputField
          label="Start Date"
          name="start"
          placeholder="Start Date"
          dateOnly
          rules={{
            required: { value: true, message: 'Please enter a date.' },
          }}
          {...props}
          width="8"
        />
        <DateInputField
          width="8"
          label="End Date"
          name="end"
          placeholder="End Date"
          dateOnly
          rules={{
            required: { value: true, message: 'Please enter a date.' },
          }}
          {...props}
        />
      </Form.Group>
      {Array.from(Array(roleFieldRows).keys()).map((roleFieldGroupIndex) => {
        return (
          <Form.Group key={roleFieldGroupIndex}>
            {roleFields
              ?.filter(
                (role: string, index: number) =>
                  index === roleFieldGroupIndex * 2 ||
                  index === roleFieldGroupIndex * 2 + 1
              )
              .map((field: string, index: number) => {
                const name = allRoles?.find(
                  (role: { id: string; name: string }) => role.id === field
                )?.name;
                return (
                  <SelectInputField
                    key={field}
                    loading={loadingTeamMembers}
                    closeOnChange={true}
                    defaultValue={[]}
                    name={`teamMemberRoles.${field}`}
                    placeholder={name}
                    multiple
                    width="8"
                    label={name}
                    options={teamMembersOptions}
                    {...props}
                  />
                );
              })}
          </Form.Group>
        );
      })}
      {defaultBroadRoles?.map((roleId: string) => {
        const name = allRoles?.find(
          (role: { id: string; name: string }) => role.id === roleId
        )?.name;
        return (
          <Form.Group>
            <SelectInputField
              loading={loadingTeamMembers}
              defaultValue={[]}
              name={`teamMemberRoles.${roleId}`}
              placeholder={name}
              multiple
              width="16"
              label={name}
              options={teamMembersOptions}
              {...props}
            />
          </Form.Group>
        );
      })}
      <Form.Group>
        <TextAreaField width="16" label="Note" name="note" {...props} />
      </Form.Group>
      {!isOnlyFlowProject && (
        <ProjectItemsContainer.Provider
          initialState={{
            resource: ProjectProjectItemResource,
            parentId: instance.event?.resource?.id || instance.projectId,
            searchFields: ['name'],
            defaultOrder: [
              'type ASC',
              'createDate ASC',
              'name ASC',
              'costPerItem ASC',
            ],
          }}
        >
          <Accordion
            defaultActiveIndex={[0]}
            exclusive={false}
            inverted={theme === themes.dark}
            panels={[
              {
                key: 'project-items',
                title: {
                  content: (
                    <Heading as="h5" style={{ display: 'inline-block' }}>
                      Items
                    </Heading>
                  ),
                },
                content: {
                  content: (
                    <Segment style={{ padding: 0 }}>
                      <Checkbox
                        label="The stage has no items (for meetings, notifications or other types of stages)"
                        checked={!(projectItemIds?.length > 0)}
                        onChange={(event, data) => {
                          if (data.checked) {
                            setValue('projectItemIds', []);
                          }
                        }}
                      />
                      <FlowProjectItemsTable
                        addDefaultValues={!!instance.event}
                        projectItemIds={projectItemIds || []}
                        handleChange={(projectItemIds: string[]) => {
                          setValue('projectItemIds', projectItemIds);
                        }}
                      />
                    </Segment>
                  ),
                },
              },
            ]}
          />
        </ProjectItemsContainer.Provider>
      )}
      {endDate &&
        createStageProjectDeadline &&
        isBefore(
          endOfDay(toDateObject(createStageProjectDeadline)),
          toDateObject(endDate)
        ) && (
          <div style={{ padding: '0.5em' }}>
            Please note that creating this stage will extend the project
            deadline from{' '}
            {DateFormatter.format(toDateObject(createStageProjectDeadline))} to{' '}
            {DateFormatter.format(toDateObject(endDate))}
          </div>
        )}

      {endDate &&
        editStageProjectDeadline &&
        isBefore(
          endOfDay(toDateObject(editStageProjectDeadline)),
          toDateObject(endDate)
        ) && (
          <div style={{ padding: '0.5em' }}>
            Please note that editing this stage will extend the project deadline
            from {DateFormatter.format(toDateObject(editStageProjectDeadline))}{' '}
            to {DateFormatter.format(toDateObject(endDate))}
          </div>
        )}
    </>
  );
};

export default EventFormFields;
