import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectResource from '../../../api/resources/projects/ProjectResource';
import { TeamMember } from '../../team/types';
import Table from '../../../components/tables/Table';
import Row from '../../../components/tables/Row';
import Header from '../../../components/tables/Header';
import HeaderCell from '../../../components/tables/HeaderCell';
import { Project, ProjectItem, ProjectTimeSpent } from '../../projects/types';
import { Checkbox, Icon } from 'semantic-ui-react';
import { getProjectFullName } from '../../projects/utils';
import styled from 'styled-components';
import { GRADIENT_DEFAULT_COLOR } from '../../../contexts/theme/ThemeContext';
import tinycolor from 'tinycolor2';
import Cell from '../../../components/tables/Cell';
import { MonthlySalariesFindByDateResponse } from '../types';
import MonthlySalariesResource from '../../../api/resources/salaries/MonthlySalariesResource';
import TextInputCell from '../../../components/tables/inputCells/TextInputCell';
import MonthlyProjectBonusNotesResource from '../../../api/resources/salaries/MonthlyProjectBonusNotesResource';
import EditFormFields from '../../projects/forms/EditFormFields';
import ProjectItemsAndTasks from '../../projects/ProjectItemsAndTasks';
import EditForm from '../../../containers/resourceTable/EditForm';
import NumberInputCell from '../../../components/tables/inputCells/NumberInputCell';
import MonthlyProjectBonusResource from '../../../api/resources/salaries/MonthlyProjectBonusResource';
import TopDogsModal from './TopDogsModal';
import { TEAM_MEMBER_FREELANCE_EMPLOYMENT_TYPE } from '../../team/constants';
import ProjectTimeSpentTable from '../../projects/projectTimeSpent/ProjectTimeSpentTable';
import { v4 } from 'uuid';

const formatterFunction = new Intl.NumberFormat('bg', {
  style: 'currency',
  currency: 'BGN',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}).format;

const Wrapper = styled.div`
  background-color: #1e1e1e;
  margin-bottom: 20px;
  border-radius: 20px !important;
  overflow: auto;

  .table-container {
    border-radius: 20px !important;
    padding: 20px 20px 30px;
    overflow-x: auto;
    background: ${`linear-gradient(45deg, ${GRADIENT_DEFAULT_COLOR} 0%, ${tinycolor(
      GRADIENT_DEFAULT_COLOR
    )
      .setAlpha(tinycolor(GRADIENT_DEFAULT_COLOR).getAlpha() / 2)
      .toRgbString()} 50%, ${tinycolor(GRADIENT_DEFAULT_COLOR)
      .setAlpha(0)
      .toRgbString()} 100%)`};
  }

  .projects-salaries.ui.table.compact {
    font-size: 14px;
  }

  .projects-salaries.ui.table thead th,
  .projects-salaries.ui.table,
  .projects-salaries td {
    background-color: transparent;
    color: #fff;
  }

  .projects-salaries.ui.table thead th,
  .projects-salaries.ui.table.celled td {
    border-left: none;
    border-right: none;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .projects-salaries.ui.table {
    border: none !important;
  }

  .projects-salaries.ui.table .ui.checkbox label,
  .projects-salaries.ui.table .ui.checkbox + label,
  .projects-salaries.ui.table .ui.checkbox input:checked ~ label::after {
    color: #fff;
  }

  .projects-salaries.ui.table.ui.table.inverted tr:first-child td:focus-within,
  .projects-salaries.ui.table.ui.celled.table.inverted tr td:focus-within,
  .projects-salaries.ui.table.ui.table.inverted tr td:focus-within {
    border: none !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
`;

export const projectEditConfig = {
  title: 'Edit project',
  formFields: EditFormFields,
  formId: 'projects-edit-form-flow',
  nameAccessor: 'name' as const,
  activateInputOnClick: true,
  nestedContent: ProjectItemsAndTasks,
};

const getProjectItemsNumber = (
  project: Project & {
    projectItems: ProjectItem[];
    projectTimeSpents: ProjectTimeSpent[];
  }
) => {
  const projectItems = project.projectItems?.filter(
    (item) => item.includedInBonus
  );

  return projectItems.reduce((acc, item) => {
    return acc + +item.items;
  }, 0);
};

const ProjectsForSalariesTable = ({
  teamMembers,
  monthlySalaries,
  refetchSalaries,
  asStats = false,
}: {
  monthlySalaries: MonthlySalariesFindByDateResponse;
  teamMembers: TeamMember[];
  refetchSalaries: () => void;
  asStats?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [editProject, setEditProject] = useState<Project | null>(null);
  const [
    editProjectTimeSpentProjectId,
    setEditProjectTimeSpentProjectId,
  ] = useState<string | null>(null);

  const [editTopDogsProjectBonusId, setEditTopDogsProjectBonusId] = useState<
    string | null
  >(null);
  const [projectCoefficientRowIds, setProjectCoefficientRowIds] = useState<
    string[]
  >([]);

  const openProjectCoefficientRow = (projectId: string) => {
    setProjectCoefficientRowIds((ids) => [...ids, projectId]);
  };

  const closeProjectCoefficientRow = (projectId: string) => {
    setProjectCoefficientRowIds((ids) => ids.filter((id) => id !== projectId));
  };

  const { data: projectsResponse, performRequest: getProjects } = useAPIRequest(
    ProjectResource.getProjectsForSalaries
  );

  const { performRequest: addProjectToSalaries } = useAPIRequest(
    MonthlySalariesResource.addProjectToPerformanceComponent
  );

  const { performRequest: updateProject } = useAPIRequest(
    ProjectResource.updateById
  );

  const { performRequest: removeProjectFromSalaries } = useAPIRequest(
    MonthlySalariesResource.removeProjectFromPerformanceComponent
  );

  const { performRequest: updateTeamMemberCoefficients } = useAPIRequest(
    MonthlyProjectBonusResource.updateTeamMemberCoefficients
  );

  const { performRequest: updateProjectTimeSpentDates } = useAPIRequest(
    MonthlyProjectBonusResource.updateProjectTimeSpentsDates
  );

  const {
    performRequest: updateOrCreateMonthlyProjectBonusNotes,
  } = useAPIRequest(
    MonthlyProjectBonusNotesResource.createOrUpdateMonthlyProjectBonusNote
  );

  const fetchProjects = useCallback(() => {
    if (monthlySalaries?.monthlySalaries?.id) {
      getProjects(monthlySalaries?.monthlySalaries?.id);
    }
  }, [getProjects, monthlySalaries?.monthlySalaries?.id]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const topDogsEditProjectBonus = monthlySalaries.monthlySalaries.monthlyProjectBonuses?.find(
    (bonus) => {
      return bonus.id === editTopDogsProjectBonusId;
    }
  );
  const topDogsEditProject = projectsResponse?.data?.find(
    (project: Project) => {
      return project.id === topDogsEditProjectBonus?.projectId;
    }
  );

  const editProjectTimeSpentPeriod = monthlySalaries.monthlySalaries.monthlyProjectBonuses?.find(
    (projectBonus) => projectBonus.projectId === editProjectTimeSpentProjectId
  );

  return (
    <>
      {editProjectTimeSpentProjectId && (
        <ProjectTimeSpentTable
          mountNode={wrapperRef}
          projectId={editProjectTimeSpentProjectId}
          onClose={() => {
            setEditProjectTimeSpentProjectId(null);
          }}
          disableSelectPeriod={
            !!monthlySalaries.monthlySalaries?.locked || asStats
          }
          initialPeriod={{
            from: editProjectTimeSpentPeriod?.projectTimeSpentStartDate,
            to: editProjectTimeSpentPeriod?.projectTimeSpentEndDate,
            id: v4(),
          }}
          onSetPeriod={(period) => {
            if (editProjectTimeSpentPeriod?.id) {
              updateProjectTimeSpentDates(
                editProjectTimeSpentPeriod.id,
                period
              ).then(() => {
                refetchSalaries();
              });
            }
          }}
        />
      )}
      {editProject && (
        // @ts-ignore
        <EditForm
          mountNode={wrapperRef}
          key={`edit-form-${editProject?.id}`}
          {...projectEditConfig}
          instance={editProject}
          defaultOpen
          handleEditInstance={async (data) => {
            await updateProject(editProject.id, data);
          }}
          onClose={() => {
            setEditProject(null);
            refetchSalaries();
            fetchProjects();
          }}
        />
      )}
      {topDogsEditProjectBonus && (
        <TopDogsModal
          mountNode={wrapperRef}
          teamMembers={teamMembers}
          project={topDogsEditProject}
          monthlyProjectBonusEntry={topDogsEditProjectBonus}
          onClose={() => setEditTopDogsProjectBonusId(null)}
          onSubmit={refetchSalaries}
          locked={monthlySalaries.monthlySalaries.locked}
          asStats={asStats}
        />
      )}
      <Wrapper ref={wrapperRef}>
        <div className="table-container">
          <Table stackable className="projects-salaries" inverted={true}>
            <Header>
              <Row>
                <HeaderCell collapsing>#</HeaderCell>
                <HeaderCell collapsing />
                <HeaderCell collapsing>client</HeaderCell>
                <HeaderCell width="4">project</HeaderCell>
                <HeaderCell width="3"> top dogs </HeaderCell>
                <HeaderCell collapsing>%</HeaderCell>
                <HeaderCell width="2">notes</HeaderCell>
                <HeaderCell collapsing style={{ padding: '0 15px' }}>
                  items
                </HeaderCell>
                {!asStats && <HeaderCell collapsing />}
                {teamMembers
                  .filter(
                    (teamMember) =>
                      teamMember.employmentType !==
                        TEAM_MEMBER_FREELANCE_EMPLOYMENT_TYPE ||
                      projectsResponse?.data.find(
                        (
                          project: Project & {
                            projectItems: ProjectItem[];
                            projectTimeSpents: ProjectTimeSpent[];
                          }
                        ) => {
                          return project.projectTimeSpents?.find(
                            (projectTimeSpent: ProjectTimeSpent) =>
                              projectTimeSpent.teamMemberId === teamMember.id
                          )?.bonus;
                        }
                      )
                  )
                  .map((teamMember) => {
                    const teamMemberSalary = monthlySalaries?.monthlySalaries.teamMemberMonthlySalaries.find(
                      (teamMemberSalary) => {
                        return teamMemberSalary.teamMemberId === teamMember.id;
                      }
                    );
                    return (
                      <HeaderCell
                        key={teamMember.id}
                        collapsing
                        style={{
                          textAlign: 'center',
                          padding: '3px 8px',
                        }}
                      >
                        <div
                          style={{
                            padding: '2px 5px',
                            borderRadius: '10px',
                            backgroundColor:
                              Number(
                                teamMemberSalary?.performanceComponentWithDeduction
                              ) > 0
                                ? '#00a650'
                                : 'transparent',
                          }}
                        >
                          <p style={{ marginBottom: 0 }}>
                            {teamMember.name.split(' ')[0]}
                          </p>
                          <p>
                            {formatterFunction(
                              Number(
                                teamMemberSalary?.performanceComponentWithDeduction ??
                                  0
                              )
                            )}
                          </p>
                        </div>
                      </HeaderCell>
                    );
                  })}
              </Row>
              {monthlySalaries?.monthlySalaries &&
                projectsResponse?.data.map(
                  (
                    project: Project & {
                      projectItems: ProjectItem[];
                      projectTimeSpents: ProjectTimeSpent[];
                    },
                    index: number
                  ) => {
                    const projectBonusEntry = monthlySalaries.monthlySalaries.monthlyProjectBonuses?.find(
                      (projectBonus) => projectBonus.projectId === project.id
                    );

                    let bonusValue;

                    if (monthlySalaries.monthlySalaries.locked) {
                      bonusValue = Number(projectBonusEntry?.bonusSum);
                    } else {
                      bonusValue = Number(project.bonus);
                    }

                    return (
                      <React.Fragment key={project.id}>
                        <Row>
                          <Cell>{index + 1}</Cell>
                          <Cell>
                            <Checkbox
                              disabled={
                                monthlySalaries?.monthlySalaries.locked ||
                                asStats
                              }
                              checked={
                                monthlySalaries?.monthlySalaries.monthlyProjectBonuses?.some(
                                  (projectBonus) =>
                                    projectBonus.projectId === project.id
                                ) ?? false
                              }
                              onChange={(event, data) => {
                                if (data.checked) {
                                  addProjectToSalaries(
                                    monthlySalaries?.monthlySalaries.id,
                                    { projectId: project.id }
                                  ).then(() => {
                                    refetchSalaries();
                                  });
                                } else {
                                  removeProjectFromSalaries(
                                    monthlySalaries?.monthlySalaries.id,
                                    { projectId: project.id }
                                  ).then(() => {
                                    refetchSalaries();
                                  });
                                }
                              }}
                            />
                          </Cell>
                          <Cell>{project.client?.initials}</Cell>
                          <Cell
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (asStats) return;
                              setEditProject(project);
                            }}
                          >
                            {getProjectFullName(project)}
                          </Cell>
                          <Cell
                            onClick={() => {
                              if (projectBonusEntry) {
                                setEditTopDogsProjectBonusId(
                                  projectBonusEntry.id
                                );
                              }
                            }}
                            style={{
                              cursor: projectBonusEntry ? 'pointer' : 'inherit',
                            }}
                          >
                            {projectBonusEntry?.topDogsBonuses &&
                              teamMembers
                                .filter((teamMember) => {
                                  return Object.values(
                                    projectBonusEntry.topDogsBonuses
                                  ).find((v) => v.teamMembers[teamMember.id]);
                                })
                                .map(
                                  (teamMember) => teamMember.name.split(' ')[0]
                                )
                                .join(', ')}
                          </Cell>
                          <Cell
                            onClick={() => {
                              if (projectBonusEntry) {
                                setEditProjectTimeSpentProjectId(project.id);
                              }
                            }}
                            style={{
                              cursor: projectBonusEntry ? 'pointer' : 'inherit',
                            }}
                          >
                            {bonusValue
                              ? formatterFunction(Number(bonusValue))
                              : ''}
                          </Cell>
                          <TextInputCell
                            width={'1'}
                            disabled={asStats}
                            initialValue={
                              monthlySalaries?.monthlySalaries.monthlyProjectBonusNotes?.find(
                                (projectBonusNote) =>
                                  projectBonusNote.projectId === project.id
                              )?.notes || ''
                            }
                            onSubmit={(value) => {
                              updateOrCreateMonthlyProjectBonusNotes({
                                monthlySalariesId:
                                  monthlySalaries?.monthlySalaries.id,
                                projectId: project.id,
                                notes: value,
                              }).then(() => {
                                refetchSalaries();
                              });
                            }}
                          />
                          <Cell>{getProjectItemsNumber(project) || 0}</Cell>
                          {!asStats && (
                            <Cell style={{ cursor: 'pointer' }}>
                              {projectBonusEntry &&
                              projectCoefficientRowIds.includes(project.id) ? (
                                <Icon
                                  name="caret up"
                                  circular
                                  size="small"
                                  onClick={() => {
                                    closeProjectCoefficientRow(project.id);
                                  }}
                                />
                              ) : projectBonusEntry ? (
                                <Icon
                                  name="caret down"
                                  circular
                                  size="small"
                                  onClick={() => {
                                    openProjectCoefficientRow(project.id);
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </Cell>
                          )}
                          {teamMembers
                            .filter(
                              (teamMember) =>
                                teamMember.employmentType !==
                                  TEAM_MEMBER_FREELANCE_EMPLOYMENT_TYPE ||
                                projectsResponse?.data.find(
                                  (
                                    project: Project & {
                                      projectItems: ProjectItem[];
                                      projectTimeSpents: ProjectTimeSpent[];
                                    }
                                  ) => {
                                    return project.projectTimeSpents?.find(
                                      (projectTimeSpent: ProjectTimeSpent) =>
                                        projectTimeSpent.teamMemberId ===
                                        teamMember.id
                                    )?.bonus;
                                  }
                                )
                            )
                            .map((teamMember) => {
                              let value;

                              if (
                                monthlySalaries.monthlySalaries.locked ||
                                projectBonusEntry
                              ) {
                                value =
                                  projectBonusEntry?.teamMemberBonuses[
                                    teamMember.id
                                  ];
                              }
                              return (
                                <Cell key={teamMember.id}>
                                  {value && Number(value)
                                    ? formatterFunction(Number(value))
                                    : ''}
                                </Cell>
                              );
                            })}
                        </Row>
                        {projectCoefficientRowIds.includes(project.id) && (
                          <Row>
                            <Cell colspan="9" />
                            {teamMembers.map((teamMember) => {
                              return Number(
                                projectBonusEntry?.teamMemberBonuses[
                                  teamMember.id
                                ]
                              ) ? (
                                <NumberInputCell
                                  initialValue={
                                    projectBonusEntry?.teamMemberCoefficients?.[
                                      teamMember.id
                                    ] ?? 1
                                  }
                                  disabled={asStats}
                                  onSubmit={(value) => {
                                    if (projectBonusEntry?.id && value) {
                                      updateTeamMemberCoefficients(
                                        projectBonusEntry?.id,
                                        {
                                          teamMemberId: teamMember.id,
                                          coefficient: value,
                                        }
                                      ).then(() => {
                                        refetchSalaries();
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <Cell />
                              );
                            })}
                          </Row>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
            </Header>
          </Table>
        </div>
      </Wrapper>
    </>
  );
};

export default ProjectsForSalariesTable;
