import React from 'react';
import { Accordion } from 'semantic-ui-react';
import CurrencyExchangeRates from './CurrencyExchangeRates';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import { ProjectSetting } from '../types';
import Targets from './Targets';
import { DAILY_COST_PER_PERSON_KEY, WORK_DAYS_PER_YEAR } from '../keys';
import NumberInputField from '../../../components/forms/fields/NumberInputField';
import { useForm } from 'react-hook-form';
import { Form } from '../../../components/forms/Form';
import { differenceInBusinessDays, endOfYear, startOfYear } from 'date-fns';

type Props = {
  settings: ProjectSetting[];
  reloadSettings: () => Promise<void>;
  loading: boolean;
};

const FinanceSettings = (props: Props) => {
  const { settings, reloadSettings, loading } = props;

  const formMethods = useForm({
    defaultValues: {
      [`${DAILY_COST_PER_PERSON_KEY}_value`]:
        settings.find((setting) => setting.key === DAILY_COST_PER_PERSON_KEY)
          ?.value || 1,
      [`${WORK_DAYS_PER_YEAR}_value`]:
        settings.find((setting) => setting.key === WORK_DAYS_PER_YEAR)?.value ||
        Math.abs(
          differenceInBusinessDays(
            startOfYear(new Date()),
            endOfYear(new Date())
          )
        ) + 1,
    },
  });

  const {
    loading: updateLoading,
    performRequest: updateOrCreateSetting,
  } = useAPIRequest(ProjectSettingResource.updateOrCreate);

  const panels = [
    {
      key: 'currency-exchange-rates',
      title: 'Currency Exchange Rates (Base Currency: BGN)',
      content: {
        content: (
          <CurrencyExchangeRates
            settings={settings}
            loading={loading || updateLoading}
            updateOrCreateSetting={updateOrCreateSetting}
            reloadSettings={reloadSettings}
          />
        ),
      },
    },
    {
      key: 'targets',
      title: 'Targets',
      content: {
        content: <Targets settings={settings} loading={loading} />,
      },
    },
  ];

  return (
    <>
      <Form>
        <NumberInputField
          inline
          label="Daily Cost per Person"
          name={`${DAILY_COST_PER_PERSON_KEY}_value`}
          submitHandler={({
            [`${DAILY_COST_PER_PERSON_KEY}_value`]: value,
          }) => {
            updateOrCreateSetting({
              key: DAILY_COST_PER_PERSON_KEY,
              value,
              module: 'finances',
            });
          }}
          rules={{
            min: { value: 1, message: 'Please specify a positive number' },
          }}
          fluid={false}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />
        <NumberInputField
          inline
          label="Work Days per Year"
          name={`${WORK_DAYS_PER_YEAR}_value`}
          submitHandler={({ [`${WORK_DAYS_PER_YEAR}_value`]: value }) => {
            updateOrCreateSetting({
              key: WORK_DAYS_PER_YEAR,
              value,
              module: 'finances',
            });
          }}
          rules={{
            min: { value: 1, message: 'Please specify a positive number' },
          }}
          fluid={false}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />
      </Form>
      <Accordion.Accordion panels={panels} />
    </>
  );
};

export default FinanceSettings;
