import React, { ReactNode, SyntheticEvent } from 'react';
import { Button } from 'semantic-ui-react';
import ModalHeader from './ModalHeader';
import Modal from './Modal';
import ModalActions from './ModalActions';
import ModalContent from './ModalContent';
import Segment from '../layout/Segment';

type Props = {
  header?: ReactNode;
  content: ReactNode;
  trigger: ReactNode;
  onSubmit?: () => void;
} & React.ComponentProps<typeof Modal>;

const Confirm = (props: Props) => {
  const { header, content, trigger, onSubmit } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
    setOpen(false);
  };

  return (
    <Modal
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      trigger={trigger}
    >
      {header && <ModalHeader>{header}</ModalHeader>}
      <ModalContent style={{ pointerEvents: 'none', padding: '.5em' }}>
        <Segment style={{ borderRadius: 0 }}>{content}</Segment>
      </ModalContent>
      <ModalActions
        mainActions={
          <>
            <Button onClick={handleClose} size="large">
              Cancel
            </Button>
            {onSubmit && (
              <Button
                positive
                onClick={handleSubmit}
                type="submit"
                size="large"
              >
                Confirm
              </Button>
            )}
          </>
        }
      />
    </Modal>
  );
};

export default Confirm;
