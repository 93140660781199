import React, { SyntheticEvent, useContext } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import ResourceTable from '../../containers/resourceTable/ResourceTable';
import { createContainer } from 'unstated-next';
import useCRUDResource from '../../hooks/useCRUDResource';
import Filters from './Filters';
import Archive from './actions/Archive';
import Activate from './actions/Activate';
import { FULL_ACCESS_PERMISSION } from '../team/permission';
import { Client, ClientContact, CompanyInvoiceData } from './types';
import Delete from './actions/Delete';
import ClientResource from '../../api/resources/clients/ClientResource';
import ClientFormFields from './fields/ClientFormFields';
import { CLIENT_BUSINESSTYPE_OPTIONS } from './constants';
import { clientContactCreateInstanceConfig } from './contacts/ContactsTable';
import { Id } from '../../types/base';
import InfoBox from './InfoBox';
import { invoiceDataCreateInstanceConfig } from './invoiceData/CompanyInvoiceDataTable';
import { TabContext } from '../../containers/Tabs';
import { PROJECTS_MENU_ITEM } from '../../containers/constants';
import DateFormatter from '../../utils/dateUtils';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import {
  projectEditFormActions,
  projectEditInstanceConfig,
} from '../projects/Table';
import { PROJECT_STATUS_OPTIONS } from '../projects/constants';
import EditableList from '../../containers/EditableList';
import { Project } from '../projects/types';
import ContactsResource from '../../api/resources/clients/ContactsResource';
import CompanyInvoiceDataResource from '../../api/resources/clients/CompanyInvoiceDataResource';
import CreateForm from '../../containers/resourceTable/CreateForm';
import ClientInvoiceDataResource from '../../api/resources/clients/ClientInvoiceDataResource';
import ClientContactsResource from '../../api/resources/clients/ClientContactsResource';
import CompanyInvoiceDataEditableList from './invoiceData/CompanyInvoiceDataEditableList';
import ClientContactsEditableList from './contacts/ClientContactsEditableList';
import { projectDefaultFilters } from '../projects/Filters';
import { getProjectFullName } from '../projects/utils';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import Heading from '../../components/layout/Heading';

export const createInstanceConfig = {
  title: 'Create client',
  formFields: ClientFormFields,
  formId: 'client-create-form',
};

const ClientEditNestedContent = ({
  instance,
  reload,
}: {
  instance: Client;
  reload: () => Promise<void>;
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Accordion
        defaultActiveIndex={[2]}
        inverted={theme === themes.dark}
        panels={[
          {
            key: 'contacts-table',
            title: {
              content: (
                <Heading
                  as="h5"
                  style={{ display: 'inline-block' }}
                  onClick={(e: SyntheticEvent) => {
                    if (e.target !== e.currentTarget) {
                      e.stopPropagation();
                    }
                  }}
                >
                  Contacts{' '}
                  <CreateForm
                    {...clientContactCreateInstanceConfig}
                    trigger={
                      <Icon
                        name="plus"
                        size="mini"
                        style={{ lineHeight: '.8em', fontSize: '.8em' }}
                        onClick={(e: SyntheticEvent<HTMLElement>) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                        }}
                      />
                    }
                    handleCreateNewInstance={async (data) => {
                      await ClientContactsResource.create(instance.id, data);
                      reload();
                    }}
                  />
                </Heading>
              ),
            },
            content: {
              content: (
                <ClientContactsEditableList
                  items={instance?.contacts}
                  reload={reload}
                  handleEdit={async (data: Partial<ClientContact>, id: Id) => {
                    await ContactsResource.updateById(id, data);
                    reload();
                  }}
                />
              ),
            },
          },
          {
            key: 'invoice-addresses',
            title: {
              content: (
                <Heading
                  as="h5"
                  style={{ display: 'inline-block' }}
                  onClick={(e: SyntheticEvent) => {
                    if (e.target !== e.currentTarget) {
                      e.stopPropagation();
                    }
                  }}
                >
                  Invoice Addresses{' '}
                  <CreateForm
                    {...invoiceDataCreateInstanceConfig}
                    trigger={
                      <Icon
                        name="plus"
                        size="mini"
                        style={{ lineHeight: '.8em', fontSize: '.8em' }}
                        onClick={(e: SyntheticEvent<HTMLElement>) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                        }}
                      />
                    }
                    handleCreateNewInstance={async (data) => {
                      await ClientInvoiceDataResource.create(instance.id, data);
                      reload();
                    }}
                  />
                </Heading>
              ),
            },
            content: {
              content: (
                <CompanyInvoiceDataEditableList
                  reload={reload}
                  handleEdit={async (
                    data: Partial<CompanyInvoiceData>,
                    id: Id
                  ) => {
                    await CompanyInvoiceDataResource.updateById(id, data);
                    reload();
                  }}
                  items={instance?.companyInvoiceData}
                />
              ),
            },
          },
          {
            key: 'client-projects',
            title: {
              content: (
                <Heading as="h5" style={{ display: 'inline-block' }}>
                  Projects
                </Heading>
              ),
            },
            content: {
              content: (
                <EditableList
                  items={instance?.projects}
                  editInstanceConfig={projectEditInstanceConfig}
                  reload={reload}
                  handleEdit={async (data: Partial<Project>, id: Id) => {
                    await ProjectResource.updateById(id, data);
                    reload();
                  }}
                  editFormActions={projectEditFormActions}
                  noItemsMessage="No projects yet"
                  headerAccessor={(project: Project) =>
                    getProjectFullName(project)
                  }
                  descriptionAccessor={(project: Project) =>
                    `${
                      PROJECT_STATUS_OPTIONS.find(
                        (status) => status.value === project.status
                      )?.text
                    } ${DateFormatter.format(new Date(project.startDate))}`
                  }
                />
              ),
            },
          },
        ]}
        exclusive={false}
      />
    </>
  );
};

export const editInstanceConfig = {
  title: 'Edit client',
  formFields: ClientFormFields,
  formId: 'client-edit-form',
  nameAccessor: 'name' as const,
  activateInputOnClick: true,
  nestedContent: ClientEditNestedContent,
  scrolling: false,
};

export const editFormActions = [
  {
    key: 'archive',
    condition: (client: Client) => client.archived === false,
    component: Archive,
    permission: FULL_ACCESS_PERMISSION,
  },
  {
    key: 'activate',
    condition: (client: Client) => client.archived === true,
    component: Activate,
    permission: FULL_ACCESS_PERMISSION,
  },
  {
    key: 'delete',
    condition: (client: Client) =>
      client.archived === true && typeof client?.projects === 'undefined',
    component: Delete,
    permission: FULL_ACCESS_PERMISSION,
  },
];

type Props = {
  filters?: {
    where?: any;
  };
};

export const defaultClientFilters = { internal: false };

const Table = (props: Props) => {
  const { filters = {} } = props;
  const { setActiveIndex } = useContext(TabContext);

  const config = [
    {
      label: 'Name',
      accessor: 'name' as const,
      key: 'name',
      orderingField: 'name',
    },
    {
      label: 'Business Type',
      accessor: (client: Client) =>
        CLIENT_BUSINESSTYPE_OPTIONS.find(
          (type) => type.value === client.businessType
        )?.text,
      key: 'business-type',
      orderingField: 'businessType',
    },
    {
      label: 'Website',
      accessor: (client: Client) =>
        client.website && (
          <a
            href={
              client.website.startsWith('http')
                ? client.website
                : '//' + client.website
            }
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {client.website}
          </a>
        ),
      key: 'website',
      orderingField: 'website',
    },
    {
      label: 'Color',
      accessor: (client: Client) => (
        <div
          style={{
            width: '12px',
            height: '12px',
            backgroundColor: client.color,
          }}
        />
      ),
      key: 'color',
      orderingField: 'color',
    },
    {
      label: 'Has Projects',
      accessor: (client: Client) =>
        client.projects?.length > 0 ? (
          <span
            style={{ color: '#4183c4', display: 'block' }}
            onClick={() =>
              setActiveIndex(PROJECTS_MENU_ITEM, {
                filters: {
                  where: { clientId: client.id, ...projectDefaultFilters },
                },
              })
            }
          >
            View
          </span>
        ) : (
          'No'
        ),
      key: 'has-projects',
      orderingField: null,
      stopPropagation: true,
    },
  ];

  const ClientsContainer = createContainer(useCRUDResource);

  return (
    <ClientsContainer.Provider
      initialState={{
        resource: ClientResource,
        defaultFilter: {
          where: { ...defaultClientFilters, archived: false },
          include: [
            { relation: 'projects' },
            { relation: 'contacts' },
            { relation: 'companyInvoiceData' },
          ],
          ...filters,
        },
        defaultOrder: 'name ASC',
        searchFields: ['name'],
      }}
    >
      <ResourceTable
        columns={config}
        newInstanceConfig={createInstanceConfig}
        editInstanceConfig={editInstanceConfig}
        stateContainer={ClientsContainer}
        filterComponent={Filters}
        infoBox={InfoBox}
        editFormActions={editFormActions}
        bulkActions={[
          {
            key: 'archive',
            condition: (filters: any) => filters?.where?.archived === false,
            component: Archive,
            permission: FULL_ACCESS_PERMISSION,
          },
          {
            key: 'activate',
            condition: (filters: any) => filters?.where?.archived,
            component: Activate,
            permission: FULL_ACCESS_PERMISSION,
          },
          {
            key: 'delete',
            condition: (filters: any) => filters?.where?.archived,
            component: Delete,
            permission: FULL_ACCESS_PERMISSION,
          },
        ]}
      />
    </ClientsContainer.Provider>
  );
};

export default Table;
