import React, {
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  DotProps,
} from 'recharts';
import useAPIRequest from '../../hooks/useAPIRequest';
import InvoiceResource from '../../api/resources/invoices/InvoiceResource';
import { Invoice } from './types';
import { toDateObject } from '../../utils/dateUtils';
import Decimal from 'decimal.js-light';
import ProjectSettingResource from '../../api/resources/ProjectSettingResource';
import { TARGET_ADDITIONAL_KEY, TARGET_MARGIN_KEY } from '../settings/keys';
import { ProjectSetting } from '../settings/types';
import TotalCostResource from '../../api/resources/team/TotalCostResource';
import { TotalCost } from '../team/types';
import {
  eachDayOfInterval,
  endOfMonth,
  isAfter,
  isBefore,
  isEqual,
  startOfMonth,
} from 'date-fns';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import Heading from '../../components/layout/Heading';
import { LocalStorage } from '../../api/BrowserStorage';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

const currentMonth = new Date().getMonth();

const YEAR_STROKE = [
  '#297ed8',
  '#d81d67',
  '#7a2cd8',
  '#20d865',
  '#8884d8',
  '#ca8531',
  '#82ca9d',
];

function monthsForLocale(
  localeName = 'en-GB',
  monthFormat = 'short'
): Array<string> {
  const format = new Intl.DateTimeFormat(localeName, { month: monthFormat })
    .format;
  return [...Array(12).keys()].map((m) => format(new Date(Date.UTC(2021, m))));
}

const MONTH_OPTIONS = monthsForLocale();
const AVERAGE_WORKING_DAYS_MONTH = 21.5;

const costAsOf = (costs: TotalCost[], dateObj: Date) => {
  const options = costs
    .filter(
      (p) =>
        p.startDate &&
        (isBefore(toDateObject(p.startDate), dateObj) ||
          isEqual(toDateObject(p.startDate), dateObj))
    )
    .filter(
      (p) =>
        !p.endDate ||
        (p.endDate &&
          (isAfter(toDateObject(p.endDate), dateObj) ||
            isEqual(toDateObject(p.endDate), dateObj)))
    );
  if (options.length) {
    return options[0];
  }
};

const dataKeyLabels = {
  margin: 'Target (Margin)',
  additional: 'Target (Additional)',
  cost: 'Cost',
};

const MonthlyInvoicedChart = () => {
  const { data: invoiceResponse, performRequest: getInvoices } = useAPIRequest(
    InvoiceResource.list
  );
  const [activeDot, setActiveDot] = useState<{
    dataKey: string;
    value: number;
    index: number;
    color: string;
  } | null>(null);

  const onActiveDotMouseOver = (dotProps: DotProps, event: any) => {
    const dataKey = get(event, 'dataKey');
    const label = get(dataKeyLabels, dataKey) || dataKey;
    const value = get(event, 'value');
    const index = get(event, 'index');
    const color = get(event, 'fill');

    showToolTip(label, value, index, color);
  };

  const showToolTip = (
    dataKey: string,
    value: number,
    index: number,
    color: string
  ) => {
    setActiveDot({ dataKey, value, index, color });
  };

  const hideTooltip = () => {
    // setActiveDot(null)
  };

  const [switchedOffKeys, setSwitchedOffKeys] = useState<string[]>(
    LocalStorage.loadState('monthly-invoiced-switched-off-keys') || []
  );

  const {
    data: projectSettingsResponse,
    performRequest: getItemTypes,
  } = useAPIRequest(ProjectSettingResource.list);

  const {
    data: totalCostsResponse,
    performRequest: getTotalCosts,
  } = useAPIRequest(TotalCostResource.list);

  useEffect(() => {
    getTotalCosts();
  }, [getTotalCosts]);

  useEffect(() => {
    getItemTypes({
      filter: {
        where: {
          or: [TARGET_ADDITIONAL_KEY, TARGET_MARGIN_KEY].map((k) => ({
            key: k,
          })),
        },
      },
    });
    getInvoices({
      filter: {
        fields: { id: true, amountWithVATBG: true, issueDate: true },
      },
    });
    // eslint-disable-next-line
  }, [getInvoices]);

  useEffect(() => {
    LocalStorage.saveState(
      'monthly-invoiced-switched-off-keys',
      switchedOffKeys
    );
  }, [switchedOffKeys]);

  const [
    invoiceData,
    yearOptions,
    monthlyInvoicedAverageByYear,
  ] = useMemo(() => {
    let data: Array<{ month: string }> = [];
    let yearOptions: string[] = [];
    let monthlyInvoicedAverageByYear: { [x: string]: number } = {};

    if (
      invoiceResponse?.data &&
      projectSettingsResponse?.data &&
      totalCostsResponse?.data
    ) {
      const targetMargin =
        projectSettingsResponse.data.find(
          (item: ProjectSetting) => item.key === TARGET_MARGIN_KEY
        )?.value || 0;

      const targetAdditional =
        projectSettingsResponse.data.find(
          (item: ProjectSetting) => item.key === TARGET_ADDITIONAL_KEY
        )?.value || 0;

      const invoicesByYear = groupBy(invoiceResponse.data, ({ issueDate }) =>
        toDateObject(issueDate).getFullYear()
      );
      yearOptions = Object.keys(invoicesByYear);

      const currentYear = new Date().getFullYear();
      yearOptions.forEach((year) => {
        const invoices = invoicesByYear[year];
        const totalInvoiced = invoices.reduce((acc: Decimal, curr: Invoice) => {
          if (curr.credit) {
            return acc.sub(new Decimal(curr.amountWithVATBG || 0));
          }
          return acc.add(new Decimal(curr.amountWithVATBG || 0));
        }, new Decimal(0));
        const divider = Number(year) === currentYear ? currentMonth + 1 : 12;
        monthlyInvoicedAverageByYear[year] = totalInvoiced
          .div(divider)
          .toNumber();
      });

      MONTH_OPTIONS.forEach((month, index) => {
        const monthData: any = { month };
        for (let year of yearOptions) {
          const invoices = invoicesByYear[year].filter((invoice: Invoice) => {
            const date = toDateObject(invoice.issueDate);
            return date.getMonth() === index;
          });
          const totalInvoiced = invoices.reduce(
            (acc: Decimal, curr: Invoice) => {
              if (curr.credit) {
                return acc.sub(new Decimal(curr.amountWithVATBG || 0));
              }
              return acc.add(new Decimal(curr.amountWithVATBG || 0));
            },
            new Decimal(0)
          );

          monthData[year.toString()] = totalInvoiced.toNumber();

          if (index <= currentMonth) {
            let cost = new Decimal(0);
            let numWorkingDays = 0;
            for (let day of eachDayOfInterval({
              start: startOfMonth(new Date(Number(year), index, 6)),
              end: endOfMonth(new Date(Number(year), index, 6)),
            })) {
              if (day.getDay() !== 0 && day.getDay() !== 6) {
                numWorkingDays += 1;
                cost = cost.add(
                  new Decimal(costAsOf(totalCostsResponse.data, day)?.cost ?? 0)
                );
              }
            }

            monthData['cost'] = cost
              .div(new Decimal(numWorkingDays))
              .mul(AVERAGE_WORKING_DAYS_MONTH)
              .toNumber();

            const margin = new Decimal(targetMargin)
              .div(new Decimal(100))
              .mul(monthData['cost']);
            monthData['margin'] = new Decimal(monthData['cost'])
              .add(margin)
              .toDecimalPlaces(2)
              .toNumber();

            const additional = new Decimal(targetAdditional)
              .div(new Decimal(100))
              .mul(monthData['cost']);
            monthData['additional'] = new Decimal(monthData['cost'])
              .add(additional)
              .toDecimalPlaces(2)
              .toNumber();
          }
        }
        data.push(monthData);
      });
    }

    return [data, yearOptions, monthlyInvoicedAverageByYear];
  }, [invoiceResponse, projectSettingsResponse, totalCostsResponse]);

  const formatter = (value: number) => {
    return `${new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'BGN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)}`;
  };

  const getDataKey = (type: string) => {
    if (switchedOffKeys.includes(type)) {
      return `${type} `;
    }
    return type;
  };

  const getStroke = (type: string, color: string) => {
    if (switchedOffKeys.includes(type)) {
      return '#c5c5c5';
    }
    return color;
  };

  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        minHeight: 500,
        textAlign: 'center',
        marginTop: 20,
      }}
    >
      <Heading as="h4">Monthly Invoiced</Heading>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={invoiceData}
          margin={{ top: 25, right: 20, bottom: 5, left: 0 }}
        >
          {yearOptions.map((year, index) => {
            return (
              <Line
                key={year}
                type="monotone"
                dataKey={getDataKey(year)}
                stroke={getStroke(year, YEAR_STROKE[index])}
                activeDot={{
                  r: 6,
                  onMouseOver: onActiveDotMouseOver,
                  onMouseLeave: () => {
                    hideTooltip();
                  },
                }}
              />
            );
          })}
          <Line
            name="Cost"
            type="monotone"
            dataKey={getDataKey('cost')}
            stroke={getStroke('cost', '#C42415')}
            activeDot={{
              r: 6,
              onMouseOver: onActiveDotMouseOver,
              onMouseLeave: () => {
                hideTooltip();
              },
            }}
          />
          <Line
            name="Target (Margin)"
            type="monotone"
            dataKey={getDataKey('margin')}
            stroke={getStroke('margin', '#CA41BF')}
            activeDot={{
              r: 6,
              onMouseOver: onActiveDotMouseOver,
              onMouseLeave: () => {
                hideTooltip();
              },
            }}
          />
          <Line
            name="Target (Additional)"
            type="monotone"
            dataKey={getDataKey('additional')}
            stroke={getStroke('additional', '#2E2ECA')}
            activeDot={{
              r: 6,
              onMouseOver: onActiveDotMouseOver,
              onMouseLeave: () => {
                hideTooltip();
              },
            }}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis
            dataKey="month"
            tick={{ fill: theme === themes.dark ? '#fff' : '#000' }}
          />
          <YAxis tick={{ fill: theme === themes.dark ? '#fff' : '#000' }} />
          <Tooltip
            content={
              activeDot ? (
                <div
                  style={{
                    color: '#000',
                    backgroundColor: 'white',
                    padding: 10,
                  }}
                >
                  <div className="recharts-default-tooltip">
                    <p>{MONTH_OPTIONS[activeDot.index]}</p>
                    <p style={{ color: activeDot.color }}>
                      {activeDot.dataKey}: {formatter(activeDot.value)}
                    </p>
                    <p>
                      {get(monthlyInvoicedAverageByYear, activeDot.dataKey)
                        ? `Monthly Invoiced Average: ${formatter(
                            get(monthlyInvoicedAverageByYear, activeDot.dataKey)
                          )}`
                        : null}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )
            }
          />
          <Legend
            height={25}
            wrapperStyle={{ marginBottom: 20, top: 0, cursor: 'pointer' }}
            verticalAlign="top"
            onClick={(e: MouseEvent) => {
              const key = get(e, 'dataKey').trim();
              setSwitchedOffKeys((prevState) => {
                if (prevState.includes(key)) {
                  return prevState.filter((k) => key !== k);
                } else {
                  return prevState.concat([key]);
                }
              });
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlyInvoicedChart;
