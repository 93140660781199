import React from 'react';
import ResourceTable from '../../../containers/resourceTable/ResourceTable';
import { createContainer } from 'unstated-next';
import { FULL_ACCESS_PERMISSION } from '../../team/permission';
import DeleteContact from './DeleteContact';
import ContactFormFields from './ContactFormFields';
import useNestedCRUDResource from '../../../hooks/useNestedCrudResource';
import { Id } from '../../../types/base';
import ClientContactsResource from '../../../api/resources/clients/ClientContactsResource';

type Props = {
  clientId: Id;
};

export const clientContactCreateInstanceConfig = {
  title: 'Create new contact',
  formFields: ContactFormFields,
  formId: 'contact-create-form',
};

export const clientContactEditInstanceConfig = {
  title: 'Edit contact',
  formFields: ContactFormFields,
  formId: 'contact-edit-form',
  nameAccessor: 'name' as const,
  activateInputOnClick: true,
};

export const clientContactEditFormActions = [
  {
    key: 'delete',
    condition: () => true,
    component: DeleteContact,
    permission: FULL_ACCESS_PERMISSION,
  },
];

const ContactsTable = (props: Props) => {
  const { clientId } = props;

  const config = [
    {
      label: 'Title',
      accessor: 'title' as const,
      key: 'title',
      orderingField: 'title',
    },
    {
      label: 'Name',
      accessor: 'name' as const,
      key: 'name',
      orderingField: 'name',
    },
    {
      label: 'Name (BG)',
      accessor: 'nameBG' as const,
      key: 'nameBG',
      orderingField: 'nameBG',
    },
    {
      label: 'Position',
      accessor: 'position' as const,
      key: 'position',
      orderingField: 'position',
    },
    {
      label: 'Email',
      accessor: 'email' as const,
      key: 'email',
      orderingField: 'email',
    },
  ];

  const ClientContactsContainer = createContainer(useNestedCRUDResource);

  return (
    <ClientContactsContainer.Provider
      initialState={{
        resource: ClientContactsResource,
        defaultFilter: { where: { archived: false } },
        parentId: clientId,
        searchFields: ['name'],
      }}
    >
      <ResourceTable
        addSearch={false}
        columns={config}
        newInstanceConfig={clientContactCreateInstanceConfig}
        editInstanceConfig={clientContactEditInstanceConfig}
        stateContainer={ClientContactsContainer}
        editFormActions={clientContactEditFormActions}
      />
    </ClientContactsContainer.Provider>
  );
};

export default ContactsTable;
