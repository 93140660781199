import React, {
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import Cell from '../Cell';
import {
  Input,
  InputProps,
  Popup,
  Ref,
  SemanticWIDTHS,
} from 'semantic-ui-react';
import { ValidationRules } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import styled from 'styled-components';

type Props = {
  initialValue: number | string | null;
  onSubmit: (value: number | null) => Promise<void> | void;
  rules?: ValidationRules;
  unit?: string;
  unitPosition?: string;
  width?: SemanticWIDTHS;
  disabled?: boolean;
};

const StyledInput = styled(
  forwardRef<
    HTMLDivElement,
    InputProps & { unitPosition?: string; unit?: string }
  >(({ unit, unitPosition, ...rest }, ref) => (
    <Ref innerRef={ref}>
      <Input {...rest} />
    </Ref>
  ))
)`
  &.ui.fluid.input {
    // width: 100%;  
  }

  &.ui.fluid.input > input {
    ${(props) => `
      min-width: unset !important;
      width: ${
        props.unit && props.value.toString().length > 0
          ? `${props.value.toString().length * 8.5}px`
          : props.unit
          ? '20px'
          : `${props.value.toString().length * 15}px`
      } !important;
      -moz-appearance: textfield;
    `}
`;

const NumberInputCell = (props: Props) => {
  const {
    initialValue,
    onSubmit,
    rules = {},
    unit,
    unitPosition = 'left',
    width,
    disabled,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<number | ''>(
    typeof initialValue === 'number'
      ? initialValue
      : initialValue !== null
      ? +initialValue
      : ''
  );
  const [touched, setTouched] = useState(false);
  const [valid, setValid] = useState<{ valid: boolean; message: string }>({
    valid: true,
    message: '',
  });

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (touched && isValid) {
      onSubmit(typeof value === 'number' ? value : null);
      setTouched(false);
      if (!value) {
        setValue(0);
      }
    }
  };

  const validateInput = (input: number | '') => {
    if (isEmpty(rules)) {
      return { valid: true };
    }

    if (rules.required && input === '') {
      return {
        valid: false,
        message: get(rules.required, 'message' || 'This field is required'),
      };
    }

    if (rules.min) {
      const minValue =
        typeof get(rules.min, 'value') === 'number'
          ? get(rules.min, 'value')
          : rules.min;

      if (input < minValue) {
        return {
          valid: false,
          message:
            get(rules.min, 'message') ??
            `Please specify a number bigger than ${minValue}`,
        };
      }
    }

    if (rules.max) {
      const maxValue =
        typeof get(rules.max, 'value') === 'number'
          ? get(rules.max, 'value')
          : rules.max;

      if (input > maxValue) {
        return {
          valid: false,
          message:
            get(rules.max, 'message') ??
            `Please specify a number lower than ${maxValue}`,
        };
      }
    }

    return { valid: true };
  };

  const handleChange = (
    e: SyntheticEvent<HTMLInputElement>,
    { value }: InputProps
  ) => {
    const validationResult = validateInput(value);
    setValid((prevState) => ({ ...prevState, ...validationResult }));
    setTouched(true);
    setValue(+value ?? '');
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && touched) {
      if (isValid) {
        if (inputRef.current) {
          //@ts-ignore
          inputRef.current?.firstChild?.blur();
        }
      }
    }
  };

  const { valid: isValid, message } = valid;

  return (
    <Popup
      content={message}
      disabled={isValid}
      eventsEnabled={!isValid}
      trigger={
        <Cell negative={!isValid} width={width}>
          {unit && unitPosition === 'left' ? <span>{unit} </span> : ''}
          <StyledInput
            onClick={(e: SyntheticEvent) => {
              //@ts-ignore
              inputRef?.current?.firstChild?.select();
              e.stopPropagation();
            }}
            autoFocus={touched}
            ref={inputRef}
            style={{ display: 'inline' }}
            fluid
            type="number"
            value={value !== '' ? Number(value).toString() : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            unit={unit}
            unitPosition={unitPosition}
            disabled={disabled}
          />
          {unit && unitPosition === 'right' ? <span>{unit}</span> : ''}
        </Cell>
      }
    />
  );
};

export default NumberInputCell;
