import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  font-family: MarkOT-Medium, Lato, sans-serif;
  text-align: center;
`;

const Footer = () => {
  return (
    <StyledFooter>
      Cactus &copy; {new Date().getFullYear()} COSMOSCUBE{' '}
    </StyledFooter>
  );
};

export default Footer;
