import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  CheckboxField,
  DateInputField,
} from '../../../components/forms/fields';

type Props = FormFieldsComponentTypeProps;

const StatementFormFields = (props: Props) => {
  return (
    <>
      <Form.Group widths="equal">
        <DateInputField
          width="8"
          label="From"
          name="from"
          rules={{
            required: { value: true, message: 'Please select the start date.' },
          }}
          dateOnly
          {...props}
        />
        <DateInputField
          width="8"
          label="To"
          name="to"
          rules={{
            required: { value: true, message: 'Please select the end date.' },
          }}
          dateOnly
          {...props}
        />
      </Form.Group>
      <Form.Group>
        <CheckboxField
          width="8"
          label={
            <p style={{ minWidth: '200px', display: 'inline' }}>
              Current team member only
            </p>
          }
          name="currentTeamMemberOnly"
          {...props}
        />
      </Form.Group>
    </>
  );
};

export default StatementFormFields;
