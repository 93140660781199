import React, { useContext } from 'react';
import { Button, Checkbox, Divider } from 'semantic-ui-react';
import {
  GRADIENT_DEFAULT_COLOR,
  ThemeContext,
  themes,
} from '../../contexts/theme/ThemeContext';
import { HuePicker, AlphaPicker, RGBColor } from 'react-color';
import Heading from '../../components/layout/Heading';
import tinycolor from 'tinycolor2';

const colorResultToRGBAstring = (color: RGBColor) => {
  return tinycolor({
    r: color.r,
    g: color.g,
    b: color.b,
    a: color.a,
  }).toRgbString();
};

const ThemeSliders = () => {
  const {
    gradientMainColor,
    setGradientMainColor,
    theme,
    toggleTheme,
  } = useContext(ThemeContext);

  return (
    <div>
      <Checkbox
        className={theme === themes.dark ? 'dark-theme-toggle' : ''}
        toggle
        checked={theme === themes.dark}
        onChange={toggleTheme}
        label={'Toggle dark theme'}
      />
      <Divider />
      <Heading
        as="h5"
        style={{
          fontFamily: 'MarkOT-Medium, Open Sans, sans-serif',
          fontWeight: 'normal',
        }}
      >
        {' '}
        Header color:
      </Heading>
      <HuePicker
        color={gradientMainColor}
        onChange={(color) => {
          setGradientMainColor(
            tinycolor(colorResultToRGBAstring(color.rgb))
              .setAlpha(tinycolor(gradientMainColor).getAlpha())
              .toRgbString()
          );
        }}
      />
      <div style={{ marginTop: '14px', backgroundColor: '#fff' }}>
        <AlphaPicker
          color={gradientMainColor}
          onChange={(color) =>
            setGradientMainColor(colorResultToRGBAstring(color.rgb))
          }
        />
      </div>
      <Button
        style={{ marginTop: '14px' }}
        positive
        onClick={() => setGradientMainColor(GRADIENT_DEFAULT_COLOR)}
      >
        reset to the default color
      </Button>
    </div>
  );
};

export default ThemeSliders;
