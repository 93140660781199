import React from 'react';
import { ProjectSetting } from '../types';
import { useForm } from 'react-hook-form';
import {
  BIG_CHIEFS_BONUS_PERCENTAGE,
  TOP_DOGS_BONUS_PERCENTAGE,
  SALARIES_OVERTIME_RATE,
  TOP_DOGS_DEFAULT_ROLES_KEY,
} from '../keys';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import Loader from '../../../components/Loader';
import { Form } from '../../../components/forms/Form';
import NumberInputField from '../../../components/forms/fields/NumberInputField';
import { SelectInputField } from '../../../components/forms/fields';

type Props = {
  settings: ProjectSetting[];
  loading: boolean;
  roleOptions: { name: string; id: string; active: boolean }[];
  reloadSettings: () => Promise<void>;
};

const SalariesSettings = (props: Props) => {
  const { settings, loading, roleOptions } = props;

  const formMethods = useForm({
    defaultValues: {
      [`${SALARIES_OVERTIME_RATE}_value`]:
        settings.find((setting) => setting.key === SALARIES_OVERTIME_RATE)
          ?.value || 15,
      [`${TOP_DOGS_BONUS_PERCENTAGE}_value`]:
        settings.find((setting) => setting.key === TOP_DOGS_BONUS_PERCENTAGE)
          ?.value || 4,
      [`${BIG_CHIEFS_BONUS_PERCENTAGE}_value`]:
        settings.find((setting) => setting.key === BIG_CHIEFS_BONUS_PERCENTAGE)
          ?.value || 3.5,
      topDogsRoles:
        settings.find((setting) => setting.key === TOP_DOGS_DEFAULT_ROLES_KEY)
          ?.value ?? [],
    },
  });

  const {
    loading: updateLoading,
    performRequest: updateOrCreateSetting,
  } = useAPIRequest(ProjectSettingResource.updateOrCreate);

  return (
    <div>
      <Loader active={loading || updateLoading} />
      <Form>
        <NumberInputField
          inline
          label="Overtime Hourly Rate"
          name={`${SALARIES_OVERTIME_RATE}_value`}
          submitHandler={({ [`${SALARIES_OVERTIME_RATE}_value`]: value }) => {
            updateOrCreateSetting({
              key: SALARIES_OVERTIME_RATE,
              value,
              module: 'salaries',
            });
          }}
          rules={{
            min: { value: 1, message: 'Please specify a positive number' },
          }}
          fluid={false}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />

        <NumberInputField
          inline
          label="Top Dogs %"
          name={`${TOP_DOGS_BONUS_PERCENTAGE}_value`}
          submitHandler={({
            [`${TOP_DOGS_BONUS_PERCENTAGE}_value`]: value,
          }) => {
            updateOrCreateSetting({
              key: TOP_DOGS_BONUS_PERCENTAGE,
              value,
              module: 'salaries',
            });
          }}
          rules={{
            min: { value: 1, message: 'Please specify a positive number' },
          }}
          fluid={false}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />

        <NumberInputField
          inline
          label="Big Chiefs %"
          name={`${BIG_CHIEFS_BONUS_PERCENTAGE}_value`}
          submitHandler={({
            [`${BIG_CHIEFS_BONUS_PERCENTAGE}_value`]: value,
          }) => {
            updateOrCreateSetting({
              key: BIG_CHIEFS_BONUS_PERCENTAGE,
              value,
              module: 'salaries',
            });
          }}
          rules={{
            min: { value: 1, message: 'Please specify a positive number' },
          }}
          fluid={false}
          activateInputOnClick
          loading={loading || updateLoading}
          {...formMethods}
        />

        <div style={{ marginTop: '10px' }}>
          <SelectInputField
            activateInputOnClick
            multiple={true}
            label="Top Dogs Roles"
            name="topDogsRoles"
            options={roleOptions
              ?.filter(
                (role: { active: boolean; id: string; name: string }) =>
                  role.active
              )
              .map((role: { active: boolean; id: string; name: string }) => ({
                key: role.id,
                value: role.id,
                text: role.name,
              }))}
            submitHandler={async (data) => {
              await updateOrCreateSetting({
                key: TOP_DOGS_DEFAULT_ROLES_KEY,
                value: JSON.stringify(data.topDogsRoles),
                module: 'salaries',
              });
            }}
            {...formMethods}
          />
        </div>
      </Form>
    </div>
  );
};

export default SalariesSettings;
