import React from 'react';
import { ProjectTask } from '../types';
import Row from '../../../components/tables/Row';
import Cell from '../../../components/tables/Cell';
import { formatNumber } from '../../../utils/numberUtils';

type Props = {
  items: ProjectTask[];
};

const getSum = (items: ProjectTask[]) => {
  return items.reduce((prev, curr) => prev + +(curr.cost || 0), 0);
};

const TableFooter = ({ items }: Props) => {
  const budget = getSum(items);
  const days = items.reduce((prev, curr) => prev + +curr.days, 0);
  const hours = items.reduce((prev, curr) => prev + +(curr.hours || 0), 0);

  return items.length ? (
    <Row>
      <Cell colSpan={2}>
        {' '}
        <strong>Total</strong>{' '}
      </Cell>
      <Cell>
        <strong>{hours.toFixed(2)}</strong>{' '}
      </Cell>
      <Cell>
        <strong>{days.toFixed(2)}</strong>{' '}
      </Cell>
      <Cell>
        <strong>{formatNumber(budget)} lv</strong>
      </Cell>
    </Row>
  ) : null;
};

export default TableFooter;
