import React, { useContext } from 'react';
import { Header } from 'semantic-ui-react';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {} & React.ComponentProps<typeof Header>;

const Heading = (props: Props) => {
  const { theme } = useContext(ThemeContext);

  const { children, ...rest } = props;

  return (
    <Header inverted={theme === themes.dark} {...rest}>
      {children}
    </Header>
  );
};

export default Heading;
