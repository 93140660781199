import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Icon, List } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  ColorPickerField,
  SelectInputField,
  TextAreaField,
  TextInputField,
} from '../../../components/forms/fields';
import { CLIENT_BUSINESSTYPE_OPTIONS } from '../constants';
import FieldsDivider from '../../../components/forms/FieldsDivider';
import ContactFormFields from '../contacts/ContactFormFields';
import CompanyInvoiceDataFormFields from '../invoiceData/CompanyInvoiceDataFormFields';
import ModalForm from '../../../components/forms/ModalForm';
import { ClientContact, CompanyInvoiceData } from '../types';
import DeleteAction from '../../../components/actions/DeleteAction';
import {
  clientContactListDescriptionAccessor,
  clientContactListHeaderAccessor,
} from '../contacts/ClientContactsEditableList';
import {
  companyInvoiceDataListDescriptionAccessor,
  companyInvoiceDataListHeaderAccessor,
} from '../invoiceData/CompanyInvoiceDataEditableList';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

type Props = FormFieldsComponentTypeProps;

const ClientFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;
  const [contacts, setContacts] = useState<ClientContact[]>([]);
  const [invoiceData, setInvoiceData] = useState<CompanyInvoiceData[]>([]);

  const handleAddContact = async (data: ClientContact) => {
    setContacts((prevState) => [...prevState, data]);
  };

  const handleAddInvoiceData = async (data: CompanyInvoiceData) => {
    setInvoiceData((prevState) => [...prevState, data]);
  };

  const editInvoiceEntry = async (data: CompanyInvoiceData, idx: number) => {
    setInvoiceData((prevState) => [
      ...prevState.slice(0, idx),
      data,
      ...prevState.slice(idx + 1),
    ]);
  };

  const editContactEntry = async (data: ClientContact, idx: number) => {
    setContacts((prevState) => [
      ...prevState.slice(0, idx),
      data,
      ...prevState.slice(idx + 1),
    ]);
  };

  const deleteContactEntry = (idx: number) => {
    setContacts((prevState) => [
      ...prevState.slice(0, idx),
      ...prevState.slice(idx + 1),
    ]);
  };

  const deleteInvoiceDataEntry = (idx: number) => {
    setInvoiceData((prevState) => [
      ...prevState.slice(0, idx),
      ...prevState.slice(idx + 1),
    ]);
  };

  const { register, setValue } = formMethods;

  useEffect(() => {
    if (!instance) {
      setValue('companyInvoiceData', invoiceData);
    }
  }, [invoiceData, setValue, instance]);

  useEffect(() => {
    if (!instance) {
      register('companyInvoiceData');
    }
  }, [register, instance]);

  useEffect(() => {
    if (!instance) {
      setValue('contacts', contacts);
    }
  }, [contacts, setValue, instance]);

  useEffect(() => {
    if (!instance) {
      register('contacts');
    }
  }, [register, instance]);

  const contactsWrapper = useRef<null | HTMLDivElement>(null);
  const invoiceDataWrapper = useRef<null | HTMLDivElement>(null);

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Form.Group>
        <TextInputField
          rules={{
            required: {
              value: true,
              message: 'Please specify the client initials.',
            },
          }}
          label="Initials"
          placeholder="Initials"
          name="initials"
          width="7"
          {...formMethods}
        />
        <TextInputField
          rules={{ required: { value: true, message: 'Please enter a name.' } }}
          label="Client Name"
          name="name"
          width="9"
          placeholder="Name"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group>
        <SelectInputField
          label="Business Type"
          options={CLIENT_BUSINESSTYPE_OPTIONS}
          name="businessType"
          rules={{
            required: {
              value: true,
              message: 'Please select a business type.',
            },
          }}
          width="7"
          placeholder="Business Type"
          {...formMethods}
        />
        <TextInputField
          label="Website"
          name="website"
          {...formMethods}
          width="9"
          rules={{
            pattern: {
              value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
              message: 'Please enter a valid url',
            },
          }}
          placeholder="Website"
        />
      </Form.Group>
      <Form.Group>
        <TextInputField
          label="Telephones"
          name="telephones"
          width="7"
          placeholder="Telephones"
          {...formMethods}
        />
        <ColorPickerField
          label="Color"
          width="7"
          name="color"
          defaultValue={'#b3b3b3'}
          {...props}
        />
      </Form.Group>
      <Form.Group style={{ marginTop: '10px', marginBottom: '20px' }}>
        <TextAreaField width="16" label="Note" name="note" {...formMethods} />
      </Form.Group>
      {!instance && (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <ModalForm
            formId="new-client-add-contact"
            fields={ContactFormFields}
            header={<>Add contact</>}
            trigger={
              <Button
                icon="plus"
                size="large"
                content="Add contact"
                onClick={(e) => e.preventDefault()}
              />
            }
            submitData={handleAddContact}
          />
          <ModalForm
            formId="new-client-add-invoice-data"
            fields={CompanyInvoiceDataFormFields}
            header={<>Add invoice data</>}
            trigger={
              <Button
                icon="plus"
                size="large"
                content="Add invoice address"
                onClick={(e) => e.preventDefault()}
              />
            }
            submitData={handleAddInvoiceData}
          />
        </div>
      )}
      <div ref={contactsWrapper}>
        {contacts.length > 0 && (
          <>
            <FieldsDivider horizontal content="Contacts" />
            <List
              divided
              relaxed
              style={{ paddingLeft: '3px', marginTop: 0 }}
              inverted={theme === themes.dark}
            >
              {contacts.map((contact, idx) => (
                <ModalForm
                  key={`new-client-edit-contact-${contact.title}${contact.name}`}
                  formId={`new-client-edit-contact-${contact.title}${contact.name}`}
                  fields={ContactFormFields}
                  header={
                    <>
                      <Icon name="edit outline" circular size="small" />
                      Edit contact
                    </>
                  }
                  trigger={
                    <List.Item key={idx} style={{ marginLeft: '17px' }}>
                      <List.Content>
                        <List.Header
                          style={{ color: '#4183c4', cursor: 'pointer' }}
                        >
                          {clientContactListHeaderAccessor(contact)}
                        </List.Header>
                        {clientContactListDescriptionAccessor(contact)}
                      </List.Content>
                    </List.Item>
                  }
                  additionalActions={
                    <DeleteAction
                      callback={() => deleteContactEntry(idx)}
                      mountNode={contactsWrapper}
                    />
                  }
                  instance={contact}
                  submitData={(data: ClientContact) =>
                    editContactEntry(data, idx)
                  }
                />
              ))}
            </List>
          </>
        )}
      </div>
      <div ref={invoiceDataWrapper}>
        {invoiceData.length > 0 && (
          <>
            <FieldsDivider horizontal content="Invoice Addresses" />
            <List
              divided
              relaxed
              style={{ paddingLeft: '3px', marginTop: 0 }}
              inverted={theme === themes.dark}
            >
              {invoiceData.map((data, idx) => (
                <ModalForm
                  key={`new-client-edit-invoice-data-${data.nameBG}${data.countryBG}${data.city}${data.address}`}
                  formId={`new-client-edit-invoice-data-${data.nameBG}${data.countryBG}${data.city}${data.address}`}
                  fields={CompanyInvoiceDataFormFields}
                  header={
                    <>
                      <Icon name="edit outline" circular size="small" />
                      Edit invoice address
                    </>
                  }
                  trigger={
                    <List.Item key={idx} style={{ marginLeft: '17px' }}>
                      <List.Content>
                        <List.Header
                          style={{ color: '#4183c4', cursor: 'pointer' }}
                        >
                          {companyInvoiceDataListHeaderAccessor(data)}
                        </List.Header>
                        {companyInvoiceDataListDescriptionAccessor(data)}
                      </List.Content>
                    </List.Item>
                  }
                  additionalActions={
                    <DeleteAction
                      callback={() => deleteInvoiceDataEntry(idx)}
                      mountNode={invoiceDataWrapper}
                    />
                  }
                  instance={data}
                  submitData={(formData) => editInvoiceEntry(formData, idx)}
                />
              ))}
            </List>
          </>
        )}
      </div>
    </>
  );
};

export default ClientFormFields;
