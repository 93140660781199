import React, { useEffect } from 'react';
import get from 'lodash/get';
import DateFormatter, { toDateObject } from '../../../utils/dateUtils';
import { ResourceScheduleEvent } from '../../resourceSchedule/ResourceSchedule';
import { HOLIDAY_STATUS_OPTIONS } from '../../resourceSchedule/constants';
import { UserContext } from '../../auth/UserContext';
import {
  ColumnConfig,
  default as ResourceTable,
} from '../../../containers/resourceTable/ResourceTable';
import { getProjectFullName } from '../../projects/utils';
import useCRUDResource from '../../../hooks/useCRUDResource';
import { createContainer } from 'unstated-next';
import {
  FULL_ACCESS_PERMISSION,
  RESOURCE_SCHEDULE_PERMISSION,
} from '../../team/permission';
import Filters from './Filters';
import Delete from './actions/Delete';
import ResourceScheduleProjectEventResource from '../../../api/resources/resourceSchedule/ResourceScheduleProjectEventResource';
import { rangeFilterQuery } from '../../../components/calendar/utils';
import HolidayResourceSchedule from '../HolidayResourceSchedule';
import useAPIRequest from '../../../hooks/useAPIRequest';
import ProjectResource from '../../../api/resources/projects/ProjectResource';
import { Project } from '../../projects/types';
import TeamMemberFilter from './TeamMemberFilter';
import { hasPermission } from '../../auth/utils';
import { calculateEventDays } from '../docs/DocsTable';
import TableFooter from './TableFooter';
import PageFooter from './PageFooter';

const currentYear = new Date().getFullYear();
const LeaveRequestsContainer = createContainer(useCRUDResource);

const LeaveRequestsTable = () => {
  const { user } = UserContext.useContainer();

  const { data: projectsResponse, performRequest: getProjects } = useAPIRequest(
    ProjectResource.list
  );

  useEffect(() => {
    getProjects({
      filter: {
        where: { isHoliday: true, isHolidayForAllUsers: false },
        fields: { id: true },
      },
    });
  }, [getProjects]);

  const config: ColumnConfig<ResourceScheduleEvent>[] = [
    {
      label: 'Type',
      accessor: (event: ResourceScheduleEvent) => (
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          {getProjectFullName(event.project)}
        </span>
      ),
      key: 'type',
      orderingField: 'projectId',
    },
    {
      label: 'From',
      accessor: (event: ResourceScheduleEvent) =>
        DateFormatter.format(toDateObject(event.start)),
      key: 'start',
      orderingField: 'start',
    },
    {
      label: 'To',
      accessor: (event: ResourceScheduleEvent) =>
        DateFormatter.format(toDateObject(event.end)),
      key: 'end',
      orderingField: 'end',
    },
    {
      label: 'Days',
      accessor: (event: ResourceScheduleEvent) => calculateEventDays(event),
      key: 'days',
      orderingField: 'end',
    },
    {
      label: 'Status',
      accessor: (event: ResourceScheduleEvent) =>
        HOLIDAY_STATUS_OPTIONS.find((status) => status.value === event.status)
          ?.text,
      orderingField: 'status',
      key: 'status',
    },
  ];

  return user && projectsResponse ? (
    <LeaveRequestsContainer.Provider
      initialState={{
        resource: ResourceScheduleProjectEventResource,
        defaultFilter: {
          where: {
            and: [
              {
                ...rangeFilterQuery(
                  toDateObject(`${currentYear}-01-01`),
                  toDateObject(`${currentYear}-12-31`)
                ),
              },
              {
                or: projectsResponse.data.map((p: Pick<Project, 'id'>) => ({
                  projectId: p.id,
                })),
              },
              { teamMemberId: get(user, 'teamMemberId') },
            ],
          },
          include: [{ relation: 'project' }],
        },
        defaultOrder: 'start ASC',
      }}
    >
      <ResourceTable
        tableId="leave-requests-table"
        columns={config}
        addSearch={false}
        editPermission={RESOURCE_SCHEDULE_PERMISSION}
        createPermission={RESOURCE_SCHEDULE_PERMISSION}
        newInstanceConfig={{
          modalProps: {
            className: 'holiday-schedule',
          },
          title: 'Holiday Schedule',
          customForm: <HolidayResourceSchedule />,
          formId: 'holiday-schedule',
        }}
        editInstanceConfig={{
          customForm: ({ instance }) => (
            <>
              {' '}
              <HolidayResourceSchedule defaultStartDate={instance.start} />{' '}
            </>
          ),
          title: 'Holiday Schedule',
          nameAccessor: () => '',
          formId: 'holiday-schedule-edit',
        }}
        stateContainer={LeaveRequestsContainer}
        secondaryFilter={
          hasPermission(user, FULL_ACCESS_PERMISSION)
            ? TeamMemberFilter
            : undefined
        }
        filterComponent={Filters}
        footerComponent={TableFooter}
        pageFooter={PageFooter}
        bulkActions={[
          {
            key: 'delete',
            component: Delete,
            permission: RESOURCE_SCHEDULE_PERMISSION,
          },
        ]}
      />
    </LeaveRequestsContainer.Provider>
  ) : null;
};

export default LeaveRequestsTable;
