import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  SelectInputField,
  TextInputField,
} from '../../../components/forms/fields';
import { SelectOptions } from '../../../types/forms';
import ClientResource from '../../../api/resources/clients/ClientResource';
import useAPIRequest from '../../../hooks/useAPIRequest';
import { Client } from '../../clients/types';
import { PROJECT_CURRENCY_OPTIONS } from '../constants';
import ErrorMessage from '../../../components/ErrorMessage';
import { nameBGValidationpattern } from '../../../components/validationRules';

type Props = FormFieldsComponentTypeProps;

const CreateFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;
  const [clientOptions, setClientOptions] = useState<SelectOptions>([]);
  const { loading, data, error, performRequest: getClients } = useAPIRequest(
    ClientResource.list
  );

  useEffect(() => {
    getClients({ where: { archived: false } });
  }, [getClients]);

  useEffect(() => {
    if (data) {
      setClientOptions(
        data.data.map((instance: Client) => ({
          key: instance.id,
          value: instance.id,
          text: instance.name,
        }))
      );
    }
  }, [data]);
  return (
    <>
      <ErrorMessage error={error} />
      <Form.Group widths="equal">
        <TextInputField
          rules={{ required: { value: true, message: 'Please enter a name.' } }}
          label="Name"
          name="name"
          placeholder="Project name"
          {...formMethods}
        />
        <TextInputField
          label="Name (BG)"
          rules={{
            required: { value: true, message: 'Please enter a name.' },
            ...nameBGValidationpattern,
          }}
          name="nameBG"
          placeholder="Name (BG)"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <SelectInputField
          options={clientOptions}
          rules={{
            required: { value: true, message: 'Please select a client.' },
          }}
          name="clientId"
          label="Client"
          loading={loading}
          search
          placeholder="Client"
          {...formMethods}
        />
        <SelectInputField
          options={PROJECT_CURRENCY_OPTIONS}
          rules={{
            required: { value: true, message: 'Please select currency.' },
          }}
          name="currency"
          label="Currency"
          placeholder="Currency"
          search
          {...formMethods}
        />
      </Form.Group>
    </>
  );
};

export default CreateFormFields;
