export const HOME_MENU_ITEM = 'Home';
export const INVOICES_MENU_ITEM = 'Invoices';
export const PROPOSALS_MENU_ITEMS = 'Proposals';
export const PROJECTS_MENU_ITEM = 'Projects';
export const CLIENTS_MENU_ITEM = 'Clients';
export const TEAM_MENU_ITEM = 'Team';
export const TIMESHEETS_MENU_ITEM = 'Timesheets';
export const RESOURCE_SCHEDULE_MENU_ITEM = 'Resources';
export const PROJECT_SCHEDULE_MENU_ITEM = 'Flow';
export const PROJECT_SETTINGS_MENU_ITEM = 'Settings';
export const CUSTOMISE_MENU_ITEM = 'Customise';
export const STATS_MENU_ITEM = 'Stats';
export const SALARIES_MENU_ITEM = 'Salaries';
