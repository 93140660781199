import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

type Props = {
  clickable?: boolean;
} & React.ComponentProps<typeof Table.Row>;

const StyledTableRow = styled(({ clickable, ...rest }: Props) => (
  <Table.Row {...rest} />
))`
  ${({ clickable }) => clickable && `cursor: pointer`}
`;

const Row = ({ children, ...props }: Props) => {
  return <StyledTableRow {...props}>{children}</StyledTableRow>;
};

export default Row;
