import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { CONTACT_TITLE_OPTIONS } from '../constants';
import {
  EmailInputField,
  SelectInputField,
  TextInputField,
} from '../../../components/forms/fields';
import { nameBGValidationpattern } from '../../../components/validationRules';

type Props = {} & FormFieldsComponentTypeProps;

const ContactFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;

  return (
    <>
      <Form.Group widths="equal">
        <TextInputField
          rules={{ required: { value: true, message: 'Please enter a name.' } }}
          label="Name"
          name="name"
          placeholder="Name"
          {...formMethods}
        />
        <SelectInputField
          label="Title"
          options={CONTACT_TITLE_OPTIONS}
          name="title"
          placeholder="Title"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group>
        <TextInputField
          width="8"
          rules={{
            required: {
              value: true,
              message: 'Please enter a name in Bulgarian.',
            },
            ...nameBGValidationpattern,
          }}
          label="Name (BG)"
          name="nameBG"
          placeholder="Name (BG)"
          {...formMethods}
        />
        <TextInputField
          width="8"
          label="Position"
          name="position"
          placeholder="Position"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group>
        <EmailInputField
          width="8"
          label="Email"
          name="email"
          placeholder="Email"
          {...formMethods}
        />
      </Form.Group>
    </>
  );
};

export default ContactFormFields;
