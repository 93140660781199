import { MonthlySalariesFindByDateResponse } from '../types';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import useAPIRequest from '../../../hooks/useAPIRequest';
import MonthlySalariesResource from '../../../api/resources/salaries/MonthlySalariesResource';
import { Confirm } from '../../../components/dialogs';

const StyledLockButton = styled(Button)`
  &.ui.basic.button,
  &.ui.basic.button:focus,
  &.ui.basic.button:hover {
    box-shadow: none !important;
  }
`;

const LockAction = ({
  monthlySalaries,
  onLock,
}: {
  monthlySalaries: MonthlySalariesFindByDateResponse;
  onLock: () => void;
}) => {
  const { performRequest: lockSalaries } = useAPIRequest(
    MonthlySalariesResource.lock
  );

  const handleSubmit = async () => {
    try {
      await lockSalaries(monthlySalaries.monthlySalaries.id);
      onLock();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Confirm
      content={'Are you sure you want to lock the salaries for this month?'}
      trigger={
        <StyledLockButton
          basic
          icon="unlock"
          size="massive"
          inverted
          title="Lock the salaries"
        />
      }
      onSubmit={handleSubmit}
    />
  );
};

export default LockAction;
