import React from 'react';

export const themes = {
  light: 'light',
  dark: 'dark',
};

export const LOCAL_STORAGE_THEME_KEY = 'theme';
export const LOCAL_STORAGE_GRADIENT_KEY = 'gradientColor';
export const GRADIENT_DEFAULT_COLOR = 'rgba(0, 255, 123, 0.33)';

export const ThemeContext = React.createContext<{
  theme: string;
  toggleTheme: () => void;
  gradientMainColor: string;
  setGradientMainColor: (color: string) => void;
}>({
  theme: themes.dark,
  toggleTheme: () => {},
  gradientMainColor: GRADIENT_DEFAULT_COLOR,
  setGradientMainColor: () => {},
});
