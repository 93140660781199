import React, { RefObject } from 'react';
import { Modal as SemanticUIModal, ModalProps } from 'semantic-ui-react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

type Props = ModalProps & {
  draggableProps?: React.ComponentProps<typeof Draggable>;
  mountNode?: RefObject<HTMLDivElement | null>;
};

const Modal = (props: Props) => {
  const { draggableProps = {}, children, mountNode, ...rest } = props;

  const { onStart: onDragStart, ...draggableRestProps } = draggableProps;

  const handleDragStart = (event: DraggableEvent, data: DraggableData) => {
    event.stopPropagation();

    if (onDragStart) {
      onDragStart(event, data);
    }
  };

  return (
    <Draggable
      onStart={handleDragStart}
      handle=".handle"
      {...draggableRestProps}
    >
      <SemanticUIModal mountNode={mountNode?.current} {...rest}>
        {children}
      </SemanticUIModal>
    </Draggable>
  );
};

export default Modal;
