import React, { useContext } from 'react';
import { Tab } from 'semantic-ui-react';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';

type Props = {} & React.ComponentProps<typeof Tab.Pane>;

const TabPane = (props: Props) => {
  const { theme } = useContext(ThemeContext);

  const { children } = props;

  return (
    <Tab.Pane
      inverted={theme === themes.dark}
      style={{ border: 'none', padding: 0, ...props?.style }}
    >
      {children}
    </Tab.Pane>
  );
};

export default TabPane;
