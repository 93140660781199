import React from 'react';
import DownloadPDFAction, {
  PDFDocumentProps,
} from '../../../components/actions/DownloadPDFAction';
import { ProjectTimesheetWeekWithRelations } from '../types';
import {
  dateToString,
  default as DateFormatter,
  getWeek,
} from '../../../utils/dateUtils';
import { PDFStyles } from '../../projects/projectItems/DownloadPDFAction';
import logo from '../../../assets/logoblack_notext.png';
import { Id } from '../../../types/base';
import { TeamMember } from '../../team/types';
import { Project } from '../../projects/types';
import { PROJECT_STATUS_OPTIONS } from '../../projects/constants';
import { getProjectFullName } from '../../projects/utils';

type Props = {
  firstDay: Date;
  data: Omit<ProjectTimesheetWeekWithRelations, 'projectTask' | 'teamMember'>[];
  teamMembers: TeamMember[];
};

interface WeekTimesheetTableObject {
  dates: { [x: string]: number };
  teamMemberName: string;
  id: Id;
}

const DownloadPDFReport = (props: Props) => {
  const { firstDay, data, teamMembers } = props;

  const week = getWeek(firstDay);

  const tableData = data.reduce((acc: WeekTimesheetTableObject[], current) => {
    const existingObject = acc.find((obj) => obj.id === current.teamMemberId);
    if (existingObject) {
      if (current.timesheetEntries) {
        for (let entry of current.timesheetEntries) {
          existingObject.dates[entry.date] += Number(entry.hours);
        }
      }
      return acc;
    }

    const weekTimesheet: WeekTimesheetTableObject = {
      id: current.teamMemberId,
      dates: {},
      teamMemberName: '',
    };
    const teamMember = teamMembers.find(
      (teamMember) => teamMember.id === current.teamMemberId
    );

    if (teamMember) {
      weekTimesheet.teamMemberName = teamMember.name;
    }

    for (let day of week) {
      weekTimesheet.dates[dateToString(day)] = 0;
    }

    if (current.timesheetEntries) {
      for (let entry of current.timesheetEntries) {
        weekTimesheet.dates[entry.date.split('T')[0]] += Number(entry.hours);
      }
    }

    return [...acc, weekTimesheet];
  }, []);

  const projectTableData = data.reduce((acc: Project[], current) => {
    if (!acc.find((project) => project.id === current.projectId)) {
      return [...acc, current.project];
    }

    return acc;
  }, []);

  const projectsTableConfig = [
    {
      label: 'Name',
      accessor: (project: Project) => getProjectFullName(project),
    },
    {
      label: 'Status',
      accessor: (project: Project) =>
        PROJECT_STATUS_OPTIONS.find((status) => status.value === project.status)
          ?.text,
    },
    {
      label: 'Completed',
      accessor: (project: Project) => '0.00 %',
    },
    {
      label: 'Spent',
      accessor: (project: Project) => '0.00 %',
    },
    {
      label: 'Start Date',
      accessor: (project: Project) =>
        DateFormatter.format(new Date(project.startDate)),
    },
    {
      label: 'Deadline',
      accessor: (project: Project) =>
        project.deadline
          ? DateFormatter.format(new Date(project.deadline))
          : '',
    },
  ];

  const timesheetTableConfig = [
    {
      label: 'Team Member',
      accessor: 'teamMemberName' as const,
    },
  ];

  week.forEach((day) => {
    timesheetTableConfig.push({
      label: DateFormatter.format(day),
      // @ts-ignore
      accessor: ['dates', dateToString(day)],
      defaultValue: '0',
    });
  });

  teamMembers.forEach((teamMember) => {
    if (!tableData.find((member) => member.id === teamMember.id)) {
      tableData.push({
        id: teamMember.id,
        dates: {},
        teamMemberName: teamMember.name,
      });
    }
  });

  const pdfDocumentProps: PDFDocumentProps<WeekTimesheetTableObject> = {
    document: {
      pages: [
        {
          key: '01_timesheets-report-page',
          items: [
            {
              type: 'view',
              style: PDFStyles.header,
              content: [
                { type: 'image', style: PDFStyles.logo, src: logo },
                {
                  type: 'text',
                  style: PDFStyles.headerText,
                  content: 'Timesheets Report',
                },
              ],
            },
            {
              type: 'view',
              style: PDFStyles.container,
              content: [
                {
                  type: 'view',
                  style: {
                    marginTop: 5,
                  },
                  content: [
                    {
                      type: 'text',
                      style: { fontSize: 10, fontFamily: 'Open Sans Bold' },
                      content: `Period: ${DateFormatter.format(
                        firstDay
                      )} - ${DateFormatter.format(week[week.length - 1])}`,
                    },
                  ],
                },
                {
                  type: 'view',
                  style: PDFStyles.created,
                  content: [
                    {
                      type: 'text',
                      style: PDFStyles.textCreated,
                      content: `Created: ${DateFormatter.format(new Date())}`,
                    },
                  ],
                },
                {
                  type: 'table',
                  config: timesheetTableConfig,
                  data: tableData,
                },
                {
                  type: 'view',
                  style: {
                    marginTop: 5,
                  },
                  content: [
                    {
                      type: 'text',
                      style: {
                        fontSize: 9,
                        fontFamily: 'Open Sans Bold',
                        marginTop: 10,
                      },
                      content: 'Projects',
                    },
                  ],
                },
                {
                  type: 'table',
                  //@ts-ignore
                  config: projectsTableConfig,
                  //@ts-ignore
                  data: projectTableData,
                },
              ],
            },
          ],
        },
      ],
    },
  };

  return (
    <DownloadPDFAction
      filename={`${dateToString(firstDay)}-${dateToString(
        week[week.length - 1]
      )}_REPORT`}
      // @ts-ignore
      documentProps={pdfDocumentProps}
    />
  );
};

export default DownloadPDFReport;
