import React from 'react';
import { Id } from '../../../types/base';
import {
  invoiceDataEditFormActions,
  invoiceDataEditInstanceConfig,
} from './CompanyInvoiceDataTable';
import { CompanyInvoiceData } from '../types';
import EditableList from '../../../containers/EditableList';

type Props = {
  items?: CompanyInvoiceData[];
  handleEdit: (data: Partial<CompanyInvoiceData>, id: Id) => Promise<void>;
  reload: () => Promise<void>;
};

export const companyInvoiceDataListDescriptionAccessor = (
  invoiceData: CompanyInvoiceData
) => (
  <>
    {invoiceData.addressBG}, {invoiceData.cityBG}, {invoiceData.countryBG}
  </>
);

export const companyInvoiceDataListHeaderAccessor = (
  invoiceData: CompanyInvoiceData
) => `${invoiceData.nameBG} ${invoiceData.name && `(${invoiceData.name})`}`;

const CompanyInvoiceDataEditableList = ({
  items,
  handleEdit,
  reload,
}: Props) => {
  return (
    <EditableList
      items={items}
      editInstanceConfig={invoiceDataEditInstanceConfig}
      reload={reload}
      handleEdit={handleEdit}
      editFormActions={invoiceDataEditFormActions}
      noItemsMessage="No data"
      headerAccessor={companyInvoiceDataListHeaderAccessor}
      descriptionAccessor={companyInvoiceDataListDescriptionAccessor}
    />
  );
};

export default CompanyInvoiceDataEditableList;
