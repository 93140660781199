import { ProjectItem } from '../../projects/types';
import { formatBudgetCurrency } from '../../projects/projectItems/TableFooter';
import { getCurrencyUnit } from '../../projects/constants';
import { roundToTwo } from '../../../utils/numberUtils';
import React, { useEffect } from 'react';
import { ProjectItemsContainer } from './EventFormFields';
import ResourceTable from '../../../containers/resourceTable/ResourceTable';
import { Checkbox } from 'semantic-ui-react';

const FlowProjectItemsTable = ({
  addDefaultValues,
  projectItemIds = [],
  handleChange,
}: {
  addDefaultValues: boolean;
  projectItemIds: string[];
  handleChange: (ids: string[]) => void;
}) => {
  const container = ProjectItemsContainer.useContainer();
  useEffect(() => {
    if (container.data?.length && addDefaultValues) {
      handleChange(
        container?.data // @ts-ignore
          ?.filter((item) => item?.includedInBonus) // @ts-ignore
          .map((item) => item.id)
      );
    }
    // eslint-disable-next-line
  }, [container.data, addDefaultValues]);

  const config = [
    {
      label: '',
      key: 'includedInStage',
      accessor: (projectItem: ProjectItem) => {
        return (
          <Checkbox
            checked={projectItemIds.includes(projectItem.id)}
            onChange={(event, data) => {
              console.log(data);
              if (data.checked) {
                handleChange([...projectItemIds, projectItem.id]);
              } else {
                handleChange(
                  projectItemIds.filter((id) => id !== projectItem.id)
                );
              }
            }}
          />
        );
      },
      orderingField: null,
      cellWidth: '1' as const,
    },
    {
      label: 'Name',
      accessor: 'name' as const,
      key: 'name',
      orderingField: 'name',
      cellWidth: '7' as const,
    },
    {
      label: '# Items',
      accessor: (projectItem: ProjectItem) => projectItem.items,
      key: 'items',
      orderingField: null,
    },
    {
      label: 'Cost per Item',
      accessor: (projectItem: ProjectItem) => projectItem.costPerItem,
      key: 'costPerItem',
      orderingField: 'costPerItem',
    },
    {
      label: 'Subtotal',
      accessor: (projectItem: ProjectItem) =>
        formatBudgetCurrency(
          getCurrencyUnit(projectItem.projectCurrency),
          roundToTwo(+projectItem.items * +projectItem.costPerItem)
        ),
      key: 'subtotal',
      orderingField: null,
    },
  ];

  return (
    <ResourceTable
      tableId="invoice-project-items"
      addSearch={false}
      columns={config}
      stateContainer={ProjectItemsContainer}
    />
  );
};

export default FlowProjectItemsTable;
