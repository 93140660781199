import React from 'react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { TextInputField } from '../../../components/forms/fields';
import { Form } from 'semantic-ui-react';

type Props = FormFieldsComponentTypeProps;

const ProjectTaskFormFields = (props: Props) => {
  return (
    <>
      <Form.Group widths="equal">
        <TextInputField
          label="Name"
          name="name"
          rules={{
            required: { value: true, message: 'Please specify a name.' },
          }}
          {...props}
        />
      </Form.Group>
    </>
  );
};

export default ProjectTaskFormFields;
