import React, { useContext } from 'react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { TimesheetsContext } from '../TimesheetsTable';
import { SelectInputField } from '../../../components/forms/fields';
import { ProjectWithRelations } from '../../projects/types';
import { getProjectFullName } from '../../projects/utils';

type Props = FormFieldsComponentTypeProps;

const NewTimesheetEntryFormFields = (props: Props) => {
  const { projects, loadingProjects } = useContext(TimesheetsContext);
  const { setValue } = props;

  const options = projects
    ? projects
        .map((project: Omit<ProjectWithRelations, 'projectItems'>) => ({
          value: project.id,
          text: getProjectFullName(project),
          key: project.id,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    : [];

  const projectId = props.watch('projectId');
  const tasks = projects?.find((project) => project.id === projectId)
    ?.projectTasks;

  return (
    <>
      <SelectInputField
        options={options}
        label="Project"
        name="projectId"
        search
        loading={loadingProjects}
        rules={{
          required: { value: true, message: 'Please select a project' },
        }}
        onChange={(value) => {
          if (value) {
            const generalWorkTask =
              projects
                ?.find((project) => project.id === value)
                ?.projectTasks.find(
                  (projectTask) => projectTask.name === 'General work'
                )?.id || '';
            setValue('projectTaskId', generalWorkTask);
          }
        }}
        {...props}
      />
      {projectId && tasks && (
        <SelectInputField
          options={tasks
            .map((projectTask) => ({
              value: projectTask.id,
              text: projectTask.name,
              key: projectTask.id,
            }))
            .sort((a, b) => a.text.localeCompare(b.text))}
          clearable
          label="Project Task"
          name="projectTaskId"
          rules={{
            required: { value: true, message: 'Please select a project task' },
          }}
          defaultValue={
            tasks.find((projectTask) => projectTask.name === 'General work')?.id
          }
          {...props}
        />
      )}
    </>
  );
};

export default NewTimesheetEntryFormFields;
