import BaseNestedCRUDResource from '../../BaseNestedCRUDResource';
import Api from '../../Api';

class InvoiceInvoiceEntryResource extends BaseNestedCRUDResource {
  objectIdentifier = 'invoice-entries';
  parentIdentifier = 'invoices';

  reorder = (parentId: string, idList: string[]) => {
    return Api.client.patch(
      `${this.parentIdentifier}/${parentId}/${this.objectIdentifier}/reorder/`,
      idList
    );
  };
}

export default new InvoiceInvoiceEntryResource();
