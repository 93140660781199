import React from 'react';
import { ProjectSetting } from '../types';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import useAPIRequest from '../../../hooks/useAPIRequest';
import {
  PROJECT_ITEM_TYPES_IMAGES,
  PROJECT_ITEM_TYPES_OTHER,
  PROJECT_ITEM_TYPES_VIDEOS,
  PROJECT_NEGATIVES_TYPES,
} from '../keys';
import { Accordion } from 'semantic-ui-react';
import ProjectItemsSettingsPanel from './ProjectItemsSettingsPanel';
import ProjectBonusSetting from './ProjectBonusSetting';

type Props = {
  settings: ProjectSetting[];
  reloadSettings: () => Promise<void>;
  loading: boolean;
};

const ProjectsSettings = (props: Props) => {
  const { settings, reloadSettings, loading } = props;

  const {
    loading: updateLoading,
    performRequest: updateOrCreateSetting,
  } = useAPIRequest(ProjectSettingResource.updateOrCreate);

  const panels = [
    {
      key: 'projects-settings-items-images',
      title: 'Project Item Types: Images',
      content: {
        content: (
          <ProjectItemsSettingsPanel
            settingKey={PROJECT_ITEM_TYPES_IMAGES}
            settings={settings}
            loading={updateLoading}
            updateOrCreateSetting={updateOrCreateSetting}
            reloadSettings={reloadSettings}
          />
        ),
      },
    },
    {
      key: 'projects-settings-items-videos',
      title: 'Project Item Types: Videos',
      content: {
        content: (
          <ProjectItemsSettingsPanel
            settingKey={PROJECT_ITEM_TYPES_VIDEOS}
            settings={settings}
            loading={loading || updateLoading}
            updateOrCreateSetting={updateOrCreateSetting}
            reloadSettings={reloadSettings}
          />
        ),
      },
    },
    {
      key: 'projects-settings-items-other',
      title: 'Project Item Types: Other',
      content: {
        content: (
          <ProjectItemsSettingsPanel
            settingKey={PROJECT_ITEM_TYPES_OTHER}
            settings={settings}
            loading={loading || updateLoading}
            updateOrCreateSetting={updateOrCreateSetting}
            reloadSettings={reloadSettings}
          />
        ),
      },
    },
    {
      key: 'projects-settings-negatives',
      title: 'Negatives',
      content: {
        content: (
          <ProjectItemsSettingsPanel
            settingKey={PROJECT_NEGATIVES_TYPES}
            settings={settings}
            loading={loading || updateLoading}
            updateOrCreateSetting={updateOrCreateSetting}
            reloadSettings={reloadSettings}
          />
        ),
      },
    },
  ];

  return (
    <>
      <ProjectBonusSetting
        settings={settings}
        loading={updateLoading}
        updateOrCreateSetting={updateOrCreateSetting}
        reloadSettings={reloadSettings}
      />
      <Accordion.Accordion panels={panels} />
    </>
  );
};

export default ProjectsSettings;
