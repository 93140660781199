import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import Segment from '../../components/layout/Segment';

export const LoginPageWrapper = styled(Segment)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  overflow: hidden;
  flex-direction: column;
`;

export const FormSegment = styled(Segment)`
  max-width: 600px;
  min-width: 31%;
  margin: auto;
  flex-grow: 1;

  &.ui.segment {
    border: none;
    box-shadow: none;
  }

  & .ui.form {
    font-size: 1.2rem;
  }
`;

export const LogoWrapper = styled.div`
  margin-top: 13vh;
  margin-bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginButton = styled(Button)`
  &.ui.button {
    display: block;
    margin: auto;
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
`;

export const LoginPageFooter = styled.div`
  height: 45%;
  
  @media screen and (max-width: 502px) {
    position: relative;
    width: 100%;
    
    & img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  
  @media screen and (max-width: 920px) and (orientation: landscape) {
    display: none;
  }
}
`;
