import React, { RefObject } from 'react';
import { Button } from 'semantic-ui-react';
import { TeamMember } from '../types';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';
import Confirm from '../../../components/dialogs/Confirm';

type Props = {
  selected: TeamMember | TeamMember[];
  reload: () => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const Activate = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const handleActivate = async () => {
    if (Array.isArray(selected)) {
      const filters = {
        where: { or: selected.map((teamMember) => ({ id: teamMember.id })) },
      };
      await TeamMemberResource.updateAll({ archived: false }, filters);
      await reload();
    } else {
      await TeamMemberResource.updateById(selected.id, { archived: false });
      await reload();
    }
  };

  return (
    <Confirm
      content={
        Array.isArray(selected) && selected.length > 1
          ? 'Are you sure you want to activate the team members?'
          : 'Are you sure you want to activate the team member?'
      }
      trigger={
        <Button
          positive
          content="Activate"
          size={Array.isArray(selected) ? 'huge' : 'large'}
          className="bulk-action"
        />
      }
      onSubmit={handleActivate}
      mountNode={mountNode}
    />
  );
};

export default Activate;
