import React, { useContext, useEffect, useState } from 'react';
import { Dimmer, Loader as SemanticUILoader } from 'semantic-ui-react';
import { ThemeContext, themes } from '../contexts/theme/ThemeContext';

type Props = {
  active?: boolean;
};

const Loader = ({ active = true }: Props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    let timer: number;
    if (active) {
      timer = setTimeout(() => setShowSpinner(true), 200);
    }
    return () => clearTimeout(timer);
  }, [active]);

  return showSpinner ? (
    <Dimmer active={active} inverted={theme === themes.light}>
      <SemanticUILoader content="Loading" inverted={theme === themes.light} />
    </Dimmer>
  ) : null;
};

export default Loader;
