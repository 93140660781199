import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  DateInputField,
  SelectInputField,
  TextInputField,
  TextAreaField,
  ColorPickerField,
} from '../../../components/forms/fields';

const REMINDER_OPTIONS = [
  { key: 'null', value: 0, text: 'None' },
  { key: '30', value: 30, text: '30 minutes before start' },
  { key: '60', value: 60, text: '1 hour before start' },
  { key: '180', value: 180, text: '3 hours before start' },
  { key: '360', value: 360, text: '6 hours before start' },
  { key: '1440', value: 1440, text: '1 day before start' },
];

const MilestoneFormFields = (props: FormFieldsComponentTypeProps) => {
  return (
    <>
      <Form.Group>
        <TextInputField
          label="Name"
          name="name"
          placeholder="Name"
          width="8"
          rules={{
            required: { value: true, message: 'Please enter a name.' },
          }}
          {...props}
        />
        <SelectInputField
          options={REMINDER_OPTIONS}
          label="Reminder"
          name="reminder"
          defaultValue={0}
          {...props}
          width="8"
        />
      </Form.Group>
      <Form.Group>
        <DateInputField
          label="From"
          name="datetime"
          placeholder="When"
          rules={{
            required: { value: true, message: 'Please enter a date.' },
          }}
          {...props}
        />
        <DateInputField
          label="To"
          name="endDatetime"
          placeholder="When"
          rules={{
            required: { value: true, message: 'Please enter a date.' },
          }}
          {...props}
        />
      </Form.Group>
      <Form.Group>
        <ColorPickerField
          label="Color"
          width="8"
          name="color"
          defaultValue={'#b3b3b3'}
          {...props}
        />
      </Form.Group>
      <Form.Group>
        <TextAreaField width="16" label="Note" name="note" {...props} />
      </Form.Group>
    </>
  );
};

export default MilestoneFormFields;
