import React from 'react';
import { Id } from '../../../types/base';
import { ClientContact } from '../types';
import {
  clientContactEditFormActions,
  clientContactEditInstanceConfig,
} from './ContactsTable';
import EditableList from '../../../containers/EditableList';
import MailTo from '../../../components/tables/MailTo';

type Props = {
  items?: ClientContact[];
  handleEdit: (data: Partial<ClientContact>, id: Id) => Promise<void>;
  reload: () => Promise<void>;
};

export const clientContactListDescriptionAccessor = (
  contact: ClientContact
) => (
  <>
    {contact.position}
    {contact.position && contact.email ? ', ' : ''}
    {contact.email && <MailTo email={contact.email} label={contact.email} />}
  </>
);

export const clientContactListHeaderAccessor = (contact: ClientContact) =>
  `${contact.title ? contact.title + ' ' : ''}${contact.name} ${
    contact.nameBG ? `(${contact.nameBG})` : ''
  }`;

const ClientContactsEditableList = ({ items, handleEdit, reload }: Props) => {
  return (
    <EditableList
      items={items}
      editInstanceConfig={clientContactEditInstanceConfig}
      reload={reload}
      handleEdit={handleEdit}
      editFormActions={clientContactEditFormActions}
      noItemsMessage="No contacts"
      headerAccessor={clientContactListHeaderAccessor}
      descriptionAccessor={clientContactListDescriptionAccessor}
    />
  );
};

export default ClientContactsEditableList;
