import React, { useContext } from 'react';
import ProjectProjectItemResource from '../../api/resources/projects/ProjectProjectItemResource';
import { Project } from './types';
import ProjectProjectTasksResource from '../../api/resources/projects/ProjectProjectTasksResource';
import useNestedCRUDResource from '../../hooks/useNestedCrudResource';
import { createContainer } from 'unstated-next';
import Heading from '../../components/layout/Heading';
import Segment from '../../components/layout/Segment';
import ProjectItemsTable from './projectItems/Table';
import ProjectTasksTable from './projectTasks/Table';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import { Accordion } from 'semantic-ui-react';
import EditNoteFormFields from './forms/EditNoteFormFields';

export const ProjectItemsContainer = createContainer(useNestedCRUDResource);
export const ProjectTasksContainer = createContainer(useNestedCRUDResource);

function equalProps(
  prevProps: { instance: Project },
  nextProps: { instance: Project }
) {
  return (
    prevProps.instance.currency === nextProps.instance.currency &&
    prevProps.instance.id === nextProps.instance.id
  );
}

const ProjectItemsAndTasks = React.memo(
  ({
    instance,
    onItemsReload,
  }: {
    instance: Project;
    onItemsReload?: () => void;
  }) => {
    const { theme } = useContext(ThemeContext);
    if (instance.isInHouse) {
      return null;
    }
    return (
      <>
        <ProjectItemsContainer.Provider
          initialState={{
            resource: ProjectProjectItemResource,
            parentId: instance.id,
            searchFields: ['name'],
            defaultOrder: ['createDate ASC', 'name ASC', 'costPerItem ASC'],
          }}
        >
          <ProjectTasksContainer.Provider
            initialState={{
              resource: ProjectProjectTasksResource,
              parentId: instance.id,
              searchFields: ['name'],
              defaultOrder: 'name ASC',
            }}
          >
            <Accordion
              defaultActiveIndex={[0, 1]}
              exclusive={false}
              inverted={theme === themes.dark}
              panels={[
                {
                  key: 'project-items',
                  title: {
                    content: (
                      <Heading as="h5" style={{ display: 'inline-block' }}>
                        Items & Expenses{' '}
                        <span style={{ fontStyle: 'italic' }}>
                          (All amounts are excluding VAT)
                        </span>
                      </Heading>
                    ),
                  },
                  content: {
                    content: (
                      <Segment style={{ padding: 0 }}>
                        <ProjectItemsTable
                          projectId={instance.id}
                          projectCurrency={instance.currency}
                          onReload={onItemsReload}
                        />
                      </Segment>
                    ),
                  },
                },
                {
                  key: 'project-note',
                  title: {
                    content: (
                      <Heading as="h5" style={{ display: 'inline-block' }}>
                        Note{' '}
                      </Heading>
                    ),
                  },
                  content: {
                    content: <EditNoteFormFields instance={instance} />,
                  },
                },
                {
                  key: 'project-tasks',
                  title: {
                    content: (
                      <Heading as="h5" style={{ display: 'inline-block' }}>
                        Tasks{' '}
                        <span style={{ fontStyle: 'italic' }}>
                          (All amounts are excluding VAT)
                        </span>
                      </Heading>
                    ),
                  },
                  content: {
                    content: (
                      <Segment style={{ padding: 0 }}>
                        <ProjectTasksTable />
                      </Segment>
                    ),
                  },
                },
              ]}
            />
          </ProjectTasksContainer.Provider>
        </ProjectItemsContainer.Provider>
      </>
    );
  },
  equalProps
);

export default ProjectItemsAndTasks;
