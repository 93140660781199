import React, { useContext } from 'react';
import { InvoiceEntry } from '../types';
import { formatBudgetCurrency } from '../../projects/projectItems/TableFooter';
import Row from '../../../components/tables/Row';
import Cell from '../../../components/tables/Cell';
import Decimal from 'decimal.js-light';
import { InvoiceContext } from './Table';
import { getCurrencyUnit } from '../../projects/constants';

type Props = {
  items: InvoiceEntry[];
  subset: boolean;
};

const TableFooter = (props: Props) => {
  const { items, subset } = props;

  const amount = items.reduce((acc, curr) => {
    return acc.add(new Decimal(curr.net || 0));
  }, new Decimal(0));

  const { currency: invoiceCurrency } = useContext(InvoiceContext);

  return (
    <>
      {items.length ? (
        <Row>
          <Cell />
          <Cell colSpan={2}>
            <strong>{subset ? 'Selected Amount' : 'Total Amount'}</strong>{' '}
          </Cell>
          <Cell />
          <Cell />
          <Cell />
          <Cell>
            <strong>
              {formatBudgetCurrency(
                getCurrencyUnit(invoiceCurrency),
                amount.toNumber()
              )}
            </strong>
          </Cell>
        </Row>
      ) : null}
    </>
  );
};

export default TableFooter;
